/** @format */

import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Layout, Menu } from "antd";
import MenuItems from "./MenuItems";
import "../../../assets/style/css/App.css";

const { Sider } = Layout;

const SiderMenuLayout: React.FC<any> = (props: any) => {
  const {
    setCurrentPage,
    setMenuItems,
    setMenuTitle,
    selectedKeys,
    siderKey,
    handleMenuSelect,
  } = props;
  const hideSideMenu = useMediaQuery({ query: "(max-width: 700px)" });
  const [collapsed, setCollapsed] = useState(true);
  const items = MenuItems({
    collapsed,
    setCurrentPage,
    setMenuItems,
    setMenuTitle,
    handleMenuSelect,
  });

  // Function to handle menu item selection

  return (
    <Sider
      key={siderKey}
      width="auto"
      collapsed={!hideSideMenu && collapsed}
      onCollapse={(value) => setCollapsed(value)}
      theme="light"
      className="gradient-bg"
      style={
        hideSideMenu
          ? { position: "absolute" }
          : {
              overflow: collapsed ? "hidden" : "auto",
              minHeight: "auto",
              background: "linear-gradient(to bottom, #0056A6, #00C2E6)",
            }
      }
    >
      <Menu
        theme="light"
        mode="inline"
        items={items}
        selectedKeys={selectedKeys}
        onClick={(e) =>
          setCollapsed(
            e.key === "0" ? !collapsed : collapsed ? collapsed : !collapsed
          )
        }
        style={
          hideSideMenu
            ? {
                color: "#606060",
                position: "fixed",
                top: "70px",
                bottom: 0,
                zIndex: 1001,
                transition: "transform 200ms",
                transform: collapsed ? "translateX(0)" : "translateX(-100%)",
                overflow: "hidden",
              }
            : {
                color: "#606060",
                backgroundColor: "transparent",
                marginInline: "12px",
              }
        }
      />
    </Sider>
  );
};

export default SiderMenuLayout;
