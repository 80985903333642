import React, { useContext, useEffect } from "react";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { WORK_MODE } from "../../../../../constants/workModes";
import { DATA_NEEDED_TYPE } from "../../../../../constants/DataNeededTypes";
import { SharedContext } from "../../context/sharedContext";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../utils/transaltion";
import { selectFilterOption } from "../../../../../utils/functions";

const SettingsActionDynamicFields: React.FC<{
  workMode: string;
  modeDynamicFields: string;
}> = ({ workMode }) => {
  /**translation*/
  const { t } = useTranslation();
  const {
    dynamicFieldsSettings,
    setDynamicFieldsSettings,
    settingsChoicesList,
  } = useContext(SharedContext);
  const [form] = Form.useForm();
  const handleInputChange = (key: number, field: string, value: any) => {
    // Update the dynamicFields array based on the changed value
    let updatedFields: any;

    if (field === DATA_NEEDED_TYPE.DATE) {
      updatedFields = dynamicFieldsSettings.map((fieldItem) =>
        fieldItem.key === key
          ? { ...fieldItem, [field]: dayjs(value) }
          : fieldItem
      );
    } else {
      updatedFields = dynamicFieldsSettings.map((fieldItem) =>
        fieldItem.key === key ? { ...fieldItem, [field]: value } : fieldItem
      );
      if (field === "type") {
        if (value === DATA_NEEDED_TYPE.BOOLEAN) {
          // Set default value to "true" for boolean type
          updatedFields = updatedFields.map((fieldItem: any) =>
            fieldItem.key === key
              ? { ...fieldItem, defaultValue: "true" }
              : fieldItem
          );
        } else if (value === DATA_NEEDED_TYPE.DATE) {
          const formattedDate = dayjs(); //
          form.setFieldsValue({ [`Value${key}`]: formattedDate }); // Set default value to current date for date type
          updatedFields = updatedFields.map((fieldItem: any) =>
            fieldItem.key === key
              ? {
                  ...fieldItem,
                  defaultValue: formattedDate,
                }
              : fieldItem
          );
        }
      }
    }

    setDynamicFieldsSettings(updatedFields);
  };

  useEffect(() => {
    if (dynamicFieldsSettings?.length > 0) {
      const dynamicFieldName = Object.fromEntries(
        dynamicFieldsSettings?.map((field: any) => [
          `Name${field.key}`,
          getTranslation(field?.name || "", "data"),
        ])
      );
      const dynamicFieldSuffix = Object.fromEntries(
        dynamicFieldsSettings?.map((field: any) => [
          `Suffix${field.key}`,
          field.suffix || "",
        ])
      );
      const dynamicFieldTypeValues = Object.fromEntries(
        dynamicFieldsSettings?.map((field: any) => [
          `Type${field.key}`,
          field.type || DATA_NEEDED_TYPE.STRING,
        ])
      );
      const dynamicFieldChoiceValues = Object.fromEntries(
        dynamicFieldsSettings.map((field: any) => [
          `choice${field.key}`,
          field.choice || false,
        ])
      );

      const dynamicFieldValues = Object.fromEntries(
        dynamicFieldsSettings?.map((field: any) => [
          `Value${field.key}`,
          field.type === DATA_NEEDED_TYPE.DATE
            ? field.defaultValue
              ? dayjs(field.defaultValue)
              : undefined
            : field.choice === true
            ? getTranslation(field?.defaultValue || "", "data")
            : field?.defaultValue || undefined,
        ])
      );
      form.setFieldsValue({
        ...dynamicFieldName,
        ...dynamicFieldSuffix,
        ...dynamicFieldTypeValues,
        ...dynamicFieldValues,
        ...dynamicFieldChoiceValues,
      });
    }
  }, [dynamicFieldsSettings]);
  return (
    <>
      <Form form={form}>
        {dynamicFieldsSettings?.map((field, index) => (
          <Row key={field.key} gutter={16}>
            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
              <Form.Item
                {...{ labelCol: { style: { width: 65 } } }}
                name={`Name${field.key}`}
                label={`${t("NAME_FIELD")}${index + 1}`}
                rules={[
                  {
                    validator: (_, value) => {
                      const formValues = form.getFieldsValue();
                      const dataNameValues = Object.entries(formValues)
                        .filter(
                          ([key, val]) =>
                            key.startsWith("Name") &&
                            key !== `Name${field.key}` &&
                            val !== ""
                        )
                        .map(([key, val]) => val);

                      if (dataNameValues.includes(value)) {
                        return Promise.reject(t("NAME_USED"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  disabled={true}
                  onChange={(e) => {
                    handleInputChange(field.key, "name", e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
              <Form.Item
                {...{ labelCol: { style: { width: 105 } } }}
                name={`Value${field.key}`}
                label={`${t("VALUE")}${index + 1}`}
              >
                {(field?.type === DATA_NEEDED_TYPE.STRING || !field?.type) &&
                  field.choice === true &&
                  !(
                    !settingsChoicesList ||
                    Object.keys(settingsChoicesList).length === 0
                  ) &&
                  field.suffix &&
                  settingsChoicesList[field.suffix] && (
                    <Select
                      disabled={workMode === WORK_MODE.VIEW}
                      filterOption={(input, option) =>
                        selectFilterOption(input, option)
                      }
                      onChange={(value) => {
                        handleInputChange(field.key, "defaultValue", value);
                      }}
                      value={
                        field?.defaultValue
                          ? String(field.defaultValue)
                          : undefined
                      }
                      defaultValue={
                        field?.defaultValue
                          ? String(field.defaultValue)
                          : undefined
                      }
                    >
                      {field.suffix &&
                        settingsChoicesList[field.suffix]?.map(
                          (choice: any) => (
                            <Select.Option key={choice} value={choice}>
                              {getTranslation(choice, "data")}
                            </Select.Option>
                          )
                        )}
                    </Select>
                  )}
                {(field?.type === DATA_NEEDED_TYPE.STRING || !field?.type) &&
                  field.suffix &&
                  !settingsChoicesList[field.suffix] && (
                    <Input
                      disabled={workMode === WORK_MODE.VIEW}
                      value={
                        field?.defaultValue ? String(field.defaultValue) : ""
                      }
                      defaultValue={
                        field?.defaultValue ? String(field.defaultValue) : ""
                      }
                      onChange={(e) => {
                        handleInputChange(
                          field.key,
                          "defaultValue",
                          e.target.value
                        );
                      }}
                    />
                  )}
                {(field?.type === DATA_NEEDED_TYPE.STRING || !field?.type) &&
                  field.suffix &&
                  field.choice === false &&
                  !(
                    !settingsChoicesList ||
                    Object.keys(settingsChoicesList).length === 0
                  ) &&
                  field.suffix &&
                  settingsChoicesList[field.suffix] && (
                    <Input
                      disabled={workMode === WORK_MODE.VIEW}
                      value={
                        field?.defaultValue ? String(field.defaultValue) : ""
                      }
                      defaultValue={
                        field?.defaultValue ? String(field.defaultValue) : ""
                      }
                      onChange={(e) => {
                        handleInputChange(
                          field.key,
                          "defaultValue",
                          e.target.value
                        );
                      }}
                    />
                  )}
                {field?.type === DATA_NEEDED_TYPE.NUMBER && (
                  <Input
                    type="number"
                    disabled={field?.choice}
                    value={field?.defaultValue ? Number(field.defaultValue) : 0}
                    defaultValue={
                      field?.defaultValue ? Number(field.defaultValue) : 0
                    }
                    onChange={(e) => {
                      handleInputChange(
                        field.key,
                        "defaultValue",
                        Number(e.target.value)
                      );
                    }}
                  />
                )}
                {field?.type === DATA_NEEDED_TYPE.BOOLEAN && (
                  <Select
                    disabled={field?.choice}
                    value={
                      field?.defaultValue ? String(field.defaultValue) : "true"
                    }
                    defaultValue={"true"}
                    onChange={(value) => {
                      handleInputChange(field.key, "defaultValue", value);
                    }}
                  >
                    <Select.Option value="true">True</Select.Option>
                    <Select.Option value="false">False</Select.Option>
                  </Select>
                )}
                {field?.type === DATA_NEEDED_TYPE.DATE && (
                  <DatePicker
                    disabled={field?.choice}
                    style={{ width: "95%" }}
                    disabledDate={(current) => {
                      return (
                        current &&
                        dayjs(current).isBefore(dayjs().startOf("day"))
                      );
                    }}
                    showTime={{ format: "HH:mm:ss" }}
                    format={"YYYY-MM-DDTHH:mm:ssZ"}
                    onChange={(value) => {
                      handleInputChange(
                        field.key,
                        "defaultValue",
                        value ? value.toISOString() : undefined
                      );
                    }}
                    value={
                      field.defaultValue ? dayjs(field.defaultValue) : undefined
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        ))}
      </Form>
    </>
  );
};

export default SettingsActionDynamicFields;
