import { Input, Typography, Row, Col } from "antd";
import { checkAlphanumericType, isNegative } from "../../../../utils/functions";
import "dayjs/locale/en";
import {
  checkTypeListAttribute,
  formatValueBasedOnType,
  transformAttributesInputChartToCheckableList,
} from "../../../../utils/datatypesFunctions";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../utils/transaltion";
import { useEffect } from "react";

function MenuCollapseContentInputs(props: any) {
  const { t } = useTranslation();
  const { inputList } = props;
  const {
    formatNmber,
    currency,
    formatDate,
    dataNeededAttributes,
    orgActionSettings,
    confsActionSettings,
  } = props;

  return inputList ? (
    <Row
      style={{
        flexDirection: "row",
        marginBottom: 15,
        marginTop: 10,
      }}
      gutter={[10, 10]}
    >
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Row gutter={[10, 10]}>
          {inputList?.map((input: any, index: number) => (
            <Col xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
              <Typography className="drawer-typography">
                {getTranslation(input.AttributeName, "data")}
              </Typography>
              <Input
                key={input.AttributeName}
                value={input?.value}
                style={{
                  color: isNegative(input?.value) ? "#EE2737" : "inherit",
                  textAlign:
                    checkAlphanumericType(input.value) === "left"
                      ? "left"
                      : "right",
                }}
                readOnly
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  ) : null;
}

export default MenuCollapseContentInputs;
