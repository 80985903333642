import { ProColumns } from "@ant-design/pro-components";
import { getTranslation } from "../../../../utils/transaltion";
import { DATA_NEEDED_TYPE } from "../../../../constants/DataNeededTypes";

const DefineDynamicColumns = ({
  columnsList,
}: {
  columnsList: { name: string; type: string }[];
}) => {
  const dynamicColumns: ProColumns<any>[] = columnsList.map((column) => {
    const { name, type } = column;
    let columnDefinition: ProColumns<any> = {
      title: getTranslation(name, "data"),
      dataIndex: name,
      hideInTable: true,
    };

    // Customize column based on type
    if (type === DATA_NEEDED_TYPE.DATE || type === DATA_NEEDED_TYPE.DATETIME) {
      columnDefinition.valueType = "dateRange";
      columnDefinition.search = {
        transform: (value: [Date, Date]) => {
          if (value) {
            const formattedStartDate = new Date(value[0]);
            const formattedEndDate = new Date(value[1]);
            return {
              [name]: {
                value: [
                  formattedStartDate.toISOString(),
                  formattedEndDate.toISOString(),
                ],
                operator: "range",
              },
            };
          } else {
            return null;
          }
        },
      };
    } else if (
      type === DATA_NEEDED_TYPE.NUMBER ||
      type === DATA_NEEDED_TYPE.AMOUNT
    ) {
      columnDefinition.valueType = "digitRange";
      columnDefinition.search = {
        transform: (value: [number, number]) => {
          if (value) {
            return {
              [name]: {
                value: [value[0], value[1]],
                operator: "range",
              },
            };
          } else {
            return null;
          }
        },
      };
    } else {
      if (type === DATA_NEEDED_TYPE.STRING) {
        columnDefinition.valueType = "text";
        columnDefinition.search = {
          transform: (value) => {
            return {
              [name]: {
                value: value,
                operator: "HAS",
              },
            };
          },
        };
      } else {
        columnDefinition.valueType = "text";
      }
    }

    return columnDefinition;
  });
  return dynamicColumns;
};

export default DefineDynamicColumns;
