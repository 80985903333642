import { Line } from "@ant-design/plots";
import EmptyChartCard from "../../../common/EmptyChart";

const AcceptanceRateOverTime = (props: any) => {
  let { data } = props;
  if (data.length === 0) {
    return <EmptyChartCard />;
  }
  const config = {
    data,
    xField: "risk_date",
    yField: "value",
    connectNulls: true,
    yAxis: {
      min: 0,
    },
    tooltip: {
      shared: true,
      showMarkers: true,
      formatter: ({ risk_date, value }: any) => ({
        name: risk_date,
        value: value ? `${value?.toFixed(2)} %` : "Not set",
      }),
    },
  };

  return <Line {...config} height={300} />;
};

export default AcceptanceRateOverTime;
