export const mapRiskStatusByExecutionStage = (data: any) => {
  const mappedData = data
    ?.map((obj: any) => {
      return {
        action_type: obj.action_type,
        execution_stage: obj.execution_stage || "No execution stage",
      };
    })
    ?.reduce((accumulator: any, item: any) => {
      const { action_type, execution_stage } = item;
      const key = `${action_type}_${execution_stage}`;

      if (!accumulator[key]) {
        accumulator[key] = { action_type, execution_stage, value: 0 };
      }

      accumulator[key].value += 1;

      return accumulator;
    }, {});
  return Object.values(mappedData);
};
