export function hexToRgba(hex: string, opacity: number) {
  // Remove the leading '#' if present
  hex = hex.replace(/^#/, "");

  // Convert 3-digit hex to 6-digit hex
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  // Extract the red, green, and blue components
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Return the rgba color string
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
