export const procurement_data = [
  {
    running_risk_id: 100,
    risk_date: 293 + 45275,
    risk_status: "Open",
    risk_location: 101,
    risk_item: "SKU-233",
    quantity_at_risk: 23392,
    revenue_at_risk: 489202,
    action_type: "Alternate Supplier",
    quantity_mitigated: 20819,
    revenue_mitigated: 435392.2895861833,
    purchase_cost: 66412.61,
    freight_cost: 6037.51,
    cost_impact: 28980.048,
  },
  {
    running_risk_id: 101,
    risk_date: 293 + 45277,
    risk_status: "In progress",
    risk_location: 101,
    risk_item: "SKU-492",
    quantity_at_risk: 44360,
    revenue_at_risk: 488694,
    action_type: "Alternate Supplier",
    execution_stage: "Vendor validation",
    quantity_mitigated: 37263,
    revenue_mitigated: 410509.56992786296,
    purchase_cost: 159858.27,
    freight_cost: 8943.12,
    cost_impact: 60768.50039999999,
  },
  {
    running_risk_id: 102,
    risk_date: 293 + 45277,
    risk_status: "In progress",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 12195,
    revenue_at_risk: 243936,
    action_type: "Alternate Supplier",
    execution_stage: "Freight",
    quantity_mitigated: 11464,
    revenue_mitigated: 229313.84206642068,
    purchase_cost: 43448.56,
    freight_cost: 3095.28,
    cost_impact: 15824.905599999998,
  },
  {
    running_risk_id: 103,
    risk_date: 293 + 45231,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 105,
    risk_item: "SKU-839",
    quantity_at_risk: 3948,
    revenue_at_risk: 587708,
    action_type: "Alternate Supplier",
    execution_stage: "Freight",
    quantity_mitigated: 3159,
    revenue_mitigated: 470255.717325228,
    purchase_cost: 12951.9,
    freight_cost: 947.7,
    cost_impact: 4864.86,
  },
  {
    running_risk_id: 118,
    risk_date: 293 + 45247,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 3647,
    revenue_at_risk: 380319,
    action_type: "Alternate Supplier",
    execution_stage: "Freight",
    quantity_mitigated: 3319,
    revenue_mitigated: 346114.27502056485,
    purchase_cost: 12545.82,
    freight_cost: 730.18,
    cost_impact: 4779.36,
  },
  {
    running_risk_id: 119,
    risk_date: 293 + 45247,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-241",
    quantity_at_risk: 12462,
    revenue_at_risk: 574818,
    action_type: "Alternate Supplier",
    execution_stage: "Freight",
    quantity_mitigated: 12338,
    revenue_mitigated: 569098.4179104478,
    purchase_cost: 50092.28,
    freight_cost: 1850.7,
    cost_impact: 20257.762199999997,
  },
  {
    running_risk_id: 120,
    risk_date: 293 + 45249,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-624",
    quantity_at_risk: 5138,
    revenue_at_risk: 131339,
    action_type: "Alternate Supplier",
    execution_stage: "Freight",
    quantity_mitigated: 4882,
    revenue_mitigated: 124795.05605293889,
    purchase_cost: 22701.3,
    freight_cost: 585.84,
    cost_impact: 9314.856,
  },
  {
    running_risk_id: 121,
    risk_date: 293 + 45251,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-233",
    quantity_at_risk: 20113,
    revenue_at_risk: 356576,
    action_type: "Alternate Supplier",
    execution_stage: "Freight",
    quantity_mitigated: 17700,
    revenue_mitigated: 313796.8080346045,
    purchase_cost: 84960,
    freight_cost: 3009,
    cost_impact: 34307.91,
  },
  {
    running_risk_id: 125,
    risk_date: 293 + 45257,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 16980,
    revenue_at_risk: 289836,
    action_type: "Alternate Supplier",
    execution_stage: "Freight",
    quantity_mitigated: 14264,
    revenue_mitigated: 243475.89540636042,
    purchase_cost: 40795.04,
    freight_cost: 2710.16,
    cost_impact: 16531.976,
  },
  {
    running_risk_id: 126,
    risk_date: 293 + 45260,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 1794,
    revenue_at_risk: 25826,
    action_type: "Alternate Supplier",
    execution_stage: "Freight",
    quantity_mitigated: 1615,
    revenue_mitigated: 23249.158305462654,
    purchase_cost: 6185.45,
    freight_cost: 145.35,
    cost_impact: 1962.5480000000002,
  },
  {
    running_risk_id: 141,
    risk_date: 293 + 45252,
    risk_status: "Rejected",
    rejection_code: "Limited supplier inventory",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-135",
    quantity_at_risk: 16573,
    revenue_at_risk: 174552,
    action_type: "Alternate Supplier",
    execution_stage: "Vendor validation",
    quantity_mitigated: 13259,
    revenue_mitigated: 139647.9193869547,
    purchase_cost: 56218.16,
    freight_cost: 3182.16,
    cost_impact: 23760.128000000004,
  },
  {
    running_risk_id: 142,
    risk_date: 293 + 45254,
    risk_status: "Rejected",
    rejection_code: "Limited supplier inventory",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 4772,
    revenue_at_risk: 139101,
    action_type: "Alternate Supplier",
    execution_stage: "Vendor validation",
    quantity_mitigated: 3866,
    revenue_mitigated: 112691.63160100587,
    purchase_cost: 17242.36,
    freight_cost: 463.92,
    cost_impact: 6905.449199999999,
  },
  {
    running_risk_id: 143,
    risk_date: 293 + 45238,
    risk_status: "Rejected",
    rejection_code: "Freight cost budget constraint",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-241",
    quantity_at_risk: 14112,
    revenue_at_risk: 469291,
    action_type: "Alternate Supplier",
    execution_stage: "Freight",
    quantity_mitigated: 11713,
    revenue_mitigated: 389512.8601899093,
    purchase_cost: 51302.94,
    freight_cost: 1756.95,
    cost_impact: 18040.3626,
  },
  {
    running_risk_id: 144,
    risk_date: 293 + 45241,
    risk_status: "Rejected",
    rejection_code: "Freight cost budget constraint",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 4481,
    revenue_at_risk: 508074,
    action_type: "Alternate Supplier",
    execution_stage: "Freight",
    quantity_mitigated: 4078,
    revenue_mitigated: 462380.22137915646,
    purchase_cost: 17249.94,
    freight_cost: 1264.18,
    cost_impact: 7035.365599999999,
  },
  {
    running_risk_id: 145,
    risk_date: 293 + 45244,
    risk_status: "Rejected",
    rejection_code: "Freight cost budget constraint",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-241",
    quantity_at_risk: 13908,
    revenue_at_risk: 475462,
    action_type: "Alternate Supplier",
    execution_stage: "Freight",
    quantity_mitigated: 13491,
    revenue_mitigated: 461206.3446937015,
    purchase_cost: 60979.32,
    freight_cost: 2563.29,
    cost_impact: 20333.6352,
  },
  {
    running_risk_id: 146,
    risk_date: 293 + 45253,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 245,
    risk_item: "SKU-241",
    quantity_at_risk: 16101,
    revenue_at_risk: 469173,
    action_type: "Alternate Supplier",
    execution_stage: "Freight",
    quantity_mitigated: 15940,
    revenue_mitigated: 464481.5613937022,
    purchase_cost: 73324,
    freight_cost: 5100.8,
    cost_impact: 24311.688000000002,
  },
  {
    running_risk_id: 147,
    risk_date: 293 + 45266,
    risk_status: "Open",
    risk_location: 105,
    risk_item: "SKU-839",
    quantity_at_risk: 4063,
    revenue_at_risk: 138274,
    action_type: "Substitution",
    quantity_mitigated: 3657,
    revenue_mitigated: 124456.80974649274,
    purchase_cost: 17041.62,
    freight_cost: 877.68,
    cost_impact: 6271.755,
  },
  {
    running_risk_id: 148,
    risk_date: 293 + 45266,
    risk_status: "In progress",
    risk_location: 101,
    risk_item: "SKU-233",
    quantity_at_risk: 21543,
    revenue_at_risk: 364458,
    action_type: "Substitution",
    execution_stage: "Vendor validation",
    quantity_mitigated: 18312,
    revenue_mitigated: 309796.9129647681,
    purchase_cost: 66289.44,
    freight_cost: 4944.24,
    cost_impact: 22082.4408,
  },
  {
    running_risk_id: 149,
    risk_date: 293 + 45263,
    risk_status: "In progress",
    risk_location: 105,
    risk_item: "SKU-839",
    quantity_at_risk: 3013,
    revenue_at_risk: 590250,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 2863,
    revenue_mitigated: 560864.835711915,
    purchase_cost: 10049.13,
    freight_cost: 372.19,
    cost_impact: 3751.6752,
  },
  {
    running_risk_id: 150,
    risk_date: 293 + 45231,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 2080,
    revenue_at_risk: 150994,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 1976,
    revenue_mitigated: 143444.3,
    purchase_cost: 5769.92,
    freight_cost: 335.92,
    cost_impact: 1892.8104,
  },
  {
    running_risk_id: 151,
    risk_date: 293 + 45234,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 11173,
    revenue_at_risk: 404566,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 10280,
    revenue_mitigated: 372231.13577374024,
    purchase_cost: 33924,
    freight_cost: 1233.6,
    cost_impact: 11250.431999999999,
  },
  {
    running_risk_id: 152,
    risk_date: 293 + 45234,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 2775,
    revenue_at_risk: 389336,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 2553,
    revenue_mitigated: 358189.12,
    purchase_cost: 8731.26,
    freight_cost: 638.25,
    cost_impact: 3373.0236,
  },
  {
    running_risk_id: 153,
    risk_date: 293 + 45238,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 15863,
    revenue_at_risk: 455380,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 14912,
    revenue_mitigated: 428079.5915022379,
    purchase_cost: 53384.96,
    freight_cost: 2982.4,
    cost_impact: 22546.944,
  },
  {
    running_risk_id: 154,
    risk_date: 293 + 45239,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 4509,
    revenue_at_risk: 440439,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 4104,
    revenue_mitigated: 400878.6107784431,
    purchase_cost: 19370.88,
    freight_cost: 1067.04,
    cost_impact: 7357.651200000001,
  },
  {
    running_risk_id: 155,
    risk_date: 293 + 45239,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 47391,
    revenue_at_risk: 250689,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 38861,
    revenue_mitigated: 205566.99012470723,
    purchase_cost: 169822.57,
    freight_cost: 10103.86,
    cost_impact: 61174.9862,
  },
  {
    running_risk_id: 156,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-135",
    quantity_at_risk: 18577,
    revenue_at_risk: 313501,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 16534,
    revenue_mitigated: 279023.82160736393,
    purchase_cost: 77709.8,
    freight_cost: 3802.82,
    cost_impact: 25268.912200000002,
  },
  {
    running_risk_id: 157,
    risk_date: 293 + 45242,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 2640,
    revenue_at_risk: 132100,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 2588,
    revenue_mitigated: 129498.0303030303,
    purchase_cost: 8022.8,
    freight_cost: 414.08,
    cost_impact: 2784.1704000000004,
  },
  {
    running_risk_id: 158,
    risk_date: 293 + 45242,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 105,
    risk_item: "SKU-241",
    quantity_at_risk: 16553,
    revenue_at_risk: 508845,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 13574,
    revenue_mitigated: 417269.499788558,
    purchase_cost: 60540.04,
    freight_cost: 2714.8,
    cost_impact: 20241.5488,
  },
  {
    running_risk_id: 159,
    risk_date: 293 + 45245,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 49511,
    revenue_at_risk: 574917,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 43570,
    revenue_mitigated: 505930.6758094161,
    purchase_cost: 183865.4,
    freight_cost: 9149.7,
    cost_impact: 61764.832,
  },
  {
    running_risk_id: 160,
    risk_date: 293 + 45247,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-135",
    quantity_at_risk: 10434,
    revenue_at_risk: 53139,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 10330,
    revenue_mitigated: 52609.34157561817,
    purchase_cost: 46588.3,
    freight_cost: 2169.3,
    cost_impact: 16090.008000000003,
  },
  {
    running_risk_id: 161,
    risk_date: 293 + 45247,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 2454,
    revenue_at_risk: 232197,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 2307,
    revenue_mitigated: 218287.88875305624,
    purchase_cost: 6944.07,
    freight_cost: 461.4,
    cost_impact: 2888.1332999999995,
  },
  {
    running_risk_id: 162,
    risk_date: 293 + 45248,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-241",
    quantity_at_risk: 11915,
    revenue_at_risk: 452028,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 11677,
    revenue_mitigated: 442998.8213176668,
    purchase_cost: 44022.29,
    freight_cost: 2568.94,
    cost_impact: 18170.579700000002,
  },
  {
    running_risk_id: 163,
    risk_date: 293 + 45248,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 4966,
    revenue_at_risk: 512898,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 4569,
    revenue_mitigated: 471895.07893677003,
    purchase_cost: 16539.78,
    freight_cost: 1279.32,
    cost_impact: 5880.302999999999,
  },
  {
    running_risk_id: 164,
    risk_date: 293 + 45248,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-241",
    quantity_at_risk: 11730,
    revenue_at_risk: 586952,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 11730,
    revenue_mitigated: 586952,
    purchase_cost: 49852.5,
    freight_cost: 3636.3,
    cost_impact: 18721.08,
  },
  {
    running_risk_id: 165,
    risk_date: 293 + 45251,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-624",
    quantity_at_risk: 5484,
    revenue_at_risk: 580064,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 5320,
    revenue_mitigated: 562717.0824215901,
    purchase_cost: 20535.2,
    freight_cost: 851.2,
    cost_impact: 7271.376000000001,
  },
  {
    running_risk_id: 166,
    risk_date: 293 + 45252,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-233",
    quantity_at_risk: 17859,
    revenue_at_risk: 102868,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 14823,
    revenue_mitigated: 85380.61280026878,
    purchase_cost: 68926.95,
    freight_cost: 2075.22,
    cost_impact: 25560.7812,
  },
  {
    running_risk_id: 167,
    risk_date: 293 + 45254,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 51502,
    revenue_at_risk: 305296,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 44807,
    revenue_mitigated: 265609.0612403402,
    purchase_cost: 205216.06,
    freight_cost: 7617.19,
    cost_impact: 65978.3075,
  },
  {
    running_risk_id: 168,
    risk_date: 293 + 45255,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 19590,
    revenue_at_risk: 379401,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 18219,
    revenue_mitigated: 352848.74012251146,
    purchase_cost: 67774.68,
    freight_cost: 4554.75,
    cost_impact: 21698.828999999998,
  },
  {
    running_risk_id: 169,
    risk_date: 293 + 45257,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 3646,
    revenue_at_risk: 210793,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 3282,
    revenue_mitigated: 189748.3889193637,
    purchase_cost: 15195.66,
    freight_cost: 525.12,
    cost_impact: 5030.649600000001,
  },
  {
    running_risk_id: 179,
    risk_date: 293 + 45243,
    risk_status: "Rejected",
    rejection_code: "Limited supplier inventory",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-492",
    quantity_at_risk: 40821,
    revenue_at_risk: 350262,
    action_type: "Substitution",
    execution_stage: "Vendor validation",
    quantity_mitigated: 36739,
    revenue_mitigated: 315236.658043654,
    purchase_cost: 116830.02,
    freight_cost: 10654.31,
    cost_impact: 39520.1423,
  },
  {
    running_risk_id: 180,
    risk_date: 293 + 45247,
    risk_status: "Rejected",
    rejection_code: "Limited supplier inventory",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 10024,
    revenue_at_risk: 67990,
    action_type: "Substitution",
    execution_stage: "Vendor validation",
    quantity_mitigated: 8721,
    revenue_mitigated: 59152.11392657622,
    purchase_cost: 36453.78,
    freight_cost: 959.31,
    cost_impact: 14591.105099999997,
  },
  {
    running_risk_id: 181,
    risk_date: 293 + 45248,
    risk_status: "Rejected",
    rejection_code: "Limited supplier inventory",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 4612,
    revenue_at_risk: 238978,
    action_type: "Substitution",
    execution_stage: "Vendor validation",
    quantity_mitigated: 3828,
    revenue_mitigated: 198353.81266261925,
    purchase_cost: 16536.96,
    freight_cost: 803.88,
    cost_impact: 5722.477199999999,
  },
  {
    running_risk_id: 182,
    risk_date: 293 + 45251,
    risk_status: "Rejected",
    rejection_code: "Supplier capacity constraint",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 19969,
    revenue_at_risk: 369781,
    action_type: "Substitution",
    execution_stage: "Vendor validation",
    quantity_mitigated: 19570,
    revenue_mitigated: 362392.41674595623,
    purchase_cost: 64972.4,
    freight_cost: 3131.2,
    cost_impact: 27241.44,
  },
  {
    running_risk_id: 183,
    risk_date: 293 + 45254,
    risk_status: "Rejected",
    rejection_code: "Minimum order constraint",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 1589,
    revenue_at_risk: 96371,
    action_type: "Substitution",
    execution_stage: "Vendor validation",
    quantity_mitigated: 1431,
    revenue_mitigated: 86788.48395217118,
    purchase_cost: 4035.42,
    freight_cost: 429.3,
    cost_impact: 1607.2992000000002,
  },
  {
    running_risk_id: 184,
    risk_date: 293 + 45270,
    risk_status: "Open",
    risk_location: 105,
    risk_item: "SKU-839",
    quantity_at_risk: 4833,
    revenue_at_risk: 404431,
    action_type: "Stock Rebalancing",
    quantity_mitigated: 4060,
    revenue_mitigated: 339745.47072211874,
    freight_cost: 446.6,
    cost_impact: 178.64,
    days_of_supply_impact: 2.25,
  },
  {
    running_risk_id: 185,
    risk_date: 293 + 45269,
    risk_status: "In progress",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 2912,
    revenue_at_risk: 462213,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 2476,
    revenue_mitigated: 393008.0315934066,
    freight_cost: 718.04,
    cost_impact: 244.1336,
    days_of_supply_impact: 4.07,
  },
  {
    running_risk_id: 186,
    risk_date: 293 + 45231,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 18186,
    revenue_at_risk: 459015,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 15640,
    revenue_mitigated: 394753.9096007918,
    freight_cost: 2815.2,
    cost_impact: 957.1679999999999,
    days_of_supply_impact: 3.45,
  },
  {
    running_risk_id: 187,
    risk_date: 293 + 45232,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 4519,
    revenue_at_risk: 165019,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 3887,
    revenue_mitigated: 141940.44102677584,
    freight_cost: 1049.49,
    cost_impact: 377.8164,
    days_of_supply_impact: 4.52,
  },
  {
    running_risk_id: 188,
    risk_date: 293 + 45233,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 14864,
    revenue_at_risk: 37728,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 12189,
    revenue_mitigated: 30938.279870828846,
    freight_cost: 2072.13,
    cost_impact: 725.2455,
    days_of_supply_impact: 4.08,
  },
  {
    running_risk_id: 189,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 38755,
    revenue_at_risk: 461361,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 35655,
    revenue_mitigated: 424456.88182170043,
    freight_cost: 8557.2,
    cost_impact: 2823.876,
    days_of_supply_impact: 4.53,
  },
  {
    running_risk_id: 190,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-135",
    quantity_at_risk: 17391,
    revenue_at_risk: 508711,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 16000,
    revenue_mitigated: 468022.31039043184,
    freight_cost: 1760,
    cost_impact: 598.4,
    days_of_supply_impact: 2.83,
  },
  {
    running_risk_id: 191,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 1676,
    revenue_at_risk: 405873,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 1425,
    revenue_mitigated: 345088.91706443916,
    freight_cost: 270.75,
    cost_impact: 92.055,
    days_of_supply_impact: 5.42,
  },
  {
    running_risk_id: 192,
    risk_date: 293 + 45236,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 105,
    risk_item: "SKU-241",
    quantity_at_risk: 9674,
    revenue_at_risk: 507939,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 8127,
    revenue_mitigated: 426712.8646888567,
    freight_cost: 2113.02,
    cost_impact: 739.557,
    days_of_supply_impact: 2.71,
  },
  {
    running_risk_id: 193,
    risk_date: 293 + 45239,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 38228,
    revenue_at_risk: 563503,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 35553,
    revenue_mitigated: 524071.94095950614,
    freight_cost: 8532.72,
    cost_impact: 2559.816,
    days_of_supply_impact: 5.2,
  },
  {
    running_risk_id: 208,
    risk_date: 293 + 45256,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-135",
    quantity_at_risk: 16519,
    revenue_at_risk: 436637,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 14042,
    revenue_mitigated: 371163.9175494885,
    freight_cost: 1825.46,
    cost_impact: 693.6748,
    days_of_supply_impact: 4.24,
  },
  {
    running_risk_id: 209,
    risk_date: 293 + 45258,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 3655,
    revenue_at_risk: 373325,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 3180,
    revenue_mitigated: 324808.0711354309,
    freight_cost: 922.2,
    cost_impact: 350.436,
    days_of_supply_impact: 5.38,
  },
  {
    running_risk_id: 210,
    risk_date: 293 + 45260,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-241",
    quantity_at_risk: 15928,
    revenue_at_risk: 183555,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 15132,
    revenue_mitigated: 174381.85961828227,
    freight_cost: 1815.84,
    cost_impact: 544.752,
    days_of_supply_impact: 3.02,
  },
  {
    running_risk_id: 217,
    risk_date: 293 + 45247,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 2870,
    revenue_at_risk: 101346,
    action_type: "Stock Rebalancing",
    execution_stage: "Planner validation",
    quantity_mitigated: 2670,
    revenue_mitigated: 94283.56097560975,
    freight_cost: 534,
    cost_impact: 186.9,
    days_of_supply_impact: 5.17,
  },
  {
    running_risk_id: 218,
    risk_date: 293 + 45253,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 18927,
    revenue_at_risk: 185907,
    action_type: "Stock Rebalancing",
    execution_stage: "Planner validation",
    quantity_mitigated: 16846,
    revenue_mitigated: 165466.7576478047,
    freight_cost: 5390.72,
    cost_impact: 2048.4736000000003,
    days_of_supply_impact: 3.2,
  },
  {
    running_risk_id: 219,
    risk_date: 293 + 45234,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 37676,
    revenue_at_risk: 203117,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 30141,
    revenue_mitigated: 162494.67823017304,
    freight_cost: 9343.71,
    cost_impact: 2896.5500999999995,
    days_of_supply_impact: 3.56,
  },
  {
    running_risk_id: 220,
    risk_date: 293 + 45237,
    risk_status: "Rejected",
    rejection_code: "Freight forwarder calendar",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 3797,
    revenue_at_risk: 562085,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 3608,
    revenue_mitigated: 534106.5788780616,
    freight_cost: 902,
    cost_impact: 351.78,
    days_of_supply_impact: 2.65,
  },
  {
    running_risk_id: 221,
    risk_date: 293 + 45242,
    risk_status: "Rejected",
    rejection_code: "Freight forwarder calendar",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-241",
    quantity_at_risk: 9127,
    revenue_at_risk: 354339,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 8580,
    revenue_mitigated: 333102.73036046897,
    freight_cost: 1458.6,
    cost_impact: 539.682,
    days_of_supply_impact: 5.87,
  },
  {
    running_risk_id: 222,
    risk_date: 293 + 45244,
    risk_status: "Rejected",
    rejection_code: "No freight forwarder capacity",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-624",
    quantity_at_risk: 4686,
    revenue_at_risk: 474838,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 4171,
    revenue_mitigated: 422652.4323516859,
    freight_cost: 583.94,
    cost_impact: 204.37900000000002,
    days_of_supply_impact: 3.46,
  },
  {
    running_risk_id: 223,
    risk_date: 293 + 45246,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-233",
    quantity_at_risk: 21663,
    revenue_at_risk: 310285,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 19064,
    revenue_mitigated: 273058.82103125146,
    freight_cost: 5147.28,
    cost_impact: 1647.1296,
    days_of_supply_impact: 3.01,
  },
  {
    running_risk_id: 224,
    risk_date: 293 + 45251,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 37180,
    revenue_at_risk: 67543,
    action_type: "Stock Rebalancing",
    execution_stage: "Freight",
    quantity_mitigated: 31232,
    revenue_mitigated: 56737.5733189887,
    freight_cost: 6871.04,
    cost_impact: 2198.7327999999998,
    days_of_supply_impact: 3.2,
  },
  {
    running_risk_id: 225,
    risk_date: 293 + 45270,
    risk_status: "Open",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 12236,
    revenue_at_risk: 107588,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 10891,
    revenue_mitigated: 95761.76103301732,
    freight_cost: 10455.36,
    cost_impact: 8364.288,
  },
  {
    running_risk_id: 226,
    risk_date: 293 + 45285,
    risk_status: "In progress",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 3729,
    revenue_at_risk: 53276,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 3729,
    revenue_mitigated: 53276,
    freight_cost: 2349.27,
    cost_impact: 1926.4013999999997,
  },
  {
    running_risk_id: 227,
    risk_date: 293 + 45233,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 52333,
    revenue_at_risk: 456555,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 47100,
    revenue_mitigated: 410902.11721093766,
    freight_cost: 39093,
    cost_impact: 34010.91,
  },
  {
    running_risk_id: 228,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-135",
    quantity_at_risk: 17313,
    revenue_at_risk: 408122,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 16621,
    revenue_mitigated: 391809.3780396234,
    freight_cost: 11136.07,
    cost_impact: 9131.5774,
  },
  {
    running_risk_id: 229,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 2133,
    revenue_at_risk: 524725,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 1984,
    revenue_mitigated: 488070.51101734646,
    freight_cost: 1547.52,
    cost_impact: 1238.016,
  },
  {
    running_risk_id: 230,
    risk_date: 293 + 45238,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-233",
    quantity_at_risk: 20282,
    revenue_at_risk: 65112,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 20282,
    revenue_mitigated: 65112,
    freight_cost: 5678.96,
    cost_impact: 5054.2744,
  },
  {
    running_risk_id: 231,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-839",
    quantity_at_risk: 2246,
    revenue_at_risk: 425154,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 2022,
    revenue_mitigated: 382752.17631344614,
    freight_cost: 424.62,
    cost_impact: 360.92699999999996,
  },
  {
    running_risk_id: 232,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 2886,
    revenue_at_risk: 386612,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 2396,
    revenue_mitigated: 320971.01593901595,
    freight_cost: 670.88,
    cost_impact: 597.0832,
  },
  {
    running_risk_id: 233,
    risk_date: 293 + 45242,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 18786,
    revenue_at_risk: 376724,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 15781,
    revenue_mitigated: 316463.4006174811,
    freight_cost: 14834.14,
    cost_impact: 13054.043199999998,
  },
  {
    running_risk_id: 234,
    risk_date: 293 + 45245,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 3319,
    revenue_at_risk: 172901,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 2656,
    revenue_mitigated: 138362.47544441096,
    freight_cost: 2071.68,
    cost_impact: 1802.3615999999997,
  },
  {
    running_risk_id: 235,
    risk_date: 293 + 45246,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 15744,
    revenue_at_risk: 224631,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 15744,
    revenue_mitigated: 224631,
    freight_cost: 7242.24,
    cost_impact: 6155.904,
  },
  {
    running_risk_id: 236,
    risk_date: 293 + 45249,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 4563,
    revenue_at_risk: 198614,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 3697,
    revenue_mitigated: 160919.56125356126,
    freight_cost: 2883.66,
    cost_impact: 2537.6207999999997,
  },
  {
    running_risk_id: 237,
    risk_date: 293 + 45251,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 37219,
    revenue_at_risk: 377407,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 33498,
    revenue_mitigated: 339675.42615330877,
    freight_cost: 24453.54,
    cost_impact: 19807.3674,
  },
  {
    running_risk_id: 238,
    risk_date: 293 + 45252,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-135",
    quantity_at_risk: 11316,
    revenue_at_risk: 305336,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 10298,
    revenue_mitigated: 277867.6323789325,
    freight_cost: 4016.22,
    cost_impact: 3534.2736,
  },
  {
    running_risk_id: 239,
    risk_date: 293 + 45255,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 4688,
    revenue_at_risk: 404538,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 4360,
    revenue_mitigated: 376234.1467576792,
    freight_cost: 2398,
    cost_impact: 1966.36,
  },
  {
    running_risk_id: 240,
    risk_date: 293 + 45258,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-241",
    quantity_at_risk: 15140,
    revenue_at_risk: 280769,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 12869,
    revenue_mitigated: 238653.65,
    freight_cost: 12354.24,
    cost_impact: 10254.019199999999,
  },
  {
    running_risk_id: 241,
    risk_date: 293 + 45260,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 46342,
    revenue_at_risk: 428629,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 41245,
    revenue_mitigated: 381485.5445384317,
    freight_cost: 10723.7,
    cost_impact: 9544.093,
  },
  {
    running_risk_id: 242,
    risk_date: 293 + 45234,
    risk_status: "Rejected",
    rejection_code: "No freight forwarder capacity",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 3526,
    revenue_at_risk: 67304,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 3139,
    revenue_mitigated: 59916.9756097561,
    freight_cost: 1789.23,
    cost_impact: 1556.6301,
  },
  {
    running_risk_id: 243,
    risk_date: 293 + 45236,
    risk_status: "Rejected",
    rejection_code: "Freight cost budget constraint",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-241",
    quantity_at_risk: 13316,
    revenue_at_risk: 450135,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 10653,
    revenue_mitigated: 360114.7608140583,
    freight_cost: 7989.75,
    cost_impact: 6631.4925,
  },
  {
    running_risk_id: 244,
    risk_date: 293 + 45238,
    risk_status: "Rejected",
    rejection_code: "No freight forwarder capacity",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-624",
    quantity_at_risk: 6129,
    revenue_at_risk: 36831,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 5578,
    revenue_mitigated: 33519.875673029856,
    freight_cost: 3737.26,
    cost_impact: 3101.9258,
  },
  {
    running_risk_id: 245,
    risk_date: 293 + 45240,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-233",
    quantity_at_risk: 23610,
    revenue_at_risk: 245961,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 23138,
    revenue_mitigated: 241043.86353240153,
    freight_cost: 20824.2,
    cost_impact: 16867.602,
  },
  {
    running_risk_id: 247,
    risk_date: 293 + 45243,
    risk_status: "Rejected",
    rejection_code: "Freight forwarder calendar",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 12135,
    revenue_at_risk: 162549,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 10437,
    revenue_mitigated: 139804.1955500618,
    freight_cost: 3548.58,
    cost_impact: 2838.864,
  },
  {
    running_risk_id: 248,
    risk_date: 293 + 45245,
    risk_status: "Rejected",
    rejection_code: "Freight forwarder calendar",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 4701,
    revenue_at_risk: 519334,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 4043,
    revenue_mitigated: 446642.70623271645,
    freight_cost: 1132.04,
    cost_impact: 1007.5156,
  },
  {
    running_risk_id: 253,
    risk_date: 293 + 45257,
    risk_status: "Rejected",
    rejection_code: "Freight forwarder calendar",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-135",
    quantity_at_risk: 11040,
    revenue_at_risk: 376491,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 9826,
    revenue_mitigated: 335090.6309782609,
    freight_cost: 3144.32,
    cost_impact: 2641.2288,
  },
  {
    running_risk_id: 256,
    risk_date: 293 + 45281,
    risk_status: "In progress",
    risk_location: 245,
    risk_item: "SKU-233",
    quantity_at_risk: 24736,
    revenue_at_risk: 391122,
    action_type: "Partial Delivery",
    execution_stage: "Vendor validation",
    quantity_mitigated: 23994,
    revenue_mitigated: 379389.60494825355,
    purchase_cost: 98855.28,
    freight_cost: 5278.68,
    cost_impact: 37488.2256,
    days_of_supply_impact: 1.76,
  },
  {
    running_risk_id: 257,
    risk_date: 293 + 45279,
    risk_status: "In progress",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 39142,
    revenue_at_risk: 73139,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 33663,
    revenue_mitigated: 62901.18432885392,
    purchase_cost: 93583.14,
    freight_cost: 4712.82,
    cost_impact: 37352.464799999994,
    days_of_supply_impact: 1.12,
  },
  {
    running_risk_id: 258,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-135",
    quantity_at_risk: 18161,
    revenue_at_risk: 350672,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 17617,
    revenue_mitigated: 340167.8665271736,
    purchase_cost: 52851,
    freight_cost: 4580.42,
    cost_impact: 17803.7402,
    days_of_supply_impact: 1.51,
  },
  {
    running_risk_id: 259,
    risk_date: 293 + 45236,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 4598,
    revenue_at_risk: 323612,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 4185,
    revenue_mitigated: 294544.6324488908,
    purchase_cost: 19627.65,
    freight_cost: 1129.95,
    cost_impact: 7057.584,
    days_of_supply_impact: 1.92,
  },
  {
    running_risk_id: 260,
    risk_date: 293 + 45243,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-241",
    quantity_at_risk: 11688,
    revenue_at_risk: 57692,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 9585,
    revenue_mitigated: 47311.586242299796,
    purchase_cost: 41023.8,
    freight_cost: 1341.9,
    cost_impact: 13133.367000000002,
    days_of_supply_impact: 2.2,
  },
  {
    running_risk_id: 261,
    risk_date: 293 + 45247,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 37114,
    revenue_at_risk: 231436,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 33774,
    revenue_mitigated: 210608.3813116344,
    purchase_cost: 122937.36,
    freight_cost: 3715.14,
    cost_impact: 46861.425,
    days_of_supply_impact: 1.2,
  },
  {
    running_risk_id: 262,
    risk_date: 293 + 45250,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-135",
    quantity_at_risk: 19206,
    revenue_at_risk: 198535,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 16902,
    revenue_mitigated: 174718.2427366448,
    purchase_cost: 50367.96,
    freight_cost: 3718.44,
    cost_impact: 16766.784,
    days_of_supply_impact: 2.31,
  },
  {
    running_risk_id: 263,
    risk_date: 293 + 45255,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 2114,
    revenue_at_risk: 203677,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 2009,
    revenue_mitigated: 193560.5927152318,
    purchase_cost: 6951.14,
    freight_cost: 381.71,
    cost_impact: 2493.1690000000003,
    days_of_supply_impact: 2.89,
  },
  {
    running_risk_id: 264,
    risk_date: 293 + 45257,
    risk_status: "Rejected",
    rejection_code: "Puchase cost too high",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-241",
    quantity_at_risk: 16339,
    revenue_at_risk: 36387,
    action_type: "Partial Delivery",
    quantity_mitigated: 15849,
    revenue_mitigated: 35295.76859048902,
    purchase_cost: 47547,
    freight_cost: 2218.86,
    cost_impact: 15427.416599999999,
    days_of_supply_impact: 1.33,
  },
  {
    running_risk_id: 271,
    risk_date: 293 + 45240,
    risk_status: "Rejected",
    rejection_code: "Supplier capacity constraint",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-135",
    quantity_at_risk: 17438,
    revenue_at_risk: 449756,
    action_type: "Partial Delivery",
    execution_stage: "Vendor validation",
    quantity_mitigated: 16741,
    revenue_mitigated: 431779.1716940016,
    purchase_cost: 59263.14,
    freight_cost: 4854.89,
    days_of_supply_impact: 2.65,
  },
  {
    running_risk_id: 273,
    risk_date: 293 + 45247,
    risk_status: "Rejected",
    rejection_code: "Supplier capacity constraint",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 4015,
    revenue_at_risk: 521324,
    action_type: "Partial Delivery",
    execution_stage: "Vendor validation",
    quantity_mitigated: 3373,
    revenue_mitigated: 437964.097633873,
    purchase_cost: 12749.94,
    freight_cost: 438.49,
    days_of_supply_impact: 2.84,
  },
  {
    running_risk_id: 274,
    risk_date: 293 + 45250,
    risk_status: "Rejected",
    rejection_code: "Supplier capacity constraint",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-241",
    quantity_at_risk: 10287,
    revenue_at_risk: 497669,
    action_type: "Partial Delivery",
    execution_stage: "Vendor validation",
    quantity_mitigated: 10185,
    revenue_mitigated: 492734.3992417614,
    purchase_cost: 44202.9,
    freight_cost: 1833.3,
    days_of_supply_impact: 1.45,
  },
  {
    running_risk_id: 277,
    risk_date: 293 + 45276,
    risk_status: "Open",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 37111,
    revenue_at_risk: 421403,
    action_type: "No action",
    quantity_mitigated: 34514,
    revenue_mitigated: 391913.53350758535,
  },
  {
    running_risk_id: 278,
    risk_date: 293 + 45231,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-135",
    quantity_at_risk: 10444,
    revenue_at_risk: 554290,
    action_type: "No action",
    quantity_mitigated: 10131,
    revenue_mitigated: 537678.2832248181,
  },
  {
    running_risk_id: 279,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 2849,
    revenue_at_risk: 555603,
    action_type: "No action",
    quantity_mitigated: 2821,
    revenue_mitigated: 550142.5282555283,
  },
  {
    running_risk_id: 280,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 38855,
    revenue_at_risk: 342031,
    action_type: "No action",
    quantity_mitigated: 32250,
    revenue_mitigated: 283888.81096383993,
  },
  {
    running_risk_id: 281,
    risk_date: 293 + 45241,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-135",
    quantity_at_risk: 12634,
    revenue_at_risk: 441696,
    action_type: "No action",
    quantity_mitigated: 11371,
    revenue_mitigated: 397540.3843596644,
  },
  {
    running_risk_id: 282,
    risk_date: 293 + 45245,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 50864,
    revenue_at_risk: 509847,
    action_type: "No action",
    quantity_mitigated: 42726,
    revenue_mitigated: 428273.88569518714,
  },
  {
    running_risk_id: 283,
    risk_date: 293 + 45246,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-135",
    quantity_at_risk: 14583,
    revenue_at_risk: 374726,
    action_type: "No action",
    quantity_mitigated: 11959,
    revenue_mitigated: 307299.474319413,
  },
  {
    running_risk_id: 284,
    risk_date: 293 + 45249,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-839",
    quantity_at_risk: 3326,
    revenue_at_risk: 78126,
    action_type: "No action",
    quantity_mitigated: 3260,
    revenue_mitigated: 76575.69452796152,
  },
  {
    running_risk_id: 285,
    risk_date: 293 + 45253,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-241",
    quantity_at_risk: 10052,
    revenue_at_risk: 572313,
    action_type: "No action",
    quantity_mitigated: 8746,
    revenue_mitigated: 497955.5807799443,
  },
  {
    running_risk_id: 286,
    risk_date: 293 + 45256,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 245,
    risk_item: "SKU-492",
    quantity_at_risk: 39419,
    revenue_at_risk: 532983,
    action_type: "No action",
    quantity_mitigated: 33507,
    revenue_mitigated: 453047.0428219894,
  },
  {
    running_risk_id: 287,
    risk_date: 293 + 45257,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-233",
    quantity_at_risk: 23383,
    revenue_at_risk: 51922,
    action_type: "No action",
    quantity_mitigated: 19876,
    revenue_mitigated: 44134.69922593337,
  },
  {
    running_risk_id: 288,
    risk_date: 293 + 45236,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-492",
    quantity_at_risk: 46825,
    revenue_at_risk: 32803,
    action_type: "No action",
    quantity_mitigated: 41206,
    revenue_mitigated: 28866.64,
  },
  {
    running_risk_id: 289,
    risk_date: 293 + 45240,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 245,
    risk_item: "SKU-241",
    quantity_at_risk: 9627,
    revenue_at_risk: 417126,
    action_type: "No action",
    quantity_mitigated: 7702,
    revenue_mitigated: 333718.1315051418,
  },
  {
    running_risk_id: 290,
    risk_date: 293 + 45248,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 105,
    risk_item: "SKU-839",
    quantity_at_risk: 4052,
    revenue_at_risk: 133038,
    action_type: "No action",
    quantity_mitigated: 3850,
    revenue_mitigated: 126405.79960513327,
  },
];
