export interface StatConf {
  title: string;
  unit: string;
  field: string;
  filter: any;
  operation: string;
}

export const defaultStatConf: StatConf = {
  title: "",
  unit: "",
  field: "",
  filter: null,
  operation: "",
};
