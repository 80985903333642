import { Line, LineConfig } from "@ant-design/plots";
import React from "react";
import onChartClickEvent from "./functions/onChartClickEvent";
import { ChartParams } from "./functions/ChartParams.interface";
import { formatData } from "./functions/formatData";
import { CHART_COLOR_TYPES } from "../chart-content/components/new-chart/NewChartModal";
import { getTranslation } from "../../../../../../utils/transaltion";

interface MyLineProps {
  config: LineConfig | any;
  fields: any;
  params: ChartParams;
  data: any[];
  showDrawer?: (v: any) => void;
}

const MyLine: React.FC<MyLineProps> = ({
  config,
  fields,
  params,
  data,
  showDrawer,
}) => {
  const my_config: LineConfig = {
    data: data,
    xField: "groupField",
    yField: "valueField",
    seriesField: "categoryField",
    connectNulls: true,
    /* xAxis: {
      type: "time",
    }, */
    smooth: true,
    meta: {
      valueField: {
        formatter: (v) => formatData(v, fields?.valueField, params).data,
      },
      groupField: {
        formatter: (v) => formatData(v, fields?.groupField, params).data,
      },
      categoryField: {
        formatter: (v) =>
          formatData(v, fields?.categoryField?.at(0), params).data,
      },
    },

    // tooltip: {
    //   shared: true,
    //   showMarkers: false,
    //   formatter: ({ categoryField, valueField }: any) => ({
    //     name: getTranslation(categoryField, "data"),
    //     value: valueField
    //       ? `${
    //           formatData(valueField, fields?.valueField, {
    //             unit: config?.unit,
    //             ...params,
    //           }).data
    //         }`
    //       : "Not set",
    //   }),
    // },
    tooltip: {
      title(title, datum) {
        return getTranslation(fields?.valueField?.value, "data");
      },
      shared: true,
      showMarkers: false,
      formatter: ({ categoryField, valueField }: any) => ({
        name: getTranslation(categoryField, "data"),
        value: valueField
          ? `${
              formatData(valueField, fields?.valueField, {
                unit: config?.unit,
                ...params,
              }).data
            }`
          : "Not set",
      }),
    },

    legend: {
      position: "top",
      itemName: {
        formatter: (datum: any) => {
          return ` ${getTranslation(datum, "labels")}`;
        },
      },
    },

    style: {
      height: 250,
    },

    // ...config,
    // data: data,
    onEvent: (chart: any, event: any) => {
      onChartClickEvent(chart, event, showDrawer);
    },

    color:
      config?.pallet_type === CHART_COLOR_TYPES.PALLET
        ? config?.color_pallet?.map((color: any) => color?.color)
        : ({ categoryField }) =>
            config?.color_pallet?.find(
              (color: any) => color?.value === categoryField
            )?.color || [],
  };
  return <Line {...my_config} />;
};

export default MyLine;
