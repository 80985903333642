import React, { ReactNode, createContext, useState } from "react";
import { ColorPickerProps, Form, FormInstance, GetProp } from "antd";
import { EnumItem } from "../../../../interfaces/enums";
type dynamicF = {
  key: number;
  name?: string;
  type?: string;
  defaultValue?: any;
  position?: number;
  suffix?: string;
  choice?: any;
};
export type dynamicFDateNeeded = {
  key?: number;
  dataName?: string;
  dataType?: string;
  defaultValue?: any;
  position?: number;
  suffix?: string;
  choice?: any;
};
type Color = GetProp<ColorPickerProps, "value">;
// Define the context
type ContextType = {
  Loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  List: any[];
  setList: React.Dispatch<React.SetStateAction<any[]>>;
  listToFilter: any[];
  setListToFilter: React.Dispatch<React.SetStateAction<any[]>>;
  total: number;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
  getLoading: boolean;
  setGetLoading: React.Dispatch<React.SetStateAction<boolean>>;
  workMode: string;
  setWorkMode: React.Dispatch<React.SetStateAction<string>>;
  Record: any;
  setRecord: React.Dispatch<React.SetStateAction<any>>;
  valueEnumCurrency: EnumItem;
  setValueEnumCurrency: React.Dispatch<React.SetStateAction<EnumItem>>;
  dynamicFields: dynamicFDateNeeded[];
  setDynamicFields: React.Dispatch<React.SetStateAction<dynamicFDateNeeded[]>>;
  formSettings: FormInstance<any>;
  form: FormInstance<any>;
  isDisable: boolean;
  setIsDisable: React.Dispatch<React.SetStateAction<boolean>>;
  dynamicFieldsSettings: dynamicF[];
  setDynamicFieldsSettings: React.Dispatch<React.SetStateAction<dynamicF[]>>;
  settingsChoicesList: any;
  setSettingsChoicesList: React.Dispatch<React.SetStateAction<any>>;
};
export const SharedContext = createContext<ContextType>({
  isDisable: false,
  setIsDisable: () => {},
  Loading: true,
  setLoading: () => {},
  List: [],
  setList: () => {},
  listToFilter: [],
  setListToFilter: () => {},
  total: 0,
  setTotal: () => {},
  getLoading: false,
  setGetLoading: () => {},
  workMode: "",
  setWorkMode: () => {},
  Record: {},
  setRecord: () => {},
  valueEnumCurrency: {},
  setValueEnumCurrency: () => {},
  dynamicFields: [],
  setDynamicFields: () => {},
  form: {} as FormInstance<any>,
  formSettings: {} as FormInstance<any>,

  /**action */
  dynamicFieldsSettings: [],
  setDynamicFieldsSettings: () => {},

  settingsChoicesList: {},
  setSettingsChoicesList: () => {},
});

// Create a provider component
export const SharedContextProviderOrganization: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [isDisable, setIsDisable]: any = useState(false);
  const [workMode, setWorkMode] = useState("");
  const [Loading, setLoading] = useState(true);
  const [listToFilter, setListToFilter] = useState<any[]>([]);
  const [List, setList] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [getLoading, setGetLoading] = useState(false);
  const [dynamicFields, setDynamicFields] = useState<dynamicFDateNeeded[]>([
    { key: 1 },
  ]);
  const [Record, setRecord] = useState<any>();
  /* enums  for the filters */
  const [valueEnumCurrency, setValueEnumCurrency] = useState<{
    [key: string]: { text: string };
  }>({});
  const [form] = Form.useForm();
  const [formSettings] = Form.useForm();

  /**Action specific vars */
  const [dynamicFieldsSettings, setDynamicFieldsSettings] = useState<
    dynamicF[]
  >([{ key: 1 }]);

  //choices settings
  const [settingsChoicesList, setSettingsChoicesList] = useState<any>({});

  return (
    <SharedContext.Provider
      value={{
        settingsChoicesList,
        setSettingsChoicesList,
        dynamicFieldsSettings,
        setDynamicFieldsSettings,
        isDisable,
        setIsDisable,
        form,
        formSettings,
        dynamicFields,
        setDynamicFields,
        listToFilter,
        setListToFilter,
        valueEnumCurrency,
        setValueEnumCurrency,
        Record,
        setRecord,
        workMode,
        setWorkMode,
        Loading,
        setLoading,
        List,
        setList,
        total,
        setTotal,
        getLoading,
        setGetLoading,
      }}
    >
      {children}
    </SharedContext.Provider>
  );
};
