import { Alert, Button, Modal, Row, Space, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { getTranslation } from "../../../../../utils/transaltion";
import OrganizationsService from "../../../../../services/organizations.service";
import { MainContext } from "../../../../../utils/context";

export default function ConfirmUpdateToken(props: any) {
  const { closeConfirmUpdateTokenModal, openConfirmUpdateTokenModal, orga } =
    props;
  const [token, setToken] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmExit, setConfirmExit] = useState(false);

  const globalContext = useContext(MainContext);
  if (!globalContext) {
    return <></>;
  }

  const closeConfirmUpdateTokenModal_ = () => {
    if (token) {
      if (!confirmExit) {
        setConfirmExit(true);
      } else {
        closeConfirmUpdateTokenModal();
        setToken("");
        setConfirmExit(false);
      }
    } else {
      closeConfirmUpdateTokenModal();
    }
  };

  useEffect(() => {
    console.log(orga);
    console.log(confirmExit);
  }, [confirmExit]);

  const organisationServices = new OrganizationsService(
    globalContext.context,
    globalContext.setContext
  );

  const updateOrgaToken = () => {
    setConfirmLoading(true);
    organisationServices.updateOrgaToken(orga?.id)?.then((response) => {
      setToken(response?.token);
    });
    setConfirmLoading(false);
  };

  return (
    <Modal
      destroyOnClose={true}
      title={
        confirmExit
          ? "Important: Save Your Token"
          : "Update Service Account token"
      }
      open={openConfirmUpdateTokenModal}
      width={800}
      footer={null}
      onOk={() => {
        closeConfirmUpdateTokenModal();
      }}
      onCancel={closeConfirmUpdateTokenModal_}
    >
      <div>
        {!token ? (
          <div>
            <Alert
              message="Changing the token will revoke the current one. Do you want to proceed with this change?"
              type="warning"
              showIcon
            />
            <br></br>
            <Row justify="end">
              <Space>
                <Button
                  className="cancel-button"
                  onClick={() => {
                    closeConfirmUpdateTokenModal();
                  }}
                >
                  {getTranslation("CANCEL", "labels")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="save-button"
                  onClick={() => {
                    updateOrgaToken();
                  }}
                  loading={confirmLoading}
                >
                  {getTranslation("Confirm", "labels")}
                </Button>
              </Space>
            </Row>
          </div>
        ) : (
          <div>
            <Alert
              message={token}
              type="success"
              showIcon
              icon={
                <Typography.Text
                  copyable={{
                    text: token,
                    icon: <MdContentCopy size={24} />,
                  }}
                ></Typography.Text>
              }
            />
            <br />
            {!!confirmExit && (
              <div>
                <Alert
                  message={
                    "Please save or copy your token now. This token will not be shown again!"
                  }
                  type="warning"
                  showIcon
                />
                <br></br>

                <Row justify="end">
                  <Space>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="save-button"
                      onClick={closeConfirmUpdateTokenModal_}
                    >
                      {getTranslation("I UNDERSTAND", "labels")}
                    </Button>
                  </Space>
                </Row>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
