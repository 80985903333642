import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import { API_VERSION, SERVER } from "../../constants/server";
import { Context } from "../../interfaces/context";

declare module "axios" {
  export interface InternalAxiosRequestConfig {
    context: Context;
    setContext: (c: Context) => void;
  }
}

class Api {
  baseUrl: string;
  api: AxiosInstance;
  context: Context;
  setContext: (c: Context) => void;

  static refreshTokenPromise: any;

  private setupInterceptors() {
    // Request interceptor
    this.api.interceptors.request.use(
      (req: AxiosRequestConfig & InternalAxiosRequestConfig) => {
        req.context = this.context;
        req.setContext = this.setContext;
        return req;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // // Response interceptor with manual retry logic
    // this.api.interceptors.response.use(
    //   (response) => response,
    //   async (error) => {
    //     return this.tryWithRefresh(error);
    //   }
    // );
  }

  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string,
    version: string = API_VERSION,
    baseUrl: string = `${SERVER}/api/${version}/${path}`
  ) {
    this.context = context;
    this.setContext = setContext;

    this.baseUrl = baseUrl;

    this.api = axios.create({
      baseURL: baseUrl,
    });

    this.setupInterceptors();
  }

  async tryWithRefresh(error: any) {
    const { response, config: originalRequest } = error;

    if (response?.status === 401 && !originalRequest._retry) {
      try {
        if (!Api.refreshTokenPromise) {
          Api.refreshTokenPromise = Api.refreshToken(
            originalRequest?.context,
            originalRequest?.setContext
          ).then((res) => {
            Api.refreshTokenPromise = null; // clear state
            return res; // resolve with the new token
          });
        }

        return Api.refreshTokenPromise.then((res: any) => {
          originalRequest._retry = true;
          originalRequest.withCredentials = true;
          return axios.request(originalRequest);
        });
      } catch {
        throw error;
      }
    } else {
      originalRequest?.setContext({
        ...originalRequest?.context,
        loggedIn: false,
      });
    }

    throw error;
  }

  static async refreshToken(
    context: Context,
    setContext: (c: Context) => void
  ) {
    axios
      .post(
        `${SERVER}/auth/refresh`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setContext({
            ...context,
            loggedIn: true,
          });
        }

        return response.data;
      })
      .catch((err) => {
        //
        setContext({
          ...context,
          loggedIn: false,
        });
        throw err;
      });
  }

  static createAuthHeaders = (noAuth: boolean, accessToken: string | null) => {
    if (noAuth) {
      return {};
    }
    // return {};
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  };

  get(url: string, config = {}, noAuth = false) {
    return this.api.get(url, {
      withCredentials: true,
      ...config,
    });
  }

  delete(url: string, config = {}, noAuth = false) {
    return this.api.delete(url, {
      withCredentials: true,
      ...config,
    });
  }

  head(url: string, config = {}, noAuth = false) {
    return this.api.head(url, {
      withCredentials: true,
      ...config,
    });
  }

  options(url: string, config = {}, noAuth = false) {
    return this.api.options(url, {
      withCredentials: true,
      ...config,
    });
  }

  post(url: string, data = {}, config = {}, noAuth = false) {
    return this.api.post(url, data, {
      withCredentials: true,
      ...config,
    });
  }

  put(url: string, data = {}, config = {}, noAuth = false) {
    return this.api.put(url, data, {
      withCredentials: true,
      ...config,
    });
  }

  patch(url: string, data = {}, config = {}, noAuth = false) {
    if (!this.api) {
      return;
    }

    return this.api.patch(url, data, {
      withCredentials: true,
      ...config,
    });
  }
}

export default Api;
