import React from "react";
import { FundFlowGraph, IGraph } from "@ant-design/graphs";

import {
  CUSTOMER_ORDER_ITEM,
  FlowDataItem,
} from "../../../../interfaces/flowchart";
import {
  CardNodeCfg,
  EdgeConfig,
  MarkerPosition,
} from "@ant-design/graphs/es/interface";

const FlowGraph = (props: any) => {
  const { InputList } = props;

  const data: FlowDataItem = InputList?.at(0);
  const colorMap: Record<string, string> = {
    A: "#FFAA15",
    B: "#72CC4A",
  };
  const config = {
    data,
    onZoom: () => {},

    edgeCfg: {
      allowDragOnItem: true,
      endArrow: (edge: any) => {
        const { value } = edge;
        return {
          size: 7,
          fill: colorMap[value.extraKey] || "#40a9ff",
        };
      },
      // style: (edge: any) => {
      //   const { value } = edge;
      //   const textWidth = measureTextWidth(value.subText || "", 12);
      //   const minLength = Math.max(100, textWidth); // Set a minimum length for the edge
      //   return {
      //     stroke: colorMap[value.extraKey] || "#40a9ff",
      //     linelength: minLength + 10,
      //     lineWidth: 2,
      //   };
      // },
      // edgeStateStyles: {
      //   hover: {
      //     stroke: "#1890ff",
      //     lineWidth: 2,
      //     endArrow: {
      //       fill: "#1890ff",
      //     },
      //   },
      // },
    },
    markerCfg: (cfg: CardNodeCfg) => {
      const { edges } = data;
      const right: MarkerPosition = "right";
      return {
        position: right,

        show: edges.find((item) => item.source === cfg?.id) !== undefined,
      };
    },
    nodeCfg: {
      // style: nodeStyle,
    },
    fitCenter: true,
    autoFit: true,
    behaviors: ["drag-node", "drag-canvas", "zoom-canvas"], // Enable node dragging, canvas dragging, and zooming
  };

  // const config = {
  //   data,
  //   onZoom: () => {},

  //   edgeCfg: {
  //     allowDragOnItem: true,
  //     endArrow: (edge: any) => {
  //       const { value } = edge;
  //       return {
  //         fill: colorMap[value.extraKey] || "#40a9ff",
  //       };
  //     },
  //     //ranksepFunc: (d: any) => 700,

  //     style: (edge: any) => {
  //       const { value } = edge;
  //       return {
  //         stroke: colorMap[value.extraKey] || "#40a9ff",
  //       };
  //     },
  //     edgeStateStyles: {
  //       hover: {
  //         stroke: "#1890ff",
  //         lineWidth: 2,
  //         endArrow: {
  //           fill: "#1890ff",
  //         },
  //       },
  //     },
  //   },
  //   markerCfg: (cfg: CardNodeCfg) => {
  //     const { edges } = data;
  //     const right: MarkerPosition = "right";
  //     return {
  //       position: right,
  //       show: edges.find((item) => item.source === cfg?.id) !== undefined,
  //     };
  //   },
  //   nodeCfg: {
  //     // style: nodeStyle,
  //   },
  //   behaviors: ["drag-node", "drag-canvas","zoom-canvas"], // Enable node dragging and canvas dragging
  // };

  return <FundFlowGraph {...config} />;
};

export default FlowGraph;
