import { useContext, useState } from "react";
import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { RuleField } from "./RiskAddRuleModal";
import { ruleOperationOptions } from "../components/options";
import { MainContext } from "../../../../../../utils/context";
import { DATA_NEEDED_TYPE as DN_TYPE } from "../../../../../../constants/DataNeededTypes";

interface ExpressionNodeProps {
  data: RuleField;
  id: string;
  rulesExpr: any;
  setRulesExpr: any;
  dataNeeded: any;
  formAutomation: any;
}

export default function RiskExpressionNode(props: ExpressionNodeProps) {
  const { t } = useTranslation();
  const globalContext = useContext(MainContext);
  const formatDate = globalContext?.context.orga?.formatDate || "YYYY/MM/DD";

  const {
    data: exp,
    id,
    rulesExpr,
    setRulesExpr,
    dataNeeded,
    formAutomation,
  } = props;

  const [operatorsOptions, setOperatorsOptions] = useState<any[]>(
    exp.field
      ? ruleOperationOptions.filter(
          (op) => exp.field && op.types?.includes(dataNeeded[exp.field]?.TYPE)
        )
      : []
  );

  const onChangeExpression = (
    on_op?: string,
    on_field?: string,
    on_val?: string
  ) => {
    if (on_op) exp.operator = on_op;
    if (on_val || on_val === "") exp.value = on_val;
    if (on_field) exp.field = on_field;

    setRulesExpr({ ...rulesExpr });
    console.log(rulesExpr);
    console.log(dataNeeded);
  };

  /* Search parent and create/update value */
  const onCreateDeleteExpression = (i: string, workMode = "CREATE") => {
    const addRemoveExpression = (
      i: string,
      t = "",
      parent: RuleField,
      rule: RuleField,
      value: string | RuleField[]
    ) => {
      if (typeof value === "string") {
        if (i === t && typeof parent.value !== "string") {
          const l = i.split("_");
          const currentI = parseInt(l[l.length - 1]);
          if (workMode === "CREATE") {
            parent.value.push({
              operator: "",
              value: "",
              field: "",
            });
          } else {
            parent.value.splice(currentI, 1);
            /* if parent has only one child => child will be parent */
            if (parent.value.length === 1) {
              const e = parent.value[0];
              parent.value = e.value;
              parent.operator = e.operator;
              parent.field = e.field;
            }
          }
        }
      } else {
        value.forEach((r, index) =>
          addRemoveExpression(
            i,
            t + (t ? "_" : "") + index.toString(),
            rule,
            r,
            r.value
          )
        );
      }
    };

    let rule_ = { ...rulesExpr };
    addRemoveExpression(i, "", rule_, rule_, rule_.value);
    setRulesExpr(rule_);
  };

  formAutomation.setFieldsValue({ [`rule_expression_field${id}`]: exp?.field });
  formAutomation.setFieldsValue({
    [`rule_expression_operator${id}`]: exp?.operator,
  });
  formAutomation.setFieldsValue({ [`rule_expression_value${id}`]: exp?.value });

  return typeof exp.value === "string" ? (
    <Form form={formAutomation}>
      <div key={"div" + id}>
        <Row style={{ maxHeight: "8px" }} justify="center" align="middle">
          {/* Field */}
          <Col xs={12} sm={12} md={7} lg={8} xl={8}>
            <Form.Item
              key={"rule_expression_field" + id}
              name={"rule_expression_field" + id}
              rules={[
                {
                  required: true,
                  message: t("errors:REQUIRED", { label: t("FIELD") }),
                },
              ]}
            >
              <Select
                style={{ width: "95%" }}
                defaultValue={exp.field}
                value={exp.field}
                onChange={(value, option: any) => {
                  console.log(option.type);
                  const ops =
                    ruleOperationOptions.filter((op) =>
                      op.types?.includes(option.type)
                    ) || [];
                  setOperatorsOptions(ops);
                  if (!ops.map((op) => op.value)?.includes(exp.operator)) {
                    onChangeExpression(ops?.at(0)?.value, value, "");
                  } else onChangeExpression(undefined, value, "");
                }}
              >
                {Object.keys(dataNeeded).map((item: string) => (
                  <Select.Option
                    key={item}
                    value={item}
                    type={dataNeeded[item]?.TYPE}
                  >
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* Operator */}
          <Col xs={12} sm={12} md={7} lg={8} xl={8}>
            <Form.Item
              key={"rule_expression_operator" + id}
              name={"rule_expression_operator" + id}
              rules={[
                {
                  required: true,
                  message: t("errors:REQUIRED", {
                    label: t("labels:OPERATOR"),
                  }),
                },
              ]}
            >
              <Select
                style={{ width: "95%" }}
                options={operatorsOptions}
                defaultValue={exp.operator}
                value={exp.operator}
                onChange={(value) => onChangeExpression(value)}
                disabled={!exp.field}
              />
            </Form.Item>
          </Col>
          {/* Value */}
          <Col xs={10} sm={10} md={8} lg={8} xl={8}>
            <Form.Item
              key={"rule_expression_value" + id}
              name={"rule_expression_value" + id}
              rules={[
                //{ required: true, message: "Value is required" },
                {
                  validator: (_, value) => {
                    if (!exp.value || exp.value === "") {
                      return Promise.reject(
                        exp.field &&
                          Object.keys(dataNeeded).includes(exp.field) &&
                          dataNeeded[exp.field]?.TYPE === DN_TYPE.DATE
                          ? t("errors:INVALID", {
                              label: t("labels:VALUE"),
                            })
                          : t("errors:REQUIRED", {
                              label: t("labels:VALUE"),
                            })
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              {!exp.field ||
              ![DN_TYPE.BOOLEAN, DN_TYPE.DATE, DN_TYPE.DATETIME].includes(
                dataNeeded[exp.field]?.TYPE
              ) ? (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <Input
                    disabled={!exp.field}
                    type={
                      exp.field
                        ? dataNeeded[exp.field]?.TYPE === DN_TYPE.NUMBER
                          ? "number"
                          : "string"
                        : "string"
                    }
                    value={exp.value}
                    defaultValue={exp.value}
                    onChange={(e) =>
                      onChangeExpression(
                        undefined,
                        undefined,
                        e.target.value.toString()
                      )
                    }
                    min={
                      !exp.field || dataNeeded[exp.field]?.TYPE !== DN_TYPE.DATE
                        ? undefined
                        : new Date().toISOString().split("T")[0]
                    }
                  />
                  {id && (
                    <MinusCircleOutlined
                      style={{
                        fontSize: "20px",
                        color: "#ff671f",
                        display: "flex",
                        marginLeft: "8px",
                        border: 0,
                      }}
                      onClick={() => onCreateDeleteExpression(id, "DELETE")}
                    />
                  )}
                </span>
              ) : dataNeeded[exp.field]?.TYPE === DN_TYPE.BOOLEAN ? (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <Select
                    style={{ width: "95%" }}
                    options={[
                      { value: "true", label: "True" },
                      { value: "false", label: "False" },
                    ]}
                    value={exp.value}
                    defaultValue={exp.value}
                    onChange={(value: string) =>
                      onChangeExpression(undefined, undefined, value)
                    }
                  />
                  <MinusCircleOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ff671f",
                      display: "flex",
                      marginLeft: "8px",
                    }}
                    onClick={() => onCreateDeleteExpression(id, "DELETE")}
                  />
                </span>
              ) : dataNeeded[exp.field]?.TYPE === DN_TYPE.DATETIME ? (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <DatePicker
                    style={{ width: "95%" }}
                    disabledDate={(current) => {
                      return (
                        current &&
                        dayjs(current).isBefore(dayjs().startOf("day"))
                      );
                    }}
                    showTime={{ format: "HH:mm:ss" }}
                    format={formatDate}
                    onChange={(value) => {
                      onChangeExpression(
                        undefined,
                        undefined,
                        value ? value.toISOString() : ""
                      );
                    }}
                    value={exp.value ? dayjs(exp.value) : undefined}
                  />
                  <MinusCircleOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ff671f",
                      display: "flex",
                      marginLeft: "8px",
                    }}
                    onClick={() => onCreateDeleteExpression(id, "DELETE")}
                  />
                </span>
              ) : (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <DatePicker
                    style={{ width: "95%" }}
                    disabledDate={(current) => {
                      return (
                        current &&
                        dayjs(current).isBefore(dayjs().startOf("day"))
                      );
                    }}
                    showTime={false}
                    format={formatDate}
                    onChange={(value) => {
                      onChangeExpression(
                        undefined,
                        undefined,
                        value ? value.format("YYYY-MM-DD") : ""
                      );
                    }}
                    value={exp.value ? dayjs(exp.value) : undefined}
                  />
                  <MinusCircleOutlined
                    style={{
                      fontSize: "20px",
                      color: "#ff671f",
                      display: "flex",
                      marginLeft: "8px",
                    }}
                    onClick={() => onCreateDeleteExpression(id, "DELETE")}
                  />
                </span>
              )}
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  ) : (
    <></>
  );
}
