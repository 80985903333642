import {
  IDataNeeded,
  IOptionDataNeeded,
} from "../../../../../interfaces/dataNeededURL";

type ActionField = {
  TYPE: string;
  POSITION?: string;
  UNIT?: string;
};
export type ActionFields = {
  [key: string]: ActionField;
};
export type RiskFields = {
  [key: string]: ActionField[] | ActionField;
};

export const ACTION_FIELDS: ActionFields = {
  ACTION_TYPE: { TYPE: "STRING" },
  RISK_STATUS: { TYPE: "STRING" },
  DUE_DATE: { TYPE: "DATE" },
  RISK_QUANTITY: { TYPE: "NUMBER" },
  /* RISK_WASTE: { TYPE: "NUMBER" },
  RISK_REVENUE: { TYPE: "NUMBER" }, */
  ACTION_IMPACT_PURCHASE_COST: { TYPE: "NUMBER" },
  ACTION_IMPACT_FREIGHT_COST: { TYPE: "NUMBER" },
  EXECUTION_TYPE: { TYPE: "STRING" },
};
export const RISK_FIELDS: IOptionDataNeeded[] = [
  {
    value: "ACTION",
    type: "OBJECT",
    children: [
      {
        value: "CONTEXT",
        type: "OBJECT",
        children: [
          {
            value: "NAME",
            type: "STRING",
          },
          {
            value: "STATUS",
            type: "STRING",
          },
        ],
      },
      {
        value: "NAME",
        type: "STRING",
      },
    ],
  },
  {
    value: "CONTEXT",
    type: "OBJECT",
    children: [
      {
        value: "ITEM_CODE",
        type: "STRING",
      },
      {
        value: "LOCATION_CODE",
        type: "STRING",
      },
      {
        value: "QUANTITY_AT_RISK",
        type: "NUMBER",
      },
    ],
  },
  {
    value: "DUE_DATE",
    type: "DATE",
  },
  {
    value: "REASON",
    type: "STRING",
  },
  {
    value: "STATUS",
    type: "STRING",
  },
  {
    value: "RISK_CONF_NAME",
    type: "STRING",
  },
];

export const OPERATOR_FIELDS: ActionFields = {
  COUNT: { TYPE: "NUMBER", UNIT: "" },
  SUM: { TYPE: "NUMBER", UNIT: "" },
  AVG: { TYPE: "NUMBER", UNIT: "" },
  WAVG: { TYPE: "NUMBER", UNIT: "" },
};

export const STATS_CONFIGS: any[] = [
  {
    title: "Status by Action",
    valueField: "SUM",
    categoryField: "ACTION_TYPE",
    seriesField: "RISK_STATUS",
    value: "20000",
  },
];

export const CHART_CONFIGS: any[] = [
  {
    fields: {
      valueField: { value: "COUNT", type: "NUMBER" },
      categoryField: [{ value: "ACTION.NAME", type: "STRING" }],
      groupField: { value: "DUE_DATE", type: "DATE" },
    },
    title: "",
    type: "LINE_CHART",
    config: {
      value_field: {
        field: "",
        sort: "",
        filter: "",
        operator: "",
      },
      group_field: {
        field: "",
        sort: "",
        filter: "",
        limit: "",
      },
      category_field: [
        [
          {
            field: "",
            sort: "",
            filter: "",
          },
        ],
      ],
    },
  },
  {
    fields: {
      valueField: {
        value: "QUANTITY_ON_HAND",
        type: "NUMBER",
      },
      categoryField: [
        {
          value: "",
          type: "STRING",
        },
      ],
      groupField: {
        value: "DATE",
        type: "DATE",
      },
    },
    title: "",
    type: "LINE_CHART",
    config: {
      value_field: {
        field: "",
        sort: "",
        filter: "",
        operator: "",
      },
      group_field: {
        field: "",
        sort: "",
        filter: "",
        limit: "",
      },
      category_field: [
        [
          {
            field: "",
            sort: "",
            filter: "",
          },
        ],
      ],
    },
    color_pallet: [
      {
        value: "INVENTORY",
        color: "#008BC7",
      },
      {
        value: "NEW_INVENTORY_PLAN",
        color: "#0D8B07",
      },
    ],
    pallet_type: "By value",
    unit: "tt",
  },
];

export const CHART_DATA = [
  {
    ACTION_TYPE: "Early custom clearance",
    RISK_STATUS: "Accepted",
    SUM: 24,
  },
  {
    ACTION_TYPE: "Expedited custom clearance",
    RISK_STATUS: "Accepted",
    SUM: 20,
  },
  {
    ACTION_TYPE: "Expedited truck transfer",
    RISK_STATUS: "Accepted",
    SUM: 25,
  },
  {
    ACTION_TYPE: "No action",
    RISK_STATUS: "Accepted",
    SUM: 10,
  },
  {
    ACTION_TYPE: "Expedited truck transfer",
    RISK_STATUS: "Rejected",
    SUM: 14,
  },
  {
    ACTION_TYPE: "Early custom clearance",
    RISK_STATUS: "Rejected",
    SUM: 20,
  },
  {
    ACTION_TYPE: "Expedited custom clearance",
    RISK_STATUS: "Rejected",
    SUM: 14,
  },
  {
    ACTION_TYPE: "Rerouted truck transfer",
    RISK_STATUS: "Accepted",
    SUM: 15,
  },
  {
    ACTION_TYPE: "Rerouted truck transfer",
    RISK_STATUS: "Rejected",
    SUM: 13,
  },
  {
    ACTION_TYPE: "Deferred custom clearance",
    RISK_STATUS: "Accepted",
    SUM: 6,
  },
  {
    ACTION_TYPE: "Deferred custom clearance",
    RISK_STATUS: "Rejected",
    SUM: 13,
  },
  {
    ACTION_TYPE: "No action",
    RISK_STATUS: "Rejected",
    SUM: 3,
  },
  {
    ACTION_TYPE: "Expedited custom clearance",
    RISK_STATUS: "In progress",
    SUM: 2,
  },
  {
    ACTION_TYPE: "Expedited custom clearance",
    RISK_STATUS: "Open",
    SUM: 1,
  },
  {
    ACTION_TYPE: "Expedited truck transfer",
    RISK_STATUS: "In progress",
    SUM: 1,
  },
  {
    ACTION_TYPE: "Expedited truck transfer",
    RISK_STATUS: "Open",
    SUM: 1,
  },
  {
    ACTION_TYPE: "Rerouted truck transfer",
    RISK_STATUS: "Open",
    SUM: 1,
  },
  {
    ACTION_TYPE: "Early custom clearance",
    RISK_STATUS: "Open",
    SUM: 1,
  },
  {
    ACTION_TYPE: "No action",
    RISK_STATUS: "Open",
    SUM: 1,
  },
  {
    ACTION_TYPE: "Early custom clearance",
    RISK_STATUS: "In progress",
    SUM: 2,
  },
  {
    ACTION_TYPE: "Deferred custom clearance",
    RISK_STATUS: "In progress",
    SUM: 2,
  },
  {
    ACTION_TYPE: "Deferred custom clearance",
    RISK_STATUS: "Open",
    SUM: 1,
  },
  {
    ACTION_TYPE: "Rerouted truck transfer",
    RISK_STATUS: "In progress",
    SUM: 1,
  },
];

const copilot_config = [
  {
    dashboard_id: "analysis",
    dashboard_config: {
      /* FILTER HEADER */
      filters_header: [
        {
          title: "",
          field: "",
          op: "",
        },
      ],

      /* STATS WIDGETS */
      stat_widgets: [
        {
          title: "",
          field: "",
          calculation: "SUM" /* SUM, AVG, WAVG */,
        },
      ],

      /* CHART WIDGETS */
      chart_widgets: [
        {
          title: "",
          type: "",
          config: {
            valueField: {
              field: "",
              sort: "",
              filter: "",
            },
            categoryField: [
              {
                field: "",
                sort: "",
                filter: "",
              },
            ],
            groupField: {
              field: "",
              sort: "",
              filter: "",
              limit: "",
            },
          },
        },
      ],
    },
  },
];
