/** @format */
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MainContext } from "../../../../utils/context";
import { SharedContext } from "../context/sharedContext";
import PredefinedHeader from "./Header";
import SaveCancelBar from "./SaveCancelBar";
import { PREDEFINED_TYPES } from "../../../../constants/predefinedTypes";
import PreActionDetails from "./details/PreActionDetails";
import PreExecutionDetails from "./details/PreExecutionDetails";
import PreRiskDetails from "./details/PreRiskDetails";
import { WORK_MODE } from "../../../../constants/workModes";

interface Props {
  typeComponent: string;
}
const AddUpdatePredefineds: React.FC<Props> = (props: Props) => {
  const { state } = useLocation();
  const { record, mode } = state;

  /**props */
  const { typeComponent } = props;
  /**local vars */
  const { form, setWorkMode, setRecord, workMode } = useContext(SharedContext);

  /** global app context */
  const globalContext = useContext(MainContext);
  useEffect(() => {
    // these two vars need to be set in this parent component  that is wrapped in the shared context so it can be used in the children components
    setWorkMode(mode || WORK_MODE.CREATE);
    setRecord(record || {});
    if (!globalContext || !globalContext.context.orga) {
      return;
    }
  }, [mode]);

  if (!globalContext) {
    return <></>;
  }

  return (
    <>
      <div
        style={{
          top: 30,
          background: "transparent",
          display: "flex",
          flexDirection: "column",
          paddingBottom: 20,
          alignItems: "left",
        }}
      >
        {/**page header component */}
        <PredefinedHeader typeComponent={typeComponent} />
        {/**Action details  */}
        {typeComponent === PREDEFINED_TYPES.ACTION_PREDEFINED && (
          <PreActionDetails typeComponent={typeComponent} />
        )}
        {/**Execution details  */}
        {typeComponent === PREDEFINED_TYPES.EXECUTION_PREDEFINED && (
          <PreExecutionDetails typeComponent={typeComponent} />
        )}
        {/**Execution details  */}
        {typeComponent === PREDEFINED_TYPES.RISK_PREDEFINED && (
          <PreRiskDetails typeComponent={typeComponent} />
        )}
      </div>
      {/* FooterComponent */}
      {workMode && workMode !== WORK_MODE.VIEW && (
        <SaveCancelBar typeComponent={typeComponent} form={form} />
      )}
    </>
  );
};

export default AddUpdatePredefineds;
