export interface ChartConf {
  fields: any;
  title: string;
  type: string;
  dual: boolean;
  config: { value_field: any; category_field: any[]; group_field: any };
}

export const defaultChartConf: ChartConf = {
  fields: {},
  title: "",
  type: "",
  dual: false,
  config: {
    value_field: {
      field: "",
      sort: "/",
      filter: "",
      operator: "",
    },
    category_field: [
      {
        field: "",
        sort: "/",
        filter: "",
      },
    ],
    group_field: {
      field: "",
      sort: "/",
      filter: "",
      limit: "",
    },
  },
};
