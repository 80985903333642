import { Column, ColumnConfig } from "@ant-design/plots";
import { actionTypesColors } from "../../../common/chartColors";
import EmptyChartCard from "../../../common/EmptyChart";

const ActionTypeOverTime = (props: any) => {
  let { data } = props;
  if (data.length === 0) {
    return <EmptyChartCard />;
  }
  const config: ColumnConfig = {
    data,
    xField: "risk_date",
    yField: "value",
    seriesField: "action_type",
    isStack: true,
    color: ({ action_type }) => {
      return actionTypesColors(action_type);
    },
    label: {
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    tooltip: {
      shared: true,
      showMarkers: false,
      formatter: ({ action_type, value }: any) => ({
        name: action_type,
        value: `${value} risks`,
      }),
    },
    legend: {
      position: "bottom",
    },
    xAxis: {
      label: {
        autoRotate: true,
      },
    },
    columnWidthRatio: 0.8,
  };

  return <Column {...config} height={300} />;
};

export default ActionTypeOverTime;
