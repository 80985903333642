import { Card, Button } from "antd";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-components";
import { Link, useNavigate } from "react-router-dom";
import { WORK_MODE } from "../../../../../constants/workModes";
import { useTranslation } from "react-i18next";

const UserConfPageHeader = ({
  workModeUser,
  record,
}: {
  workModeUser: any;
  record: any;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <PageHeader
        title={
          <span style={{ alignContent: "start", marginLeft: 0 }}>
            <Link style={{ fontSize: 16, color: "#0082BA" }} to="/menu/users">
              <span
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                {t("USER_BACK_TO")}
                <ArrowLeftOutlined
                  style={{
                    marginInlineStart: 0,
                    marginInlineEnd: 8,
                    color: "#0082BA",
                    transform:
                      getComputedStyle(document.body).direction === "rtl"
                        ? "scaleX(-1)"
                        : "none", // Flip arrow for RTL
                  }}
                />
              </span>
            </Link>
          </span>
        }
        style={{ marginInlineStart: -20 }}
      />

      <div
        style={{
          height: "3%",
          marginTop: -10,
          width: "100%",
          marginBottom: 10,
        }}
      >
        <Card>
          <p
            style={{
              fontWeight: "bold",
              fontSize: 16,
              color: "#2A333B",
            }}
          >
            {t("DETAILS", { item: t("USER") })}
            {workModeUser === WORK_MODE.VIEW && (
              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  marginRight: 10,
                }}
                onClick={() =>
                  navigate("/menu/users/config", {
                    state: {
                      record: record,
                      workModeUser: WORK_MODE.UPDATE,
                    },
                  })
                }
                icon={<EditOutlined style={{ color: "#004C97" }} />}
              ></Button>
            )}
          </p>
        </Card>
      </div>
    </>
  );
};

export default UserConfPageHeader;
