import CrudService from "./main/crud.service";
import { Context } from "../interfaces/context";

const resource = { name: "predefined/risks", isPublic: true };

class PredefinedRisksService extends CrudService {
  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(resource, context, setContext, path);
  }

  async getPredefinedRisk() {
    const { data } = await this.get(
      `/organizations/${this?.context?.orga?.id}/predefined/risks?limit=500&offset=0&full=copilot&full=actions&order=desc&sort=created_at `
    );
    return data;
  }

  async getPredefinedRiskGlobal() {
    const { data } = await this.get(
      `/predefined/risks/?limit=500&offset=0&full=copilot&full=reasons&full=actions&order=desc&sort=created_at`
    );
    return data;
  }
}

export default PredefinedRisksService;
