import { setPredefinedActionsOrga } from "../../reducers/predefinedActionsOrga.Reducer";

/** dispatch predefined risk */
export const InitActions = async (actionsServices: any, dispatch: any) => {
  return actionsServices
    .getPredefinedAction()
    .then(async (res: any) => {
      dispatch(setPredefinedActionsOrga(res?.items));
    })
    .catch((err: any) => {
      console.log(err);
    });
};
