import { PageHeader } from "@ant-design/pro-components";
import { Button, Card, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { WORK_MODE } from "../../../../constants/workModes";
import { useEffect, useContext } from "react";
import {
  SharedContextFunctionsInterface,
  getPredefinedValues,
  useSharedContextFunctions,
} from "../logic/PredefinedsLogic";
import { SharedContext } from "../context/sharedContext";
import { useTranslation } from "react-i18next";
import { PERMISSIONS_LIST } from "../../../../constants/permissions";
import { isPermission } from "../../../../utils/permissionsFunctions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/store";

const PredefinedHeader: React.FC<{
  typeComponent: string;
}> = (props) => {
  const { typeComponent } = props;
  const { workMode, form, setWorkMode } = useContext(SharedContext);
  const { t } = useTranslation();
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  const { goToTable } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  useEffect(() => {}, [workMode]);

  // Use the utility function to get computed values
  const { targetRoute, backToLabel, configLabel } = getPredefinedValues(
    typeComponent,
    t
  );

  return (
    <div>
      <PageHeader
        title={
          <span style={{ alignContent: "start", marginLeft: 0 }}>
            <Popconfirm
              placement="top"
              key="delete"
              style={{ zIndex: 9999999 }}
              disabled={false}
              title={t("errors:LEAVING_PAGE_MESSAGE")}
              onConfirm={async () => {
                form.resetFields();
                await goToTable(typeComponent);
              }}
              okText={t("NO")}
              cancelText={t("YES")}
            >
              <Link
                style={{ fontSize: 16, color: "#0082BA" }}
                to={targetRoute}
                onClick={(e) => {
                  if (form.isFieldsTouched() && workMode !== WORK_MODE.VIEW) {
                    e.preventDefault();
                  } else {
                    goToTable(typeComponent);
                    form.resetFields();
                  }
                }}
              >
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                  }}
                >
                  {backToLabel}
                  <ArrowLeftOutlined
                    style={{
                      marginInlineStart: 0,
                      marginInlineEnd: 8,
                      color: "#0082BA",
                      transform:
                        getComputedStyle(document.body).direction === "rtl"
                          ? "scaleX(-1)"
                          : "none", // Flip arrow for RTL
                    }}
                  />
                </span>
              </Link>
            </Popconfirm>
          </span>
        }
        style={{ marginInlineStart: -20 }}
      />

      <div
        style={{
          height: "3%",
          marginTop: -10,
          width: "100%",
          marginBottom: 10,
        }}
      >
        <Card>
          <p
            style={{
              fontWeight: "bold",
              fontSize: 16,
              color: "#2A333B",
            }}
          >
            {configLabel}
            {workMode === WORK_MODE.VIEW &&
            (isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN) ||
              isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)) ? (
              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  marginRight: 10,
                }}
                icon={<EditOutlined style={{ color: "#004C97" }} />}
                onClick={() => setWorkMode(WORK_MODE.UPDATE)}
              ></Button>
            ) : null}
          </p>
        </Card>
      </div>
    </div>
  );
};

export default PredefinedHeader;
