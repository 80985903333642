import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { Role } from "../../interfaces/role";

const initialState: Array<Role> = [];

export const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    addRole: (state, action: PayloadAction<Role>) => {
      state.push(action.payload);
    },
    setRoles: (state, action: PayloadAction<Array<Role>>) => {
      return action.payload;
    },
    updateRole: (state, action: PayloadAction<Role>) => {
      const { id, name } = action.payload;
      const existingRole = state.find((Role) => Role.id === id);
      if (existingRole) {
        existingRole.name = name;
      }
    },
  },
});
export const { addRole, setRoles, updateRole } = roleSlice.actions;

export const RoleSelector = (state: RootState) => state.roleReducer;

export default roleSlice.reducer;
