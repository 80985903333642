export const DATA_NEEDED_TYPE = {
  STRING: "STRING",
  NUMBER: "NUMBER",
  DATE: "DATE",
  DATETIME: "DATETIME",
  BOOLEAN: "BOOLEAN",
  AMOUNT: "AMOUNT",
  ARRAY: "ARRAY",
};

export const DATA_NEEDED_TYPES = [
  { value: DATA_NEEDED_TYPE.STRING, label: "String" },
  { value: DATA_NEEDED_TYPE.NUMBER, label: "Number" },
  { value: DATA_NEEDED_TYPE.DATE, label: "Date" },
  { value: DATA_NEEDED_TYPE.DATETIME, label: "Date time" },
  { value: DATA_NEEDED_TYPE.BOOLEAN, label: "Boolean" },
  { value: DATA_NEEDED_TYPE.AMOUNT, label: "Amount" },
  { value: DATA_NEEDED_TYPE.ARRAY, label: "Array" },
];
