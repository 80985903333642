import { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Space, Row, Col, message } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import RiskExpressionForm from "../automation/risks/RiskExpressionForm";
import { riskStringFormulaComponent } from "../automation/risks/RiskStringFormula";
import { WORK_MODE } from "../../../../../constants/workModes";
import { MainContext } from "../../../../../utils/context";
import { RootState } from "../../../../../redux/store/store";
import { isPermission } from "../../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../../constants/permissions";

export interface RuleField {
  operator: string;
  field?: string;
  value: string | RuleField[];
}

export function AddPolicyModal(props: any) {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /** translation */
  const { t } = useTranslation();
  /**other declarations */
  const globalContext = useContext(MainContext);
  const orga = globalContext?.context?.orga;
  const {
    closeAutomationModal,
    actionAutomations,
    setActionAutomations,
    automationRecord,
    workModeAutomation,
    setIsAutoModalDirty,
    isAutoModalDirty,
    dataNeeded,
  } = props;

  /* Declarations */
  const ruleExprInitialState =
    workModeAutomation === WORK_MODE.CREATE
      ? {
          operator: "",
          field: "",
          value: "",
        }
      : JSON.parse(JSON.stringify(automationRecord?.rules));
  const [rulesExpr, setRulesExpr] = useState<RuleField>(ruleExprInitialState);

  /* Functions */
  /* Track rule changes */
  useEffect(() => {
    if (
      !isAutoModalDirty &&
      JSON.stringify(ruleExprInitialState) !== JSON.stringify(rulesExpr)
    ) {
      setIsAutoModalDirty(true);
    }
  }, [rulesExpr]);
  /* Handle Create Update Automation */
  const onFinish = (values: any) => {
    form_policy
      .validateFields()
      .then(() => {
        const newRule = {
          key: Date.now(), // key needed in sortable table
          name: values.rule_description,
          rules: rulesExpr,
        };
        if (workModeAutomation === WORK_MODE.CREATE) {
          setActionAutomations([...actionAutomations, newRule]);
        } else {
          setActionAutomations(
            actionAutomations?.map((r: any) => {
              return r.key === automationRecord?.key ? newRule : r;
            })
          );
        }
        closeAutomationModal();
      })
      .catch((err) => {
        message.warning(t("errors:REQUIRED_FIELDS"));
      });
  };

  const initialValues =
    workModeAutomation === WORK_MODE.CREATE
      ? {
          rule_description: "",
          rule_action: "",
          rule_reason: "",
        }
      : {
          rule_description: automationRecord?.name,
          rule_action: automationRecord?.action,
          rule_reason: automationRecord?.reason,
        };

  const [form_policy] = Form.useForm();
  return (
    <Form
      form={form_policy}
      name="dynamic_form_item"
      {...{ labelCol: { style: { width: "90px" } } }}
      labelAlign="left"
      labelWrap
      wrapperCol={{ flex: 1 }}
      style={{ marginInline: "20px" }}
      initialValues={initialValues}
    >
      <Row>
        {/* Action Name */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={t("DESCRIPTION")}
            name="rule_description"
            rules={[
              {
                required: true,
                message: t("errors:REQUIRED", { label: t("DESCRIPTION") }),
              },
            ]}
          >
            <Input
              disabled={workModeAutomation === WORK_MODE.VIEW}
              onChange={() => {
                !isAutoModalDirty && setIsAutoModalDirty(true);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        {/* Action rule formula */}
        <Form.Item label={t("FORMULA")}>
          <>{riskStringFormulaComponent(rulesExpr, dataNeeded, orga)}</>
        </Form.Item>
      </Row>
      {workModeAutomation !== WORK_MODE.VIEW && (
        <>
          {/* Expressions */}
          <RiskExpressionForm
            data={rulesExpr}
            id={""}
            rulesExpr={rulesExpr}
            setRulesExpr={setRulesExpr}
            dataNeeded={dataNeeded}
            formAutomation={form_policy}
          />
          {isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) && (
            <Row>
              <Col offset={20} style={{ marginTop: "20px" }}>
                <Form.Item>
                  <Space>
                    <Button onClick={() => closeAutomationModal()}>
                      {t("CANCEL")}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => onFinish(form_policy.getFieldsValue(true))}
                    >
                      {t("SAVE")}
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      )}
    </Form>
  );
}
