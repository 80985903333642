import {
  PaginationParams,
  PaginationResult,
} from "../interfaces/PaginateParams";

export function calculatePagination({
  current,
  pageSize,
  order = "desc",
  sort = "created_at",
  format,
}: PaginationParams): PaginationResult {
  if (!current || current <= 0) {
    current = 1;
  }
  if (!pageSize || pageSize <= 0) {
    pageSize = 20;
  }

  const offset = (current - 1) * pageSize;
  const limit = parseInt(pageSize.toString(), 10);

  return {
    offset,
    limit,
    order,
    ...(!!sort ? { sort } : {}),
    ...(format ? { format } : {}),
  };
}
