import { Pie, PieConfig } from "@ant-design/plots";
import { actionTypesColors } from "../../../common/chartColors";
import EmptyChartCard from "../../../common/EmptyChart";

const OpenRiskByActionType = (props: any) => {
  let { data } = props;
  if (data.length === 0) {
    return <EmptyChartCard />;
  }
  const config: PieConfig = {
    appendPadding: 0,
    data,
    angleField: "value",
    colorField: "action_type",
    color: ({ action_type }) => {
      return actionTypesColors(action_type);
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    radius: 0.6,
    label: {
      type: "spider",
      labelHeight: 28,
      content: ({ value }) => `${value}\n risks`,
      style: {
        fontSize: 12,
        textAlign: "center",
      },
    },
    tooltip: {
      shared: true,
      showMarkers: false,
      formatter: ({ action_type, value }: any) => ({
        name: action_type,
        value: `${value} risks`,
      }),
    },
    legend: {
      position: "bottom",
    },
    style: {
      marginInline: -18,
    },
  };

  return <Pie {...config} height={300} />;
};

export default OpenRiskByActionType;
