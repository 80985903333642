/** @format */
import React, { useContext, useState } from "react";
import { Form, Spin, Modal, notification } from "antd";
import { Space } from "antd";
import ConfigScopesModal from "./ConfigScopesModal";
import { useLocation } from "react-router-dom";
import { WORK_MODE } from "../../../../../constants/workModes";
import { PAGES } from "../../../../../constants/pages";
import { MainContext } from "../../../../../utils/context";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/userLogic";
import UserDetails from "./UserDetails";
import { SharedContext } from "../../context/sharedContext";
import UserAccountDetails from "./UserAccountDetails";
import SaveCancel from "../../../../common/SaveCancel";
import UserConfPageHeader from "./UserConfPageHeader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import ScopesService from "../../../../../services/scopes.service";
import { UUID } from "crypto";
import { REQUEST } from "../../../../../services/functions/handle-api-errors/const";
import handleApiError from "../../../../../services/functions/handle-api-errors/handleApiError";

const UserConfiguration: React.FC = () => {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /** translation */
  const { t } = useTranslation();
  /** component states */
  const { state } = useLocation();
  let { record, workModeUser } = state ?? {
    record: null,
    workModeUser: WORK_MODE.CREATE,
  };
  /** local vars */
  const [loadingSave, setLoadingSave] = useState(false);

  const [formDetailsUser] = Form.useForm();
  const [accountDetailsForm] = Form.useForm();
  /**confs shared context */
  const { scopeRecord, getLoading, userScopes, setUserScopes, openScopeModal } =
    useContext(SharedContext);
  /** global app context */
  const globalContext = useContext(MainContext);
  /** users shared logic */
  const { goToUsers, onFinishSaveUser, closeScopeModal } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;

  if (!globalContext) {
    return <></>;
  }

  const scopesService = new ScopesService(
    globalContext.context,
    globalContext.setContext,
    ""
  );

  const attachUserScope = async (userId: UUID) => {
    await scopesService
      .attachScopesToUsers({
        scopes: [
          ...userScopes,
          {
            key: "ERROR_DATA.WARNING.DETAILS.DATA",
            value: "",
            all: true,
          },
          {
            key: "ERROR_DATA.INFO.DETAILS.DATA",
            value: "",
            all: true,
          },
          {
            key: "ERROR_DATA.ERROR.DETAILS.DATA",
            value: "",
            all: true,
          },
          {
            key: "ERROR_DATA.INFO.CODE",
            value: "",
            all: true,
          },
          {
            key: "ERROR_DATA.WARNING.CODE",
            value: "",
            all: true,
          },
          {
            key: "ERROR_DATA.ERROR.CODE",
            value: "",
            all: true,
          },
          {
            key: "ERROR_DATA.INFO.MODULE",
            value: "",
            all: true,
          },
          {
            key: "ERROR_DATA.WARNING.MODULE",
            value: "",
            all: true,
          },
          {
            key: "ERROR_DATA.ERROR.MODULE",
            value: "",
            all: true,
          },
        ],
        users: [userId],
      })
      .catch((err) => {
        notification.error({
          message: `${t("errors:Error on updating user scopes")}`,
          placement: "bottomRight",
        });
      });
  };

  const onFinish = async () => {
    console.log(record);
    accountDetailsForm
      .validateFields()
      .then((values) => {
        const roles = values.role;
        setLoadingSave(true);
        formDetailsUser
          .validateFields()
          .then(async (values: any) => {
            console.log(values);
            await onFinishSaveUser(
              record,
              values,
              roles,
              workModeUser,
              setLoadingSave
            )
              .then((response: any) => {
                console.log(response);
                if (workModeUser !== WORK_MODE.VIEWPROFILE) {
                  attachUserScope(response?.data?.id);
                }
              })
              .catch((err) => {
                setLoadingSave(false);
                handleApiError(err, REQUEST.UPDATE);
              });
          })
          .catch((err) => {
            setLoadingSave(false);
            notification.error({
              message: `${t("errors:REQUIRED_FIELDS")}`,
              placement: "bottomRight",
            });
          });
      })
      .catch((err) => {
        setLoadingSave(false);
        notification.error({
          message: `${t("errors:REQUIRED_FIELDS")}`,
          placement: "bottomRight",
        });
      });
  };
  return (
    <>
      <Spin spinning={getLoading} size="large" tip="Loading...">
        <div
          style={{
            top: 30,
            background: "transparent",
            display: "flex",
            flexDirection: "column",
            paddingBottom: 20,
            alignItems: "left",
          }}
        >
          {/**Page header */}
          <UserConfPageHeader workModeUser={workModeUser} record={record} />
          {/** User details collapse */}
          <UserDetails
            workModeUser={workModeUser}
            record={record}
            globalContext={globalContext}
            formDetailsUser={formDetailsUser}
          />
          <UserAccountDetails
            workModeUser={workModeUser}
            record={record}
            accountDetailsForm={accountDetailsForm}
          />
          <div
            style={{
              marginInlineStart: -23,
              backgroundColor: "white",
              zIndex: 999,
              position: "fixed",
              width: "100%",
              bottom: 0,
            }}
          >
            <div
              style={{
                marginBottom: 15,
                marginTop: 5,
                alignSelf: "left",
                marginInlineStart: 15,
                width: "30%",
                zIndex: 99999,
                height: 25,
              }}
            >
              <Space size={"small"}>
                {workModeUser !== WORK_MODE.VIEW && (
                  <SaveCancel
                    className="button-group"
                    confirmLoading={loadingSave}
                    onClickSave={() => {
                      onFinish();
                    }}
                    onClickCancel={() => {
                      goToUsers();
                    }}
                  />
                )}
              </Space>
            </div>
          </div>
          <Modal
            destroyOnClose={true}
            title={t("SCOPE_CONFIGURATION")}
            open={openScopeModal}
            onCancel={closeScopeModal}
            width={1000}
            footer={null}
          >
            <ConfigScopesModal
              closeScopeModal={closeScopeModal}
              userScopes={userScopes}
              setUserScopes={setUserScopes}
              workModeScope={workModeUser}
              scopeRecord={scopeRecord}
              updateSource={PAGES.USERS}
            />
          </Modal>
        </div>
      </Spin>
    </>
  );
};

export default UserConfiguration;
