import { ProColumns } from "@ant-design/pro-components";
import MenuOptions from "../../../common/menu/MenuOptions";
import { WORK_MODE } from "../../../../constants/workModes";
import { useContext, useState } from "react";
import { SharedContext } from "../context/sharedContext";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/OrganizationLogic";
import { Currencies } from "../data/data.currency";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/store";
import { isPermission } from "../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../constants/permissions";
type CurrencyCode = keyof typeof Currencies;

const OrganizationTableDef: any = () => {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );

  const [confirmDeleteLoading, setConfirmDeleteLoading] = useState(false);
  /** Logic */
  const { Delete, goToConfig } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;

  /** Shared vars */
  const { setRecord, valueEnumCurrency, setWorkMode } =
    useContext(SharedContext);
  const { t } = useTranslation();

  // Define columns
  const columns: ProColumns<any>[] = [
    {
      title: t("ORGANIZATION_NAME"),
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      valueType: "text",
      render: (text: any, record: any, index: any) => {
        return <span>{text}</span>;
      },
    },

    {
      title: t("DESCRIPTION"),
      dataIndex: "description",
      sorter: false,
      valueType: "text" as const,
      render: (text: any, record: any, index: any) => {
        return <span>{record?.description}</span>;
      },
    },

    {
      title: t("CURRENCY"),
      dataIndex: "currency",
      valueType: "select" as const,
      valueEnum: valueEnumCurrency,
      fieldProps: {
        showSearch: true, // Enable search in filter
        optionFilterProp: "label", // Search by label
        filterOption: (input: any, option: any) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      },
      render: (text: any, record: any, index: any) => {
        const currencyCode: CurrencyCode = record?.currency;
        const currencyName = Currencies[currencyCode];
        return <span>{record?.currency + " : " + currencyName}</span>;
      },
    },

    isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)
      ? {
          title: t("MORE_ACTIONS"),
          valueType: "option",
          key: "option",
          align: "center",
          render: (text, record) => (
            <span>
              <MenuOptions
                viewRecord={() => {
                  setWorkMode(WORK_MODE.VIEW);
                  setRecord(record);
                  goToConfig(record, WORK_MODE.VIEW);
                }}
                editRecord={() => {
                  setWorkMode(WORK_MODE.UPDATE);
                  setRecord(record);

                  goToConfig(record, WORK_MODE.UPDATE);
                }}
                deleteRecord={() => {
                  Delete(record.id, setConfirmDeleteLoading);
                }}
                title={t("errors:CONFIRM_DELETE_MESSAGE")}
                confirmDeleteLoading={confirmDeleteLoading}
              />
            </span>
          ),
        }
      : {},
  ];

  return columns;
};

export default OrganizationTableDef;
