/** @format */

import React, { useEffect, useState } from "react";
import { Button, Layout, Menu } from "antd";
import "../../../assets/style/css/App.css";
import { FaCaretLeft } from "react-icons/fa6";

const { Sider } = Layout;

const SiderSubMenuLayout: React.FC<any> = (props: any) => {
  const {
    menuItems,
    menuTitle,
    setMenuItems,
    siderKey,
    selectedKeys,
    handleMenuSelect,
  } = props;

  return (
    <Sider
      key={siderKey}
      collapsed={false}
      width={"auto"}
      theme="light"
      className="gradient-bg"
      style={{
        position: "absolute",
        minHeight: "calc(100vh - 71px)",
        zIndex: 99,
        transition: "transform 200ms",
      }}
    >
      <br></br>
      <span
        style={{
          fontWeight: "bold",
          marginInlineStart: "18px",
        }}
      >
        {menuTitle}
      </span>
      <br></br>
      <br></br>
      <Menu
        theme="light"
        mode="inline"
        items={menuItems}
        selectedKeys={selectedKeys}
        onSelect={handleMenuSelect}
        style={{
          color: "#606060",
          backgroundColor: "transparent",
          borderInline: 1,
          flexGrow: 1,
        }}
      />
      <div
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          padding: "10px 16px",
          textAlign: "center",
        }}
      >
        <Button
          style={{ border: 0 }}
          icon={<FaCaretLeft />}
          onClick={() => {
            setMenuItems([]);
          }}
        >
          {"Collapse"}
        </Button>
      </div>
    </Sider>
  );
};

export default SiderSubMenuLayout;
