import { Input, Typography, Row, Col } from "antd";
import { isNegative } from "../../../../utils/functions";
import "dayjs/locale/en";
import { getTranslation } from "../../../../utils/transaltion";
import { useContext } from "react";
import { SharedContext } from "../context/SharedContext";

function MenuCollapseContentInputs(props: any) {
  const { inputList } = props;
  const { descriptorsListUnique } = useContext(SharedContext);
  return inputList ? (
    <Row
      style={{
        flexDirection: "row",
        marginBottom: 15,
        marginTop: 10,
      }}
      gutter={[10, 10]}
    >
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Row gutter={[10, 10]}>
          {inputList?.map((input: any, index: number) => (
            <Col xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
              <Typography className="drawer-typography">
                {getTranslation(input.AttributeName, "data")}
              </Typography>
              <Input
                key={input.AttributeName}
                value={input?.value}
                style={{
                  color: isNegative(input?.value) ? "#EE2737" : "inherit",
                  textAlign: !descriptorsListUnique.hasOwnProperty(
                    input.AttributeName
                  )
                    ? "left"
                    : descriptorsListUnique[input.AttributeName]?.type.includes(
                        "NUMBER"
                      ) ||
                      descriptorsListUnique[input.AttributeName]?.type.includes(
                        "AMOUNT"
                      )
                    ? "right"
                    : "left",
                }}
                readOnly
              />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  ) : null;
}

export default MenuCollapseContentInputs;
