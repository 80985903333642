import CrudService from "./main/crud.service";
import { Context } from "../interfaces/context";

const resource = { name: "riskconfs", isPublic: false };

class RiskConfsService extends CrudService {
  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(resource, context, setContext, path);
  }
  async deleteRisks(args: string) {
    const { data } = await this.delete(
      `/organizations/${this.context.orga.id}/riskconfs/` +
        args +
        `?recursive=1`
    );
    return data;
  }
  async addRisk(args: any) {
    const { data } = await this.post(
      `/organizations/${this.context.orga.id}/riskconfs/save`,
      args
    );
    return { data };
  }
}

export default RiskConfsService;
