import { PaginationParams } from "../interfaces/PaginateParams";
import { calculatePagination } from "./calculatePagination";

export function getParamsQuery(params: PaginationParams): string {
  return Object.entries(calculatePagination(params))
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
}
