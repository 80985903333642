import { setPredefinedExecutionOrga } from "../../reducers/predefinedExecutionsOrga.Reducer";

/** dispatch predefined risk */
export const InitExecutions = async (
  executionsServices: any,
  dispatch: any
) => {
  return executionsServices
    .getPredefinedExecution()
    .then(async (res: any) => {
      dispatch(setPredefinedExecutionOrga(res?.items));
    })
    .catch((err: any) => {
      console.log(err);
    });
};
