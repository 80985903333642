import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MainContext } from "../../../../../utils/context";
import { SharedContext } from "../../context/sharedContext";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { message } from "antd";
import { WORK_MODE } from "../../../../../constants/workModes";
import { useTranslation } from "react-i18next";
import { createKey, getTranslation } from "../../../../../utils/transaltion";
import PredefinedActionService from "../../../../../services/predefined-actions.service";
import { GetChoicesByKeys } from "../../../../../utils/ChoicesFunction";
import ActionsConfsService from "../../../../../services/action-confs.service";
import RisksConfsService from "../../../../../services/risk-confs.service";
import { REQUEST } from "../../../../../services/functions/handle-api-errors/const";
import handleApiError from "../../../../../services/functions/handle-api-errors/handleApiError";

export interface SharedContextFunctionsInterface {
  getPRedefinedActionIdsByName: (ActionName: string) => any[];
  getActionParameters: (
    mode: string,
    PredefinedActionSettings: any,
    record?: any
  ) => Promise<void>;
  updateAction: (action_: any) => Promise<void>;
  filterListeActions: (params: any, filters?: any) => Promise<void>;
  getEnums: (
    setValueEnumPredefinedAction: React.Dispatch<React.SetStateAction<any>>,
    setValueEnumPredefinedRisk: React.Dispatch<React.SetStateAction<any>>
  ) => Promise<void>;
  getActionsList: (page?: any, pageSize?: any) => Promise<void>;
  deleteAction: (id: string) => Promise<void>;
  setDeleteLoading: (state: boolean) => Promise<void>;
  setRecordAction: (record: any) => Promise<void>;
  changeWorkMode: (workMode: string) => Promise<void>;
  showActionModal: () => Promise<void>;
  closeActionModal: () => Promise<void>;
  handleKeysActionDnd: (workModeRisk: string) => Promise<void>;
  onFinishSaveRisk: (
    values: any,
    workModeRisk: string,
    record: any,
    setLoadingSave: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  goToRiskConfiguration: (
    navigate: NavigateFunction,
    record: any,
    mode: string
  ) => Promise<void>;
  goToRiskTable: () => Promise<void>;
  getRisksList: (page?: any, pageSize?: any) => Promise<void>;
  Deleterisk: (id: string) => Promise<void>;
}

export const useSharedContextFunctions = () => {
  const { t } = useTranslation();
  /** get predefined risks from redux */
  const predefinedActionList = useSelector(
    (state: RootState) => state.predefinedActionOrgaReducer
  );
  /**confs shared context */
  const {
    setSettingsChoicesList,
    setConfirmDeleteLoading,
    setGetLoadingRisk,
    riskActions,
    setRiskActions,
    setOpenActionModal,
    setActionRecord,
    workModeRisk,
    setWorkModeAction,
    setGetActionsLoading,
    setActionData,
    setTotalActions,
    setDynamicFieldsSettings,
    setIsReadyForKey,
  } = useContext(SharedContext);
  /**global context */
  const location = useLocation();
  const navigate = useNavigate();
  const globalContext = useContext(MainContext);
  if (!globalContext) {
    return <></>;
  }
  const risksServices = new RisksConfsService(
    globalContext?.context,
    globalContext?.setContext
  );
  const actionsServices = new ActionsConfsService(
    globalContext.context,
    globalContext.setContext
  );
  /**Action */
  const predefinedActionServices = new PredefinedActionService(
    globalContext.context,
    globalContext.setContext
  );
  /** handle open Action menu */
  const handleKeysActionDnd = async (workModeRisk: string) => {
    // give  keys to list action for the dnd component
    if (workModeRisk === WORK_MODE.UPDATE || workModeRisk === WORK_MODE.VIEW) {
      setRiskActions(
        riskActions
          ?.sort((a: any, b: any) => a.priority - b.priority)
          ?.map((obj: any, index: any) => ({
            ...obj,
            key: index + 1,
          }))
      );
    }

    setGetLoadingRisk(false);
  };
  const changeWorkMode = (workMode: string) => {
    setWorkModeAction(workMode);
  };

  const showActionModal = () => {
    setOpenActionModal(true);
  };

  const closeActionModal = () => {
    const currentPath = location.pathname; // Current URL path
    // Find the position of '/action/' in the path, if it exists
    const actionIndex = currentPath.indexOf("/action/");
    // If '/action/' exists, slice the path up to '/action/', else use the full current path
    const newPath =
      actionIndex !== -1 ? currentPath.slice(0, actionIndex) : currentPath;
    // Replace the current URL without navigating
    navigate(newPath, { replace: true });

    setOpenActionModal(false);
  };
  const setRecordAction = (record: any) => {
    setActionRecord(record);
  };

  const setDeleteLoading = (state: boolean) => {
    setConfirmDeleteLoading(state);
  };

  const deleteAction = async (id: string) => {
    setDeleteLoading(true);
    await actionsServices
      .deleteAction(id)
      .then((res) => {
        message.success(t("errors:DELETE_SUCCESS"));
        getActionsList({ page: 1, pageSize: 20 });
      })
      .catch((err) => {
        handleApiError(err, REQUEST.DELETE);
      });
    setDeleteLoading(false);
  };

  const getActionsList = async (page?: any, pageSize?: any) => {
    setGetActionsLoading(true);

    await actionsServices
      .getResources({ pageSize: 20 }, ["risk", "model", "executions"])
      .then((res) => {
        setActionData(res?.items);
        setTotalActions(res?.total);
      })
      .catch((err) => {
        handleApiError(err);
      });
    setGetActionsLoading(false);
  };

  const getEnums = async (
    setValueEnumPredefinedAction: React.Dispatch<React.SetStateAction<any>>,
    setValueEnumPredefinedRisk: React.Dispatch<React.SetStateAction<any>>
  ) => {
    // predefined action enum
    const updatedEnumPredefinedAction: { [key: string]: { text: string } } = {};
    const nameSet: Set<string> = new Set(); // Set to store unique curr.name values

    predefinedActionList?.forEach((curr: any) => {
      // Check if curr.name is not already in the Set
      if (!nameSet.has(curr.name)) {
        updatedEnumPredefinedAction[curr.name] = {
          text: getTranslation(curr.name, "data"),
        };
        nameSet.add(curr.name); // Add curr.name to the Set
      }
    });
    setValueEnumPredefinedAction(updatedEnumPredefinedAction);
    /** get risks */
    await risksServices
      .getResources({ pageSize: 20 })
      .then(async (res: any) => {
        const updatedValueEnumRisk: { [key: string]: { text: string } } = {};
        res.items.forEach((curr: any) => {
          updatedValueEnumRisk[curr.id] = {
            text: curr.name,
          };
        });
        setValueEnumPredefinedRisk(updatedValueEnumRisk);
      })
      .catch((err: any) => {});
  };
  const getPRedefinedActionIdsByName = (ActionName: string) => {
    const filteredIds: any[] = [];
    predefinedActionList?.forEach((action: any) => {
      if (action.name.includes(createKey(ActionName))) {
        filteredIds.push(action.id);
      }
    });
    return filteredIds;
  };
  /** filter function */
  const filterListeActions = async (params: any, filters?: any) => {
    let filters_ = JSON.parse(JSON.stringify(filters || {}));
    setGetActionsLoading(true);

    await actionsServices
      .filterResource(params, filters_, ["risk", "model", "executions"])
      .then(async (response) => {
        setGetActionsLoading(false);
        setActionData(response?.items);
        setTotalActions(response?.total);
      })
      .catch((err) => {
        setGetActionsLoading(false);
        handleApiError(err);
      });
  };

  const updateAction = async (action: any) => {
    let action_ = JSON.parse(JSON.stringify(action));
    delete action_["model"];
    delete action_["created_at"];
    delete action_["created_by"];
    delete action_["updated_at"];
    delete action_["updated_by"];
    action_["reasons"] = action_.reasons?.map((reason: any) => reason.id);
    action_["roles"] = action_.roles?.map((role: any) => role.id);
    for (const obj of action_.executions) {
      delete obj["created_at"];
      delete obj["created_by"];
      delete obj["updated_at"];
      delete obj["updated_by"];
      delete obj["autoPolicy"];
      delete obj["action"];
      if (obj.hasOwnProperty("key")) {
        delete obj["key"];
      }
      if (obj.hasOwnProperty("id")) {
        delete obj["model"];
      } else {
        obj["model"] = obj["model"].id;
      }
      obj["reasons"] = obj.reasons?.map((reason: any) => reason.id);
      obj["roles"] = obj.roles?.map((role: any) => role.id);
    }

    await actionsServices
      .saveActions(action_)
      .then((res) => {
        message.success(t("errors:UPDATE_SUCCESS"));
        closeActionModal();
        getActionsList();
      })
      .catch((err) => {
        handleApiError(err, REQUEST.UPDATE);
      });
  };

  const getActionParameters = async (
    mode: string,
    PredefinedActionSettings: any,
    record?: any
  ) => {
    setDynamicFieldsSettings([]);
    const orgSettings = globalContext?.context?.orga?.actionSettings || {};
    if (mode === WORK_MODE.VIEW) {
      const dynamicFieldsSettingsValues = Object.keys(record || {}).map(
        (key, index) => ({
          key: index + 1,
          defaultValue: record[key],
          name: PredefinedActionSettings[key]?.name,
          type: PredefinedActionSettings[key]?.type,
          suffix: key,
          choice: PredefinedActionSettings[key]?.choice,
        })
      );
      setDynamicFieldsSettings(dynamicFieldsSettingsValues);
    } else {
      GetChoicesByKeys(
        PredefinedActionSettings,
        predefinedActionServices,
        setSettingsChoicesList
      );
      if (mode === WORK_MODE.CREATE) {
        // creation mode mergedSettings takes settings from the predefined
        const mergedSettings: any = { ...PredefinedActionSettings };
        // Iterate through keys in org
        Object.keys(orgSettings).forEach((key) => {
          // Check if the key exists in PredefinedActionSettings
          if (PredefinedActionSettings[key]) {
            // Take type and defaultValue from orgSettings and update the mergedSettings
            mergedSettings[key] = {
              ...PredefinedActionSettings[key],
              type: PredefinedActionSettings[key]?.type,
              defaultValue: orgSettings[key],
              choice: PredefinedActionSettings[key]?.choice,
            };
          } // else leave it as is with predefined actions values
        });

        const dynamicFieldsSettingsValues = Object.keys(mergedSettings).map(
          (key, index) => ({
            key: index + 1,
            defaultValue: mergedSettings[key].defaultValue,
            name: mergedSettings[key].name,
            type: mergedSettings[key].type,
            suffix: key,
            choice: mergedSettings[key].choice,
          })
        );
        if (Object.keys(dynamicFieldsSettingsValues).length !== 0) {
          setDynamicFieldsSettings(dynamicFieldsSettingsValues);
        }
      } else {
        // update mode
        const mergedSettings: any = { ...PredefinedActionSettings };

        // Iterate through keys in PredefinedActionSetting
        Object.keys(mergedSettings).forEach((key) => {
          // Check if the key exists in record
          if (record && record[key]) {
            mergedSettings[key] = {
              name: PredefinedActionSettings[key]?.name,
              type: PredefinedActionSettings[key]?.type,
              defaultValue: record[key],
              suffix: key,
              choice: PredefinedActionSettings[key]?.choice,
            };
          } else if (orgSettings[key]) {
            // If the key doesn't exist in record, check if it exists in orgSetting
            mergedSettings[key] = {
              name: PredefinedActionSettings[key]?.name,
              type: PredefinedActionSettings[key]?.type,
              defaultValue: orgSettings[key],
              suffix: key,
              choice: PredefinedActionSettings[key]?.choice,
            };
          }
        });

        const dynamicFieldsSettingsValues = Object.keys(mergedSettings).map(
          (key, index) => ({
            key: index + 1,
            defaultValue: mergedSettings[key].defaultValue,
            name: mergedSettings[key].name,
            type: mergedSettings[key].type,
            suffix: key,
            choice: mergedSettings[key].choice,
          })
        );
        if (
          PredefinedActionSettings === undefined &&
          workModeRisk === WORK_MODE.CREATE
        ) {
          //  here it's  update mode  from creation risk and not from action table
          const dynamicFieldsSettingsValues = Object.keys(record || {}).map(
            (key, index) => ({
              key: index + 1,
              defaultValue: record[key].defaultValue,
              name: record[key].name,
              type: record[key].type,
              suffix: key,
              choice: record[key].choice,
            })
          );
          setDynamicFieldsSettings(dynamicFieldsSettingsValues);
        } else {
          if (Object.keys(dynamicFieldsSettingsValues).length !== 0) {
            setDynamicFieldsSettings(dynamicFieldsSettingsValues);
          }
        }
      }
    }
  };

  return {
    getPRedefinedActionIdsByName,
    getActionParameters,
    handleKeysActionDnd,
    changeWorkMode,
    showActionModal,
    closeActionModal,
    setRecordAction,
    setDeleteLoading,
    deleteAction,
    getActionsList,
    getEnums,
    filterListeActions,
    updateAction,
  };
};
