import CrudService from "./main/crud.service";
import { Context } from "../interfaces/context";
import { FilterRules } from "./interfaces/FilterRules";

const resource = { name: "licenses", isPublic: true };

class LicensesService extends CrudService {
  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(resource, context, setContext, path);
  }

  async filterResource(
    params: any,
    filters?: FilterRules,
    fulls = ["copilots"]
  ): Promise<any> {
    return await super.filterResource(params, filters, fulls);
  }
}

export default LicensesService;
