import { Empty } from "antd";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { FaMagnifyingGlassChart } from "react-icons/fa6";

const EmptyChartCard = (props: any) => {
  const { styleProps } = props;
  const { t } = useTranslation();

  const combinedStyle: CSSProperties = {
    height: "250px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    ...styleProps,
  };
  const descriptionStyle = {
    fontWeight: "300",
    fontSize: 12,
  };
  return (
    <Empty
      style={combinedStyle}
      image={
        <FaMagnifyingGlassChart style={{ fontSize: 120, color: "#0082ba" }} />
      }
      description={
        <span style={descriptionStyle}>{t("errors:NO_DATA_CHARTS")}</span>
      }
    />
  );
};

export default EmptyChartCard;
