import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  ColorPicker,
  Form,
  Input,
  Row,
  Select,
  Tag,
  Tooltip,
} from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { Reason } from "../../../../../interfaces/reasons";
import { SharedContext } from "../../context/sharedContext";
import { WORK_MODE } from "../../../../../constants/workModes";
import { selectFilterOption } from "../../../../../utils/functions";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../../logic/PredefinedsLogic";
import PredefinedActionSettings from "../settings/PredefinedActionSettings";
import { LockOutlined, SyncOutlined } from "@ant-design/icons";
import { getTranslation } from "../../../../../utils/transaltion";
import { useTranslation } from "react-i18next";

const PreActionDetails: React.FC<{
  typeComponent: string;
}> = (props) => {
  /**props */
  const { typeComponent } = props;
  /** translation */
  const { t } = useTranslation();
  /** roles from redux */
  const rolesRedux = useSelector((state: RootState) => state.roleReducer);
  /**reasons from Redux */
  const actionReasonsRedux: Reason[] = useSelector(
    (state: RootState) => state.reasonsActionsReducer
  );
  /** get predefined risks from redux */
  const predefinedExecutionsRedux = useSelector(
    (state: RootState) => state.predefinedExecutionGlobalReducer
  )?.filter((item: any) => item?.enabled === true);
  const {
    Record,
    isAuto,
    setIsAuto,
    isDisable,
    setIsDisable,
    actionColor,
    setActionColor,
    workMode,
    form,
  } = useContext(SharedContext);
  /** shared logic */
  const { handleUpdateAction, getConfigurationFromURL } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  const [initialValAction, setInitialValAction] = useState<any>({});
  const [syncIconClassName, setSyncIconClassName] = useState<string>("");

  useEffect(() => {
    handleUpdateAction(Record);
    if (workMode === WORK_MODE.CREATE || !workMode) {
      setIsDisable(true);
    }
    const initialValues =
      workMode === WORK_MODE.CREATE || !workMode
        ? {
            name: "",
            model: "",
            copilot_name: "",
            url: "",
            uiConfiguration: "#DBE7F1",
            reasons: [],
            enabled: true,
          }
        : {
            name: Record?.name,
            roles: Record?.roles?.map((roles: any) => roles.id),
            reasons: Record?.reasons?.map((reason: any) => reason.id),
            canAuto: Record?.canAuto,
            enabled: Record?.enabled,
            url: Record.url,
            executions: Record?.executions?.map((reason: any) => reason.id),
            uiConfiguration: Record?.uiConfiguration?.couleur || "#DBE7F1",
          };
    workMode === WORK_MODE.CREATE && setActionColor("#DBE7F1");
    form.setFieldsValue(initialValues);

    setInitialValAction(initialValues);
  }, [workMode, Record, actionReasonsRedux, typeComponent, form]);

  return (
    <div style={{ marginTop: 20, width: "100%", marginBottom: "20px" }}>
      <Collapse
        className="panel-style"
        accordion
        expandIconPosition="end"
        size="large"
        defaultActiveKey="menu"
      >
        <Collapse.Panel
          className="panel-style"
          header={
            <span style={{ color: "#2A333B" }}>
              {t(`labels:DETAILS`, {
                item: t(typeComponent),
              })}
            </span>
          }
          key="menu"
        >
          <Form
            preserve={false}
            form={form}
            name="wrap"
            {...{ labelCol: { style: { width: 200 } } }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            initialValues={initialValAction}
          >
            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t(`labels:NAME`, {
                    item: t(""),
                  })}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:NAME`, {
                          item: t(typeComponent),
                        }),
                      }),
                    },
                  ]}
                >
                  <Input disabled={form.getFieldValue("name")} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t("URL")}
                  name="url"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:URL`),
                      }),
                    },
                    {
                      type: "url",
                      message: t(`errors:INVALID`, {
                        label: t("URL"),
                      }),
                    },
                  ]}
                >
                  <Input
                    allowClear
                    readOnly={workMode === WORK_MODE.VIEW}
                    //disabled={workMode === WORK_MODE.VIEW}
                  />
                </Form.Item>
              </Col>
              {workMode !== WORK_MODE.VIEW && (
                <Col xs={12} sm={2} md={2} lg={2} xl={2}>
                  <Tooltip title={t("errors:SYNCHRONIZE")}>
                    <Button
                      style={{ marginInlineStart: 3 }}
                      onClick={() => {
                        form
                          .validateFields(["url"])
                          .then(() => {
                            setSyncIconClassName("syncIcon");
                            getConfigurationFromURL(
                              form,
                              form.getFieldValue("url"),
                              setSyncIconClassName
                            );
                          })
                          .catch(() => {});
                      }}
                      type="text"
                      icon={<SyncOutlined className={syncIconClassName} />}
                    ></Button>
                  </Tooltip>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t("ROLES")}
                  name="roles"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:ROLES`),
                      }),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      selectFilterOption(input, option)
                    }
                    mode="multiple"
                    disabled={workMode === WORK_MODE.VIEW ? true : false}
                  >
                    {rolesRedux?.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {getTranslation(item.name, "data")}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t("REASON_CODE")}
                  name="reasons"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:REASON_CODE`),
                      }),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      selectFilterOption(input, option)
                    }
                    disabled={workMode === WORK_MODE.VIEW}
                    mode="multiple"
                    tagRender={(props: any) => {
                      const { label, value, onClose } = props;
                      const is_sys = actionReasonsRedux?.find(
                        (obj: any) => obj.id === value
                      )?.is_system;
                      return (
                        <Tag
                          color={is_sys ? "red" : "blue"}
                          closable={workMode !== WORK_MODE.VIEW && !is_sys}
                          onClose={onClose}
                        >
                          {label}
                          {is_sys ? (
                            <LockOutlined style={{ marginInline: "4px" }} />
                          ) : (
                            ""
                          )}
                        </Tag>
                      );
                    }}
                  >
                    {actionReasonsRedux?.map((item: any) => {
                      const isDisabled =
                        item.is_system &&
                        Record?.reasons?.some(
                          (reason: any) => reason.id === item.id
                        );
                      return (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          disabled={isDisabled}
                        >
                          {getTranslation(item?.name, "data")}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item label={t("EXECUTION_PREDEFINED")} name="executions">
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      selectFilterOption(input, option)
                    }
                    disabled={workMode === WORK_MODE.VIEW ? true : false}
                    mode="multiple"
                  >
                    {predefinedExecutionsRedux?.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {getTranslation(item.name, "data")}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t("COLOR_ON_CHARTS")}
                  name="uiConfiguration"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:COLOR_ON_CHARTS`),
                      }),
                    },
                  ]}
                >
                  <ColorPicker
                    disabled={workMode === WORK_MODE.VIEW ? true : false}
                    defaultValue={actionColor}
                    value={actionColor}
                    onChange={(value, hex) => {
                      setActionColor(hex);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              {/* Disable */}
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Form.Item name="enabled">
                  <Checkbox
                    disabled={true}
                    checked={isDisable}
                    onChange={() => {
                      setIsDisable(!isDisable);
                    }}
                  >
                    {t("ENABLED")}
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Form.Item name="canAuto">
                  <Checkbox
                    disabled={workMode === WORK_MODE.VIEW}
                    checked={isAuto}
                    onChange={() => {
                      setIsAuto(!isAuto);
                    }}
                  >
                    {t("AUTO_FEASIBLE")}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <PredefinedActionSettings
        formAction={form}
        actionParameters={Record?.actionParameters}
      />
    </div>
  );
};

export default PreActionDetails;
