import dayjs from "dayjs";
import { Button } from "antd";
import { TbFilterEdit } from "react-icons/tb";
import { ProColumns } from "@ant-design/pro-components";

import { FilterConf } from "../interfaces/FilterConf";
import { DATA_NEEDED_TYPE } from "../../../../../constants/DataNeededTypes";

export const DefineTableColumns = (
  updateFilterConfigsModal: any,
  filterConfigs: FilterConf[],
  valueEnumPredefinedAction: {
    [key: string]: { text: string };
  },
  valueEnumPredefinedExecutions: {
    [key: string]: { text: string };
  }
) => {
  const getInitDateRange = () => {
    const currentDate = dayjs();
    return [currentDate.subtract(3, "month"), currentDate];
  };

  const createColumn = (filterConfig: FilterConf): ProColumns => {
    return {
      title: filterConfig?.title,
      dataIndex: filterConfig?.field,
      valueType:
        filterConfig?.type === DATA_NEEDED_TYPE.NUMBER
          ? "digitRange"
          : filterConfig?.type === DATA_NEEDED_TYPE.DATE
          ? "dateRange"
          : filterConfig?.type === DATA_NEEDED_TYPE.BOOLEAN
          ? "select"
          : "text",
      valueEnum:
        filterConfig?.type === DATA_NEEDED_TYPE.BOOLEAN
          ? { TRUE: "True", FALSE: "False" }
          : undefined,
      sorter: true,
      ellipsis: true,
    };
  };

  const columns: ProColumns[] = [
    {
      title: "Date Range",
      dataIndex: "dateRange",
      valueType: "dateRange",
      initialValue: getInitDateRange(),
    },
    {
      title: "Risk Status",
      dataIndex: "risk_status",
      sorter: true,
      valueType: "select",
      ellipsis: true,
      valueEnum: {
        Open: { text: "Open" },
        "In progress": {
          text: "In progress",
        },
        Accepted: { text: "Accepted" },
        Rejected: { text: "Rejected" },
      },
      fieldProps: {
        mode: "multiple",
      },
    },
    {
      title: "Action Type",
      dataIndex: "action_type",
      sorter: true,
      valueType: "select",
      ellipsis: true,
      valueEnum: valueEnumPredefinedAction,
      fieldProps: {
        mode: "multiple",
      },
    },
    {
      title: "Execution type",
      dataIndex: "execution_type",
      sorter: true,
      valueType: "select",
      ellipsis: true,
      valueEnum: valueEnumPredefinedExecutions,
      fieldProps: {
        mode: "multiple",
        max: 3,
      },
    },
    ...filterConfigs?.map((filterConfig) => createColumn(filterConfig)),
    {
      ellipsis: true,
      renderFormItem: (_, { defaultRender }) => {
        return (
          <Button
            type="primary"
            icon={<TbFilterEdit />}
            style={{ width: "auto", backgroundColor: "#0082ba" }}
            onClick={() => updateFilterConfigsModal()}
          >
            Edit filters
          </Button>
        );
      },
    },
  ];
  return columns;
};
