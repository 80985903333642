import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

type ReasonsRisks = {
  id: string;
  is_system: boolean;
  name: string;
};
const initialState: Array<ReasonsRisks> = [];

export const reasonsRisksSlice = createSlice({
  name: "reasonsRisks",
  initialState,
  reducers: {
    addReasonsRisks: (state, action: PayloadAction<ReasonsRisks>) => {
      state.push(action.payload);
    },
    setReasonsRisks: (state, action: PayloadAction<Array<ReasonsRisks>>) => {
      return action.payload;
    },
    updateReasonsRisks: (state, action: PayloadAction<ReasonsRisks>) => {
      const { id, name, is_system } = action.payload;
      const existingReasonsRisks = state.find(
        (ReasonsRisks) => ReasonsRisks?.id === id
      );
      if (existingReasonsRisks) {
        existingReasonsRisks.name = name;
        existingReasonsRisks.is_system = is_system;
      }
    },
  },
});
export const { addReasonsRisks, setReasonsRisks, updateReasonsRisks } =
  reasonsRisksSlice.actions;

export const ReasonsRisksSelector = (state: RootState) =>
  state.reasonsRisksReducer;

export default reasonsRisksSlice.reducer;
