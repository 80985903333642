import { Col, Modal, Row } from "antd";
import { useState } from "react";

import StatCard from "./StatCard";
import NewStatCard from "./NewStatCard";
import StatModal from "./StatModal";
import { WORK_MODE } from "../../../../../constants/workModes";
import { StatConf } from "../interfaces/StatConf";
import { ActionFields } from "../constants/configs";

interface FilterHeaderProps {
  copilotDataneededFields: any;
  statConfigs: StatConf[];
  setStatConfigs: (value: StatConf[]) => void;
  data: any[];
}

export default function StatContent(props: FilterHeaderProps) {
  const { copilotDataneededFields, statConfigs, setStatConfigs } = props;

  const [openStatConfModal, setOpenStatConfModal] = useState(false);
  const [workModeStatConfModal, setWorkModeStatConfModal] = useState(
    WORK_MODE.CREATE
  );
  const [currentRecord, setCurrentRecord] = useState();
  const [recordIndexStatConf, setRecordIndexStatConf] = useState();

  const createStatConf = (conf: any) => {
    setStatConfigs([...statConfigs, conf]);
    closeStatConfModal();
  };
  const updateStatConf = (e_config: any, e_index: any) => {
    setStatConfigs(
      statConfigs?.map((config, index) => {
        return e_index === index ? e_config : config;
      })
    );
    closeStatConfModal();
  };
  const deleteStatConfig = (i: number) => {
    const categories = statConfigs?.filter(
      (conf, index: number) => i !== index
    );
    setStatConfigs(categories);
  };
  /* Modal open modes */
  const createStatConfModal = () => {
    setWorkModeStatConfModal(WORK_MODE.CREATE);
    setOpenStatConfModal(true);
  };
  const updateStatConfModal = (conf: any, index: any) => {
    setRecordIndexStatConf(index);
    setCurrentRecord(conf);
    setWorkModeStatConfModal(WORK_MODE.UPDATE);
    setOpenStatConfModal(true);
  };
  const viewStatConfModal = (conf: any, index: any) => {
    setRecordIndexStatConf(index);
    setCurrentRecord(conf);
    setWorkModeStatConfModal(WORK_MODE.VIEW);
    setOpenStatConfModal(true);
  };
  const closeStatConfModal = () => {
    setOpenStatConfModal(false);
  };

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          flexDirection: "row",
          marginBottom: 15,
        }}
      >
        {statConfigs?.map((config, index) => {
          return (
            <Col sm={24} lg={8} xl={8} key={config?.title}>
              <StatCard
                value={20}
                recordIndexStatConf={index}
                statConf={config}
                updateStatConfModal={updateStatConfModal}
                viewStatConfModal={viewStatConfModal}
                deleteStatConfig={deleteStatConfig}
              />
            </Col>
          );
        })}
        <Col sm={24} lg={8} xl={8}>
          <NewStatCard createStatConfModal={createStatConfModal} />
        </Col>
      </Row>

      <Modal
        title={
          workModeStatConfModal === WORK_MODE.CREATE
            ? "Add statistic card"
            : "Edit statistic card"
        }
        open={openStatConfModal}
        onCancel={closeStatConfModal}
        destroyOnClose={true}
        footer={null}
        width={800}
      >
        <StatModal
          copilotContextFields={copilotDataneededFields}
          workMode={workModeStatConfModal}
          recordStatConf={currentRecord}
          recordIndexStatConf={recordIndexStatConf}
          createStatConf={createStatConf}
          updateStatConf={updateStatConf}
          closeStatModal={closeStatConfModal}
        />
      </Modal>
    </>
  );
}
