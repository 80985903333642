import React, { ReactNode, createContext, useState } from "react";
import { RisksItem } from "../../../../interfaces/risks";
import { ActionItem } from "../../../../interfaces/actions";
import { Scope } from "../../../../interfaces/scope";

type dynamicF = {
  key: number;
  name?: string;
  type?: string;
  defaultValue?: string;
  suffix?: string;
  choice?: any;
};
// Define the context
type RiskConfContextType = {
  /**risk confs vars */
  workModeRisk: string;
  setWorkModeRisk: React.Dispatch<React.SetStateAction<string>>;
  riskList: RisksItem[];
  setRiskList: React.Dispatch<React.SetStateAction<RisksItem[]>>;
  totalRisk: number;
  setTotalRisk: React.Dispatch<React.SetStateAction<number>>;
  getLoadingRisk: boolean;
  setGetLoadingRisk: React.Dispatch<React.SetStateAction<boolean>>;
  isReadyForKey: boolean;
  setIsReadyForKey: React.Dispatch<React.SetStateAction<boolean>>;
  riskActions: ActionItem[];
  setRiskActions: React.Dispatch<React.SetStateAction<ActionItem[]>>;
  predefinedRiskId: string;
  setPredefinedRiskId: React.Dispatch<React.SetStateAction<string>>;
  /** action confs vars */
  openActionModal: boolean;
  setOpenActionModal: React.Dispatch<React.SetStateAction<boolean>>;
  actionRecord: any[];
  setActionRecord: React.Dispatch<React.SetStateAction<any>>;
  workModeAction: string;
  setWorkModeAction: React.Dispatch<React.SetStateAction<string>>;
  /** user conf vars */
  workModeScope: string;
  setWorkModeScope: React.Dispatch<React.SetStateAction<string>>;
  scopeRecord: any[];
  setScopeRecord: React.Dispatch<React.SetStateAction<any>>;
  getLoading: boolean;
  setGetLoading: React.Dispatch<React.SetStateAction<boolean>>;
  userScopes: Scope[];
  setUserScopes: React.Dispatch<React.SetStateAction<Scope[]>>;
  openScopeModal: boolean;
  setOpenScopeModal: React.Dispatch<React.SetStateAction<boolean>>;
  confirmDeleteLoading: boolean;
  setConfirmDeleteLoading: React.Dispatch<React.SetStateAction<boolean>>;
  totalActions: number;
  setTotalActions: React.Dispatch<React.SetStateAction<number>>;
  getActionsLoading: boolean;
  setGetActionsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  actionData: ActionItem[];
  setActionData: React.Dispatch<React.SetStateAction<ActionItem[]>>;
  riskRules: any[];
  setRiskRules: React.Dispatch<React.SetStateAction<any[]>>;
  dynamicFieldsSettings: dynamicF[];
  setDynamicFieldsSettings: React.Dispatch<React.SetStateAction<dynamicF[]>>;
  pageState: number;
  setPageState: React.Dispatch<React.SetStateAction<number>>;
  pageStateRisk: number;
  setPageStateRisk: React.Dispatch<React.SetStateAction<number>>;
  pageStateUser: number;
  setPageStateUser: React.Dispatch<React.SetStateAction<number>>;
  userList: any[];
  setUserList: React.Dispatch<React.SetStateAction<any>>;
  settingsChoicesList: any;
  setSettingsChoicesList: React.Dispatch<React.SetStateAction<any>>;
};
export const SharedContext = createContext<RiskConfContextType>({
  // Provide default values that match the defined types
  setRiskList: () => {},
  workModeRisk: "",
  setWorkModeRisk: () => {},
  totalRisk: 0,
  setTotalRisk: () => {},
  riskList: [],
  getLoadingRisk: false,
  setGetLoadingRisk: () => {},
  isReadyForKey: false,
  setIsReadyForKey: () => {},
  riskActions: [],
  setRiskActions: () => {},
  predefinedRiskId: "",
  setPredefinedRiskId: () => {},
  openActionModal: false,
  setOpenActionModal: () => {},
  actionRecord: [],
  setActionRecord: () => {},
  workModeAction: "",
  setWorkModeAction: () => {},
  workModeScope: "",
  setWorkModeScope: () => {},
  scopeRecord: [],
  setScopeRecord: () => {},
  getLoading: false,
  setGetLoading: () => {},
  userScopes: [],
  setUserScopes: () => {},
  openScopeModal: false,
  setOpenScopeModal: () => {},
  confirmDeleteLoading: false,
  setConfirmDeleteLoading: () => {},
  totalActions: 0,
  setTotalActions: () => {},
  getActionsLoading: false,
  setGetActionsLoading: () => {},
  actionData: [],
  setActionData: () => {},
  riskRules: [],
  setRiskRules: () => {},
  dynamicFieldsSettings: [],
  setDynamicFieldsSettings: () => {},
  pageState: 1,
  setPageState: () => {},
  pageStateRisk: 1,
  setPageStateRisk: () => {},
  pageStateUser: 1,
  setPageStateUser: () => {},
  userList: [],
  setUserList: () => {},
  settingsChoicesList: {},
  setSettingsChoicesList: () => {},
});

// Create a provider component
export const SharedContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  /*risk confs vars*/
  const [workModeRisk, setWorkModeRisk] = useState<string>("");
  const [riskList, setRiskList] = useState<RisksItem[]>([]);
  const [totalRisk, setTotalRisk] = useState<any>(0);
  const [getLoadingRisk, setGetLoadingRisk] = useState(true);
  const [isReadyForKey, setIsReadyForKey] = useState(false);
  const [riskActions, setRiskActions] = useState<ActionItem[]>([]);
  const [predefinedRiskId, setPredefinedRiskId] = useState("");
  const [riskRules, setRiskRules] = useState<any[]>([]);
  const [pageStateRisk, setPageStateRisk] = useState<number>(1);
  /**action confs vars */
  const [workModeAction, setWorkModeAction] = useState("");
  const [actionRecord, setActionRecord] = useState([]);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [confirmDeleteLoading, setConfirmDeleteLoading] = useState(false);
  const [actionData, setActionData] = useState<ActionItem[]>([]);
  const [getActionsLoading, setGetActionsLoading] = useState(false);
  const [totalActions, setTotalActions] = useState(0);
  const [pageState, setPageState] = useState<number>(1);
  /** user confs vars */
  const [workModeScope, setWorkModeScope] = useState("");
  const [scopeRecord, setScopeRecord] = useState([]);
  const [getLoading, setGetLoading] = useState(false);
  const [userScopes, setUserScopes] = useState<Scope[]>([]);
  const [openScopeModal, setOpenScopeModal] = useState(false);
  const [pageStateUser, setPageStateUser] = useState<number>(1);
  const [userList, setUserList] = useState([]);

  /**actio settings */
  const [dynamicFieldsSettings, setDynamicFieldsSettings] = useState<
    dynamicF[]
  >([{ key: 1 }]);
  const [settingsChoicesList, setSettingsChoicesList] = useState<any>({});

  return (
    <SharedContext.Provider
      value={{
        settingsChoicesList,
        setSettingsChoicesList,
        userList,
        setUserList,
        pageStateUser,
        setPageStateUser,
        pageStateRisk,
        setPageStateRisk,
        dynamicFieldsSettings,
        setDynamicFieldsSettings,
        pageState,
        setPageState,
        riskRules,
        setRiskRules,
        workModeRisk,
        setWorkModeRisk,
        riskList,
        setRiskList,
        totalRisk,
        setTotalRisk,
        getLoadingRisk,
        setGetLoadingRisk,
        isReadyForKey,
        setIsReadyForKey,
        riskActions,
        setRiskActions,
        predefinedRiskId,
        setPredefinedRiskId,
        openActionModal,
        setOpenActionModal,
        actionRecord,
        setActionRecord,
        workModeAction,
        setWorkModeAction,
        confirmDeleteLoading,
        setConfirmDeleteLoading,
        workModeScope,
        setWorkModeScope,
        scopeRecord,
        setScopeRecord,
        getLoading,
        setGetLoading,
        userScopes,
        setUserScopes,
        openScopeModal,
        setOpenScopeModal,
        totalActions,
        setTotalActions,
        getActionsLoading,
        setGetActionsLoading,
        actionData,
        setActionData,
      }}
    >
      {children}
    </SharedContext.Provider>
  );
};
