import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { PreDefinedRiskItem } from "../../interfaces/pre-defined-risk";

const initialState: Array<PreDefinedRiskItem> = [];

export const predefinedRiskOrgaSlice = createSlice({
  name: "predefined_risks_Orga",
  initialState,
  reducers: {
    addPredefinedRiskOrga: (
      state,
      action: PayloadAction<PreDefinedRiskItem>
    ) => {
      state.push(action.payload);
    },
    setPredefinedRisksOrga: (
      state,
      action: PayloadAction<Array<PreDefinedRiskItem>>
    ) => {
      return action.payload;
    },
    updatePreDefinedRiskOrga: (
      state,
      action: PayloadAction<PreDefinedRiskItem>
    ) => {
      const { id } = action.payload;
      const index = state.findIndex((preDefined) => preDefined.id === id);

      if (index !== -1) {
        const updatedState = state.map((item, i) =>
          i === index ? { ...item, ...action.payload } : item
        );

        // console.warn("Updated State:", updatedState);
        return updatedState;
      }

      return state;
    },
  },
});
export const {
  addPredefinedRiskOrga,
  setPredefinedRisksOrga,
  updatePreDefinedRiskOrga,
} = predefinedRiskOrgaSlice.actions;

export const PredefinedRiskOrgaSelector = (state: RootState) =>
  state.predefinedRiskOrgaReducer;

export default predefinedRiskOrgaSlice.reducer;
