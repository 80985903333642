/** @format */
import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Spin, notification } from "antd";
import { message } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WORK_MODE } from "../../../../../constants/workModes";
import { MainContext } from "../../../../../utils/context";
import AddActionModal from "../../actions/components/AddActionModal";
import { PAGES } from "../../../../../constants/pages";
import { SharedContext } from "../../context/sharedContext";
import { validate as uuidValidate } from "uuid";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/riskLogic";
import RiskPageHeader from "./RiskPageHeader";
import RiskPolicies from "./RiskPolicies";
import FooterComponent from "./RiskFooter";
import RiskDetails from "./RiskDetails";
import RiskActionsSection from "./RiskActionsSection";
import { useTranslation } from "react-i18next";
import {
  navigateOnInvalidUUID,
  updateURL,
} from "../../../../../utils/functions";
import { getTranslation } from "../../../../../utils/transaltion";

const RiskConfiguration: React.FC = () => {
  const { t } = useTranslation();
  const { uuid } = useParams<{ uuid?: string }>();
  const { uuidaction: uuidAction } = useParams<{ uuidaction?: string }>();
  const location = useLocation();
  const { state } = useLocation();
  const sharedWorkModeRisk = useContext(SharedContext)?.workModeRisk;

  // Use workModeRisk from shared context if it's available else take  state value
  const workModeRisk =
    sharedWorkModeRisk ?? state?.workModeRisk ?? WORK_MODE.VIEW;
  /**local vars */
  const [form] = Form.useForm();
  const [loadingSave, setLoadingSave] = useState(false);
  const [riskDetails, setRiskDetails] = useState<any>({});

  /**confs shared context */
  const {
    setGetLoadingRisk,
    riskActions,
    setRiskActions,
    predefinedRiskId,
    isReadyForKey,
    getLoadingRisk,
    openActionModal,
    setOpenActionModal,
    actionRecord,
    setPredefinedRiskId,
    workModeAction,
    setWorkModeAction,
    setWorkModeRisk,
    setActionRecord,
  } = useContext(SharedContext);
  const navigate = useNavigate();
  /** global app context */
  const globalContext = useContext(MainContext);
  /** get shared logic */
  const { getRiskById, onFinishSaveRisk, handleKeysActionDnd } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;

  const closeActionModal = () => {
    //updateURLOnCloseActionModal();
    updateURL(location.pathname, navigate, "action");
    setOpenActionModal(false);
  };

  /** handle form fields values when mode= update or view */
  const handleUpdateViewModes = (id: string) => {
    setGetLoadingRisk(true);
    getRiskById(id, setRiskDetails);
  };

  useEffect(() => {
    const hasKey = riskActions?.every((obj) => obj.hasOwnProperty("key"));
    if (!hasKey) {
      handleKeysActionDnd(workModeRisk);
    } else {
      setGetLoadingRisk(false);
    }
  }, [riskActions]);

  useEffect(() => {
    setPredefinedRiskId("");
    if (workModeRisk === WORK_MODE.CREATE) {
      setRiskActions([]);

      form.resetFields();
      form.setFieldsValue({
        copilot_name: "",
      });
      form.setFieldsValue({
        model: "",
      });
      form.setFieldsValue({
        name: "",
      });
    }
    if (uuid && uuidValidate(uuid)) {
      if (!workModeRisk) {
        setWorkModeRisk(WORK_MODE.VIEW);
      }
      if (
        workModeRisk === WORK_MODE.UPDATE ||
        workModeRisk === WORK_MODE.VIEW
      ) {
        handleUpdateViewModes(uuid);
      }
    }
    navigateOnInvalidUUID(navigate, uuid);

    if (!globalContext || !globalContext.context.orga) {
      return;
    }
  }, [workModeRisk, uuid]);

  useEffect(() => {
    if (uuidAction && uuidValidate(uuidAction)) {
      setOpenActionModal(true);
      setActionRecord(riskActions?.find((obj: any) => obj?.id === uuidAction));
      if (!workModeAction) {
        setWorkModeAction(WORK_MODE.VIEW);
      }
    }
    navigateOnInvalidUUID(navigate, uuidAction);
  }, [uuidAction, riskActions]);

  const onFinish = async () => {
    form
      .validateFields()
      .then(async (values) => {
        onFinishSaveRisk(values, workModeRisk, riskDetails, setLoadingSave);
      })
      .catch((err) => {
        if (err?.code === "ERR_NETWORK") {
          notification.error({
            message: t("errors:NETWORK_ERROR"),
            placement: "bottomRight",
          });
        } else {
          message.error(t("errors:CREATE_FAILED"));
        }
      });
  };

  return (
    <Spin spinning={getLoadingRisk} size="large" tip="Loading...">
      <div
        style={{
          top: 30,
          background: "transparent",
          display: "flex",
          flexDirection: "column",
          paddingBottom: 20,
          alignItems: "left",
        }}
      >
        {/**page header component */}
        <RiskPageHeader
          workModeRisk={workModeRisk}
          form={form}
          record={riskDetails}
        />
        {/** Risk details */}
        <RiskDetails
          onFinish={onFinish}
          form={form}
          workModeRisk={workModeRisk}
          record={riskDetails}
        />
        {/**Policies */}
        <RiskPolicies workModeRisk={workModeRisk} record={riskDetails} />
        {/**Actions section */}
        <RiskActionsSection workModeRisk={workModeRisk} />
      </div>
      {/* ADD Action/Execution Modals */}
      <Modal
        destroyOnClose={true}
        title={t("ACTION_CONFIGURATION")}
        open={openActionModal}
        onCancel={closeActionModal}
        width={1200}
        footer={null}
      >
        <AddActionModal
          closeActionModal={closeActionModal}
          riskActions={riskActions}
          setRiskActions={setRiskActions}
          workModeAction={workModeAction}
          setWorkModeAction={setWorkModeAction}
          actionRecord={actionRecord}
          predefinedRiskId={predefinedRiskId}
          updateSource={PAGES.RISKS}
        />
      </Modal>
      {/* FooterComponent */}
      <FooterComponent
        workModeRisk={workModeRisk}
        form={form}
        onFinish={onFinish}
        loadingSave={loadingSave}
      />
    </Spin>
  );
};

export default RiskConfiguration;
