import React, { useContext, useEffect, useState } from "react";
import { Typography, Divider, Spin } from "antd";
import MenuCollapseContentInputs from "../chartsLibrary/InputsList";
import { VIEW_TYPES } from "../../../../constants/viewtypes";
import MapComponent from "../../../common/map/mapView";
import FlowGraph from "../chartsLibrary/FlowChart";
import MixedAreaBarLine from "../../../common/charts/MixedAreaLineBar";
import DynamicTable from "../chartsLibrary/DynamicTable";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../logic/runningShared.logic";
import { CATEGORY_VIEWS } from "../../../../constants/categoryviews";
import MyLine from "../../copilots/common/charts/charts/MyLine";
import { MainContext } from "../../../../utils/context";
import { DualAxesGrouped } from "../chartsLibrary/DualAxesGrouped";
import { SharedContext } from "../context/SharedContext";
import { MyBar, MyColumn, MyPie } from "../../copilots/common/charts/charts";
import MyWaterfFallChart from "../../copilots/common/charts/charts/MyWaterfFallChart";
import MapInboundFreight from "../chartsLibrary/map/mapView";

function SideBarTabs(props: any) {
  const { category, listViews, isTabActive } = props;
  const [otherOptions, setOtherOptions] = useState<any>(null);
  const { getCurrentRiskActions } =
    useSharedContextFunctions() as SharedContextFunctions;

  const [loadingOtherOptions, setLoadingOtherOptions] = useState(true);
  const globalContext = useContext(MainContext);
  const orgaFormatNumber =
    globalContext?.context?.orga?.formatNumber || "en-US";
  const orgaCurrency = globalContext?.context?.orga?.currency || "EUR";
  const orgaDateFormat =
    globalContext?.context?.orga?.formatDate || "DD/MM/YYYY";
  const userLanguage = globalContext?.context?.user?.preferredLanguage;

  const { descriptorsList } = useContext(SharedContext);
  const { currentAction } = useContext(SharedContext);
  useEffect(() => {
    setOtherOptions(
      []
    );
    if (isTabActive && category === CATEGORY_VIEWS.OTHER_OPTIONS) {
      setOtherOptions(
        currentAction?.contexts?.at(0)?.data?.DATA?.OTHER_OPTIONS || []
      );
    }
  }, [category, currentAction]);
  /** get  predefinedActionList from redux */

  return (
    <>
      {category === CATEGORY_VIEWS.OTHER_OPTIONS ? (
        <>
          <>
            <DynamicTable
              key={category}
              Type={category}
              Data={otherOptions} // Pass otherOptions as data
            />
          </>
        </>
      ) : (
        listViews?.map((item: any, index: number) => {
          const dataIsNotEmpty = Array.isArray(item?.data)
            ? item?.data.length > 0
            : item?.data && Object.keys(item?.data).length > 0;

          return dataIsNotEmpty ? (
            <React.Fragment key={`${item?.data?.type}_${index}`}>
              <Typography
                style={{
                  marginBottom: 8,
                  color: "#393434",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                {item?.title}
              </Typography>
              {item?.type === VIEW_TYPES.inputs && (
                <MenuCollapseContentInputs
                  key={item?.index}
                  inputList={item?.data}
                />
              )}
              {item?.type === VIEW_TYPES.dynamic_table &&
                category !== CATEGORY_VIEWS.OTHER_OPTIONS && (
                  <>
                    <DynamicTable
                      key={item?.index}
                      Type={category}
                      Data={item?.data}
                    />
                  </>
                )}
              {item?.type === VIEW_TYPES.dualAxes_chart && (
                <DualAxesGrouped
                  dataSetY1={item?.data?.DataSetY1 || []}
                  dataSetY2={item?.data?.DataSetY2 || []}
                  config={{ descriptorsList: descriptorsList }}
                  yTitles={item?.yTitles}
                  params={{
                    orgaDateFormat: orgaDateFormat,
                    orgaFormatNumber: orgaFormatNumber,
                    orgaCurrency: orgaCurrency,
                    language: userLanguage,
                  }}
                />
              )}
              {item?.type === VIEW_TYPES.line_chart && (
                <MyLine
                  data={item?.data}
                  params={{
                    dateFormat: orgaDateFormat,
                    numberFormat: orgaFormatNumber,
                    currency: orgaCurrency,
                    language: userLanguage,
                  }}
                  config={item?.config}
                  fields={item?.fields}
                />
              )}
              {item.type === VIEW_TYPES.map && (
                <MapComponent key={item?.index} InputList={item?.data} />
              )}
              {item.type === VIEW_TYPES.map_sea && (
                <MapInboundFreight key={item?.index} InputList={item?.data} />
              )}

              {item.type === VIEW_TYPES.flow_chart &&
                (item?.data.length > 1 ? (
                  item.data?.map((flowItem: any, index: number) => (
                    <FlowGraph key={index} InputList={[flowItem]} />
                  ))
                ) : (
                  <FlowGraph key={item?.index} InputList={item.data} />
                ))}

              {item.type === VIEW_TYPES.waterfall_chart && (
                <MyWaterfFallChart
                  key={item?.index}
                  data={item?.data}
                  params={{
                    dateFormat: orgaDateFormat,
                    numberFormat: orgaFormatNumber,
                    currency: orgaCurrency,
                    language: userLanguage,
                  }}
                  config={item?.config}
                  fields={item?.fields}
                />
              )}
              {item.type === VIEW_TYPES.mix_charts && (
                <MixedAreaBarLine
                  key={item?.index}
                  data={item?.data}
                  params={{
                    dateFormat: orgaDateFormat,
                    numberFormat: orgaFormatNumber,
                    currency: orgaCurrency,
                    language: userLanguage,
                  }}
                />
              )}
              {item.type === VIEW_TYPES.bar_chart && (
                <MyBar
                  data={item?.data}
                  params={{
                    dateFormat: orgaDateFormat,
                    numberFormat: orgaFormatNumber,
                    currency: orgaCurrency,
                    language: userLanguage,
                  }}
                  config={item?.config}
                  fields={item?.fields}
                />
              )}
              {item.type === VIEW_TYPES.column_chart && (
                <MyColumn
                  data={item?.data}
                  params={{
                    dateFormat: orgaDateFormat,
                    numberFormat: orgaFormatNumber,
                    currency: orgaCurrency,
                    language: userLanguage,
                  }}
                  config={item?.config}
                  fields={item?.fields}
                />
              )}
              {item.type === VIEW_TYPES.pie && (
                <MyPie
                  data={item?.data}
                  params={{
                    dateFormat: orgaDateFormat,
                    numberFormat: orgaFormatNumber,
                    currency: orgaCurrency,
                    language: userLanguage,
                  }}
                  config={item?.config}
                  fields={item?.fields}
                />
              )}
              {index < listViews.length - 1 && <Divider />}
            </React.Fragment>
          ) : null;
        })
      )}
    </>
  );
}

export default SideBarTabs;
