export const Supply_Matching_Risk = [
  /** Risk 2  Delay Supply**/
  {
    id: 1,
    risk_conf_name: "Supply Risk",
    due_date: "2024-11-25 00:00:00",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge25255645", name: "Action Error" },
      { id: "fgfergger25255645", name: "Execution Error" },
    ],
    current_action: {
      action_conf: null,
      action_conf_name: "Delay Supply",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Action_Reason: "Excess stock risk",
                Delayed_Deployment_Quantity: "480",
                Market: "C0003",
                Location_code: "Riyadh",
                Item_Code: "SKU-346",
                Source: "Breda",
                Consolidation_Opportunity: "High",
              },
              OTHER_OPTIONS: [
                {
                  Action_Name: "No Action",
                  Execution_Stage: "Action Auto Rejected",
                  Reason_Code: "Consolidation Opportunity",
                },
              ],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Item_Code: "SKU-346",
                Date_of_risk: "2024-11-29",
                Excess_Quantity: "480",
                Monthly_Excess_Stock_Cost: "1,800€",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },

              Demand_Supply_Matching: [
                {
                  date: "2024-11-31",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-01",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 560,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -56,
                  Delta_value: 0,
                  Supply_Plan: 560,
                },
                {
                  date: "2024-12-02",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 480,
                  Delta_value: 26400,
                  Supply_Plan: 960,
                },
                {
                  date: "2024-12-03",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -140,
                  Delta_value: 7700,
                  Supply_Plan: 460,
                },
                {
                  date: "2024-12-04",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 380,
                },
                {
                  date: "2024-12-05",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 500,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -20,
                  Delta_value: 1100,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-06",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 154,
                  Delta_value: 0,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-07",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -40,
                  Delta_value: 2200,
                  Supply_Plan: 360,
                },
                {
                  date: "2024-12-08",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 300,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 47,
                  Delta_value: 0,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-09",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -10,
                  Delta_value: 0,
                  Supply_Plan: 600,
                },
              ],

              Stock_Situaton: [
                { Stock_Situation: "Available Stock", Quantity: 20 },
                { Stock_Situation: "Custom", Quantity: 100 },
                { Stock_Situation: "Intransit", Quantity: 360 },
                { Stock_Situation: "Available at Source", Quantity: 380 },
                { Stock_Situation: "Total Stock Value", Quantity: 860 },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Demand Planner",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4c7d5b58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Market Validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    status: "WAIT_FOR_ACTION",
    copilot: "",
    model: "Supply Risk",
    context: {
      Location_code: "Riyadh",
      Item_Code: "SKU-346",
      Date_of_risk: "2024-11-29",
      Excess_Quantity: "480",
      Monthly_Excess_Stock_Cost: "1,800€",
    },
    impact: "1,800€",

    actions:[
      {
        action_conf: null,
        action_conf_name: "Delay Supply",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Action_Reason: "Excess stock risk",
                  Delayed_Deployment_Quantity: "480",
                  Market: "C0003",
                  Location_code: "Riyadh",
                  Item_Code: "SKU-346",
                  Source: "Breda",
                  Consolidation_Opportunity: "High",
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "No Action",
                    Execution_Stage: "Action Auto Rejected",
                    Reason_Code: "Consolidation Opportunity",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Item_Code: "SKU-346",
                  Date_of_risk: "2024-11-29",
                  Excess_Quantity: "480",
                  Monthly_Excess_Stock_Cost: "1,800€",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Demand_Supply_Matching: [
                  {
                    date: "2024-11-31",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 560,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -56,
                    Delta_value: 0,
                    Supply_Plan: 560,
                  },
                  {
                    date: "2024-12-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 480,
                    Delta_value: 26400,
                    Supply_Plan: 960,
                  },
                  {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -140,
                    Delta_value: 7700,
                    Supply_Plan: 460,
                  },
                  {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 380,
                  },
                  {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 500,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 1100,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 154,
                    Delta_value: 0,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -40,
                    Delta_value: 2200,
                    Supply_Plan: 360,
                  },
                  {
                    date: "2024-12-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 47,
                    Delta_value: 0,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 0,
                    Supply_Plan: 600,
                  },
                ],
    
                Stock_Situaton: [
                  { Stock_Situation: "Available Stock", Quantity: 20 },
                  { Stock_Situation: "Custom", Quantity: 100 },
                  { Stock_Situation: "Intransit", Quantity: 360 },
                  { Stock_Situation: "Available at Source", Quantity: 380 },
                  { Stock_Situation: "Total Stock Value", Quantity: 860 },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Market Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Decrease Supply",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Action_Reason: "Excess stock risk",
                  Decreased_Supply_Quantity: "102",
                  Market: "C0003",
                  Location_code: "Riyadh",
                  Item_Code: "SKU-346",
                  Source: "Breda",
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Delay Supply",
                    Execution_Stage: "Action Auto Rejected",
                    Reason_Code: "Over stock risk",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Item_Code: "SKU-995",
                  Date_of_risk: "2024-11-30",
                  Quantity: "20",
                  Impact_on_Freight_CO2_emissions: "5.4 KG",
                  Impact_on_Freight_Cost: "950€",
                  Supply_Date: "2024-11-10",
                  Supply_Order: "100304000",
                  Source: "Breda",
                  Destination: " Dubai",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Demand_Supply_Matching: [
                  {
                    date: "2024-11-31",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 560,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 0,
                    Supply_Plan: 560,
                  },
                  {
                    date: "2024-12-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 480,
                    Delta_value: 26400,
                    Supply_Plan: 960,
                  },
                  {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -140,
                    Delta_value: 7700,
                    Supply_Plan: 460,
                  },
                  {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 380,
                  },
                  {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 500,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 1100,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 10,
                    Delta_value: 0,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -40,
                    Delta_value: 2200,
                    Supply_Plan: 360,
                  },
                  {
                    date: "2024-12-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 64,
                    Delta_value: 0,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 71,
                    Delta_value: 0,
                    Supply_Plan: 600,
                  },
                ],
                Stock_Situaton: [
                  {
                    Stock_Situation: "Available Stock",
                    Quantity: 20,
                  },
                  {
                    Stock_Situation: "Stock at Customs",
                    Quantity: 100,
                  },
                  {
                    Stock_Situation: "Intransit",
                    Quantity: 360,
                  },
                  {
                    Stock_Situation: "Available at Source",
                    Quantity: 380,
                  },
                  {
                    Stock_Situation: "Total Stock",
                    Quantity: 860,
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7fefefec3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Market Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf769bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Manufacturing Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9333bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Logistics",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-b462-4809-93fd-2f5d5f11987611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Delay Deployment",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Action_Reason: "Excess stock risk",
                  Decreased_Supply_Quantity: "100",
                  Market: "C0003",
                  Location_code: "Dubai",
                  Item_Code: "SKU-213",
                  Source: "Breda",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Location_code: "Dubai",
                  Item_Code: "SKU-213",
                  Date_of_risk: "2024-11-29",
                  Excess_Quantity: "100",
                  Monthly_Excess_Stock_Cost: "1,000€",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                OTHER_OPTIONS: [
                  {
                    Action_Name: "No Action",
                    Execution_Stage: "Action Auto Rejected",
                    Reason_Code: "Over stock risk",
                  },
                  {
                    Action_Name: "Descrease Supply",
                    Execution_Stage: "Action Auto Rejected",
                    Reason_Code: "Low stock risk",
                  },
                ],
                Demand_Supply_Matching: [
                  {
                    date: "2024-11-31",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 560,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 0,
                    Supply_Plan: 560,
                  },
                  {
                    date: "2024-12-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 480,
                    Delta_value: 26400,
                    Supply_Plan: 960,
                  },
                  {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -140,
                    Delta_value: 7700,
                    Supply_Plan: 460,
                  },
                  {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 380,
                  },
                  {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 500,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 1100,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 10,
                    Delta_value: 0,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -40,
                    Delta_value: 2200,
                    Supply_Plan: 360,
                  },
                  {
                    date: "2024-12-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 64,
                    Delta_value: 0,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 71,
                    Delta_value: 0,
                    Supply_Plan: 600,
                  },
                ],
                Stock_Situaton: [
                  {
                    Stock_Situation: "Available Stock",
                    Quantity: 100,
                  },
                  {
                    Stock_Situation: "Stock at Customs",
                    Quantity: 800,
                  },
                  {
                    Stock_Situation: "Intransit",
                    Quantity: 1200,
                  },
                  {
                    Stock_Situation: "Available at Source",
                    Quantity: 920,
                  },
                  {
                    Stock_Situation: "Total Stock",
                    Quantity: 3020,
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7d544b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Market Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebnhh9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      }
    ]
    
  },

  /** Risk 4 Decrease Supply*/
  {
    id: 4,
    risk_conf_name: "Supply Risk",
    due_date: "2024-11-26 00:00:00",
    copilot: "Procurement	",
    model: "Fulfilment Shortage",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge25255645", name: "Action Error" },
      { id: "fgfergger25255645", name: "Execution Error" },
    ],
    status: "WAIT_FOR_ACTION",
    context: {
      Location_code: "Riyadh",
      Item_Code: "SKU-346",
      Date_of_risk: "2024-11-30",
      Excess_Quantity: "102",
      Monthly_Excess_Stock_Cost: "1,400€",
    },
    impact: "1,400€",
    current_action: {
      action_conf: null,
      action_conf_name: "Decrease Supply",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Action_Reason: "Excess stock risk",
                Decreased_Supply_Quantity: "102",
                Market: "C0003",
                Location_code: "Riyadh",
                Item_Code: "SKU-346",
                Source: "Breda",
              },
              OTHER_OPTIONS: [
                {
                  Action_Name: "Delay Supply",
                  Execution_Stage: "Action Auto Rejected",
                  Reason_Code: "Over stock risk",
                },
              ],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Item_Code: "SKU-995",
                Date_of_risk: "2024-11-30",
                Quantity: "20",
                Impact_on_Freight_CO2_emissions: "5.4 KG",
                Impact_on_Freight_Cost: "950€",
                Supply_Date: "2024-11-10",
                Supply_Order: "100304000",
                Source: "Breda",
                Destination: " Dubai",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              Demand_Supply_Matching: [
                {
                  date: "2024-11-31",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-01",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 560,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -10,
                  Delta_value: 0,
                  Supply_Plan: 560,
                },
                {
                  date: "2024-12-02",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 480,
                  Delta_value: 26400,
                  Supply_Plan: 960,
                },
                {
                  date: "2024-12-03",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -140,
                  Delta_value: 7700,
                  Supply_Plan: 460,
                },
                {
                  date: "2024-12-04",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 380,
                },
                {
                  date: "2024-12-05",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 500,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -20,
                  Delta_value: 1100,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-06",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 10,
                  Delta_value: 0,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-07",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -40,
                  Delta_value: 2200,
                  Supply_Plan: 360,
                },
                {
                  date: "2024-12-08",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 300,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 64,
                  Delta_value: 0,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-09",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 71,
                  Delta_value: 0,
                  Supply_Plan: 600,
                },
              ],
              Stock_Situaton: [
                {
                  Stock_Situation: "Available Stock",
                  Quantity: 20,
                },
                {
                  Stock_Situation: "Stock at Customs",
                  Quantity: 100,
                },
                {
                  Stock_Situation: "Intransit",
                  Quantity: 360,
                },
                {
                  Stock_Situation: "Available at Source",
                  Quantity: 380,
                },
                {
                  Stock_Situation: "Total Stock",
                  Quantity: 860,
                },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Demand Planner",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4c7fefefec3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Market Validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf769bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Manufacturing Planning",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9333bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Logistics",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f11987611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    actions:[
      {
        action_conf: null,
        action_conf_name: "Delay Supply",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Action_Reason: "Excess stock risk",
                  Delayed_Deployment_Quantity: "480",
                  Market: "C0003",
                  Location_code: "Riyadh",
                  Item_Code: "SKU-346",
                  Source: "Breda",
                  Consolidation_Opportunity: "High",
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "No Action",
                    Execution_Stage: "Action Auto Rejected",
                    Reason_Code: "Consolidation Opportunity",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Item_Code: "SKU-346",
                  Date_of_risk: "2024-11-29",
                  Excess_Quantity: "480",
                  Monthly_Excess_Stock_Cost: "1,800€",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Demand_Supply_Matching: [
                  {
                    date: "2024-11-31",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 560,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -56,
                    Delta_value: 0,
                    Supply_Plan: 560,
                  },
                  {
                    date: "2024-12-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 480,
                    Delta_value: 26400,
                    Supply_Plan: 960,
                  },
                  {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -140,
                    Delta_value: 7700,
                    Supply_Plan: 460,
                  },
                  {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 380,
                  },
                  {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 500,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 1100,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 154,
                    Delta_value: 0,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -40,
                    Delta_value: 2200,
                    Supply_Plan: 360,
                  },
                  {
                    date: "2024-12-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 47,
                    Delta_value: 0,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 0,
                    Supply_Plan: 600,
                  },
                ],
    
                Stock_Situaton: [
                  { Stock_Situation: "Available Stock", Quantity: 20 },
                  { Stock_Situation: "Custom", Quantity: 100 },
                  { Stock_Situation: "Intransit", Quantity: 360 },
                  { Stock_Situation: "Available at Source", Quantity: 380 },
                  { Stock_Situation: "Total Stock Value", Quantity: 860 },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Market Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Decrease Supply",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Action_Reason: "Excess stock risk",
                  Decreased_Supply_Quantity: "102",
                  Market: "C0003",
                  Location_code: "Riyadh",
                  Item_Code: "SKU-346",
                  Source: "Breda",
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Delay Supply",
                    Execution_Stage: "Action Auto Rejected",
                    Reason_Code: "Over stock risk",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Item_Code: "SKU-995",
                  Date_of_risk: "2024-11-30",
                  Quantity: "20",
                  Impact_on_Freight_CO2_emissions: "5.4 KG",
                  Impact_on_Freight_Cost: "950€",
                  Supply_Date: "2024-11-10",
                  Supply_Order: "100304000",
                  Source: "Breda",
                  Destination: " Dubai",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Demand_Supply_Matching: [
                  {
                    date: "2024-11-31",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 560,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 0,
                    Supply_Plan: 560,
                  },
                  {
                    date: "2024-12-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 480,
                    Delta_value: 26400,
                    Supply_Plan: 960,
                  },
                  {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -140,
                    Delta_value: 7700,
                    Supply_Plan: 460,
                  },
                  {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 380,
                  },
                  {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 500,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 1100,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 10,
                    Delta_value: 0,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -40,
                    Delta_value: 2200,
                    Supply_Plan: 360,
                  },
                  {
                    date: "2024-12-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 64,
                    Delta_value: 0,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 71,
                    Delta_value: 0,
                    Supply_Plan: 600,
                  },
                ],
                Stock_Situaton: [
                  {
                    Stock_Situation: "Available Stock",
                    Quantity: 20,
                  },
                  {
                    Stock_Situation: "Stock at Customs",
                    Quantity: 100,
                  },
                  {
                    Stock_Situation: "Intransit",
                    Quantity: 360,
                  },
                  {
                    Stock_Situation: "Available at Source",
                    Quantity: 380,
                  },
                  {
                    Stock_Situation: "Total Stock",
                    Quantity: 860,
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7fefefec3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Market Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf769bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Manufacturing Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9333bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Logistics",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-b462-4809-93fd-2f5d5f11987611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Delay Deployment",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Action_Reason: "Excess stock risk",
                  Decreased_Supply_Quantity: "100",
                  Market: "C0003",
                  Location_code: "Dubai",
                  Item_Code: "SKU-213",
                  Source: "Breda",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Location_code: "Dubai",
                  Item_Code: "SKU-213",
                  Date_of_risk: "2024-11-29",
                  Excess_Quantity: "100",
                  Monthly_Excess_Stock_Cost: "1,000€",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                OTHER_OPTIONS: [
                  {
                    Action_Name: "No Action",
                    Execution_Stage: "Action Auto Rejected",
                    Reason_Code: "Over stock risk",
                  },
                  {
                    Action_Name: "Descrease Supply",
                    Execution_Stage: "Action Auto Rejected",
                    Reason_Code: "Low stock risk",
                  },
                ],
                Demand_Supply_Matching: [
                  {
                    date: "2024-11-31",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 560,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 0,
                    Supply_Plan: 560,
                  },
                  {
                    date: "2024-12-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 480,
                    Delta_value: 26400,
                    Supply_Plan: 960,
                  },
                  {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -140,
                    Delta_value: 7700,
                    Supply_Plan: 460,
                  },
                  {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 380,
                  },
                  {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 500,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 1100,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 10,
                    Delta_value: 0,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -40,
                    Delta_value: 2200,
                    Supply_Plan: 360,
                  },
                  {
                    date: "2024-12-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 64,
                    Delta_value: 0,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 71,
                    Delta_value: 0,
                    Supply_Plan: 600,
                  },
                ],
                Stock_Situaton: [
                  {
                    Stock_Situation: "Available Stock",
                    Quantity: 100,
                  },
                  {
                    Stock_Situation: "Stock at Customs",
                    Quantity: 800,
                  },
                  {
                    Stock_Situation: "Intransit",
                    Quantity: 1200,
                  },
                  {
                    Stock_Situation: "Available at Source",
                    Quantity: 920,
                  },
                  {
                    Stock_Situation: "Total Stock",
                    Quantity: 3020,
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7d544b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Market Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebnhh9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      }
    ]
    
  },

  /** Risk 6 Delay Deployment*/
  {
    id: 6,
    risk_conf_name: "Supply Risk",
    due_date: "2024-11-25 00:00:00",
    copilot: "Procurement	",
    model: "Supply Risk",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge455645", name: "Action Error" },
      { id: "fgfergger545545645", name: "Execution Error" },
    ],
    status: "WAIT_FOR_ACTION",
    context: {
      Location_code: "Dubai",
      Item_Code: "SKU-213",
      Date_of_risk: "2024-11-29",
      Excess_Quantity: "100",
      Monthly_Excess_Stock_Cost: "1,000€",
    },
    impact: "1,000€",

    current_action: {
      action_conf: null,
      action_conf_name: "Delay Deployment",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Action_Reason: "Excess stock risk",
                Decreased_Supply_Quantity: "100",
                Market: "C0003",
                Location_code: "Dubai",
                Item_Code: "SKU-213",
                Source: "Breda",
              },

              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Location_code: "Dubai",
                Item_Code: "SKU-213",
                Date_of_risk: "2024-11-29",
                Excess_Quantity: "100",
                Monthly_Excess_Stock_Cost: "1,000€",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },

              OTHER_OPTIONS: [
                {
                  Action_Name: "No Action",
                  Execution_Stage: "Action Auto Rejected",
                  Reason_Code: "Over stock risk",
                },
                {
                  Action_Name: "Descrease Supply",
                  Execution_Stage: "Action Auto Rejected",
                  Reason_Code: "Low stock risk",
                },
              ],
              Demand_Supply_Matching: [
                {
                  date: "2024-11-31",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-01",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 560,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -10,
                  Delta_value: 0,
                  Supply_Plan: 560,
                },
                {
                  date: "2024-12-02",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 480,
                  Delta_value: 26400,
                  Supply_Plan: 960,
                },
                {
                  date: "2024-12-03",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -140,
                  Delta_value: 7700,
                  Supply_Plan: 460,
                },
                {
                  date: "2024-12-04",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 380,
                },
                {
                  date: "2024-12-05",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 500,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -20,
                  Delta_value: 1100,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-06",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 10,
                  Delta_value: 0,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-07",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -40,
                  Delta_value: 2200,
                  Supply_Plan: 360,
                },
                {
                  date: "2024-12-08",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 300,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 64,
                  Delta_value: 0,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-09",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 71,
                  Delta_value: 0,
                  Supply_Plan: 600,
                },
              ],
              Stock_Situaton: [
                {
                  Stock_Situation: "Available Stock",
                  Quantity: 100,
                },
                {
                  Stock_Situation: "Stock at Customs",
                  Quantity: 800,
                },
                {
                  Stock_Situation: "Intransit",
                  Quantity: 1200,
                },
                {
                  Stock_Situation: "Available at Source",
                  Quantity: 920,
                },
                {
                  Stock_Situation: "Total Stock",
                  Quantity: 3020,
                },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Demand Planner",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4c7d544b58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Market Validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebnhh9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    actions:[
      {
        action_conf: null,
        action_conf_name: "Delay Supply",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Action_Reason: "Excess stock risk",
                  Delayed_Deployment_Quantity: "480",
                  Market: "C0003",
                  Location_code: "Riyadh",
                  Item_Code: "SKU-346",
                  Source: "Breda",
                  Consolidation_Opportunity: "High",
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "No Action",
                    Execution_Stage: "Action Auto Rejected",
                    Reason_Code: "Consolidation Opportunity",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Item_Code: "SKU-346",
                  Date_of_risk: "2024-11-29",
                  Excess_Quantity: "480",
                  Monthly_Excess_Stock_Cost: "1,800€",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Demand_Supply_Matching: [
                  {
                    date: "2024-11-31",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 560,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -56,
                    Delta_value: 0,
                    Supply_Plan: 560,
                  },
                  {
                    date: "2024-12-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 480,
                    Delta_value: 26400,
                    Supply_Plan: 960,
                  },
                  {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -140,
                    Delta_value: 7700,
                    Supply_Plan: 460,
                  },
                  {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 380,
                  },
                  {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 500,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 1100,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 154,
                    Delta_value: 0,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -40,
                    Delta_value: 2200,
                    Supply_Plan: 360,
                  },
                  {
                    date: "2024-12-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 47,
                    Delta_value: 0,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 0,
                    Supply_Plan: 600,
                  },
                ],
    
                Stock_Situaton: [
                  { Stock_Situation: "Available Stock", Quantity: 20 },
                  { Stock_Situation: "Custom", Quantity: 100 },
                  { Stock_Situation: "Intransit", Quantity: 360 },
                  { Stock_Situation: "Available at Source", Quantity: 380 },
                  { Stock_Situation: "Total Stock Value", Quantity: 860 },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7d5b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Market Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Decrease Supply",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Action_Reason: "Excess stock risk",
                  Decreased_Supply_Quantity: "102",
                  Market: "C0003",
                  Location_code: "Riyadh",
                  Item_Code: "SKU-346",
                  Source: "Breda",
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Delay Supply",
                    Execution_Stage: "Action Auto Rejected",
                    Reason_Code: "Over stock risk",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Item_Code: "SKU-995",
                  Date_of_risk: "2024-11-30",
                  Quantity: "20",
                  Impact_on_Freight_CO2_emissions: "5.4 KG",
                  Impact_on_Freight_Cost: "950€",
                  Supply_Date: "2024-11-10",
                  Supply_Order: "100304000",
                  Source: "Breda",
                  Destination: " Dubai",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Demand_Supply_Matching: [
                  {
                    date: "2024-11-31",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 560,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 0,
                    Supply_Plan: 560,
                  },
                  {
                    date: "2024-12-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 480,
                    Delta_value: 26400,
                    Supply_Plan: 960,
                  },
                  {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -140,
                    Delta_value: 7700,
                    Supply_Plan: 460,
                  },
                  {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 380,
                  },
                  {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 500,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 1100,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 10,
                    Delta_value: 0,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -40,
                    Delta_value: 2200,
                    Supply_Plan: 360,
                  },
                  {
                    date: "2024-12-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 64,
                    Delta_value: 0,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 71,
                    Delta_value: 0,
                    Supply_Plan: 600,
                  },
                ],
                Stock_Situaton: [
                  {
                    Stock_Situation: "Available Stock",
                    Quantity: 20,
                  },
                  {
                    Stock_Situation: "Stock at Customs",
                    Quantity: 100,
                  },
                  {
                    Stock_Situation: "Intransit",
                    Quantity: 360,
                  },
                  {
                    Stock_Situation: "Available at Source",
                    Quantity: 380,
                  },
                  {
                    Stock_Situation: "Total Stock",
                    Quantity: 860,
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7fefefec3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Market Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf769bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Manufacturing Planning",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9333bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Logistics",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf9bafe-b462-4809-93fd-2f5d5f11987611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Delay Deployment",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Action_Reason: "Excess stock risk",
                  Decreased_Supply_Quantity: "100",
                  Market: "C0003",
                  Location_code: "Dubai",
                  Item_Code: "SKU-213",
                  Source: "Breda",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  Location_code: "Dubai",
                  Item_Code: "SKU-213",
                  Date_of_risk: "2024-11-29",
                  Excess_Quantity: "100",
                  Monthly_Excess_Stock_Cost: "1,000€",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                OTHER_OPTIONS: [
                  {
                    Action_Name: "No Action",
                    Execution_Stage: "Action Auto Rejected",
                    Reason_Code: "Over stock risk",
                  },
                  {
                    Action_Name: "Descrease Supply",
                    Execution_Stage: "Action Auto Rejected",
                    Reason_Code: "Low stock risk",
                  },
                ],
                Demand_Supply_Matching: [
                  {
                    date: "2024-11-31",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-01",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 560,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -10,
                    Delta_value: 0,
                    Supply_Plan: 560,
                  },
                  {
                    date: "2024-12-02",
                    Forecast_Status: "Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 480,
                    Delta_value: 26400,
                    Supply_Plan: 960,
                  },
                  {
                    date: "2024-12-03",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -140,
                    Delta_value: 7700,
                    Supply_Plan: 460,
                  },
                  {
                    date: "2024-12-04",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -100,
                    Delta_value: 5500,
                    Supply_Plan: 380,
                  },
                  {
                    date: "2024-12-05",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 500,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -20,
                    Delta_value: 1100,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-06",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 480,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 10,
                    Delta_value: 0,
                    Supply_Plan: 480,
                  },
                  {
                    date: "2024-12-07",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 400,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: -40,
                    Delta_value: 2200,
                    Supply_Plan: 360,
                  },
                  {
                    date: "2024-12-08",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 300,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 64,
                    Delta_value: 0,
                    Supply_Plan: 300,
                  },
                  {
                    date: "2024-12-09",
                    Forecast_Status: "Non-Firmed",
                    Market_Location: "C0004",
                    Item_code: "SKU-346",
                    Quantity_in_units: 600,
                    Planned_Shipping_Location: 100,
                    Delta_Quantity: 71,
                    Delta_value: 0,
                    Supply_Plan: 600,
                  },
                ],
                Stock_Situaton: [
                  {
                    Stock_Situation: "Available Stock",
                    Quantity: 100,
                  },
                  {
                    Stock_Situation: "Stock at Customs",
                    Quantity: 800,
                  },
                  {
                    Stock_Situation: "Intransit",
                    Quantity: 1200,
                  },
                  {
                    Stock_Situation: "Available at Source",
                    Quantity: 920,
                  },
                  {
                    Stock_Situation: "Total Stock",
                    Quantity: 3020,
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.413239+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.413244+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.422537+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Demand Planner",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 1,
            execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
            execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
            id: "4c7d544b58-95c3-41ab-86e9-df259b36e9ca",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
              },
              {
                id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.422541+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Market Validation",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebnhh9bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      }
    ]
    
  },
];

export const Supply_Matching_Start_Risk =

{
  id: 11,
  risk_conf_name: "Supply Risk",
  due_date: "2024-11-26 00:00:00",
  copilot: "Procurement	",
  model: "Fulfilment Shortage",
  reason: [
    { id: "fgfergger545645", name: "Missing Data" },
    { id: "fgfergge25255645", name: "Action Error" },
    { id: "fgfergger25255645", name: "Execution Error" },
  ],
  status: "WAIT_FOR_ACTION",
  context: {
    Location_code: "Riyadh",
    Item_Code: "SKU-346",
    Date_of_risk: "2024-11-30",
    Excess_Quantity: "102",
    Monthly_Excess_Stock_Cost: "1,400€",
  },
  impact: "1,400€",
  current_action: {
    action_conf: null,
    action_conf_name: "Decrease Supply",
    action_conf_policy: {},
    action_conf_priority: 1,
    action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
    action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
    contexts: [
      {
        computed: false,
        created_at: "2024-08-14T17:25:07.443005+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        data: {
          DATA: {
            ACTION_DETAILS: {
              Action_Reason: "Excess stock risk",
              Decreased_Supply_Quantity: "102",
              Market: "C0003",
              Location_code: "Riyadh",
              Item_Code: "SKU-346",
              Source: "Breda",
            },
            OTHER_OPTIONS: [
              {
                Action_Name: "Delay Supply",
                Execution_Stage: "Action Auto Rejected",
                Reason_Code: "Over stock risk",
              },
            ],
            ERRORS: {
              ERROR: [
                {
                  CODE: "MISSING_DATA",
                  DETAILS: {
                    DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                  },
                  MODULE: "Exobrain-actions",
                },
                {
                  CODE: "MISSING_DATA",
                  DETAILS: {
                    DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                  },
                  MODULE: "Exobrain-actions",
                },
              ],
              WARNING: [
                {
                  CODE: "UNPROCESSABLE_DATA",
                  DETAILS: {
                    DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                  },
                  MODULE: "Exobrain-actions",
                },
                {
                  CODE: "UNPROCESSABLE_DATA",
                  DETAILS: {
                    DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                  },
                  MODULE: "Exobrain-actions",
                },
              ],
            },
            IMPACT: {
              Item_Code: "SKU-995",
              Date_of_risk: "2024-11-30",
              Quantity: "20",
              Impact_on_Freight_CO2_emissions: "5.4 KG",
              Impact_on_Freight_Cost: "950€",
              Supply_Date: "2024-11-10",
              Supply_Order: "100304000",
              Source: "Breda",
              Destination: " Dubai",
            },
            MASTER: {
              ITEM_MASTER: [
                {
                  ITEM_CODE: "VGPSLWNL003",
                  ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                  ITEM_TYPE: "F",
                  UNIT_PER_PALLET: null,
                  WEIGHT_PER_UNIT_IN_KG: null,
                },
              ],
              LOCATION_ITEM_MASTER: [
                {
                  DAYS_OF_SUPPLY_THRESHOLD: null,
                  ITEM_CODE: "VGPSLWNL003",
                  LOCATION_CODE: "264",
                  STANDARD_COST_PER_UNIT: null,
                },
              ],
              LOCATION_MASTER: [
                {
                  APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                  LOCATION_CITY: "JEDDAH",
                  LOCATION_CONTACT_NAME: null,
                  LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                  LOCATION_DESCRIPTION: "JEDDAH PORT",
                  LOCATION_ID: "JEDDAH PORT",
                  LOCATION_PRIMARY_LANGUAGE: null,
                  LOCATION_TYPE: null,
                  LOCATION_ZIP_CODE: null,
                },
                {
                  APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                  LOCATION_CITY: "   RIYADH",
                  LOCATION_CONTACT_NAME: null,
                  LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                  LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                  LOCATION_ID: "264",
                  LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                  LOCATION_TYPE: "BRANCH",
                  LOCATION_ZIP_CODE: null,
                },
              ],
            },
            Demand_Supply_Matching: [
              {
                date: "2024-11-31",
                Forecast_Status: "Firmed",
                Market_Location: "C0004",
                Item_code: "SKU-346",
                Quantity_in_units: 400,
                Planned_Shipping_Location: 100,
                Delta_Quantity: -100,
                Delta_value: 5500,
                Supply_Plan: 300,
              },
              {
                date: "2024-12-01",
                Forecast_Status: "Firmed",
                Market_Location: "C0004",
                Item_code: "SKU-346",
                Quantity_in_units: 560,
                Planned_Shipping_Location: 100,
                Delta_Quantity: -10,
                Delta_value: 0,
                Supply_Plan: 560,
              },
              {
                date: "2024-12-02",
                Forecast_Status: "Firmed",
                Market_Location: "C0004",
                Item_code: "SKU-346",
                Quantity_in_units: 480,
                Planned_Shipping_Location: 100,
                Delta_Quantity: 480,
                Delta_value: 26400,
                Supply_Plan: 960,
              },
              {
                date: "2024-12-03",
                Forecast_Status: "Non-Firmed",
                Market_Location: "C0004",
                Item_code: "SKU-346",
                Quantity_in_units: 600,
                Planned_Shipping_Location: 100,
                Delta_Quantity: -140,
                Delta_value: 7700,
                Supply_Plan: 460,
              },
              {
                date: "2024-12-04",
                Forecast_Status: "Non-Firmed",
                Market_Location: "C0004",
                Item_code: "SKU-346",
                Quantity_in_units: 480,
                Planned_Shipping_Location: 100,
                Delta_Quantity: -100,
                Delta_value: 5500,
                Supply_Plan: 380,
              },
              {
                date: "2024-12-05",
                Forecast_Status: "Non-Firmed",
                Market_Location: "C0004",
                Item_code: "SKU-346",
                Quantity_in_units: 500,
                Planned_Shipping_Location: 100,
                Delta_Quantity: -20,
                Delta_value: 1100,
                Supply_Plan: 480,
              },
              {
                date: "2024-12-06",
                Forecast_Status: "Non-Firmed",
                Market_Location: "C0004",
                Item_code: "SKU-346",
                Quantity_in_units: 480,
                Planned_Shipping_Location: 100,
                Delta_Quantity: 10,
                Delta_value: 0,
                Supply_Plan: 480,
              },
              {
                date: "2024-12-07",
                Forecast_Status: "Non-Firmed",
                Market_Location: "C0004",
                Item_code: "SKU-346",
                Quantity_in_units: 400,
                Planned_Shipping_Location: 100,
                Delta_Quantity: -40,
                Delta_value: 2200,
                Supply_Plan: 360,
              },
              {
                date: "2024-12-08",
                Forecast_Status: "Non-Firmed",
                Market_Location: "C0004",
                Item_code: "SKU-346",
                Quantity_in_units: 300,
                Planned_Shipping_Location: 100,
                Delta_Quantity: 64,
                Delta_value: 0,
                Supply_Plan: 300,
              },
              {
                date: "2024-12-09",
                Forecast_Status: "Non-Firmed",
                Market_Location: "C0004",
                Item_code: "SKU-346",
                Quantity_in_units: 600,
                Planned_Shipping_Location: 100,
                Delta_Quantity: 71,
                Delta_value: 0,
                Supply_Plan: 600,
              },
            ],
            Stock_Situaton: [
              {
                Stock_Situation: "Available Stock",
                Quantity: 20,
              },
              {
                Stock_Situation: "Stock at Customs",
                Quantity: 100,
              },
              {
                Stock_Situation: "Intransit",
                Quantity: 360,
              },
              {
                Stock_Situation: "Available at Source",
                Quantity: 380,
              },
              {
                Stock_Situation: "Total Stock",
                Quantity: 860,
              },
            ],
          },
        },
        decision_time: "2024-06-11T12:57:28.367528+00:00",
        id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
        inputs_data: null,
        is_deleted: false,
        note: "",
        outputs_data: null,
        reason: null,
        reason_comment: "",
        role: "",

        updated_at: "2024-09-03T12:57:28.367542+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
        user: {
          id: "602d5335-2be1-485f-a694-313d9428fafa",
        },
      },
    ],
    created_at: "2024-08-14T17:25:07.456366+00:00",
    created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
    current_execution: {
      action: {
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
      },
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.384630+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {},
          decision_time: null,
          id: "69fd7354-d60b-41bc-aa96-56515831adb3",
          inputs_data: {},
          is_deleted: false,
          note: "",
          outputs_data: {},
          reason: null,
          reason_comment: "",
          role: "",
          updated_at: "2024-08-14T17:25:07.384634+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          user: {
            id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.393646+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      execution_conf: null,
      execution_conf_name: "Truck Booking",
      execution_conf_policy: {
        automation: [],
      },
      execution_conf_priority: 1,
      execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
      execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
      id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
      is_deleted: false,
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      status: "OPEN",
      updated_at: "2024-08-14T17:25:07.393649+00:00",
      updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
    },
    executions: [
      {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.413239+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.413244+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.422537+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Demand Planner",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
        execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
        id: "4c7fsdfefec3-41ab-86e9-df259b36e9ca",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
          },
          {
            id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.422541+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Market Validation",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 2,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebjhjjjf769bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Manufacturing Planning",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 3,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "eb578f9333bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Logistics",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 4,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9rrbafe-b462-4809-93fd-2f5d5f11987611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
    ],
    feasible: true,
    id: "ca965ff0745136-804b-4718-8ac1-ca1705a5c8e9",
    is_deleted: false,
    risk: {
      id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
    },
    roles: [
      {
        id: "746fd295-827b-4568-9933-1f193cf8f13d",
      },
      {
        id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
      },
    ],
    // "status": "WAIT_FOR_EXECUTION",
    status: "OPEN",
    updated_at: "2024-09-03T12:57:28.379118+00:00",
    updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
  },
  actions:[
    {
      action_conf: null,
      action_conf_name: "Delay Supply",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Action_Reason: "Excess stock risk",
                Delayed_Deployment_Quantity: "480",
                Market: "C0003",
                Location_code: "Riyadh",
                Item_Code: "SKU-346",
                Source: "Breda",
                Consolidation_Opportunity: "High",
              },
              OTHER_OPTIONS: [
                {
                  Action_Name: "No Action",
                  Execution_Stage: "Action Auto Rejected",
                  Reason_Code: "Consolidation Opportunity",
                },
              ],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Item_Code: "SKU-346",
                Date_of_risk: "2024-11-29",
                Excess_Quantity: "480",
                Monthly_Excess_Stock_Cost: "1,800€",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
  
              Demand_Supply_Matching: [
                {
                  date: "2024-11-31",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-01",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 560,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -56,
                  Delta_value: 0,
                  Supply_Plan: 560,
                },
                {
                  date: "2024-12-02",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 480,
                  Delta_value: 26400,
                  Supply_Plan: 960,
                },
                {
                  date: "2024-12-03",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -140,
                  Delta_value: 7700,
                  Supply_Plan: 460,
                },
                {
                  date: "2024-12-04",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 380,
                },
                {
                  date: "2024-12-05",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 500,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -20,
                  Delta_value: 1100,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-06",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 154,
                  Delta_value: 0,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-07",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -40,
                  Delta_value: 2200,
                  Supply_Plan: 360,
                },
                {
                  date: "2024-12-08",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 300,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 47,
                  Delta_value: 0,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-09",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -10,
                  Delta_value: 0,
                  Supply_Plan: 600,
                },
              ],
  
              Stock_Situaton: [
                { Stock_Situation: "Available Stock", Quantity: 20 },
                { Stock_Situation: "Custom", Quantity: 100 },
                { Stock_Situation: "Intransit", Quantity: 360 },
                { Stock_Situation: "Available at Source", Quantity: 380 },
                { Stock_Situation: "Total Stock Value", Quantity: 860 },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Demand Planner",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4c7d5b58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Market Validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    {
      action_conf: null,
      action_conf_name: "Decrease Supply",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Action_Reason: "Excess stock risk",
                Decreased_Supply_Quantity: "102",
                Market: "C0003",
                Location_code: "Riyadh",
                Item_Code: "SKU-346",
                Source: "Breda",
              },
              OTHER_OPTIONS: [
                {
                  Action_Name: "Delay Supply",
                  Execution_Stage: "Action Auto Rejected",
                  Reason_Code: "Over stock risk",
                },
              ],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Item_Code: "SKU-995",
                Date_of_risk: "2024-11-30",
                Quantity: "20",
                Impact_on_Freight_CO2_emissions: "5.4 KG",
                Impact_on_Freight_Cost: "950€",
                Supply_Date: "2024-11-10",
                Supply_Order: "100304000",
                Source: "Breda",
                Destination: " Dubai",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              Demand_Supply_Matching: [
                {
                  date: "2024-11-31",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-01",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 560,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -10,
                  Delta_value: 0,
                  Supply_Plan: 560,
                },
                {
                  date: "2024-12-02",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 480,
                  Delta_value: 26400,
                  Supply_Plan: 960,
                },
                {
                  date: "2024-12-03",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -140,
                  Delta_value: 7700,
                  Supply_Plan: 460,
                },
                {
                  date: "2024-12-04",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 380,
                },
                {
                  date: "2024-12-05",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 500,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -20,
                  Delta_value: 1100,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-06",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 10,
                  Delta_value: 0,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-07",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -40,
                  Delta_value: 2200,
                  Supply_Plan: 360,
                },
                {
                  date: "2024-12-08",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 300,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 64,
                  Delta_value: 0,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-09",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 71,
                  Delta_value: 0,
                  Supply_Plan: 600,
                },
              ],
              Stock_Situaton: [
                {
                  Stock_Situation: "Available Stock",
                  Quantity: 20,
                },
                {
                  Stock_Situation: "Stock at Customs",
                  Quantity: 100,
                },
                {
                  Stock_Situation: "Intransit",
                  Quantity: 360,
                },
                {
                  Stock_Situation: "Available at Source",
                  Quantity: 380,
                },
                {
                  Stock_Situation: "Total Stock",
                  Quantity: 860,
                },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Demand Planner",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4c7fefefec3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Market Validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf769bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Manufacturing Planning",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9333bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Logistics",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f11987611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    {
      action_conf: null,
      action_conf_name: "Delay Deployment",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Action_Reason: "Excess stock risk",
                Decreased_Supply_Quantity: "100",
                Market: "C0003",
                Location_code: "Dubai",
                Item_Code: "SKU-213",
                Source: "Breda",
              },
  
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                Location_code: "Dubai",
                Item_Code: "SKU-213",
                Date_of_risk: "2024-11-29",
                Excess_Quantity: "100",
                Monthly_Excess_Stock_Cost: "1,000€",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
  
              OTHER_OPTIONS: [
                {
                  Action_Name: "No Action",
                  Execution_Stage: "Action Auto Rejected",
                  Reason_Code: "Over stock risk",
                },
                {
                  Action_Name: "Descrease Supply",
                  Execution_Stage: "Action Auto Rejected",
                  Reason_Code: "Low stock risk",
                },
              ],
              Demand_Supply_Matching: [
                {
                  date: "2024-11-31",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-01",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 560,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -10,
                  Delta_value: 0,
                  Supply_Plan: 560,
                },
                {
                  date: "2024-12-02",
                  Forecast_Status: "Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 480,
                  Delta_value: 26400,
                  Supply_Plan: 960,
                },
                {
                  date: "2024-12-03",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -140,
                  Delta_value: 7700,
                  Supply_Plan: 460,
                },
                {
                  date: "2024-12-04",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -100,
                  Delta_value: 5500,
                  Supply_Plan: 380,
                },
                {
                  date: "2024-12-05",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 500,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -20,
                  Delta_value: 1100,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-06",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 480,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 10,
                  Delta_value: 0,
                  Supply_Plan: 480,
                },
                {
                  date: "2024-12-07",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 400,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: -40,
                  Delta_value: 2200,
                  Supply_Plan: 360,
                },
                {
                  date: "2024-12-08",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 300,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 64,
                  Delta_value: 0,
                  Supply_Plan: 300,
                },
                {
                  date: "2024-12-09",
                  Forecast_Status: "Non-Firmed",
                  Market_Location: "C0004",
                  Item_code: "SKU-346",
                  Quantity_in_units: 600,
                  Planned_Shipping_Location: 100,
                  Delta_Quantity: 71,
                  Delta_value: 0,
                  Supply_Plan: 600,
                },
              ],
              Stock_Situaton: [
                {
                  Stock_Situation: "Available Stock",
                  Quantity: 100,
                },
                {
                  Stock_Situation: "Stock at Customs",
                  Quantity: 800,
                },
                {
                  Stock_Situation: "Intransit",
                  Quantity: 1200,
                },
                {
                  Stock_Situation: "Available at Source",
                  Quantity: 920,
                },
                {
                  Stock_Situation: "Total Stock",
                  Quantity: 3020,
                },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.413239+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "87cd732a-93b3-4f7f-b6b7-0bd40cb8bd45",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.413244+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.422537+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Demand Planner",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "d45169c2-bb4f-4357-8258-bd6197b598b2",
          execution_model: "fd99b889-425f-421f-bd97-50d9f75a6603",
          id: "4c7d544b58-95c3-41ab-86e9-df259b36e9ca",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "8d2ce755-2bcc-40d5-8c6d-54158757f093",
            },
            {
              id: "bf81bbf6-c5ce-4558-8199-670476fe25d8",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.422541+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Market Validation",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebnhh9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca069836-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    }
  ]
}

export const Supply_Matching_reasons = [
  { id: "customer request", name: "customer request" },
  { id: "supply constraint", name: "supply constraint" },
  {
    id: "production capacity constraint",
    name: "production capacity constraint",
  },
  { id: "freight budget", name: "freight budget" },
  { id: "capacity limitation", name: "capacity limitation" },
];


