export const fulfillment_data: any[] = [
  {
    running_risk_id: 1000,
    risk_date: 293 + 45275,
    risk_status: "Open",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 12266,
    revenue_at_risk: 272427.86,
    action_type: "Alternate DC",
    quantity_mitigated: 11040,
    revenue_mitigated: 245198.39999999997,
    standard_value: 16891.2,
    freight_cost: 9273.6,
    cost_impact: 26164.800000000003,
    days_of_supply_impact: 2.33,
  },
  {
    running_risk_id: 1001,
    risk_date: 293 + 45277,
    risk_status: "In progress",
    risk_location: 152,
    risk_item: "SKU-792",
    quantity_at_risk: 18909,
    revenue_at_risk: 434907,
    action_type: "Alternate DC",
    execution_stage: "Customer validation",
    quantity_mitigated: 16262,
    revenue_mitigated: 374026,
    standard_value: 13822.7,
    freight_cost: 12521.74,
    cost_impact: 26344.440000000002,
    days_of_supply_impact: 3.34,
  },
  {
    running_risk_id: 1002,
    risk_date: 293 + 45277,
    risk_status: "In progress",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 26047,
    revenue_at_risk: 484213.73,
    action_type: "Alternate DC",
    execution_stage: "Planner validation",
    quantity_mitigated: 25527,
    revenue_mitigated: 474546.93,
    standard_value: 30887.67,
    freight_cost: 18889.98,
    cost_impact: 49777.649999999994,
    days_of_supply_impact: 2.85,
  },
  {
    running_risk_id: 1003,
    risk_date: 293 + 45276,
    risk_status: "In progress",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 49978,
    revenue_at_risk: 1143496.64,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 46980,
    revenue_mitigated: 1074902.4,
    standard_value: 50738.4,
    freight_cost: 33825.6,
    cost_impact: 84564,
    days_of_supply_impact: 2.71,
  },
  {
    running_risk_id: 1004,
    risk_date: 293 + 45231,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-792",
    quantity_at_risk: 37532,
    revenue_at_risk: 853102.36,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 32653,
    revenue_mitigated: 742202.69,
    standard_value: 39183.6,
    freight_cost: 14040.79,
    cost_impact: 53224.39,
    days_of_supply_impact: 2.24,
  },
  {
    running_risk_id: 1005,
    risk_date: 293 + 45232,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-792",
    quantity_at_risk: 22333,
    revenue_at_risk: 431696.89,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 22333,
    revenue_mitigated: 431696.89,
    standard_value: 24566.3,
    freight_cost: 15633.1,
    cost_impact: 40199.4,
    days_of_supply_impact: 3.02,
  },
  {
    running_risk_id: 1006,
    risk_date: 293 + 45233,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 31302,
    revenue_at_risk: 718067.88,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 28172,
    revenue_mitigated: 646265.68,
    standard_value: 27890.28,
    freight_cost: 16058.04,
    cost_impact: 43948.32,
    days_of_supply_impact: 2.66,
  },
  {
    running_risk_id: 1007,
    risk_date: 293 + 45233,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 47664,
    revenue_at_risk: 1073393.28,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 40992,
    revenue_mitigated: 923139.8400000001,
    standard_value: 56978.88,
    freight_cost: 33203.52,
    cost_impact: 90182.4,
    days_of_supply_impact: 2.92,
  },
  {
    running_risk_id: 1008,
    risk_date: 293 + 45234,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 30870,
    revenue_at_risk: 634687.2,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 26857,
    revenue_mitigated: 552179.92,
    standard_value: 31691.26,
    freight_cost: 15308.49,
    cost_impact: 46999.75,
    days_of_supply_impact: 3.27,
  },
  {
    running_risk_id: 1009,
    risk_date: 293 + 45236,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 42266,
    revenue_at_risk: 906605.7,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 38885,
    revenue_mitigated: 834083.25,
    standard_value: 54827.85,
    freight_cost: 26441.8,
    cost_impact: 81269.65,
    days_of_supply_impact: 2.24,
  },
  {
    running_risk_id: 1010,
    risk_date: 293 + 45236,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 21193,
    revenue_at_risk: 399488.05,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 20982,
    revenue_mitigated: 395510.69999999995,
    standard_value: 19513.26,
    freight_cost: 13428.48,
    cost_impact: 32941.74,
    days_of_supply_impact: 3.08,
  },
  {
    running_risk_id: 1011,
    risk_date: 293 + 45237,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 17563,
    revenue_at_risk: 373565.01,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 16861,
    revenue_mitigated: 358633.47000000003,
    standard_value: 19895.98,
    freight_cost: 8599.11,
    cost_impact: 28495.09,
    days_of_supply_impact: 3.26,
  },
  {
    running_risk_id: 1012,
    risk_date: 293 + 45238,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 33642,
    revenue_at_risk: 697398.66,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 31960,
    revenue_mitigated: 662530.8,
    standard_value: 41548,
    freight_cost: 27166,
    cost_impact: 68714,
    days_of_supply_impact: 2.22,
  },
  {
    running_risk_id: 1013,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 26723,
    revenue_at_risk: 612758.39,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 23784,
    revenue_mitigated: 545367.12,
    standard_value: 29016.48,
    freight_cost: 10702.8,
    cost_impact: 39719.28,
    days_of_supply_impact: 2.64,
  },
  {
    running_risk_id: 1014,
    risk_date: 293 + 45241,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 26392,
    revenue_at_risk: 554232,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 23753,
    revenue_mitigated: 498813,
    standard_value: 23753,
    freight_cost: 19714.99,
    cost_impact: 43467.990000000005,
    days_of_supply_impact: 2.65,
  },
  {
    running_risk_id: 1015,
    risk_date: 293 + 45244,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 25715,
    revenue_at_risk: 563929.95,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 22630,
    revenue_mitigated: 496275.8999999999,
    standard_value: 23082.6,
    freight_cost: 11541.3,
    cost_impact: 34623.899999999994,
    days_of_supply_impact: 2.86,
  },
  {
    running_risk_id: 1016,
    risk_date: 293 + 45244,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 15572,
    revenue_at_risk: 352705.8,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 15261,
    revenue_mitigated: 345661.65,
    standard_value: 19076.25,
    freight_cost: 13429.68,
    cost_impact: 32505.93,
    days_of_supply_impact: 2.7,
  },
  {
    running_risk_id: 1017,
    risk_date: 293 + 45246,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 30273,
    revenue_at_risk: 638154.84,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 28760,
    revenue_mitigated: 606260.7999999999,
    standard_value: 33649.2,
    freight_cost: 20707.2,
    cost_impact: 54356.399999999994,
    days_of_supply_impact: 2.65,
  },
  {
    running_risk_id: 1018,
    risk_date: 293 + 45247,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 36713,
    revenue_at_risk: 835220.75,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 33042,
    revenue_mitigated: 751705.5,
    standard_value: 43285.02,
    freight_cost: 20155.62,
    cost_impact: 63440.64,
    days_of_supply_impact: 3.11,
  },
  {
    running_risk_id: 1019,
    risk_date: 293 + 45247,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 26734,
    revenue_at_risk: 577454.4,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 23794,
    revenue_mitigated: 513950.4,
    standard_value: 29980.44,
    freight_cost: 17369.62,
    cost_impact: 47350.06,
    days_of_supply_impact: 3.16,
  },
  {
    running_risk_id: 1020,
    risk_date: 293 + 45249,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 19909,
    revenue_at_risk: 393401.84,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 16127,
    revenue_mitigated: 318669.52,
    standard_value: 16449.54,
    freight_cost: 8063.5,
    cost_impact: 24513.04,
    days_of_supply_impact: 2.12,
  },
  {
    running_risk_id: 1021,
    risk_date: 293 + 45251,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-995",
    quantity_at_risk: 22874,
    revenue_at_risk: 430717.42,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 19443,
    revenue_mitigated: 366111.69,
    standard_value: 24303.75,
    freight_cost: 9527.07,
    cost_impact: 33830.82,
    days_of_supply_impact: 3.18,
  },
  {
    running_risk_id: 1022,
    risk_date: 293 + 45251,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 16464,
    revenue_at_risk: 339652.32,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 16135,
    revenue_mitigated: 332865.05,
    standard_value: 21782.25,
    freight_cost: 9196.95,
    cost_impact: 30979.2,
    days_of_supply_impact: 2.08,
  },
  {
    running_risk_id: 1023,
    risk_date: 293 + 45254,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-995",
    quantity_at_risk: 20556,
    revenue_at_risk: 430853.76,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 19529,
    revenue_mitigated: 409327.84,
    standard_value: 17771.39,
    freight_cost: 13865.59,
    cost_impact: 31636.98,
    days_of_supply_impact: 2.16,
  },
  {
    running_risk_id: 1024,
    risk_date: 293 + 45255,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 13409,
    revenue_at_risk: 267107.28,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 13141,
    revenue_mitigated: 261768.72000000003,
    standard_value: 11038.44,
    freight_cost: 10907.03,
    cost_impact: 21945.47,
    days_of_supply_impact: 2,
  },
  {
    running_risk_id: 1025,
    risk_date: 293 + 45257,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 19669,
    revenue_at_risk: 458681.08,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 16326,
    revenue_mitigated: 380722.32,
    standard_value: 24815.52,
    freight_cost: 7673.22,
    cost_impact: 32488.74,
    days_of_supply_impact: 3.07,
  },
  {
    running_risk_id: 1026,
    risk_date: 293 + 45260,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 22507,
    revenue_at_risk: 419080.34,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 21382,
    revenue_mitigated: 398132.84,
    standard_value: 29293.34,
    freight_cost: 19885.26,
    cost_impact: 49178.6,
    days_of_supply_impact: 2.99,
  },
  {
    running_risk_id: 1027,
    risk_date: 293 + 45233,
    risk_status: "Rejected",
    rejection_code: "Customer constraint for shipping point",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 24758,
    revenue_at_risk: 521403.48,
    action_type: "Alternate DC",
    execution_stage: "Customer validation",
    quantity_mitigated: 23025,
    revenue_mitigated: 484906.5,
    standard_value: 20492.25,
    freight_cost: 22564.5,
    cost_impact: 43056.75,
    days_of_supply_impact: 2,
  },
  {
    running_risk_id: 1028,
    risk_date: 293 + 45235,
    risk_status: "Rejected",
    rejection_code: "Customer constraint for shipping point",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 22131,
    revenue_at_risk: 516980.16,
    action_type: "Alternate DC",
    execution_stage: "Customer validation",
    quantity_mitigated: 18591,
    revenue_mitigated: 434285.75999999995,
    standard_value: 25655.58,
    freight_cost: 12270.06,
    cost_impact: 37925.64,
    days_of_supply_impact: 3.31,
  },
  {
    running_risk_id: 1029,
    risk_date: 293 + 45237,
    risk_status: "Rejected",
    rejection_code: "Customer constraint for shipping point",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-995",
    quantity_at_risk: 12492,
    revenue_at_risk: 283068.72,
    action_type: "Alternate DC",
    execution_stage: "Customer validation",
    quantity_mitigated: 10119,
    revenue_mitigated: 229296.53999999998,
    standard_value: 12446.37,
    freight_cost: 6880.92,
    cost_impact: 19327.29,
    days_of_supply_impact: 2.68,
  },
  {
    running_risk_id: 1030,
    risk_date: 293 + 45237,
    risk_status: "Rejected",
    rejection_code: "Delivery cost too high",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 47180,
    revenue_at_risk: 892645.6,
    action_type: "Alternate DC",
    execution_stage: "Customer validation",
    quantity_mitigated: 40575,
    revenue_mitigated: 767679,
    standard_value: 48690,
    freight_cost: 35300.25,
    cost_impact: 83990.25,
    days_of_supply_impact: 2.5,
  },
  {
    running_risk_id: 1031,
    risk_date: 293 + 45242,
    risk_status: "Rejected",
    rejection_code: "Delivery cost too high",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-346",
    quantity_at_risk: 38261,
    revenue_at_risk: 739967.74,
    action_type: "Alternate DC",
    execution_stage: "Customer validation",
    quantity_mitigated: 37114,
    revenue_mitigated: 717784.76,
    standard_value: 34516.02,
    freight_cost: 28206.64,
    cost_impact: 62722.659999999996,
    days_of_supply_impact: 2.18,
  },
  {
    running_risk_id: 1032,
    risk_date: 293 + 45244,
    risk_status: "Rejected",
    rejection_code: "Customer constraint for shipping point",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 10415,
    revenue_at_risk: 234754.1,
    action_type: "Alternate DC",
    execution_stage: "Customer validation",
    quantity_mitigated: 9270,
    revenue_mitigated: 208945.8,
    standard_value: 10197,
    freight_cost: 6118.2,
    cost_impact: 16315.2,
    days_of_supply_impact: 2.27,
  },
  {
    running_risk_id: 1033,
    risk_date: 293 + 45246,
    risk_status: "Rejected",
    rejection_code: "Customer constraint for shipping point",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 30546,
    revenue_at_risk: 674761.14,
    action_type: "Alternate DC",
    execution_stage: "Customer validation",
    quantity_mitigated: 24437,
    revenue_mitigated: 539813.33,
    standard_value: 21993.3,
    freight_cost: 19060.86,
    cost_impact: 41054.16,
    days_of_supply_impact: 2.25,
  },
  {
    running_risk_id: 1034,
    risk_date: 293 + 45250,
    risk_status: "Rejected",
    rejection_code: "Customer constraint for shipping point",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 17787,
    revenue_at_risk: 413014.14,
    action_type: "Alternate DC",
    execution_stage: "Customer validation",
    quantity_mitigated: 14230,
    revenue_mitigated: 330420.6,
    standard_value: 20918.1,
    freight_cost: 6403.5,
    cost_impact: 27321.6,
    days_of_supply_impact: 2.14,
  },
  {
    running_risk_id: 1035,
    risk_date: 293 + 45251,
    risk_status: "Rejected",
    rejection_code: "Customer constraint for shipping point",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 37141,
    revenue_at_risk: 860928.38,
    action_type: "Alternate DC",
    execution_stage: "Customer validation",
    quantity_mitigated: 34170,
    revenue_mitigated: 792060.6,
    standard_value: 31436.4,
    freight_cost: 15034.8,
    cost_impact: 46471.2,
    days_of_supply_impact: 3.39,
  },
  {
    running_risk_id: 1036,
    risk_date: 293 + 45255,
    risk_status: "Rejected",
    rejection_code: "Customer constraint for shipping point",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 33151,
    revenue_at_risk: 605668.77,
    action_type: "Alternate DC",
    execution_stage: "Customer validation",
    quantity_mitigated: 27184,
    revenue_mitigated: 496651.68,
    standard_value: 30717.92,
    freight_cost: 25281.12,
    cost_impact: 55999.03999999999,
    days_of_supply_impact: 2.45,
  },
  {
    running_risk_id: 1037,
    risk_date: 293 + 45236,
    risk_status: "Rejected",
    rejection_code: "No inventory at alternative DC",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 30769,
    revenue_at_risk: 705225.48,
    action_type: "Alternate DC",
    execution_stage: "Planner validation",
    quantity_mitigated: 25231,
    revenue_mitigated: 578294.52,
    standard_value: 30781.82,
    freight_cost: 13120.12,
    cost_impact: 43901.94,
    days_of_supply_impact: 3.53,
  },
  {
    running_risk_id: 1038,
    risk_date: 293 + 45239,
    risk_status: "Rejected",
    rejection_code: "No inventory at alternative DC",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 10219,
    revenue_at_risk: 232482.25,
    action_type: "Alternate DC",
    execution_stage: "Planner validation",
    quantity_mitigated: 9402,
    revenue_mitigated: 213895.5,
    standard_value: 12222.6,
    freight_cost: 6581.4,
    cost_impact: 18804,
    days_of_supply_impact: 2.34,
  },
  {
    running_risk_id: 1039,
    risk_date: 293 + 45243,
    risk_status: "Rejected",
    rejection_code: "No inventory at alternative DC",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 14388,
    revenue_at_risk: 298119.36,
    action_type: "Alternate DC",
    execution_stage: "Planner validation",
    quantity_mitigated: 13813,
    revenue_mitigated: 286205.36,
    standard_value: 19890.72,
    freight_cost: 11879.18,
    cost_impact: 31769.9,
    days_of_supply_impact: 2.45,
  },
  {
    running_risk_id: 1040,
    risk_date: 293 + 45247,
    risk_status: "Rejected",
    rejection_code: "Delivery cost budget constraint",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-792",
    quantity_at_risk: 20366,
    revenue_at_risk: 417299.34,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 19959,
    revenue_mitigated: 408959.91000000003,
    standard_value: 18162.69,
    freight_cost: 14570.07,
    cost_impact: 32732.76,
    days_of_supply_impact: 2.7,
  },
  {
    running_risk_id: 1041,
    risk_date: 293 + 45252,
    risk_status: "Rejected",
    rejection_code: "Delivery cost too high",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 17625,
    revenue_at_risk: 338928.75,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 14805,
    revenue_mitigated: 284700.15,
    standard_value: 19098.45,
    freight_cost: 13324.5,
    cost_impact: 32422.95,
    days_of_supply_impact: 3.29,
  },
  {
    running_risk_id: 1042,
    risk_date: 293 + 45254,
    risk_status: "Rejected",
    rejection_code: "Parcel carrier calendar",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 16262,
    revenue_at_risk: 309628.48,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 15612,
    revenue_mitigated: 297252.48,
    standard_value: 14987.52,
    freight_cost: 12957.96,
    cost_impact: 27945.48,
    days_of_supply_impact: 2.88,
  },
  {
    running_risk_id: 1043,
    risk_date: 293 + 45238,
    risk_status: "Rejected",
    rejection_code: "Delivery cost budget constraint",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 32827,
    revenue_at_risk: 668029.45,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 26919,
    revenue_mitigated: 547801.65,
    standard_value: 27188.19,
    freight_cost: 22073.58,
    cost_impact: 49261.770000000004,
    days_of_supply_impact: 2.67,
  },
  {
    running_risk_id: 1044,
    risk_date: 293 + 45241,
    risk_status: "Rejected",
    rejection_code: "Delivery cost budget constraint",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 18389,
    revenue_at_risk: 374767.82,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 15263,
    revenue_mitigated: 311059.94,
    standard_value: 21062.94,
    freight_cost: 13736.7,
    cost_impact: 34799.64,
    days_of_supply_impact: 2.1,
  },
  {
    running_risk_id: 1045,
    risk_date: 293 + 45244,
    risk_status: "Rejected",
    rejection_code: "Parcel carrier calendar",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 17592,
    revenue_at_risk: 398634.72,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 14778,
    revenue_mitigated: 334869.48,
    standard_value: 12856.86,
    freight_cost: 8719.02,
    cost_impact: 21575.88,
    days_of_supply_impact: 3.47,
  },
  {
    running_risk_id: 1046,
    risk_date: 293 + 45253,
    risk_status: "Rejected",
    rejection_code: "Parcel carrier calendar",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 22571,
    revenue_at_risk: 528612.82,
    action_type: "Alternate DC",
    execution_stage: "Freight",
    quantity_mitigated: 18734,
    revenue_mitigated: 438750.27999999997,
    standard_value: 23042.82,
    freight_cost: 13301.14,
    cost_impact: 36343.96,
    days_of_supply_impact: 3.02,
  },
  {
    running_risk_id: 1047,
    risk_date: 293 + 45266,
    risk_status: "Open",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 44067,
    revenue_at_risk: 867679.23,
    action_type: "Substitution",
    quantity_mitigated: 35254,
    revenue_mitigated: 694151.26,
    standard_value: 36311.62,
    freight_cost: 25735.42,
    cost_impact: 62047.04,
    days_of_supply_impact: 2.4,
  },
  {
    running_risk_id: 1048,
    risk_date: 293 + 45266,
    risk_status: "In progress",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 13239,
    revenue_at_risk: 268354.53,
    action_type: "Substitution",
    execution_stage: "Customer validation",
    quantity_mitigated: 12842,
    revenue_mitigated: 260307.34000000003,
    standard_value: 11686.22,
    freight_cost: 8732.56,
    cost_impact: 20418.78,
    days_of_supply_impact: 3.46,
  },
  {
    running_risk_id: 1049,
    risk_date: 293 + 45263,
    risk_status: "In progress",
    risk_location: 101,
    risk_item: "SKU-346",
    quantity_at_risk: 45353,
    revenue_at_risk: 832227.55,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 36736,
    revenue_mitigated: 674105.6000000001,
    standard_value: 36736,
    freight_cost: 35266.56,
    cost_impact: 72002.56,
    days_of_supply_impact: 2.59,
  },
  {
    running_risk_id: 1050,
    risk_date: 293 + 45231,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 33772,
    revenue_at_risk: 654163.64,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 28031,
    revenue_mitigated: 542960.47,
    standard_value: 26909.76,
    freight_cost: 21864.18,
    cost_impact: 48773.94,
    days_of_supply_impact: 3.49,
  },
  {
    running_risk_id: 1051,
    risk_date: 293 + 45234,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-346",
    quantity_at_risk: 36228,
    revenue_at_risk: 806797.56,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 34055,
    revenue_mitigated: 758404.8500000001,
    standard_value: 45293.15,
    freight_cost: 25200.7,
    cost_impact: 70493.85,
    days_of_supply_impact: 2.3,
  },
  {
    running_risk_id: 1052,
    risk_date: 293 + 45234,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 39640,
    revenue_at_risk: 769016,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 37658,
    revenue_mitigated: 730565.2,
    standard_value: 44436.44,
    freight_cost: 21841.64,
    cost_impact: 66278.08,
    days_of_supply_impact: 2.76,
  },
  {
    running_risk_id: 1053,
    risk_date: 293 + 45238,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-995",
    quantity_at_risk: 36720,
    revenue_at_risk: 700617.6,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 29376,
    revenue_mitigated: 560494.08,
    standard_value: 27613.44,
    freight_cost: 19388.16,
    cost_impact: 47001.6,
    days_of_supply_impact: 2.82,
  },
  {
    running_risk_id: 1054,
    risk_date: 293 + 45239,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 43015,
    revenue_at_risk: 828899.05,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 40004,
    revenue_mitigated: 770877.0800000001,
    standard_value: 41604.16,
    freight_cost: 30003,
    cost_impact: 71607.16,
    days_of_supply_impact: 3.47,
  },
  {
    running_risk_id: 1055,
    risk_date: 293 + 45239,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 29292,
    revenue_at_risk: 548053.32,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 24606,
    revenue_mitigated: 460378.25999999995,
    standard_value: 30265.38,
    freight_cost: 12795.12,
    cost_impact: 43060.5,
    days_of_supply_impact: 2.49,
  },
  {
    running_risk_id: 1056,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-995",
    quantity_at_risk: 12963,
    revenue_at_risk: 272871.15,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 11538,
    revenue_mitigated: 242874.90000000002,
    standard_value: 15460.92,
    freight_cost: 10614.96,
    cost_impact: 26075.879999999997,
    days_of_supply_impact: 2.03,
  },
  {
    running_risk_id: 1057,
    risk_date: 293 + 45242,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 47592,
    revenue_at_risk: 979443.36,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 45213,
    revenue_mitigated: 930483.54,
    standard_value: 39787.44,
    freight_cost: 32101.23,
    cost_impact: 71888.67,
    days_of_supply_impact: 2.47,
  },
  {
    running_risk_id: 1058,
    risk_date: 293 + 45242,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 20381,
    revenue_at_risk: 386219.95,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 17936,
    revenue_mitigated: 339887.2,
    standard_value: 17577.28,
    freight_cost: 13272.64,
    cost_impact: 30849.92,
    days_of_supply_impact: 2.44,
  },
  {
    running_risk_id: 1059,
    risk_date: 293 + 45245,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 19368,
    revenue_at_risk: 358695.36,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 18981,
    revenue_mitigated: 351528.12,
    standard_value: 16513.47,
    freight_cost: 16893.09,
    cost_impact: 33406.56,
    days_of_supply_impact: 3.06,
  },
  {
    running_risk_id: 1060,
    risk_date: 293 + 45247,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-995",
    quantity_at_risk: 11637,
    revenue_at_risk: 248449.95,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 10939,
    revenue_mitigated: 233547.65000000002,
    standard_value: 12908.02,
    freight_cost: 7438.52,
    cost_impact: 20346.54,
    days_of_supply_impact: 2.31,
  },
  {
    running_risk_id: 1061,
    risk_date: 293 + 45247,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-792",
    quantity_at_risk: 28695,
    revenue_at_risk: 621246.75,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 28122,
    revenue_mitigated: 608841.3,
    standard_value: 30934.2,
    freight_cost: 16591.98,
    cost_impact: 47526.18,
    days_of_supply_impact: 3.22,
  },
  {
    running_risk_id: 1062,
    risk_date: 293 + 45248,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 32829,
    revenue_at_risk: 687767.55,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 28562,
    revenue_mitigated: 598373.9,
    standard_value: 37130.6,
    freight_cost: 15994.72,
    cost_impact: 53125.32,
    days_of_supply_impact: 2.24,
  },
  {
    running_risk_id: 1063,
    risk_date: 293 + 45248,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 21731,
    revenue_at_risk: 418104.44,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 20645,
    revenue_mitigated: 397209.8,
    standard_value: 19612.75,
    freight_cost: 17135.35,
    cost_impact: 36748.1,
    days_of_supply_impact: 2.86,
  },
  {
    running_risk_id: 1064,
    risk_date: 293 + 45248,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-792",
    quantity_at_risk: 23955,
    revenue_at_risk: 532280.1,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 19164,
    revenue_mitigated: 425824.07999999996,
    standard_value: 19930.56,
    freight_cost: 14947.92,
    cost_impact: 34878.48,
    days_of_supply_impact: 3.5,
  },
  {
    running_risk_id: 1065,
    risk_date: 293 + 45251,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 22709,
    revenue_at_risk: 471438.84,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 20439,
    revenue_mitigated: 424313.64,
    standard_value: 16759.98,
    freight_cost: 16759.98,
    cost_impact: 33519.96,
    days_of_supply_impact: 3.32,
  },
  {
    running_risk_id: 1066,
    risk_date: 293 + 45252,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 28970,
    revenue_at_risk: 604603.9,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 26073,
    revenue_mitigated: 544143.51,
    standard_value: 28680.3,
    freight_cost: 25551.54,
    cost_impact: 54231.84,
    days_of_supply_impact: 2.64,
  },
  {
    running_risk_id: 1067,
    risk_date: 293 + 45254,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 37419,
    revenue_at_risk: 851656.44,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 35549,
    revenue_mitigated: 809095.24,
    standard_value: 30927.63,
    freight_cost: 34838.02,
    cost_impact: 65765.65,
    days_of_supply_impact: 2.83,
  },
  {
    running_risk_id: 1068,
    risk_date: 293 + 45255,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 15958,
    revenue_at_risk: 330011.44,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 15958,
    revenue_mitigated: 330011.44,
    standard_value: 20426.24,
    freight_cost: 15160.1,
    cost_impact: 35586.340000000004,
    days_of_supply_impact: 2.4,
  },
  {
    running_risk_id: 1069,
    risk_date: 293 + 45257,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 13286,
    revenue_at_risk: 278607.42,
    action_type: "Substitution",
    execution_stage: "Freight",
    quantity_mitigated: 13154,
    revenue_mitigated: 275839.38,
    standard_value: 14600.94,
    freight_cost: 10391.66,
    cost_impact: 24992.6,
    days_of_supply_impact: 3.44,
  },
  {
    running_risk_id: 1070,
    risk_date: 293 + 45238,
    risk_status: "Rejected",
    rejection_code: "Customer constraint for substitute",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 17773,
    revenue_at_risk: 408956.73,
    action_type: "Substitution",
    execution_stage: "Customer validation",
    quantity_mitigated: 15285,
    revenue_mitigated: 351707.85000000003,
    standard_value: 16049.25,
    freight_cost: 8712.45,
    cost_impact: 24761.7,
    days_of_supply_impact: 3.48,
  },
  {
    running_risk_id: 1071,
    risk_date: 293 + 45243,
    risk_status: "Rejected",
    rejection_code: "Sales order cancellation",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 29303,
    revenue_at_risk: 643786.91,
    action_type: "Substitution",
    execution_stage: "Customer validation",
    quantity_mitigated: 26959,
    revenue_mitigated: 592289.2300000001,
    standard_value: 28037.36,
    freight_cost: 15636.22,
    cost_impact: 43673.58,
    days_of_supply_impact: 2.04,
  },
  {
    running_risk_id: 1072,
    risk_date: 293 + 45249,
    risk_status: "Rejected",
    rejection_code: "Customer constraint for substitute",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 15997,
    revenue_at_risk: 293065.04,
    action_type: "Substitution",
    execution_stage: "Customer validation",
    quantity_mitigated: 13278,
    revenue_mitigated: 243252.96,
    standard_value: 18456.42,
    freight_cost: 7037.34,
    cost_impact: 25493.76,
    days_of_supply_impact: 2.98,
  },
  {
    running_risk_id: 1073,
    risk_date: 293 + 45252,
    risk_status: "Rejected",
    rejection_code: "Sales order cancellation",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-792",
    quantity_at_risk: 44816,
    revenue_at_risk: 873463.84,
    action_type: "Substitution",
    execution_stage: "Customer validation",
    quantity_mitigated: 44816,
    revenue_mitigated: 873463.8399999999,
    standard_value: 50193.92,
    freight_cost: 28682.24,
    cost_impact: 78876.16,
    days_of_supply_impact: 2.18,
  },
  {
    running_risk_id: 1074,
    risk_date: 293 + 45252,
    risk_status: "Rejected",
    rejection_code: "Sales order cancellation",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-792",
    quantity_at_risk: 22355,
    revenue_at_risk: 477279.25,
    action_type: "Substitution",
    execution_stage: "Customer validation",
    quantity_mitigated: 18332,
    revenue_mitigated: 391388.2,
    standard_value: 16498.8,
    freight_cost: 16132.16,
    cost_impact: 32630.96,
    days_of_supply_impact: 3.33,
  },
  {
    running_risk_id: 1075,
    risk_date: 293 + 45259,
    risk_status: "Rejected",
    rejection_code: "Customer constraint for substitute",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 40895,
    revenue_at_risk: 857159.2,
    action_type: "Substitution",
    execution_stage: "Customer validation",
    quantity_mitigated: 40487,
    revenue_mitigated: 848607.52,
    standard_value: 39677.26,
    freight_cost: 36843.17,
    cost_impact: 76520.43,
    days_of_supply_impact: 2.08,
  },
  {
    running_risk_id: 1076,
    risk_date: 293 + 45233,
    risk_status: "Rejected",
    rejection_code: "No inventory of finished goods inventory",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 26753,
    revenue_at_risk: 517135.49,
    action_type: "Substitution",
    execution_stage: "Planner validation",
    quantity_mitigated: 24881,
    revenue_mitigated: 480949.73000000004,
    standard_value: 30354.82,
    freight_cost: 18660.75,
    cost_impact: 49015.57,
    days_of_supply_impact: 2.89,
  },
  {
    running_risk_id: 1077,
    risk_date: 293 + 45236,
    risk_status: "Rejected",
    rejection_code: "No inventory of finished goods inventory",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 27232,
    revenue_at_risk: 599104,
    action_type: "Substitution",
    execution_stage: "Planner validation",
    quantity_mitigated: 26688,
    revenue_mitigated: 587136,
    standard_value: 31491.84,
    freight_cost: 11742.72,
    cost_impact: 43234.56,
    days_of_supply_impact: 2.88,
  },
  {
    running_risk_id: 1078,
    risk_date: 293 + 45241,
    risk_status: "Rejected",
    rejection_code: "No inventory of finished goods inventory",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 18358,
    revenue_at_risk: 389373.18,
    action_type: "Substitution",
    execution_stage: "Planner validation",
    quantity_mitigated: 17624,
    revenue_mitigated: 373805.04,
    standard_value: 16214.08,
    freight_cost: 13394.24,
    cost_impact: 29608.32,
    days_of_supply_impact: 2.87,
  },
  {
    running_risk_id: 1079,
    risk_date: 293 + 45243,
    risk_status: "Rejected",
    rejection_code: "No inventory of finished goods inventory",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 24830,
    revenue_at_risk: 492875.5,
    action_type: "Substitution",
    execution_stage: "Planner validation",
    quantity_mitigated: 20113,
    revenue_mitigated: 399243.05,
    standard_value: 29968.37,
    freight_cost: 16291.53,
    cost_impact: 46259.9,
    days_of_supply_impact: 2.39,
  },
  {
    running_risk_id: 1080,
    risk_date: 293 + 45247,
    risk_status: "Rejected",
    rejection_code: "No inventory of finished goods inventory",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 46975,
    revenue_at_risk: 1095457,
    action_type: "Substitution",
    execution_stage: "Planner validation",
    quantity_mitigated: 42278,
    revenue_mitigated: 985922.96,
    standard_value: 52001.94,
    freight_cost: 41432.44,
    cost_impact: 93434.38,
    days_of_supply_impact: 2.43,
  },
  {
    running_risk_id: 1081,
    risk_date: 293 + 45248,
    risk_status: "Rejected",
    rejection_code: "No inventory of finished goods inventory",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 41960,
    revenue_at_risk: 869411.2,
    action_type: "Substitution",
    execution_stage: "Planner validation",
    quantity_mitigated: 41541,
    revenue_mitigated: 860729.5199999999,
    standard_value: 42371.82,
    freight_cost: 39879.36,
    cost_impact: 82251.18,
    days_of_supply_impact: 3.12,
  },
  {
    running_risk_id: 1082,
    risk_date: 293 + 45251,
    risk_status: "Rejected",
    rejection_code: "No inventory of finished goods inventory",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 30965,
    revenue_at_risk: 599172.75,
    action_type: "Substitution",
    execution_stage: "Planner validation",
    quantity_mitigated: 30656,
    revenue_mitigated: 593193.6,
    standard_value: 45677.44,
    freight_cost: 21152.64,
    cost_impact: 66830.08,
    days_of_supply_impact: 2.39,
  },
  {
    running_risk_id: 1083,
    risk_date: 293 + 45254,
    risk_status: "Rejected",
    rejection_code: "No inventory of finished goods inventory",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 47822,
    revenue_at_risk: 1016217.5,
    action_type: "Substitution",
    execution_stage: "Planner validation",
    quantity_mitigated: 46388,
    revenue_mitigated: 985745,
    standard_value: 60304.4,
    freight_cost: 34327.12,
    cost_impact: 94631.52,
    days_of_supply_impact: 3.02,
  },
  {
    running_risk_id: 1125,
    risk_date: 293 + 45270,
    risk_status: "Open",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 32868,
    revenue_at_risk: 725068.08,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 26952,
    revenue_mitigated: 594561.12,
    standard_value: 26143.44,
    freight_cost: 64684.8,
    cost_impact: 90828.24,
  },
  {
    running_risk_id: 1126,
    risk_date: 293 + 45285,
    risk_status: "In progress",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 13074,
    revenue_at_risk: 256904.1,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 11113,
    revenue_mitigated: 218370.45,
    standard_value: 15224.81,
    freight_cost: 28560.41,
    cost_impact: 43785.22,
  },
  {
    running_risk_id: 1127,
    risk_date: 293 + 45233,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 25435,
    revenue_at_risk: 501323.85,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 25435,
    revenue_mitigated: 501323.85,
    standard_value: 28232.85,
    freight_cost: 61807.05,
    cost_impact: 90039.9,
  },
  {
    running_risk_id: 1128,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 25023,
    revenue_at_risk: 538244.73,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 24523,
    revenue_mitigated: 527489.73,
    standard_value: 26975.3,
    freight_cost: 49781.69,
    cost_impact: 76756.99,
  },
  {
    running_risk_id: 1129,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 13009,
    revenue_at_risk: 253805.59,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 12359,
    revenue_mitigated: 241124.09,
    standard_value: 10505.15,
    freight_cost: 27684.16,
    cost_impact: 38189.31,
  },
  {
    running_risk_id: 1130,
    risk_date: 293 + 45238,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 22039,
    revenue_at_risk: 492130.87,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 20276,
    revenue_mitigated: 452763.08,
    standard_value: 25345,
    freight_cost: 38727.16,
    cost_impact: 64072.16,
  },
  {
    running_risk_id: 1131,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 23109,
    revenue_at_risk: 448314.6,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 20336,
    revenue_mitigated: 394518.4,
    standard_value: 30910.72,
    freight_cost: 53890.4,
    cost_impact: 84801.12,
  },
  {
    running_risk_id: 1132,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-792",
    quantity_at_risk: 49122,
    revenue_at_risk: 975562.92,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 47649,
    revenue_mitigated: 946309.14,
    standard_value: 65755.62,
    freight_cost: 86244.69,
    cost_impact: 152000.31,
  },
  {
    running_risk_id: 1133,
    risk_date: 293 + 45242,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 30879,
    revenue_at_risk: 699100.56,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 29027,
    revenue_mitigated: 657171.2800000001,
    standard_value: 29027,
    freight_cost: 71406.42,
    cost_impact: 100433.42,
  },
  {
    running_risk_id: 1134,
    risk_date: 293 + 45245,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 44854,
    revenue_at_risk: 1011009.16,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 41715,
    revenue_mitigated: 940256.1,
    standard_value: 45469.35,
    freight_cost: 99698.85,
    cost_impact: 145168.2,
  },
  {
    running_risk_id: 1135,
    risk_date: 293 + 45246,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 48151,
    revenue_at_risk: 994799.66,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 41410,
    revenue_mitigated: 855530.6,
    standard_value: 49692,
    freight_cost: 101868.6,
    cost_impact: 151560.6,
  },
  {
    running_risk_id: 1136,
    risk_date: 293 + 45249,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 38101,
    revenue_at_risk: 872512.9,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 32767,
    revenue_mitigated: 750364.2999999999,
    standard_value: 39975.74,
    freight_cost: 73398.08,
    cost_impact: 113373.82,
  },
  {
    running_risk_id: 1137,
    risk_date: 293 + 45251,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 45521,
    revenue_at_risk: 1059273.67,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 39604,
    revenue_mitigated: 921585.08,
    standard_value: 43564.4,
    freight_cost: 103762.48,
    cost_impact: 147326.88,
  },
  {
    running_risk_id: 1138,
    risk_date: 293 + 45252,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 37236,
    revenue_at_risk: 725357.28,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 35747,
    revenue_mitigated: 696351.5599999999,
    standard_value: 53977.97,
    freight_cost: 83647.98,
    cost_impact: 137625.95,
  },
  {
    running_risk_id: 1139,
    risk_date: 293 + 45255,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 19869,
    revenue_at_risk: 365589.6,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 19075,
    revenue_mitigated: 350980,
    standard_value: 19456.5,
    freight_cost: 34144.25,
    cost_impact: 53600.75,
  },
  {
    running_risk_id: 1140,
    risk_date: 293 + 45258,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 30529,
    revenue_at_risk: 613632.9,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 29308,
    revenue_mitigated: 589090.8,
    standard_value: 24618.72,
    freight_cost: 52461.32,
    cost_impact: 77080.04000000001,
  },
  {
    running_risk_id: 1141,
    risk_date: 293 + 45260,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-346",
    quantity_at_risk: 13560,
    revenue_at_risk: 246792,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 12882,
    revenue_mitigated: 234452.4,
    standard_value: 14814.3,
    freight_cost: 27825.12,
    cost_impact: 42639.42,
  },
  {
    running_risk_id: 1142,
    risk_date: 293 + 45234,
    risk_status: "Rejected",
    rejection_code: "Delivery cost budget constraint",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 46461,
    revenue_at_risk: 988690.08,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 41351,
    revenue_mitigated: 879949.28,
    standard_value: 42178.02,
    freight_cost: 93453.26,
    cost_impact: 135631.28,
  },
  {
    running_risk_id: 1143,
    risk_date: 293 + 45236,
    risk_status: "Rejected",
    rejection_code: "Delivery cost too high",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-346",
    quantity_at_risk: 31281,
    revenue_at_risk: 687556.38,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 27528,
    revenue_mitigated: 605065.44,
    standard_value: 33033.6,
    freight_cost: 66342.48,
    cost_impact: 99376.07999999999,
  },
  {
    running_risk_id: 1144,
    risk_date: 293 + 45238,
    risk_status: "Rejected",
    rejection_code: "Delivery cost budget constraint",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 48314,
    revenue_at_risk: 970145.12,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 44933,
    revenue_mitigated: 902254.64,
    standard_value: 64703.52,
    freight_cost: 115028.48,
    cost_impact: 179732,
  },
  {
    running_risk_id: 1145,
    risk_date: 293 + 45240,
    risk_status: "Rejected",
    rejection_code: "Parcel carrier calendar",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-995",
    quantity_at_risk: 24741,
    revenue_at_risk: 577702.35,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 21030,
    revenue_mitigated: 491050.5,
    standard_value: 24394.8,
    freight_cost: 43111.5,
    cost_impact: 67506.3,
  },
  {
    running_risk_id: 1146,
    risk_date: 293 + 45242,
    risk_status: "Rejected",
    rejection_code: "Parcel carrier calendar",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 27723,
    revenue_at_risk: 561667.98,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 27169,
    revenue_mitigated: 550443.94,
    standard_value: 29070.83,
    freight_cost: 49719.27,
    cost_impact: 78790.1,
  },
  {
    running_risk_id: 1147,
    risk_date: 293 + 45243,
    risk_status: "Rejected",
    rejection_code: "Delivery cost budget constraint",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 36641,
    revenue_at_risk: 770926.64,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 34443,
    revenue_mitigated: 724680.72,
    standard_value: 50975.64,
    freight_cost: 80596.62,
    cost_impact: 131572.26,
  },
  {
    running_risk_id: 1148,
    risk_date: 293 + 45245,
    risk_status: "Rejected",
    rejection_code: "Parcel carrier calendar",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-995",
    quantity_at_risk: 24295,
    revenue_at_risk: 527444.45,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 23567,
    revenue_mitigated: 511639.57,
    standard_value: 20738.96,
    freight_cost: 45955.65,
    cost_impact: 66694.61,
  },
  {
    running_risk_id: 1149,
    risk_date: 293 + 45246,
    risk_status: "Rejected",
    rejection_code: "Delivery cost budget constraint",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 48332,
    revenue_at_risk: 1042521.24,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 41566,
    revenue_mitigated: 896578.6199999999,
    standard_value: 53204.48,
    freight_cost: 106824.62,
    cost_impact: 160029.1,
  },
  {
    running_risk_id: 1150,
    risk_date: 293 + 45249,
    risk_status: "Rejected",
    rejection_code: "Delivery cost too high",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 48414,
    revenue_at_risk: 979415.22,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 39216,
    revenue_mitigated: 793339.6799999999,
    standard_value: 41176.8,
    freight_cost: 81569.28,
    cost_impact: 122746.08,
  },
  {
    running_risk_id: 1151,
    risk_date: 293 + 45250,
    risk_status: "Rejected",
    rejection_code: "Delivery cost budget constraint",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 48993,
    revenue_at_risk: 904900.71,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 40175,
    revenue_mitigated: 742032.25,
    standard_value: 42987.25,
    freight_cost: 99634,
    cost_impact: 142621.25,
  },
  {
    running_risk_id: 1152,
    risk_date: 293 + 45253,
    risk_status: "Rejected",
    rejection_code: "Parcel carrier calendar",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-995",
    quantity_at_risk: 15649,
    revenue_at_risk: 319865.56,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 14085,
    revenue_mitigated: 287897.4,
    standard_value: 21268.35,
    freight_cost: 36902.7,
    cost_impact: 58171.049999999996,
  },
  {
    running_risk_id: 1153,
    risk_date: 293 + 45257,
    risk_status: "Rejected",
    rejection_code: "Delivery cost budget constraint",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-792",
    quantity_at_risk: 49224,
    revenue_at_risk: 1141996.8,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 49224,
    revenue_mitigated: 1141996.8,
    standard_value: 73836,
    freight_cost: 112722.96,
    cost_impact: 186558.96000000002,
  },
  {
    running_risk_id: 1154,
    risk_date: 293 + 45259,
    risk_status: "Rejected",
    rejection_code: "Parcel carrier calendar",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 15454,
    revenue_at_risk: 343851.5,
    action_type: "Express Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 14373,
    revenue_mitigated: 319799.25,
    standard_value: 17535.06,
    freight_cost: 29320.92,
    cost_impact: 46855.979999999996,
  },
  {
    running_risk_id: 1155,
    risk_date: 293 + 45282,
    risk_status: "Open",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 43363,
    revenue_at_risk: 836038.64,
    action_type: "Partial Delivery",
    quantity_mitigated: 43363,
    revenue_mitigated: 836038.64,
    standard_value: 53336.49,
    freight_cost: 19946.98,
    cost_impact: 73283.47,
    days_of_supply_impact: 2.09,
  },
  {
    running_risk_id: 1156,
    risk_date: 293 + 45281,
    risk_status: "In progress",
    risk_location: 152,
    risk_item: "SKU-792",
    quantity_at_risk: 33970,
    revenue_at_risk: 678380.9,
    action_type: "Partial Delivery",
    execution_stage: "Customer validation",
    quantity_mitigated: 30573,
    revenue_mitigated: 610542.81,
    standard_value: 39133.44,
    freight_cost: 17426.61,
    cost_impact: 56560.05,
    days_of_supply_impact: 3.33,
  },
  {
    running_risk_id: 1157,
    risk_date: 293 + 45279,
    risk_status: "In progress",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 30205,
    revenue_at_risk: 662093.6,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 25977,
    revenue_mitigated: 569415.84,
    standard_value: 36108.03,
    freight_cost: 21820.68,
    cost_impact: 57928.71,
    days_of_supply_impact: 2.23,
  },
  {
    running_risk_id: 1158,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 38844,
    revenue_at_risk: 827377.2,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 34960,
    revenue_mitigated: 744648,
    standard_value: 43350.4,
    freight_cost: 19927.2,
    cost_impact: 63277.600000000006,
    days_of_supply_impact: 2.18,
  },
  {
    running_risk_id: 1159,
    risk_date: 293 + 45236,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 32041,
    revenue_at_risk: 606215.72,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 31401,
    revenue_mitigated: 594106.9199999999,
    standard_value: 44903.43,
    freight_cost: 18840.6,
    cost_impact: 63744.03,
    days_of_supply_impact: 3.49,
  },
  {
    running_risk_id: 1160,
    risk_date: 293 + 45243,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 39021,
    revenue_at_risk: 843243.81,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 32388,
    revenue_mitigated: 699904.68,
    standard_value: 40808.88,
    freight_cost: 26234.28,
    cost_impact: 67043.16,
    days_of_supply_impact: 2.66,
  },
  {
    running_risk_id: 1161,
    risk_date: 293 + 45247,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 30867,
    revenue_at_risk: 610549.26,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 25620,
    revenue_mitigated: 506763.60000000003,
    standard_value: 38942.4,
    freight_cost: 24339,
    cost_impact: 63281.4,
    days_of_supply_impact: 2.23,
  },
  {
    running_risk_id: 1162,
    risk_date: 293 + 45250,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-346",
    quantity_at_risk: 48806,
    revenue_at_risk: 1010284.2,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 43926,
    revenue_mitigated: 909268.2,
    standard_value: 36458.58,
    freight_cost: 36458.58,
    cost_impact: 72917.16,
    days_of_supply_impact: 2.36,
  },
  {
    running_risk_id: 1163,
    risk_date: 293 + 45255,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 25454,
    revenue_at_risk: 512389.02,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 20618,
    revenue_mitigated: 415040.34,
    standard_value: 30514.64,
    freight_cost: 11752.26,
    cost_impact: 42266.9,
    days_of_supply_impact: 3.43,
  },
  {
    running_risk_id: 1164,
    risk_date: 293 + 45257,
    risk_status: "Rejected",
    rejection_code: "Sales order cancellation",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 45553,
    revenue_at_risk: 856396.4,
    action_type: "Partial Delivery",
    quantity_mitigated: 37809,
    revenue_mitigated: 710809.2000000001,
    standard_value: 31381.47,
    freight_cost: 32893.83,
    cost_impact: 64275.3,
    days_of_supply_impact: 3.16,
  },
  {
    running_risk_id: 1165,
    risk_date: 293 + 45236,
    risk_status: "Rejected",
    rejection_code: "Customer constraint for partial delivery",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 23774,
    revenue_at_risk: 452894.7,
    action_type: "Partial Delivery",
    quantity_mitigated: 20446,
    revenue_mitigated: 389496.30000000005,
    standard_value: 25148.58,
    freight_cost: 17788.02,
    cost_impact: 42936.600000000006,
    days_of_supply_impact: 3.06,
  },
  {
    running_risk_id: 1166,
    risk_date: 293 + 45243,
    risk_status: "Rejected",
    rejection_code: "Customer constraint for partial delivery",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-346",
    quantity_at_risk: 34485,
    revenue_at_risk: 803845.35,
    action_type: "Partial Delivery",
    quantity_mitigated: 33796,
    revenue_mitigated: 787784.76,
    standard_value: 39541.32,
    freight_cost: 25347,
    cost_impact: 64888.32,
    days_of_supply_impact: 2.11,
  },
  {
    running_risk_id: 1167,
    risk_date: 293 + 45245,
    risk_status: "Rejected",
    rejection_code: "Customer constraint for partial delivery",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-792",
    quantity_at_risk: 15465,
    revenue_at_risk: 294298.95,
    action_type: "Partial Delivery",
    quantity_mitigated: 13300,
    revenue_mitigated: 253099,
    standard_value: 20216,
    freight_cost: 12901,
    cost_impact: 33117,
    days_of_supply_impact: 3.2,
  },
  {
    running_risk_id: 1168,
    risk_date: 293 + 45249,
    risk_status: "Rejected",
    rejection_code: "Sales order cancellation",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-792",
    quantity_at_risk: 41189,
    revenue_at_risk: 954349.13,
    action_type: "Partial Delivery",
    quantity_mitigated: 38718,
    revenue_mitigated: 897096.06,
    standard_value: 33297.48,
    freight_cost: 28264.14,
    cost_impact: 61561.62,
    days_of_supply_impact: 2.01,
  },
  {
    running_risk_id: 1169,
    risk_date: 293 + 45255,
    risk_status: "Rejected",
    rejection_code: "Sales order cancellation",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 34994,
    revenue_at_risk: 734174.12,
    action_type: "Partial Delivery",
    quantity_mitigated: 28696,
    revenue_mitigated: 602042.08,
    standard_value: 28122.08,
    freight_cost: 27835.12,
    cost_impact: 55957.2,
    days_of_supply_impact: 3.41,
  },
  {
    running_risk_id: 1170,
    risk_date: 293 + 45235,
    risk_status: "Rejected",
    rejection_code: "Sales order cancellation",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 28840,
    revenue_at_risk: 595546,
    action_type: "Partial Delivery",
    execution_stage: "Customer validation",
    quantity_mitigated: 27975,
    revenue_mitigated: 577683.75,
    standard_value: 37486.5,
    freight_cost: 14826.75,
    cost_impact: 52313.25,
    days_of_supply_impact: 3.18,
  },
  {
    running_risk_id: 1171,
    risk_date: 293 + 45240,
    risk_status: "Rejected",
    rejection_code: "Delivery in full",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 37509,
    revenue_at_risk: 856330.47,
    action_type: "Partial Delivery",
    execution_stage: "Customer validation",
    quantity_mitigated: 33759,
    revenue_mitigated: 770717.97,
    standard_value: 32071.05,
    freight_cost: 26669.61,
    cost_impact: 58740.66,
    days_of_supply_impact: 3.21,
  },
  {
    running_risk_id: 1172,
    risk_date: 293 + 45246,
    risk_status: "Rejected",
    rejection_code: "Delivery in full",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 28275,
    revenue_at_risk: 592078.5,
    action_type: "Partial Delivery",
    execution_stage: "Customer validation",
    quantity_mitigated: 27144,
    revenue_mitigated: 568395.36,
    standard_value: 32844.24,
    freight_cost: 17100.72,
    cost_impact: 49944.96,
    days_of_supply_impact: 2.12,
  },
  {
    running_risk_id: 1173,
    risk_date: 293 + 45247,
    risk_status: "Rejected",
    rejection_code: "Sales order cancellation",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 38763,
    revenue_at_risk: 815961.15,
    action_type: "Partial Delivery",
    execution_stage: "Customer validation",
    quantity_mitigated: 34500,
    revenue_mitigated: 726225,
    standard_value: 31395,
    freight_cost: 23805,
    cost_impact: 55200,
    days_of_supply_impact: 3.51,
  },
  {
    running_risk_id: 1174,
    risk_date: 293 + 45250,
    risk_status: "Rejected",
    rejection_code: "Parcel carrier calendar",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-213",
    quantity_at_risk: 47425,
    revenue_at_risk: 1088878,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 40786,
    revenue_mitigated: 936446.56,
    standard_value: 55468.96,
    freight_cost: 30181.64,
    cost_impact: 85650.6,
    days_of_supply_impact: 2.25,
  },
  {
    running_risk_id: 1175,
    risk_date: 293 + 45253,
    risk_status: "Rejected",
    rejection_code: "No parcel carrier available",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 23779,
    revenue_at_risk: 453941.11,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 21639,
    revenue_mitigated: 413088.50999999995,
    standard_value: 22071.78,
    freight_cost: 18176.76,
    cost_impact: 40248.53999999999,
    days_of_supply_impact: 2.72,
  },
  {
    running_risk_id: 1176,
    risk_date: 293 + 45259,
    risk_status: "Rejected",
    rejection_code: "No parcel carrier available",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 23730,
    revenue_at_risk: 523246.5,
    action_type: "Partial Delivery",
    execution_stage: "Freight",
    quantity_mitigated: 21832,
    revenue_mitigated: 481395.6,
    standard_value: 20085.44,
    freight_cost: 15937.36,
    cost_impact: 36022.8,
    days_of_supply_impact: 2.23,
  },
  {
    running_risk_id: 1177,
    risk_date: 293 + 45276,
    risk_status: "Open",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 35012,
    revenue_at_risk: 741904.28,
    action_type: "No action",
    quantity_mitigated: 34514,
    revenue_mitigated: 391913.53350758535,
  },
  {
    running_risk_id: 1178,
    risk_date: 293 + 45231,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-213",
    quantity_at_risk: 37999,
    revenue_at_risk: 797219.02,
    action_type: "No action",
    quantity_mitigated: 10131,
    revenue_mitigated: 537678.2832248181,
  },
  {
    running_risk_id: 1179,
    risk_date: 293 + 45235,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 31760,
    revenue_at_risk: 695226.4,
    action_type: "No action",
    quantity_mitigated: 2821,
    revenue_mitigated: 550142.5282555283,
  },
  {
    running_risk_id: 1180,
    risk_date: 293 + 45240,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 38310,
    revenue_at_risk: 885727.2,
    action_type: "No action",
    quantity_mitigated: 32250,
    revenue_mitigated: 283888.81096383993,
  },
  {
    running_risk_id: 1181,
    risk_date: 293 + 45241,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 13679,
    revenue_at_risk: 308324.66,
    action_type: "No action",
    quantity_mitigated: 11371,
    revenue_mitigated: 397540.3843596644,
  },
  {
    running_risk_id: 1182,
    risk_date: 293 + 45245,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 33416,
    revenue_at_risk: 685362.16,
    action_type: "No action",
    quantity_mitigated: 42726,
    revenue_mitigated: 428273.88569518714,
  },
  {
    running_risk_id: 1183,
    risk_date: 293 + 45246,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-995",
    quantity_at_risk: 40720,
    revenue_at_risk: 754134.4,
    action_type: "No action",
    quantity_mitigated: 11959,
    revenue_mitigated: 307299.474319413,
  },
  {
    running_risk_id: 1184,
    risk_date: 293 + 45249,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-792",
    quantity_at_risk: 30313,
    revenue_at_risk: 684164.41,
    action_type: "No action",
    quantity_mitigated: 3260,
    revenue_mitigated: 76575.69452796152,
  },
  {
    running_risk_id: 1185,
    risk_date: 293 + 45253,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 134,
    risk_item: "SKU-792",
    quantity_at_risk: 41182,
    revenue_at_risk: 765985.2,
    action_type: "No action",
    quantity_mitigated: 8746,
    revenue_mitigated: 497955.5807799443,
  },
  {
    running_risk_id: 1186,
    risk_date: 293 + 45256,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 100,
    risk_item: "SKU-995",
    quantity_at_risk: 41983,
    revenue_at_risk: 795577.85,
    action_type: "No action",
    quantity_mitigated: 33507,
    revenue_mitigated: 453047.0428219894,
  },
  {
    running_risk_id: 1187,
    risk_date: 293 + 45257,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-213",
    quantity_at_risk: 13353,
    revenue_at_risk: 248632.86,
    action_type: "No action",
    quantity_mitigated: 19876,
    revenue_mitigated: 44134.69922593337,
  },
  {
    running_risk_id: 1188,
    risk_date: 293 + 45236,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 28685,
    revenue_at_risk: 539564.85,
    action_type: "No action",
    quantity_mitigated: 41206,
    revenue_mitigated: 28866.64,
  },
  {
    running_risk_id: 1189,
    risk_date: 293 + 45240,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 101,
    risk_item: "SKU-213",
    quantity_at_risk: 32092,
    revenue_at_risk: 617450.08,
    action_type: "No action",
    quantity_mitigated: 7702,
    revenue_mitigated: 333718.1315051418,
  },
  {
    running_risk_id: 1190,
    risk_date: 293 + 45248,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 152,
    risk_item: "SKU-346",
    quantity_at_risk: 37526,
    revenue_at_risk: 696107.3,
    action_type: "No action",
    quantity_mitigated: 3850,
    revenue_mitigated: 126405.79960513327,
  },
];
