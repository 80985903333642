import { Waterfall, WaterfallConfig } from "@ant-design/plots";
import { actionTypesColors } from "../../../common/chartColors";

const RevenueAtRiskByAction = (props: any) => {
  let { data } = props;

  const config: WaterfallConfig = {
    appendPadding: [0, 0, 20, 0],
    data,
    xField: "action_type",
    yField: "value",
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
        formatter: (action_type) => {
          const words = action_type.split(" ");
          words.splice(1, 0, "\n");
          words.splice(3, 0, "\n");
          return words.join(" ");
        },
      },
    },
    tooltip: {
      shared: true,
      showMarkers: false,
    },
    total: {
      style: {
        fill: "#96a6a6",
      },
    },
    color: ({ action_type }) => actionTypesColors(action_type),
    label: false,
    meta: {
      value: {
        formatter: (v) =>
          `${(v / (v > 99999 ? 1000000 : 1000)).toFixed(2)}${
            v > 99999 ? " m" : " K"
          }€`,
      },
    },
    waterfallStyle: ({ action_type }) => {
      return {
        radius: 5,
        //stroke: actionTypesColors(action_type),
        lineWidth: 3,
        //fillOpacity: 0.5,
      };
    },
    columnWidthRatio: 0.8,
    legend: false,
  };

  return <Waterfall {...config} height={300} />;
};

export default RevenueAtRiskByAction;
