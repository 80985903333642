import { PaginationParams } from "../interfaces/PaginateParams";
import { getFullsQuery } from "./getFullsQuery";
import { getParamsQuery } from "./getParamsQuery";

export const getManyUrlQuery = (
  ressource: string,
  params: PaginationParams,
  fulls?: string[]
): string => {
  const paramsSet = getParamsQuery(params);
  const fullsSet = getFullsQuery(fulls);
  return `${ressource}?${paramsSet}&${fullsSet}`;
};

export const getOneUrlQuery = (ressource: string, fulls?: string[]): string => {
  const fullsSet = getFullsQuery(fulls);
  return `${ressource}?${fullsSet}`;
};
