import React from "react";
import { Card, Col, Dropdown, Row, Space } from "antd";
import {
  ChartLine,
  ChartPieSlice,
  CheckSquareOffset,
  CurrencyCircleDollar,
} from "../../../assets/images";
import "./chat.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/store";
import { getTranslation } from "../../../utils/transaltion";
import { Link } from "react-router-dom";
import CustomIcon from "../codification/components/CustomIcon";
import { t } from "i18next";

const StandardChatComponent: React.FC = () => {
  const copilotList = useSelector(
    (state: RootState) => state.userCopilotReducer
  );

  return (
    <div>
      <Row gutter={[16, 16]} style={{ padding: "20px" }}>
        <Col key={1} xs={24} sm={12} md={6} className="card-container">
          <Card
            className="card"
            hoverable
            onClick={() => {
              // handleSendMessage(card?.title);
            }}
          >
            <div className="card-title">{t("LANDING_PAGE_TAKE_ACTIONS")}</div>
            <Dropdown
              className="card-icon"
              menu={{
                items: [
                  ...copilotList.map((copilot: any) => {
                    return {
                      icon: (
                        <CustomIcon
                          name={copilot?.uiConfiguration?.iconName}
                          size={"30px"}
                        />
                      ),
                      label: (
                        <Link
                          to={`/menu/${copilot?.name
                            ?.toString()
                            ?.toLowerCase()}`}
                        >
                          {getTranslation(copilot?.name, "data")}
                        </Link>
                      ),
                    };
                  }),
                ],
                onClick: () => {},
              }}
            >
              <Space>
                <CheckSquareOffset />
              </Space>
            </Dropdown>
          </Card>
        </Col>
        <Col key={2} xs={24} sm={12} md={6} className="card-container">
          <Card
            className="card"
            hoverable
            onClick={() => {
              // handleSendMessage(card?.title);
            }}
          >
            <div className="card-title">
              {t("LANDING_PAGE_MONITOR_ACTIONS")}
            </div>
            <Dropdown
              className="card-icon"
              menu={{
                items: [
                  ...copilotList.map((copilot: any) => {
                    return {
                      icon: (
                        <CustomIcon
                          name={copilot?.uiConfiguration?.iconName}
                          size={"30px"}
                        />
                      ),
                      label: (
                        <Link
                          to={`/menu/${copilot?.name
                            ?.toString()
                            ?.toLowerCase()}/action-monitoring`}
                        >
                          {getTranslation(copilot?.name, "data")}
                        </Link>
                      ),
                    };
                  }),
                ],
                onClick: () => {},
              }}
            >
              <Space>
                <ChartPieSlice />
              </Space>
            </Dropdown>
          </Card>
        </Col>
        <Col key={3} xs={24} sm={12} md={6} className="card-container">
          <Card
            className="card"
            hoverable
            onClick={() => {
              // handleSendMessage(card?.title);
            }}
          >
            <div className="card-title">
              {t("LANDING_PAGE_AUTOMATE_ACTIONS")}
            </div>
            <Dropdown
              className="card-icon"
              menu={{
                items: [
                  ...copilotList.map((copilot: any) => {
                    return {
                      icon: (
                        <CustomIcon
                          name={copilot?.uiConfiguration?.iconName}
                          size={"30px"}
                        />
                      ),
                      label: (
                        <Link
                          to={`/menu/${copilot?.name
                            ?.toString()
                            ?.toLowerCase()}/action-analysis`}
                        >
                          {getTranslation(copilot?.name, "data")}
                        </Link>
                      ),
                    };
                  }),
                ],
                onClick: () => {},
              }}
            >
              <Space>
                <ChartLine />
              </Space>
            </Dropdown>
          </Card>
        </Col>
        <Col key={4} xs={24} sm={12} md={6} className="card-container">
          <Card
            className="card"
            hoverable
            onClick={() => {
              // handleSendMessage(card?.title);
            }}
          >
            <div className="card-title">
              {t("LANDING_PAGE_UNDERSTAND_BENEFITS")}
            </div>
            <Dropdown
              className="card-icon"
              menu={{
                items: [
                  ...copilotList.map((copilot: any) => {
                    return {
                      icon: (
                        <CustomIcon
                          name={copilot?.uiConfiguration?.iconName}
                          size={"30px"}
                        />
                      ),
                      label: (
                        <Link
                          to={`/menu/${copilot?.name
                            ?.toString()
                            ?.toLowerCase()}/action-roi`}
                        >
                          {getTranslation(copilot?.name, "data")}
                        </Link>
                      ),
                    };
                  }),
                ],
                onClick: () => {},
              }}
            >
              <Space>
                <CurrencyCircleDollar />
              </Space>
            </Dropdown>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default StandardChatComponent;
