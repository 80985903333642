import React from "react";
import { DualAxes, DualAxesConfig } from "@ant-design/plots";
import { getTranslation } from "../../../../utils/transaltion";
import { getFormatedValue } from "../../../../utils/datatypesFunctions";

interface DualAxesGroupedProps {
  dataSetY1: any[];
  dataSetY2: any[];
  params: any;
  config: any;
  yTitles: any;
}

export const DualAxesGrouped: React.FC<DualAxesGroupedProps> = ({
  dataSetY1,
  dataSetY2,
  params,
  config,
  yTitles,
}) => {
  const calculateTickInterval = (maxValue: number): number => {
    if (maxValue === 0) return 1; // Avoid zero interval
    // Determine the magnitude of the maximum value
    const magnitude = Math.floor(Math.log10(maxValue));
    // Calculate an interval based on the magnitude
    let interval: number;

    if (maxValue < 20) {
      interval = 1;
    } else if (maxValue < 100) {
      interval = 10;
    } else if (maxValue < 5000) {
      interval = 100;
    } else {
      if (maxValue > 5000 && maxValue < 10000) {
        interval = 1000;
      } else {
        interval = Math.pow(10, magnitude - 1);
      }
    }

    return interval;
  };

  const maxY1 = Math.ceil(
    Math.max(...dataSetY1?.map((item) => item?.yField1 || 0))
  );
  const maxY2 = Math.ceil(
    Math.max(...dataSetY2?.map((item) => item?.yField2 || 0))
  );

  // Calculate tick intervals
  const tickIntervalY1 = calculateTickInterval(maxY1);
  const tickIntervalY2 = calculateTickInterval(maxY2);

  const configDualAxes: DualAxesConfig = {
    data: [dataSetY1, dataSetY2],
    xField: "categoryField",
    yField: ["yField1", "yField2"],
    geometryOptions: [
      {
        geometry: "column",
        seriesField: "seriesFieldY1",
        isGroup: true,
        isStack: true, // Stack the bars by groupeField
        //   groupField: "groupeField",
        columnWidthRatio: 0.3,
        marginRatio: 0,
      },
      {
        geometry: "line",
        seriesField: "seriesFieldY2",
        isGroup: true,
        point: {
          shape: "circle",
          size: 5,
          style: {
            fillOpacity: 1,
            stroke: "#fff",
            lineWidth: 2,
          },
        },

        lineStyle: {
          opacity: 0,
        },
      },
    ],
    xAxis: {
      label: {
        formatter: (text: string) => {
          return getTranslation(text, "data");
        },
      },
    },

    yAxis: [
      {
        title: { text: yTitles?.yTitle1 },
        tickInterval: tickIntervalY1,
        max: maxY1 + maxY1 * 0.1, // 10% more than the max value
        min: 1,
        // label: {
        //   formatter: (value: any) => {
        //     const options: Intl.NumberFormatOptions = {
        //       style: "currency",
        //       minimumFractionDigits: 2,
        //       maximumFractionDigits: 2,
        //     };
        //     return Intl.NumberFormat(params?.language, options).format(value);
        //   },
        // },
      },
      {
        title: { text: yTitles?.yTitle2 },
        tickInterval: tickIntervalY2,
        position: "right",
        max: maxY2 + maxY2 * 0.1, // 10% more than the max value
        min: 0,
        // label: {
        //   formatter: (value: any) => {
        //     const options: Intl.NumberFormatOptions = {
        //       style: "currency",
        //       minimumFractionDigits: 2,
        //       maximumFractionDigits: 2,
        //     };
        //     return Intl.NumberFormat(params?.language, options).format(value);
        //   },
        // },
      },
    ],
    tooltip: {
      title(title, datum) {
        return getTranslation(title, "data");
      },
      shared: true,
      showMarkers: false,
      formatter: (datum: any) => {
        const seriesField = datum.hasOwnProperty("seriesFieldY1")
          ? datum.seriesFieldY1
          : datum?.seriesFieldY2 || "not Set";
        const valueField = datum.hasOwnProperty("yField1")
          ? datum.yField1
          : datum.yField2;

        return {
          name: getTranslation(seriesField, "data"),
          value: getFormatedValue(
            valueField,
            seriesField,
            {
              formatNumber: params?.orgaFormatNumber,
              currency: params?.orgaCurrency,
              formatDate: params?.orgaDateFormat,
            },
            config?.descriptorsList
          ),
        };
      },
    },

    legend: {
      position: "top",
      itemName: {
        formatter: (datum: any) => {
          return ` ${getTranslation(datum, "data")}`;
        },
      },
      clickable: true,
    },

    interactions: [
      {
        type: "element-active",
      },
      {
        type: "legend-filter",
      },
    ],
  };

  return <DualAxes {...configDualAxes} />;
};
