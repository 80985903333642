import { ProColumns } from "@ant-design/pro-components";
import { User } from "../../../../../interfaces/user";
import MenuOptions from "../../../../common/menu/MenuOptions";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/userLogic";
import { WORK_MODE } from "../../../../../constants/workModes";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../utils/transaltion";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { isPermission } from "../../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../../constants/permissions";
import { getLanguageName } from "../../../../../utils/functions";
import { LanguagesEnum } from "../../../../../constants/languages";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { SharedContext } from "../../context/sharedContext";

export const DefineTableColumns: any = (
  setGetLoading: React.Dispatch<SetStateAction<boolean>>,
  setTotalUser: React.Dispatch<SetStateAction<number>>
) => {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /**translation */
  const { t } = useTranslation();
  /**Enums */
  const [valueEnumRoles, setValueEnumRoles] = useState<{
    [key: string]: { text: string };
  }>({});
  const { setUserScopes } = useContext(SharedContext);
  useEffect(() => {
    getEnums(setValueEnumRoles);
  }, []);
  /** users logic */
  const { goToUserConfiguration, DeleteUser, getEnums } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;

  /** column definition */
  const columns: ProColumns<User>[] = [
    {
      title: t(`NAME`, {
        item: t("USER"),
      }),
      search: false,
      dataIndex: "displayname",
      render: (text, record) => {
        return (
          <span>
            {record?.displayname
              ? record.displayname
              : record?.firstname || record?.lastname
              ? record?.firstname + " " + record?.lastname
              : record?.email}
          </span>
        );
      },
      valueType: "text",
    },
    {
      title: t("USER_FIRST_NAME"),
      dataIndex: "firstname",
      hideInTable: true,
      render: (text, record) => {
        return <span>{record?.firstname}</span>;
      },
      valueType: "text",
    },
    {
      title: t("USER_LAST_NAME"),
      dataIndex: "lastname",
      hideInTable: true,

      valueType: "text",
      render: (text, record) => {
        return <span>{record?.lastname}</span>;
      },
    },

    {
      title: t("ROLES"),
      dataIndex: "role",
      valueType: "select",
      valueEnum: valueEnumRoles,
      hideInTable: false,
      fieldProps: {
        showSearch: true,
        optionFilterProp: "label",
        filterOption: (input: any, option: any) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      },
      render: (text, record) => {
        return record?.roles
          ?.map((role: any) => getTranslation(role?.name, "data"))
          .join(", ");
      },
    },
    {
      title: t("LANGUAGE"),
      dataIndex: "preferredLanguage",
      filters: false,
      valueType: "select",
      valueEnum: LanguagesEnum,
      render: (text, record) => {
        return (
          <span>
            {record?.preferredLanguage
              ? getTranslation(
                  getLanguageName(record.preferredLanguage),
                  "labels"
                )
              : ""}
          </span>
        );
      },
    },
    isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)
      ? {
          title: t("MORE_ACTIONS"),
          valueType: "option",
          key: "option",
          width: "20%",
          align: "center",

          render: (text, record) => (
            <span>
              <MenuOptions
                viewRecord={async () => {
                  setUserScopes([]);
                  goToUserConfiguration(record, WORK_MODE.VIEW);
                }}
                editRecord={async () => {
                  setUserScopes([]);
                  goToUserConfiguration(record, WORK_MODE.UPDATE);
                }}
                deleteRecord={() => {
                  setUserScopes([]);
                  DeleteUser(record.id, setGetLoading, setTotalUser);
                }}
                title={t("errors:CONFIRM_DELETE_MESSAGE")}
              />
            </span>
          ),
        }
      : {},
  ];

  return columns;
};
