import { CSSProperties } from "react";
import BarLoader from "react-spinners/BarLoader";

export const LoadingPage: React.FC = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <BarLoader
      color="#004c97"
      loading={true}
      cssOverride={override}
      height={6}
      width={200}
      speedMultiplier={1}
    />
  </div>
);
const override: CSSProperties = {
  //display: "block",
  margin: "0 auto",
  borderColor: "red",
};
