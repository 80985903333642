import React, { useContext, useEffect } from "react";
import { Collapse, Form, FormInstance, Typography } from "antd";
import { MODES_DYNAMIC_FIELDS } from "../../../../../constants/dynamicFieldsModes";
import SettingsActionDynamicFields from "../../common/actionsettings/SettingDynamicFields";
import { useTranslation } from "react-i18next";

const ActionParametersSettings: React.FC<{
  form: FormInstance<any>;
  workMode: string;
}> = ({ form, workMode }) => {
  const { t } = useTranslation();
  return (
    <Collapse
      className="panel-style"
      accordion
      expandIconPosition="end"
      size="large"
      destroyInactivePanel={true}
      //defaultActiveKey="settings"
      style={{ marginTop: 40, width: "95%", marginBottom: "20px" }}
    >
      <Collapse.Panel
        className="panel-style"
        header={
          <span style={{ fontSize: "1.5rem" }}>
            {t("SETTINGS", { item: t("ACTION") })}
          </span>
        }
        key="settings"
      >
        <SettingsActionDynamicFields
          workMode={workMode}
          modeDynamicFields={MODES_DYNAMIC_FIELDS.ACTION_PARAMETERS}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default ActionParametersSettings;
