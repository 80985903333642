import CrudService from "./main/crud.service";
import { Context } from "../interfaces/context";

const resource = { name: "actionconfs", isPublic: false };

class ActionsService extends CrudService {
  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(resource, context, setContext, path);
  }
  async deleteAction(id: string) {
    const { data } = await this.delete(
      `/organizations/${this.context.orga.id}/actionconfs/${id}?recursive=1`
    );
    return data;
  }

  async saveActions(args: any) {
    console.log(args);
    const { data } = await this.post(
      `organizations/${this.context.orga.id}/actionconfs/save`,
      args
    );
    return data;
  }

  async getActionsById(args: string): Promise<any> {
    const data = await this.get(
      `/organizations/${this.context.orga.id}/actionconfs/` + args
    );
    return data;
  }
}

export default ActionsService;
