import { ProTable } from "@ant-design/pro-components";
import { Button, Tooltip, Popover } from "antd";
import { t } from "i18next";
import { FiSearch } from "react-icons/fi";
import { getTranslation } from "../../../../../utils/transaltion";
import { DefineTableColumns } from "./TableRunning.def";
import { useContext, useEffect, useRef, useState } from "react";
import { SharedContext } from "../../context/SharedContext";
import { CloseCircleOutlined } from "@ant-design/icons";

export const FilterSection = (props: any) => {
  const [loadingAccept, setLoadingAccept] = useState<boolean>(false);
  const [isFirstSearchClick, setIsFirstSearchClick] = useState(true);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const {
    copilotName,
    menu,
    appendData,
    filterParams,
    setFilterparams,
    setSortingOrder,
    sortingOrder,
    setSortingField,
    sortingField,
    formRef,
  } = props;
  const { selectedRisk, setSelectedRisk, riskScope } =
    useContext(SharedContext);
  const columns = DefineTableColumns({
    copilotName,
    menu,
    currentRiskId: selectedRisk?.id,
    setSelectedRisk,
    setLoadingAccept,
  });

  const onReset = () => {
    if (
      Object.keys(filterParams)?.length !== 0 &&
      sortingOrder != "desc" &&
      sortingField != "created_at"
    ) {
      setFilterparams({});
      setSortingOrder("desc");
      setSortingField("created_at");
      appendData([], {});
    }
    setPopoverVisible(false);
  };

  useEffect(() => {
    appendData([], filterParams);
  }, [riskScope]);

  return (
    /* filter button */

    <Tooltip title={t("SEARCH")}>
      <Popover
        placement="bottom"
        content={() => (
          <div>
            <div
              style={{
                textAlign: "end",
                marginInlineEnd: "24px",
                cursor: "pointer",
              }}
            >
              <CloseCircleOutlined
                style={{ fontSize: "20px" }}
                onClick={() => {
                  setPopoverVisible(false);
                }}
              />
            </div>
            <ProTable
              style={{ padding: "-30px" }}
              columns={columns}
              request={(params): any => {
                const filterArgs: {
                  [key: string]: string;
                } = {};
                const { current, pageSize, ...rest } = params;

                Object.entries(rest).forEach(([key, value]) => {
                  try {
                    filterArgs[key] = JSON.parse(value);
                  } catch (e) {
                    filterArgs[key] = value;
                  }
                });
                if (!isFirstSearchClick) {
                  setFilterparams(filterArgs);
                  //  console.log("****filterArgs", filterArgs);
                  appendData([], filterArgs);
                  setPopoverVisible(false);
                } else {
                  setIsFirstSearchClick(false);
                }

                return {};
              }}
              onReset={onReset}
              search={{
                span: {
                  xs: 24,
                  sm: 12,
                  md: 12,
                  lg: 12,
                  xl: 12,
                  xxl: 12,
                },
                filterType: "query",
                searchText: getTranslation("APPLY", "labels"),
                resetText: t("RESET_TEXT_BUTTON"),
                className: "custom-search-form",
                labelWidth: "auto",
              }}
              /* form={{
                      formRef,
                      onFinish: async (values) => {
                        handleSearchClick();
                        return true;
                      },
                    }} */
              form={{
                formRef,
                syncToUrl: (values: any) => {
                  // handleSearchClick();
                  return values;
                },
              }}
              tableRender={(_, dom) => <></>}
            />
          </div>
        )}
        trigger={["click"]}
        open={popoverVisible}
        onOpenChange={(visible) => setPopoverVisible(!popoverVisible)}
        overlayStyle={{
          background: "#fff",
          borderRadius: 4,
          maxWidth: "800px",
        }}
      >
        <Button icon={<FiSearch />} shape="default" />
      </Popover>
    </Tooltip>
  );
};
