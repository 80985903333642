// SortingMenu.tsx
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Menu, Dropdown, Tooltip, Divider } from "antd";
import { TbArrowsSort } from "react-icons/tb";
import CustomSelectableSortingIcon from "./CustomSelectableSortingIcon";
import { getTranslation } from "../../../../utils/transaltion";
import { Sortingtypes } from "../../../../constants/sortingTypes";
import { useTranslation } from "react-i18next";
import { blue } from "@ant-design/colors";

interface SortingMenuProps {
  fields: any[];
  onSort: (fieldKey: string, sortOrder: Sortingtypes) => void;
  sortingMenuActive: boolean;
  setSortingMenuActive: Dispatch<SetStateAction<boolean>>;
}

const SortingMenu: React.FC<SortingMenuProps> = ({
  fields,
  onSort,
  sortingMenuActive,
  setSortingMenuActive,
}) => {
  const { t } = useTranslation();
  const [selectedIcon, setSelectedIcon] = useState<string | null>(null);
  const [selectedFieldKey, setSelectedFieldKey] = useState<string | null>(null);
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleSort = (fieldKey: string, sortOrder: Sortingtypes) => {
    setSelectedIcon(sortOrder);
    onSort(fieldKey, sortOrder);
    setSelectedFieldKey(fieldKey);
    setMenuVisible(true);
    setSortingMenuActive(true);
  };
  useEffect(() => {
    if (!sortingMenuActive) {
      setSelectedFieldKey(null);
    }
  }, [sortingMenuActive]);
  return (
    <Tooltip placement="top" title={t("SORTING")}>
      <Dropdown
        placement="bottomRight"
        trigger={["click"]}
        overlayStyle={{ overflow: "auto" }}
        open={menuVisible}
        onOpenChange={setMenuVisible}
        dropdownRender={() => (
          <Menu style={{ width: 200, overflow: "auto", border: "1px" }}>
            <Menu.Item
              key="title"
              style={{
                fontSize: 15,
                fontWeight: "500",
                color: "#5c5656",
                marginInlineStart: -2,
              }}
            >
              {t("SORTABLE_FIELDS")}
            </Menu.Item>
            <Divider style={{ marginTop: -2, marginBottom: -2 }} />
            {fields.map((field) => (
              <Menu.Item key={field.key}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginInlineStart: 10,
                  }}
                >
                  <span style={{ marginInlineEnd: 6 }}>
                    {getTranslation(field.name, "data")}
                  </span>
                  <CustomSelectableSortingIcon
                    fieldKey={field.name}
                    selectedIcon={
                      selectedFieldKey === field.name ? selectedIcon : null
                    }
                    onClick={handleSort}
                  />
                </div>
              </Menu.Item>
            ))}
          </Menu>
        )}
      >
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <TbArrowsSort
            size={18}
            style={{
              color: isHovered || sortingMenuActive ? blue.primary : "#1c1c1c",
            }}
          />
        </div>
      </Dropdown>
    </Tooltip>
  );
};

export default SortingMenu;
