import { PageHeader } from "@ant-design/pro-components";
import { Button, Card, Form, Input, message, Row, Space } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { MainContext } from "../../../utils/context";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/store";
import { WORK_MODE } from "../../../constants/workModes";
import { isPermission } from "../../../utils/permissionsFunctions";
import { getTranslation } from "../../../utils/transaltion";
import { PERMISSIONS_LIST } from "../../../constants/permissions";
import ConnectionsService from "../../../services/connections.service";
import handleApiError from "../../../services/functions/handle-api-errors/handleApiError";
import { REQUEST } from "../../../services/functions/handle-api-errors/const";
import BackLink from "../../common/BackLink";

export default function AddUpdateConnection() {
  const globalContext = useContext(MainContext);
  const { t } = useTranslation();
  const [formConnection] = Form.useForm();
  const navigate = useNavigate();

  const { state } = useLocation();
  let { record, workModeConnection } = state ?? {
    record: null,
    workModeConnection: WORK_MODE.CREATE,
  };

  const [connectionRecord, setConnectionRecord] = useState<any>(record);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [connectLoading, setConnectLoading] = useState<boolean>(false);

  /** current user permissions from redux */
  const permissionsRedux = useSelector(
    (state: RootState) => state.permissionReducer
  );

  const hasOrgAdminPermission = () => {
    return isPermission(permissionsRedux, PERMISSIONS_LIST.ORG_ADMIN);
  };

  /* Services  */
  if (!globalContext) {
    return <></>;
  }
  const connectionsService = new ConnectionsService(
    globalContext.context,
    globalContext.setContext
  );

  const onFinishCreateUpdate = () => {
    formConnection
      .validateFields()
      .then((data) => {
        if (workModeConnection === WORK_MODE.UPDATE || connectionRecord?.id) {
          updateConnection(connectionRecord?.id, data);
        } else if (workModeConnection === WORK_MODE.CREATE) {
          createConnection(data);
        }
      })
      .catch((err: any) => {
        message.warning(t("errors:REQUIRED_FIELDS"));
      });
  };

  const createConnection = async (connection: any) => {
    setSaveLoading(true);
    const newConnection = {
      name: connection?.name,
      description: connection?.description,
      type: "ONEDRIVE_FILES",
      settings: { file: connection?.file },
    };
    await connectionsService
      .createResource(newConnection)
      .then(async (res) => {
        setSaveLoading(false);
        message.success(
          getTranslation("CONNECTION_CREATED_SUCCESSFULLY", "notifications")
        );
        setConnectionRecord(res?.data);
      })
      .catch((err) => {
        handleApiError(err, REQUEST.CREATE);
        setSaveLoading(false);
      });
  };

  const updateConnection = async (id: string, connection: any) => {
    setSaveLoading(true);
    const newConnection = {
      name: connection?.name,
      description: connection?.description,
      settings: { file: connection?.file },
    };
    await connectionsService
      .updateResource(id, newConnection)
      .then(async () => {
        message.success(
          getTranslation("CONNECTION_UPDATED_SUCCESSFULLY", "notifications")
        );
        setSaveLoading(false);
      })
      .catch((err) => {
        handleApiError(err, REQUEST.UPDATE);
        setSaveLoading(false);
      });
  };

  const connectConnection = async (id: string) => {
    setConnectLoading(true);
    await connectionsService
      .connect(id)
      .then(async (res) => {
        setConnectLoading(false);
        res?.redirect &&
          window.open(res?.redirect, "_blank", "rel=noopener noreferrer");
      })
      .catch((err) => {
        handleApiError(err, REQUEST.UPDATE);
        setConnectLoading(false);
      });
  };

  return (
    <div>
      <PageHeader
        title={
          <BackLink
            link={"/menu/configurations/connections"}
            title={t("BACK_TO_CONNECTIONS")}
          />
        }
        style={{ marginInlineStart: -20 }}
      />
      <Card style={{ marginBlock: "10px" }}>
        {getTranslation("CONNECTION_CONFIGURATION", "labels")}
      </Card>
      <Card>
        <Form
          form={formConnection}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          name="wrap"
          labelAlign="left"
          labelWrap
          initialValues={
            workModeConnection === WORK_MODE.CREATE
              ? {
                  name: "",
                  description: "",
                  file: "",
                }
              : {
                  name: connectionRecord?.name,
                  description: connectionRecord?.description,
                  file: connectionRecord?.settings?.file,
                }
          }
        >
          <Form.Item
            label={t("DATA_SOURCE_NAME") + " :"}
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input readOnly={workModeConnection === WORK_MODE.VIEW} />
          </Form.Item>
          <Form.Item
            label={t("DATA_SOURCE_DESCRIPTION") + ":"}
            name="description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input readOnly={workModeConnection === WORK_MODE.VIEW} />
          </Form.Item>
          <Form.Item
            label={t("FILE_NAME") + ":"}
            name="file"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input readOnly={workModeConnection === WORK_MODE.VIEW} />
          </Form.Item>
          <Button
            type="primary"
            className="button-add-risk"
            disabled={
              !connectionRecord?.id || workModeConnection === WORK_MODE.VIEW
            }
            loading={connectLoading}
            onClick={() => {
              connectConnection(connectionRecord?.id);
            }}
          >
            {getTranslation("CONNECT_WITH_MICROSOFT", "labels")}
          </Button>
        </Form>
      </Card>
      <div
        style={{
          backgroundColor: "white",
          zIndex: 999,
          position: "fixed",
          width: "100%",
          bottom: 0,
        }}
      >
        {hasOrgAdminPermission() && workModeConnection !== WORK_MODE.VIEW && (
          <Row justify="start" style={{ marginBlock: "20px" }}>
            <Space>
              <Button
                className="cancel-button"
                onClick={() => {
                  navigate("/menu/configurations/connections");
                }}
              >
                {getTranslation("CANCEL", "labels")}
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                className="save-button"
                onClick={() => {
                  onFinishCreateUpdate();
                }}
                loading={saveLoading}
              >
                {getTranslation("SAVE", "labels")}
              </Button>
            </Space>
          </Row>
        )}
      </div>
    </div>
  );
}
