import React from "react";
import {
  BoxIcon,
  DecisionIcon,
  FulfillmentIcon,
  InboundFreight,
  Inventory2Icon,
  PurchaseIcon,
} from "../../../../assets/images";
import { Button, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import {
  SButton,
  SContainer,
  SDesc,
  SImg,
  SLeft,
  SLine,
  SRight,
  SSection,
  STitle,
  SWhatWeDo,
} from "./section-style";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Automate = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const getMenuIcon = (src: string | undefined): React.ReactNode => {
    return (
      <span
        style={{
          float: "inline-start",
          paddingInline: "5px",
        }}
      >
        <img
          alt=""
          src={src}
          height={30}
          style={{
            float: "inline-start",
          }}
        />
      </span>
    );
  };
  return (
    <SSection>
      <SContainer>
        <SRight>
          <SImg src={DecisionIcon} color="red" />
        </SRight>
        <SLeft>
          <Button
            size="large"
            shape="circle"
            icon={<ArrowLeftOutlined />}
            onClick={() => {
              window.history.back();
            }}
            style={{
              backgroundColor: "#004c97",
            }}
          ></Button>
          <STitle>{t("LANDING_PAGE_CHOOSE_YOUR_COPILOT")}</STitle>
          <br />
          <SWhatWeDo>
            <SLine src="../img/line.png" />
          </SWhatWeDo>
          <SDesc>{t("LANDING_PAGE_DO_YOU_WANT_TO_WORK_ON")}</SDesc>
          <Row>
            <Col
              xs={24}
              md={12}
              xl={6}
              style={{ marginBlockEnd: "10px", paddingInlineEnd: "10px" }}
            >
              <SButton
                onClick={() => {
                  navigate("/menu/logistic_events/action-analysis");
                }}
              >
                {getMenuIcon(InboundFreight)}{" "}
                {t("data:COPILOT_INBOUND_FREIGHT_MANAGEMENT")}
              </SButton>
            </Col>
            <Col
              xs={24}
              md={12}
              xl={6}
              style={{ marginBlockEnd: "10px", paddingInlineEnd: "10px" }}
            >
              <SButton
                onClick={() => {
                  navigate("/menu/procurement/action-analysis");
                }}
              >
                {getMenuIcon(PurchaseIcon)}
                {t("data:COPILOT_PROCUREMENT")}
              </SButton>
            </Col>
            <Col
              xs={24}
              md={12}
              xl={6}
              style={{ marginBlockEnd: "10px", paddingInlineEnd: "10px" }}
            >
              <SButton
                onClick={() => {
                  navigate("/menu/fulfillment/action-analysis");
                }}
              >
                {getMenuIcon(FulfillmentIcon)}
                {t("data:COPILOT_FULFILLMENT")}
              </SButton>
            </Col>
            <Col
              xs={24}
              md={12}
              xl={6}
              style={{ marginBlockEnd: "10px", paddingInlineEnd: "10px" }}
            >
              <SButton
                onClick={() => {
                  navigate("/menu/demand_matching/action-analysis");
                }}
              >
                {getMenuIcon(Inventory2Icon)}{" "}
                {t("data:COPILOT_DEMAND_MATCHING")}
              </SButton>
            </Col>
            <Col
              xs={24}
              md={12}
              xl={6}
              style={{ marginBlockEnd: "10px", paddingInlineEnd: "10px" }}
            >
              <SButton
                onClick={() => {
                  navigate("/menu/supply_matching/action-analysis");
                }}
              >
                {getMenuIcon(BoxIcon)} {t("data:COPILOT_SUPPLY_MATCHING")}
              </SButton>
            </Col>
          </Row>
        </SLeft>
      </SContainer>
    </SSection>
  );
};

export default Automate;
