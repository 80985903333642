import { useState } from "react";
import enUS from "antd/lib/locale/en_US";
import { Card, Col, ConfigProvider, Row, Space, Switch } from "antd";

const ChartCard = (props: any) => {
  const { title, chart } = props;

  return <Card title={title}>{chart} </Card>;
};

export default ChartCard;
