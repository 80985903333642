import { MyBar, MyColumn, MyLine, MyPie } from "../../charts";
import { CHART_DATA } from "../../../constants/configs";
import EmptyChartCard from "../../../../../../common/charts/EmptyChartCard";
import MyWaterfFallChart from "../../charts/MyWaterfFallChart";
import { groupData } from "./groupData";
import MyGauge from "../../charts/MyGauge";
import { ChartParams } from "../../charts/functions/ChartParams.interface";

export default function GetChartByConfig(
  config: any,
  fields: any,
  params: ChartParams,
  data: any[],
  showDrawer: (value: any) => void
) {
  // console.log(config);
  // config["data"] = CHART_DATA.map((item: any) => {
  //   return {
  //     valueField:
  //       config?.config?.value_field?.operator === "COUNT"
  //         ? item["SUM"]
  //         : item[config?.config?.value_field?.field],
  //     categoryField: item[config?.config?.category_field?.at(0)?.field],
  //     seriesField: item[config?.config?.group_field?.field],
  //   };
  // });
  // if (config?.type === "PIE_CHART") {
  //   return (
  //     <MyPie
  //       config={config}
  //       fields={fields}
  //       params={params}
  //       data={[]}
  //       showDrawer={showDrawer}
  //     />
  //   );
  // } else if (config?.type === "BAR_CHART") {
  //   return (
  //     <MyBar
  //       config={config}
  //       fields={fields}
  //       params={params}
  //       data={[]}
  //       showDrawer={showDrawer}
  //     />
  //   );
  // } else if (config?.type === "COLUMN_CHART") {
  //   return (
  //     <MyColumn
  //       config={config}
  //       fields={fields}
  //       params={params}
  //       data={[]}
  //       showDrawer={showDrawer}
  //     />
  //   );
  // } else if (config?.type === "WATER_FALL_CHART") {
  //   return (
  //     <MyWaterfFallChart
  //       config={{ ...config, ...{ data: groupData(config["data"]) } }}
  //       showDrawer={showDrawer}
  //     />
  //   );
  // } else if (config?.type === "LINE_CHART") {
  //   return (
  //     <MyLine
  //       config={config}
  //       fields={fields}
  //       params={params}
  //       data={[]}
  //       showDrawer={showDrawer}
  //     />
  //   );
  // } else if (config?.type === "GAUGE_CHART") {
  //   return <MyGauge config={config} showDrawer={showDrawer} />;
  // } else {
  return <EmptyChartCard />;
  //}
}
