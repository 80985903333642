import { Bar, BarConfig } from "@ant-design/plots";

const DaysOfSupplyImpact = (props: any) => {
  let { data } = props;

  const config: BarConfig = {
    appendPadding: [0, 0, 20, 0],
    data,
    xField: "value",
    yField: "action_type",
    isStack: true,
    legend: {
      position: "bottom",
    },
    barStyle: {
      radius: [5, 5, 0, 0],
    },
    color: "#5BC2E7",
    tooltip: {
      shared: true,
      showMarkers: false,
      formatter: ({ action_type, value }: any) => ({
        name: "Value",
        value: value ? `${value.toFixed(2)} day` : "Not set",
      }),
    },
    barWidthRatio: 0.8,
    style: {
      height: "100%",
    },
  };

  return <Bar {...config} height={300} />;
};

export default DaysOfSupplyImpact;
