import { MenuProps } from "antd";
import {
  AiRobot,
  Desktop,
  UserCirclePlus,
  Compass,
  Users,
  Key,
  TreeStructure,
  ShieldWarning,
  HandPointing,
  Note,
  Code,
  CheckSquareOffset,
  ChartPieSlice,
  CurrencyCircleDollar,
  ChartLine,
} from "../../../assets/images";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/store";
import { PERMISSIONS_LIST } from "../../../constants/permissions";
import { isPermission } from "../../../utils/permissionsFunctions";
import { getTranslation } from "../../../utils/transaltion";
import CustomIcon from "../../pages/codification/components/CustomIcon";
import { useEffect } from "react";
import { HAS_GPT } from "../../../constants/server";

const MenuItems = (props: any): MenuProps["items"] => {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  const {
    collapsed,
    setCurrentPage,
    setMenuItems,
    setMenuTitle,
    handleMenuSelect,
  } = props;
  const { t } = useTranslation();
  const copilotList = useSelector(
    (state: RootState) => state.userCopilotReducer
  );

  const getCopilotMenuIcon = (icon?: React.ReactNode): React.ReactNode => {
    return (
      <span
        style={{
          marginLeft: collapsed ? "-10px" : "",
          marginTop: collapsed ? "5px" : "",
          float: "inline-start",
        }}
      >
        {icon}
      </span>
    );
  };

  type MenuItem = Required<MenuProps>["items"][number];
  function getItem(
    key: React.Key,
    label: string,
    link?: string,
    icon?: React.ReactNode,
    menus?: any[]
  ): MenuItem {
    return {
      key,
      icon,
      label: link ? (
        <Link
          to={link}
          onClick={() => {
            setMenuItems(menus);
            setMenuTitle(label);
          }}
        >
          {label}
        </Link>
      ) : (
        <span>{label}</span>
      ),
      onClick: () => {
        menus?.length === 0 && handleMenuSelect({ key: key?.toString() });
        setMenuItems(menus);
        setMenuTitle(label);
      },
      onMouseEnter: () => {
        setMenuItems(menus);
        setMenuTitle(label);
      },
    } as MenuItem;
  }

  function getSubItems(
    key: React.Key,
    parentKey: React.Key,
    label: string,
    link?: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label: link ? (
        <Link
          to={link}
          onClick={() => {
            setCurrentPage(label);
            setMenuItems([]);
          }}
        >
          {label}
        </Link>
      ) : (
        <span>{label}</span>
      ),
      onClick: () => {
        handleMenuSelect({ key: parentKey?.toString() });
      },
    } as MenuItem;
  }

  const configurationsChilds = [
    isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)
      ? getSubItems(
          62,
          2,
          t("RISKS"),
          "/menu/configurations",
          <ShieldWarning />
        )
      : null,
    isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)
      ? getSubItems(
          63,
          2,
          t("ACTIONS"),
          "/menu/configurations/actions",
          <HandPointing />
        )
      : null,
    isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)
      ? getSubItems(
          64,
          2,
          t("EXECUTIONS"),
          "/menu/configurations/executions",
          <Note />
        )
      : null,
    isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)
      ? getSubItems(
          65,
          2,
          getTranslation("CONNECTIONS", "labels"),
          "/menu/configurations/connections",
          <TreeStructure />
        )
      : null,
  ].filter((item) => item !== null);

  const administrationChilds = [
    isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)
      ? getSubItems(
          71,
          1,
          t("USERS"),
          "/menu/users",
          <Users style={{ color: "#004c97" }} />
        )
      : null,
    isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)
      ? getSubItems(72, 1, t("CODIFICATION"), "/menu/codifications", <Code />)
      : null,
    isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)
      ? getSubItems(
          73,
          1,
          t("EXECUTIONS_PREDEFINED"),
          "/menu/predefined_executions",
          <Note />
        )
      : null,
    isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)
      ? getSubItems(
          74,
          1,
          t("ACTIONS_PREDEFINED"),
          "/menu/predefined_actions",
          <HandPointing />
        )
      : null,
    isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)
      ? getSubItems(
          75,
          1,
          t("RISKS_PREDEFINED"),
          "/menu/predefined_risks",
          <ShieldWarning />
        )
      : null,
    isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)
      ? getSubItems(
          76,
          1,
          t("ORGANIZATIONS"),
          "/menu/organization",
          <TreeStructure />
        )
      : null,
    isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)
      ? getSubItems(80, 1, t("LICENSES"), "/menu/licenses", <Key />)
      : null,
  ].filter((item) => item !== null);

  const copilotSubMenu = (copilot: any, index: number) => {
    return [
      getSubItems(
        310 + index,
        3,
        t("OPEN_ACTIONS"),
        `/menu/${copilot?.name?.toString()?.toLowerCase()}`,
        <CheckSquareOffset />
      ),
      getSubItems(
        320 + index,
        3,
        t("ACTION_MONITORING"),
        `/menu/${copilot?.name?.toString()?.toLowerCase()}/action-monitoring`,
        <ChartPieSlice />
      ),
      getSubItems(
        330 + index,
        3,
        t("ACTION_ANALYSIS"),
        `/menu/${copilot?.name?.toString()?.toLowerCase()}/action-analysis`,
        <ChartLine />
      ),
      getSubItems(
        340 + index,
        3,
        t("ACTION_ROI"),
        `/menu/${copilot?.name?.toString()?.toLowerCase()}/action-roi`,
        <CurrencyCircleDollar />
      ),
      getSubItems(
        350 + index,
        3,
        t("RISKS_HISTORY"),
        `/menu/${copilot?.name?.toString()?.toLowerCase()}/risks_history`,
        <ShieldWarning />
      ),
    ];
  };

  let copilotsM = copilotList?.map((copilot: any, index: number) => {
    const icon = (
      <CustomIcon name={copilot?.uiConfiguration?.iconName} size={"30px"} />
    );
    const name = getTranslation(`${copilot?.name}`, "data");
    return getSubItems(
      99 + index,
      3,
      t(`${name}`),
      undefined,
      getCopilotMenuIcon(icon),
      copilotSubMenu(copilot, index)
    );
  });

  const items = [
    getItem(
      1,
      t("ADMINISTRATION"),
      "",
      <UserCirclePlus />,
      administrationChilds
    ),
    getItem(2, t("CONFIGURATION"), "", <Desktop />, configurationsChilds),
    getItem(3, t("COPILOTS"), "", <Compass />, copilotsM),
  ];
  if (HAS_GPT) {
    items.push(getItem(4, t("GPT"), "/menu", <AiRobot />, []));
  }
  return items;
};

export default MenuItems;
