/** @format */

import { useEffect, useState } from "react";
import "./assets/style/css/App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
/* antd */
import Api from "./services/main/api";
/* Components */
import Login from "./components/pages/login/Login";
import MainLayout from "./components/layouts/MainLayout";
import NotFound from "./components/result/404.component";
import ExecutionsTable from "./components/pages/confs/executions/ExecutionsTable";
import ActionsComponent from "./components/pages/confs/actions/components/ActionsTable";
import UsersTable from "./components/pages/confs/users/components/UserTable";
import UserConfiguration from "./components/pages/confs/users/components/UserConfiguration";
import DndList from "./components/common/dnd-list/DnDContext";
/* Interfaces */
import { Context } from "./interfaces/context";
import { MainContext } from "./utils/context";
/* Constants */
import { PAGES } from "./constants/pages";
import InitStore from "./redux/store/initStore";
import RiskTable from "./components/pages/confs/risks/components/RiskTable";
import RiskConfiguration from "./components/pages/confs/risks/components/RiskConfiguration";
import RunningComponent from "./components/pages/runnings/RunningComponent";
import Codifications from "./components/pages/codification/CodifcationComponent";
import { PREDEFINED_TYPES } from "./constants/predefinedTypes";
import PredefinedsTableComponent from "./components/pages/predefineds/components/PredefinedTable";
import AddUpdatePredefineds from "./components/pages/predefineds/components/AddUpdatePredefineds";
import { SharedContextProvider } from "./components/pages/predefineds/context/sharedContext";
import { useTranslation } from "react-i18next";
import i18n from "./parameters/i18n";
import { ConfigProvider } from "antd";
import { antDesignLocales } from "./constants/languagesLocales";
import { LandingPage } from "./components/pages/landing-page";
import Actions from "./components/pages/landing-page/components/Actions";
import Monitor from "./components/pages/landing-page/components/Monitor";
import Automate from "./components/pages/landing-page/components/Automate";
import Profits from "./components/pages/landing-page/components/Benefits";
import { useDispatch, useSelector } from "react-redux";
import ProtectedRoute from "./utils/protectedRoutesPermissions";
import { PERMISSIONS_LIST } from "./constants/permissions";
import { setUserPermissions } from "./utils/permissionsFunctions";
import { RootState } from "./redux/store/store";
import ActionMonitoring2 from "./components/pages/copilots/action-monitoring";
import Licenses from "./components/pages/licenses";
import { RUNNING_MENU } from "./constants/riskMenus";
import { LoadingPage } from "./components/result/Loadingpage";
import ConnectionList from "./components/pages/connections/ConnectionTable";
import AddUpdateConnection from "./components/pages/connections/AddUpdateConnection";
import ChatComponent from "./components/pages/chat_exobrain/ChatComponent";
import OrganizationTable from "./components/pages/organization/components/OrganizationTable";
import { SharedContextProviderOrganization } from "./components/pages/organization/context/sharedContext";
import AddUpdateOrganization from "./components/pages/organization/components/AddUpdateOrganization";
import ErrorPage from "./components/result/500.component";
import ActionMonitoring from "./components/pages/copilots/action-monitoring";
import ActionAnalysis2 from "./components/pages/copilots/action-analysis";
import ActionROI2 from "./components/pages/copilots/action-roi";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  const [context, setContext] = useState<Context>({
    page: PAGES.MAIN,
    loggedIn: null,
    orga: null,
    user: null,
  });
  const [locales, setLocales] = useState(
    antDesignLocales[context?.user?.preferredLanguage] ||
      antDesignLocales["en-US"]
  );
  const [getLoading, setGetLoading] = useState(true);
  const { t } = useTranslation();
  const initializationComplete = useSelector(
    (state: RootState) => state.initializationReducer
  );

  useEffect(() => {
    if (context?.user?.preferredLanguage !== "") {
      // Change the language of the i18next instance
      i18n.changeLanguage(context?.user?.preferredLanguage);

      // Update the lang attribute of the <html> tag
      document.documentElement.lang = context?.user?.preferredLanguage;

      // Set the text direction based on the selected language
      document.body.style.direction = i18n.language !== "ar-DZ" ? "ltr" : "rtl";

      // Update the Ant Design locales to match the selected language
      setLocales(antDesignLocales[i18n.language]);
    }
  }, [context.user, t, i18n.language]);

  const api = new Api(context, setContext, "");

  const get_orga = async () => {
    const res = await api
      .get("/organizations/my")
      .then((res) => {
        const orga = res?.data;
        if (res.data.enabled) {
          get_user(orga);
          setContext({ ...context, loggedIn: true });
        } else {
          setContext({ ...context, loggedIn: false });
        }
      })
      .catch((err) => {
        setContext({ ...context, loggedIn: false });
        setGetLoading(false);
      });
  };

  const get_user = async (orga: any) => {
    const res = await api
      .get(
        `/organizations/${orga.id}/users/me?full=roles&full=permissions&full=scopes`
      )
      .then(async (res) => {
        // setGetLoading(false);
        const user = res.data;
        await api.get(`/permissions/`).then((res) => {
          setGetLoading(false);
          setUserPermissions(res?.data?.items, user?.roles, dispatch);
        });
        setContext({ ...context, orga, user, loggedIn: true });
      })
      .catch((err) => {
        setContext({ ...context, loggedIn: false });
        setGetLoading(false);
      });
  };
  useEffect(() => {
    if (context.loggedIn && context.orga) {
      setGetLoading(false);
    }
    if (context.loggedIn === null) {
      get_orga();
    }
    if (context.loggedIn && context.orga === null) {
      get_orga();
    }
  }, [context.loggedIn]);

  useEffect(() => {
    const timer: any = setTimeout(() => {
      setGetLoading(false);
    }, 30000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {getLoading && !initializationComplete && <LoadingPage />}
      <ConfigProvider
        locale={locales || antDesignLocales["en-US"]}
        direction={context?.user?.preferredLanguage !== "ar-DZ" ? "ltr" : "rtl"}
      >
        <MainContext.Provider value={{ context, setContext }}>
          {context.loggedIn ? (
            <Router>
              {context.orga && <InitStore />}
              {initializationComplete ? (
                <Routes>
                  <Route path="/home/actions" element={<Actions />}></Route>
                  <Route path="/home/monitor" element={<Monitor />}></Route>
                  <Route path="/home/automate" element={<Automate />}></Route>
                  <Route path="/home/profits" element={<Profits />}></Route>
                  <Route path="/list" element={<DndList />}></Route>
                  <Route path="/" element={<MainLayout />}>
                    <Route path="" element={<ChatComponent />}></Route>
                  </Route>
                  <Route path="/menu" element={<MainLayout />}>
                    <Route path="" element={<ChatComponent />}></Route>
                    <Route
                      path=":copilotName"
                      element={
                        <RunningComponent
                          menu={RUNNING_MENU.OPEN_ACTIONS}
                          hasDashboard={false}
                        />
                      }
                    />
                    <Route
                      path=":copilotName/risks_history"
                      element={
                        <RunningComponent
                          menu={RUNNING_MENU.RISKS_HISTORY}
                          hasDashboard={false}
                        />
                      }
                    />
                    <Route
                      path=":copilotName/action-monitoring"
                      element={<ActionMonitoring />}
                    />
                    <Route
                      path=":copilotName/action-analysis"
                      element={<ActionAnalysis2 />}
                    />
                    <Route
                      path=":copilotName/action-roi"
                      element={<ActionROI2 />}
                    />
                    <Route
                      path="codifications"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.EXOBRAIN_ADMIN}
                          element={<Codifications />}
                        />
                      }
                    />
                    <Route
                      path="predefined_executions"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.EXOBRAIN_ADMIN}
                          element={
                            <SharedContextProvider>
                              <PredefinedsTableComponent
                                typeComponent={
                                  PREDEFINED_TYPES.EXECUTION_PREDEFINED
                                }
                              />
                            </SharedContextProvider>
                          }
                        />
                      }
                    />

                    <Route
                      path="add_predefined_executions"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.EXOBRAIN_ADMIN}
                          element={
                            <SharedContextProvider>
                              <AddUpdatePredefineds
                                typeComponent={
                                  PREDEFINED_TYPES.EXECUTION_PREDEFINED
                                }
                              />
                            </SharedContextProvider>
                          }
                        />
                      }
                    />
                    <Route
                      path="predefined_actions"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.EXOBRAIN_ADMIN}
                          element={
                            <SharedContextProvider>
                              <PredefinedsTableComponent
                                typeComponent={
                                  PREDEFINED_TYPES.ACTION_PREDEFINED
                                }
                              />
                            </SharedContextProvider>
                          }
                        />
                      }
                    />
                    <Route
                      path="add_predefined_actions"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.EXOBRAIN_ADMIN}
                          element={
                            <SharedContextProvider>
                              <AddUpdatePredefineds
                                typeComponent={
                                  PREDEFINED_TYPES.ACTION_PREDEFINED
                                }
                              />
                            </SharedContextProvider>
                          }
                        />
                      }
                    />
                    <Route
                      path="predefined_risks"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.EXOBRAIN_ADMIN}
                          element={
                            <SharedContextProvider>
                              <PredefinedsTableComponent
                                typeComponent={PREDEFINED_TYPES.RISK_PREDEFINED}
                              />
                            </SharedContextProvider>
                          }
                        />
                      }
                    />
                    <Route
                      path="add_predefined_risks"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.EXOBRAIN_ADMIN}
                          element={
                            <SharedContextProvider>
                              <AddUpdatePredefineds
                                typeComponent={PREDEFINED_TYPES.RISK_PREDEFINED}
                              />
                            </SharedContextProvider>
                          }
                        />
                      }
                    />

                    <Route
                      path="organization"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.EXOBRAIN_ADMIN}
                          element={
                            <SharedContextProviderOrganization>
                              <OrganizationTable />
                            </SharedContextProviderOrganization>
                          }
                        />
                      }
                    />

                    <Route
                      path="add_organization"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.EXOBRAIN_ADMIN}
                          element={
                            <SharedContextProviderOrganization>
                              <AddUpdateOrganization />
                            </SharedContextProviderOrganization>
                          }
                        />
                      }
                    />
                    <Route
                      path="view_organization"
                      element={
                        <SharedContextProviderOrganization>
                          <AddUpdateOrganization />
                        </SharedContextProviderOrganization>
                      }
                    />
                    <Route
                      path="licenses"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.EXOBRAIN_ADMIN}
                          element={
                            <SharedContextProviderOrganization>
                              <Licenses />
                            </SharedContextProviderOrganization>
                          }
                        />
                      }
                    />
                    <Route path="dashboard" element={<>Dashboard</>} />

                    <Route
                      path="users"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.ORG_ADMIN}
                          element={<UsersTable />}
                        />
                      }
                    />
                    <Route
                      path="users/config"
                      element={<UserConfiguration />}
                    />

                    <Route
                      path="configurations"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.ORG_ADMIN}
                          element={<RiskTable />}
                        />
                      }
                    />
                    <Route
                      path="configurations/risks"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.ORG_ADMIN}
                          element={<RiskConfiguration />}
                        />
                      }
                    />
                    <Route
                      path="configurations/risks/:uuid?/action?/:uuidaction?/execution?/:uuidexecution?"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.ORG_ADMIN}
                          element={<RiskConfiguration />}
                        />
                      }
                    />
                    <Route
                      path="configurations/executions/execution?/:uuidexecution?"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.ORG_ADMIN}
                          element={<ExecutionsTable />}
                        />
                      }
                    />
                    <Route
                      path="configurations/actions/action?/:uuidaction?/execution?/:uuidexecution?"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.ORG_ADMIN}
                          element={<ActionsComponent />}
                        />
                      }
                    />
                    <Route
                      path="configurations/connections"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.ORG_ADMIN}
                          element={<ConnectionList />}
                        />
                      }
                    />
                    <Route
                      path="configurations/connections/details"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.ORG_ADMIN}
                          element={<AddUpdateConnection />}
                        />
                      }
                    />

                    <Route
                      path="configurations"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.ORG_ADMIN}
                          element={<RiskTable />}
                        />
                      }
                    />
                    <Route
                      path="configurations/risks"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.ORG_ADMIN}
                          element={<RiskConfiguration />}
                        />
                      }
                    />
                    <Route
                      path="configurations/executions"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.ORG_ADMIN}
                          element={<ExecutionsTable />}
                        />
                      }
                    />
                    <Route
                      path="configurations/actions"
                      element={
                        <ProtectedRoute
                          permissionsList={permissionsList}
                          permissionsRequired={PERMISSIONS_LIST.ORG_ADMIN}
                          element={<ActionsComponent />}
                        />
                      }
                    />

                    <Route path="*" element={<NotFound />} />
                  </Route>
                  <Route path="*" element={<NotFound />} />
                  <Route path="/error" element={<ErrorPage />} />
                </Routes>
              ) : (
                <LoadingPage />
              )}
            </Router>
          ) : (
            <Login />
          )}
        </MainContext.Provider>
      </ConfigProvider>
    </>
  );
};

export default App;
