import { Divider, Drawer } from "antd";
import React from "react";

export default function ChartDetailsDrawer(props: any) {
  const { onCloseDrawer, openDrawer, data } = props;
  console.log(data);
  return (
    <>
      <Drawer title="Details" onClose={onCloseDrawer} open={openDrawer}>
        <p style={{ color: data?.color, fontSize: 24 }}>
          {data?.data?.categoryField ||
            (data?.data && data?.data[0]?.categoryField) ||
            data?.data?.percent}{" "}
          - {data?.data?.valueField} risks
        </p>
        <Divider />
        <p style={{ fontSize: 18 }}> Related risks :</p>
        <p> risk...</p>
        <p> risk...</p>
        <p> risk...</p>
        <p> ...</p>
        <Divider />
        <p style={{ color: "#0082ba ", fontSize: 18 }}>More Details ...</p>
      </Drawer>
    </>
  );
}
