export const mapOpenRiskByActionType = (data: any) => {
  const mappedData = data
    ?.map((obj: any) => {
      return {
        action_type: obj.action_type,
        risk_status: obj.risk_status,
      };
    })
    ?.filter(
      (risk: any) =>
        risk.risk_status === "Open" || risk.risk_status === "In progress"
    )
    ?.reduce((accumulator: any, item: any) => {
      const { action_type } = item;
      const key = `${action_type}`;

      if (!accumulator[key]) {
        accumulator[key] = { action_type, value: 0 };
      }

      accumulator[key].value += 1;

      return accumulator;
    }, {});
  return Object.values(mappedData);
};
