import { Col, Form, Row, Select } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { RuleField } from "./RiskAddRuleModal";
import RiskExpressionNode from "./RiskExpressionNode";
import { AND, OR } from "../components/options";

interface ExpressionFormProps {
  data: RuleField;
  id: string;
  rulesExpr: any;
  setRulesExpr: any;
  dataNeeded: any;
  formAutomation: any;
}

export default function RiskExpressionForm(props: ExpressionFormProps) {
  const {
    data: exp,
    id,
    rulesExpr,
    setRulesExpr,
    dataNeeded,
    formAutomation,
  } = props;
  const value = exp?.value;
  /* expr id : parent1ID_parent2Id_childId */
  const extandable = id.split("_").length < 3; //max level
  const is_logic_block =
    typeof value != "string" && (exp?.operator === AND || exp?.operator === OR);

  const onUpdateBlock = (i: string, block_op = AND) => {
    const addExpression = (
      i: string,
      t = "",
      rule: RuleField,
      value: string | RuleField[]
    ) => {
      if (i === t) {
        if (rule.operator !== AND && rule.operator !== OR) {
          rule.value = [
            {
              operator: rule.operator,
              value: rule.value,
              field: rule.field,
            },
            {
              operator: "",
              value: "",
              field: "",
            },
          ];
          rule.operator = block_op;
          rule.field = undefined;
          return;
        } else {
          rule.operator = block_op;
        }
      } else if (typeof value != "string") {
        value.forEach((r, index) =>
          addExpression(i, t + (t ? "_" : "") + index.toString(), r, r.value)
        );
      }
    };

    let rule_ = { ...rulesExpr };
    addExpression(i, "", rule_, rule_.value);
    setRulesExpr(rule_);
  };

  const onAddExpression = (i: string) => {
    const addExpression = (i: string, t = "", rule: RuleField) => {
      /* typeof rule.value !== "string" => it has childs */
      if (typeof rule.value !== "string") {
        if (i === t) {
          rule.value.push({
            operator: "",
            value: "",
            field: "",
          });
          return;
        } else {
          /* each elementId = parentId_childId : child can have childs */
          rule.value.forEach((r, index) =>
            addExpression(i, t + (t ? "_" : "") + index.toString(), r)
          );
        }
      }
    };
    let rule_ = { ...rulesExpr };
    addExpression(i, "", rule_);
    setRulesExpr(rule_);
  };

  return (
    <Form>
      <Row justify="center" align="middle">
        {extandable && (
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <span style={{ display: "flex", alignItems: "center" }}>
              <Select
                style={{ width: "95%" }}
                options={
                  is_logic_block
                    ? [
                        { value: AND, label: "And" },
                        { value: OR, label: "Or" },
                      ]
                    : [
                        { value: "/", label: "/" },
                        { value: AND, label: "And" },
                        { value: OR, label: "Or" },
                      ]
                }
                defaultValue={
                  exp?.operator === AND || exp?.operator === OR
                    ? exp.operator
                    : "/"
                }
                value={
                  exp?.operator === AND || exp?.operator === OR
                    ? exp.operator
                    : "/"
                }
                onChange={(value) => {
                  onUpdateBlock(id, value);
                }}
              />
              {is_logic_block && (
                <PlusCircleOutlined
                  style={{
                    fontSize: "20px",
                    color: "#1677ff",
                    margin: "5px",
                  }}
                  onClick={() => onAddExpression(id)}
                />
              )}
            </span>
          </Col>
        )}
        {/* Expressions */}
        {is_logic_block ? (
          <Col
            xs={20}
            sm={20}
            md={20}
            lg={20}
            xl={20}
            style={{
              borderLeft: "3px solid #000000",
              paddingLeft: "10px",
              borderRadius: "5px",
            }}
          >
            {value.map((value, index) => {
              return (
                /* Element with childs */
                <RiskExpressionForm
                  key={"ExpressionForm" + index.toString()}
                  data={value}
                  id={id + (id ? "_" : "") + index.toString()}
                  rulesExpr={rulesExpr}
                  setRulesExpr={setRulesExpr}
                  dataNeeded={dataNeeded}
                  formAutomation={formAutomation}
                />
              );
            })}
          </Col>
        ) : (
          <Col
            xs={extandable ? 20 : 24}
            sm={extandable ? 20 : 24}
            md={extandable ? 20 : 24}
            lg={extandable ? 20 : 24}
            xl={extandable ? 20 : 24}
          >
            <Form.Item>
              {/* Expression */}
              {/* Element without childs */}
              <RiskExpressionNode
                key={id}
                data={exp}
                id={id}
                rulesExpr={rulesExpr}
                setRulesExpr={setRulesExpr}
                dataNeeded={dataNeeded}
                formAutomation={formAutomation}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
}
