import React, { useEffect, useState } from "react";
import { Form, Select, Tag } from "antd";
import { selectFilterOption } from "../../../../../utils/functions";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../../logic/runningShared.logic";
import { useTranslation } from "react-i18next";
import { createKey, getTranslation } from "../../../../../utils/transaltion";
import { Fulfillment_reasons } from "../../data/fulfillment";
import { Supply_Matching_reasons } from "../../data/supplyMatching";
import { Demand_Matching_reasons } from "../../data/demandMatching";
import { Inbound_Freight_reasons } from "../../data/inboundFreight";
const { Option } = Select;

interface ReasonSelectionProps {
  copilotName: string;
  currentActionExecutionReasons: any[];
  setInputOtherVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReasonCodeSelection: React.FC<ReasonSelectionProps> = ({
  copilotName,
  currentActionExecutionReasons,
  setInputOtherVisible,
}) => {
  const [demoActionExecutionReasons, setDemoActionExecutionReasons] =
    useState<any>([]);
  /** translation */
  const { t } = useTranslation();
  /** shared logic */
  const { handleOnSelectReasonCode } =
    useSharedContextFunctions() as SharedContextFunctions;
  const customTagRender = (props: any) => {
    const { label, value } = props;
    return (
      <Tag
        style={{
          backgroundColor: "#004c97",
          color: "white",
          borderRadius: "4px",
          padding: "0 8px",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        {label}
      </Tag>
    );
  };

  useEffect(() => {
    if (createKey(copilotName) === createKey("fulfillment")) {
      setDemoActionExecutionReasons(Fulfillment_reasons);
    } else {
      if (createKey(copilotName) === createKey("supply matching")) {
        setDemoActionExecutionReasons(Supply_Matching_reasons);
      } else {
        if (createKey(copilotName) === createKey("demand matching")) {
          setDemoActionExecutionReasons(Demand_Matching_reasons);
        } else {
          if (
            createKey(copilotName) === createKey("inbound freight management")
          ) {
            setDemoActionExecutionReasons(Inbound_Freight_reasons);
          }
        }
      }
    }
  }, [copilotName]);
  return (
    <Form.Item label={t("REASONS")} name="reason" required>
      <Select
        showSearch
        filterOption={(input, option) => selectFilterOption(input, option)}
        onSelect={(value) => {
          handleOnSelectReasonCode(
            value,
            currentActionExecutionReasons &&
              currentActionExecutionReasons?.length > 0 &&
              demoActionExecutionReasons.length === 0
              ? currentActionExecutionReasons
              : demoActionExecutionReasons,
            setInputOtherVisible
          );
        }}
        tagRender={customTagRender}
        mode="tags"
        maxCount={1}
        allowClear
      >
        {currentActionExecutionReasons &&
        currentActionExecutionReasons?.length > 0 &&
        demoActionExecutionReasons.length === 0
          ? currentActionExecutionReasons
              ?.sort((a, b) => a.name.localeCompare(b.name))
              ?.map((item) => (
                <Option key={item?.id} value={item?.id}>
                  {getTranslation(item?.name, "data")}
                </Option>
              ))
          : demoActionExecutionReasons
              ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
              ?.map((item: any) => (
                <Option key={item?.id} value={item?.id}>
                  {getTranslation(item?.name, "data")}
                </Option>
              ))}
      </Select>
    </Form.Item>
  );
};

export default ReasonCodeSelection;
