import { useEffect, useState } from "react";
import { Card, Col, ConfigProvider, Row } from "antd";
import ChartCard from "../common/ChartCard";
import FilterHeader from "../common/filter_header/FilterHeader";
import { FilterData, getData } from "../common/FilterData";
import {
  AcceptanceAutomationRateOverTime,
  CompleteStatusByAction,
  RejectionByReasonCode,
  RiskStatusByExecutionStage,
  Top10Accepted,
  Top10Rejected,
} from "./components/charts";
import {
  mapAcceptanceAutomationRateOverTime,
  mapCompleteStatusByAction,
  mapRejectionByReasonCodeData,
  mapRiskRejectedByExecutionStage,
  mapTop10Accepted,
  mapTop10Rejected,
} from "./components/data_mapping";
import { DASHBOARDS } from "../../../../constants/dashboards";
import { COPILOT_TYPES } from "../../../../constants/copilottypes";

const ActionAnalysis = (props: any) => {
  const { copilot } = props;
  const [filteredData, setFilteredData]: any = useState([]);
  const [filterOptions, setFilterOptions]: any = useState({});
  /* sum */
  const [purchaseCost, setPurchaseCost] = useState(0);
  const [freightCost, setFreightCost] = useState(0);
  const [daysofSupplyImpact, setDaysofSupplyImpact] = useState(0);
  useEffect(() => {
    const sorted_data = getData(copilot);
    sorted_data.sort((a, b) => a?.risk_date - b?.risk_date);

    FilterData(filterOptions, sorted_data, setFilteredData);
  }, [filterOptions, copilot]);

  useEffect(() => {
    setPurchaseCost(
      filteredData
        ?.filter(
          (risk: any) =>
            risk.risk_status === "Accepted" || risk.risk_status === "Rejected"
        )
        ?.reduce(
          (total: any, obj: any) => total + parseFloat(obj.purchase_cost || 0),
          0
        )
    );
    setFreightCost(
      filteredData
        ?.filter(
          (risk: any) =>
            risk.risk_status === "Accepted" || risk.risk_status === "Rejected"
        )
        ?.reduce(
          (total: any, obj: any) => total + parseFloat(obj.freight_cost || 0),
          0
        )
    );
    setDaysofSupplyImpact(
      filteredData
        ?.filter(
          (risk: any) =>
            risk.risk_status === "Accepted" || risk.risk_status === "Rejected"
        )
        ?.reduce(
          (total: any, obj: any) =>
            total + parseFloat(obj.days_of_supply_impact || 0),
          0
        ) / filteredData?.length
    );
  }, [filteredData, copilot]);

  return (
    <ConfigProvider>
      <Row
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          marginBottom: 15,
        }}
      >
        <FilterHeader
          setFilterOptions={setFilterOptions}
          dashboard={DASHBOARDS.ACTION_ANALYSIS}
          copilot={copilot}
        />
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          marginBottom: 15,
        }}
      >
        <Col sm={24} lg={8} xl={8}>
          <Card
            title={
              copilot === COPILOT_TYPES.INBOUND_FREIGHT
                ? "Logistics Cost"
                : "Purchase Cost"
            }
            size="small"
          >
            <span
              style={{
                color: "red",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              {purchaseCost.toLocaleString("us-US", {
                style: "currency",
                currency: "EUR",
              })}
            </span>
          </Card>
        </Col>
        <Col sm={24} lg={8} xl={8}>
          <Card title={"Freight Cost"} size="small">
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "16px" }}
            >
              {freightCost.toLocaleString("us-US", {
                style: "currency",
                currency: "EUR",
              })}
            </span>
          </Card>
        </Col>
        <Col sm={24} lg={8} xl={8}>
          <Card title={"Days of Supply Impact"} size="small">
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "16px" }}
            >
              {daysofSupplyImpact.toFixed(2)} day
            </span>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col sm={24} lg={12} xl={8}>
          <ChartCard
            title="Acceptance Rate and Automation Over Time"
            chart={
              <AcceptanceAutomationRateOverTime
                data={mapAcceptanceAutomationRateOverTime(filteredData)}
              />
            }
          ></ChartCard>
        </Col>
        <Col sm={24} lg={12} xl={8}>
          <ChartCard
            title="Complete Actions Accepted and Rejected by Type"
            chart={
              <CompleteStatusByAction
                data={mapCompleteStatusByAction(filteredData)}
              />
            }
          ></ChartCard>
        </Col>
        <Col sm={24} lg={12} xl={8}>
          <ChartCard
            title="Rejection by Reason Code"
            chart={
              <RejectionByReasonCode
                data={mapRejectionByReasonCodeData(filteredData)}
              />
            }
          ></ChartCard>
        </Col>
        <Col sm={24} lg={12} xl={8}>
          <ChartCard
            title="Top Locations Accepted"
            chart={<Top10Accepted data={mapTop10Accepted(filteredData)} />}
          ></ChartCard>
        </Col>
        <Col sm={24} lg={12} xl={8}>
          <ChartCard
            title="Top Locations Rejected"
            chart={<Top10Rejected data={mapTop10Rejected(filteredData)} />}
          ></ChartCard>
        </Col>
        <Col sm={24} lg={12} xl={8}>
          <ChartCard
            title="Risk Rejected by Execution Stage"
            chart={
              <RiskStatusByExecutionStage
                data={mapRiskRejectedByExecutionStage(filteredData)}
              />
            }
          ></ChartCard>
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default ActionAnalysis;
