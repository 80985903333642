import { demand_matching_data } from "../../../../assets/data/copilots/demand_matching_data";
import { fulfillment_data } from "../../../../assets/data/copilots/fulfillment_data";
import { procurement_data } from "../../../../assets/data/copilots/procurement_data";
import { supply_matching_data } from "../../../../assets/data/copilots/supply_matching_data";
import { inbound_freight_data } from "../../../../assets/data/copilots/inbound_freight_data";
import { COPILOT_TYPES } from "../../../../constants/copilottypes";

export function daytoDateSinceReference(
  numberOfDays: number,
  referenceDate = 25569
): Date {
  const date = new Date((numberOfDays - referenceDate) * 24 * 60 * 60 * 1000);
  return date;
}

export const FilterData = (
  filterOptions: any,
  data: any,
  setFilteredData: any
) => {
  setFilteredData(
    data.filter(
      (risk: any) =>
        (!filterOptions?.dateRange ||
          (new Date(filterOptions.dateRange?.at(0)) <=
            daytoDateSinceReference(risk.risk_date) &&
            new Date(filterOptions.dateRange?.at(1)) >=
              daytoDateSinceReference(risk.risk_date))) &&
        (!filterOptions?.risk_location ||
          risk.risk_location
            ?.toString()
            .includes(filterOptions?.risk_location)) &&
        (!filterOptions?.risk_item ||
          risk.risk_item?.includes(filterOptions?.risk_item)) &&
        (!filterOptions?.risk_status ||
          filterOptions?.risk_status.length <= 0 ||
          filterOptions?.risk_status?.includes(risk.risk_status)) &&
        (!filterOptions?.action_type ||
          filterOptions?.action_type.length <= 0 ||
          filterOptions?.action_type?.includes(risk.action_type))
    )
  );
};

export const getData = (copilot: string) => {
  return copilot === COPILOT_TYPES.PROCUREMENT
    ? [...procurement_data]
    : copilot === COPILOT_TYPES.FULFILLEMENT
    ? [...fulfillment_data]
    : copilot === COPILOT_TYPES.DEMAND_MATCHING
    ? [...demand_matching_data]
    : copilot === COPILOT_TYPES.SUPPLY_MATCHING
    ? [...supply_matching_data]
    : copilot === COPILOT_TYPES.INBOUND_FREIGHT
    ? [...inbound_freight_data]
    : [];
};
