import { Tag } from "antd";
import React from "react";
import { hexToRgba } from "./functions";
interface MyTagProps {
  color: string;
  text: React.ReactNode;
}
export default function CustomTag(props: MyTagProps) {
  const { color, text } = props;
  return (
    <Tag
      style={{
        color: hexToRgba(color, 1),
        borderColor: hexToRgba(color, 0.6),
        backgroundColor: hexToRgba(color, 0.12),
        border: 0,
        fontSize: "14px",
        fontWeight: 600,
        paddingInline: "9px",
      }}
    >
      {text}
    </Tag>
  );
}
