import CrudService from "./main/crud.service";
import { Context } from "../interfaces/context";

const resource = { name: "predefined/executions", isPublic: true };

class PredefinedExecutionsService extends CrudService {
  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(resource, context, setContext, path);
  }

  async getPredefinedExecution() {
    const { data } = await this.get(
      `/organizations/${this?.context?.orga?.id}/predefined/executions?limit=500&offset=0&full=reasons&full=roles&order=desc&sort=created_at `
    );
    return data;
  }
  async getPredefinedExecutionGlobal() {
    const { data } = await this.get(
      `/predefined/executions/?limit=500&offset=0&full=reasons&full=roles&order=desc&sort=created_at`
    );
    return data;
  }
  async deletePredefinedExecution(args: string) {
    const { data } = await this.delete(`/predefined/executions/` + args);
    return data;
  }

  async createPredefinedExecution(args: any) {
    console.log(args);
    const { data } = await this.post(`/predefined/executions/`, args);
    return { data };
  }

  async updatePredefinedExecution(id: string, args: any) {
    console.log(args);
    let data = await this.patch(`/predefined/executions/` + id, args);
    return { data };
  }
}

export default PredefinedExecutionsService;
