import { Column, ColumnConfig } from "@ant-design/plots";
import { riskStatusColors } from "../../../common/chartColors";

const CompleteStatusByAction = (props: any) => {
  let { data } = props;

  const config: ColumnConfig = {
    data,
    xField: "action_type",
    yField: "value",
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
        formatter: (action_type) => {
          const words = action_type.split(" ");
          words.splice(1, 0, "\n");
          words.splice(3, 0, "\n");
          return words.join(" ");
        },
      },
    },
    seriesField: "risk_status",
    isStack: true,
    color: ({ risk_status }) => {
      return riskStatusColors(risk_status);
    },
    tooltip: {
      shared: true,
      showMarkers: false,
      formatter: ({ risk_status, value }: any) => ({
        name: risk_status,
        value: `${value} risks`,
      }),
    },
    legend: {
      position: "bottom",
    },
    columnWidthRatio: 0.8,
  };

  return <Column {...config} height={300} />;
};

export default CompleteStatusByAction;
