import { setReasonsExecutions } from "../../reducers/reasonsExecutions.Reducer";

/** dispatch predefined risk */
export const InitReasonsExecutions = async (
  reasonsExecutionsServices: any,
  dispatch: any
) => {
  return reasonsExecutionsServices
    .getResources({ pageSize: 1000, sort: false })
    .then(async (res: any) => {
      dispatch(setReasonsExecutions(res?.items));
    })
    .catch((err: any) => {
      console.log(err);
    });
};
