import React, { useContext, useEffect } from "react";
import { Collapse, Form, FormInstance, Input, Select } from "antd";
import { SharedContext } from "../../context/sharedContext";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { WORK_MODE } from "../../../../../constants/workModes";
import { selectFilterOption } from "../../../../../utils/functions";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../utils/transaltion";

const RiskDetails: React.FC<{
  onFinish: () => void;
  form: FormInstance<any>;
  workModeRisk: any;
  record: any;
}> = (props) => {
  const { t } = useTranslation();
  /**props */
  const { onFinish, form, record } = props;
  /** get predefined risks from redux */
  const { setPredefinedRiskId, workModeRisk } = useContext(SharedContext);
  const predefinedRList = useSelector(
    (state: RootState) => state.predefinedRiskOrgaReducer
  )?.filter((item: any) => item?.enabled === true && item?.is_deleted !== true);

  const copilotsRedux = useSelector(
    (state: RootState) => state.userCopilotReducer
  );

  const getCopilotName = async (id: string) => {
    setPredefinedRiskId(id);

    const risk = predefinedRList?.find((risk: any) => risk.id === id);

    if (risk && risk.copilot) {
      const copilotName = copilotsRedux?.find(
        (copilot: any) => copilot.id === risk.copilot.id
      )?.name;
      const translatedCopilotName = getTranslation(copilotName, "data");
      // display the copilot name linked to the selected predefined risk
      form.setFieldsValue({
        copilot_name: translatedCopilotName,
      });
    }
  };

  useEffect(() => {
    if (workModeRisk === WORK_MODE.CREATE || !workModeRisk) {
      form.resetFields();
      form.setFieldsValue({
        copilot_name: "",
        name: "",
        model: "",
      });
    }

    if (workModeRisk === WORK_MODE.UPDATE || workModeRisk === WORK_MODE.VIEW) {
      setPredefinedRiskId(record?.model?.id);
      const translatedCopilotName = getTranslation(
        record?.model?.copilot?.name,
        "data"
      );
      form.setFieldsValue({
        copilot_name: translatedCopilotName,
        name: record?.name,
        model: record?.model?.id,
      });
    }
  }, [workModeRisk, record]);

  return (
    <div style={{ marginTop: 20, width: "100%", marginBottom: "20px" }}>
      <Collapse
        className="panel-style"
        accordion
        expandIconPosition="end"
        size="large"
        destroyInactivePanel={true}
        defaultActiveKey="menu_risk"
      >
        <Collapse.Panel
          className="panel-style"
          header={<span> {t("DETAILS", { item: t("RISK") })} </span>}
          key="menu_risk"
        >
          <Form
            onFinish={onFinish}
            form={form}
            initialValues={
              workModeRisk === WORK_MODE.CREATE
                ? {
                    name: "",
                    model: "",
                    copilot_name: "",
                  }
                : {}
            }
          >
            <Form.Item
              label={t("NAME", { item: t("") })}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("errors:REQUIRED", {
                    label: t("FIELD"),
                  }),
                },
              ]}
            >
              <Input readOnly={workModeRisk === WORK_MODE.VIEW} />
            </Form.Item>
            <Form.Item
              label={t("RISK_PREDEFINED")}
              name="model"
              rules={[
                {
                  required: true,
                  message: t("errors:REQUIRED", {
                    label: t("RISK_PREDEFINED"),
                  }),
                },
              ]}
            >
              <Select
                disabled={workModeRisk === WORK_MODE.CREATE ? false : true}
                showSearch
                filterOption={(input, option) =>
                  selectFilterOption(input, option)
                }
                onChange={(value) => {
                  getCopilotName(value);
                }}
              >
                {predefinedRList?.map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {getTranslation(item?.name, "data")}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={t("COPILOT")} name="copilot_name">
              <Input readOnly />
            </Form.Item>
          </Form>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default RiskDetails;
