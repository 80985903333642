import React, { useEffect, useState } from "react";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "./map.css";
import { getTranslation } from "../../../utils/transaltion";

const LeafletRoutingMachine = (props: any) => {
  const map = useMap();
  const [enlargedContent, setEnlargedContent] = useState<string | null>(null);
  const { InputList } = props;

  // let DefaultIcon = L.icon({
  //   iconUrl: require("./icon/location-pin.png"),
  //   iconSize: [50, 50],
  // });
  const createColoredMarker = (color: string) => {
    return L.divIcon({
      className: "custom-marker",
      html: `
        <svg fill="${color}" width="30px" height="30px" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 0c-1.66 0-3 1.34-3 3 0 2 3 5 3 5s3-3 3-5c0-1.66-1.34-3-3-3zm0 1c1.11 0 2 .9 2 2 0 1.11-.89 2-2 2-1.1 0-2-.89-2-2 0-1.1.9-2 2-2z" transform="translate(1)" />
        </svg>`,
      iconSize: [30, 30],
      iconAnchor: [15, 30],
    });
  };
  useEffect(() => {
    map.eachLayer((layer) => {
      if (layer instanceof L.Marker || layer instanceof L.Polyline) {
        map.removeLayer(layer);
      }
    });
    InputList.forEach((route: any) => {
      // console.log("change in route", route);

      const startMarker = L.marker(route.waypoints[0], {
        icon: createColoredMarker("#004C97"),
      })
        .addTo(map)
        .bindTooltip(getTranslation(route?.shipping_location_city, "data"), {
          permanent: false,
          direction: "top",
        });

      const endMarker = L.marker(route.waypoints[1], {
        icon: createColoredMarker("#EA7600"),
      })
        .addTo(map)
        .bindTooltip(getTranslation(route?.receiving_location_city, "data"), {
          permanent: false,
          direction: "top",
        });

      const line = L.polyline(
        [startMarker.getLatLng(), endMarker.getLatLng()],
        {
          color: "#004C97",
          weight: 4,
          opacity: 1,
        }
      ).addTo(map);

      // Create a function to handle marker and line click events
      const handleClick = (latlng: L.LatLng) => {
        // Split the content by semicolons
        const items = route.popupContent.split(";");

        // Process each item to format the text before and after the colon
        const formattedContent = items
          .map((item: any) => {
            const [label, value] = item.split(":");
            if (!value) return item;
            return `<span style="color: #004c97;">${label.trim()}:</span> ${value.trim()}`;
          })
          .join("<br>");

        // Create and open the popup with the formatted content
        const popup = L.popup({ className: "custom-popup" })
          .setLatLng(latlng)
          .setContent(formattedContent)
          .openOn(map);

        // On popup double click, set the content to be enlarged
        popup.on("dblclick", () => {
          setEnlargedContent(formattedContent);
          popup.removeFrom(map);
        });
      };

      // Attach click event to startMarker
      startMarker.on("click", () => {
        handleClick(startMarker.getLatLng());
      });

      // Attach click event to endMarker
      endMarker.on("click", () => {
        handleClick(endMarker.getLatLng());
      });

      // Attach click event to line
      line.on("click", (event: L.LeafletMouseEvent) => {
        handleClick(event.latlng);
      });
    });
  }, [map, InputList]);

  // Function to close the enlarged content
  const closeEnlargedContent = () => {
    setEnlargedContent(null);
  };

  return (
    <>
      {enlargedContent && (
        <div className="enlarged-popup-content" onClick={closeEnlargedContent}>
          {enlargedContent}
        </div>
      )}
    </>
  );
};

export default LeafletRoutingMachine;
