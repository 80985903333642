import React from "react";
import { Button, Result } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { subTitle } = state ?? { subTitle: "Sorry, something went wrong." };
  return (
    <Result
      status="500"
      title="500"
      subTitle={subTitle}
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate("/menu");
          }}
        >
          Back Home
        </Button>
      }
    />
  );
};

export default ErrorPage;
