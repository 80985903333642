import { Form, Table, message } from "antd";
import { useState, useEffect } from "react";
import { CodifTableDef } from "../columnsDef/CodifTable.Def";
import CodificationCreation from "./CodificationCreation";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/codificationLogic";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/store";

function CodificationTable(props: any) {
  /** props */
  const { codificationtype } = props;
  const [editingRow, setEditingRow] = useState(null);
  const [rows, setRows] = useState([]);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const columns = CodifTableDef(codificationtype, messageApi);
  /** get shared logic */
  const { getCodifList } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  const copilotList = useSelector((state: RootState) => state.copilotReducer);

  const rolesList = useSelector((state: RootState) => state.roleReducer);
  const reasonsActionList = useSelector(
    (state: RootState) => state.reasonsActionsReducer
  );
  const reasonsExecutionList = useSelector(
    (state: RootState) => state.reasonsExecutionsReducer
  );
  const reasonsRisksList = useSelector(
    (state: RootState) => state.reasonsRisksReducer
  );
  useEffect(() => {
    getCodifList(codificationtype, setRows);
  }, [
    copilotList,
    rolesList,
    reasonsActionList,
    reasonsExecutionList,
    reasonsRisksList,
  ]);
  return (
    <div>
      {contextHolder}
      <div>
        <CodificationCreation codificationtype={codificationtype} />

        <br />
        <Form form={form}>
          <Table
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: (event) => {
                  setEditingRow(null);
                },
              };
            }}
            columns={columns}
            dataSource={rows}
            rowKey="id"
          />
        </Form>
      </div>
    </div>
  );
}
export default CodificationTable;
