import { daytoDateSinceReference } from "../../../../../../utils/myDatetime";

export const mapAcceptanceAutomationRateOverTime = (data: any) => {
  const mappedData = data
    ?.map((obj: any) => {
      return {
        risk_status: obj.risk_status,
        approval_type: obj.approval_type,
        risk_date: daytoDateSinceReference(obj.risk_date).toLocaleDateString(),
      };
    })
    ?.reduce((accumulator: any, item: any) => {
      const { risk_date, risk_status, approval_type } = item;
      const key_acceptance = `${risk_date}-acceptance`;
      const key_automation = `${risk_date}-automation`;

      if (!accumulator[key_acceptance]) {
        accumulator[key_acceptance] = {
          risk_date,
          type: "Acceptance",
          accepted_nb: null,
          total: null,
          value: null,
        };
      }
      if (!accumulator[key_automation]) {
        accumulator[key_automation] = {
          risk_date,
          type: "Automation",
          automated_nb: null,
          total: null,
          value: null,
        };
      }

      if (risk_status === "Accepted" || risk_status === "Rejected") {
        accumulator[key_acceptance].total += 1;
        if (risk_status === "Accepted")
          accumulator[key_acceptance].accepted_nb += 1;
        accumulator[key_acceptance].value = accumulator[key_acceptance]
          .accepted_nb
          ? (100 * accumulator[key_acceptance].accepted_nb) /
            accumulator[key_acceptance].total
          : null;
      }

      if (approval_type === "Automated" || approval_type === "Manual") {
        accumulator[key_automation].total += 1;
        if (approval_type === "Automated")
          accumulator[key_automation].automated_nb += 1;
        accumulator[key_automation].value = accumulator[key_acceptance]
          .accepted_nb
          ? (100 * accumulator[key_automation].automated_nb) /
            accumulator[key_automation].total
          : null;
      }

      return accumulator;
    }, {});
  return Object.values(mappedData);
};
