import { ProTable } from "@ant-design/pro-components";
import { Button, Tooltip, Popover } from "antd";
import { t } from "i18next";
import { FiSearch } from "react-icons/fi";
import { getTranslation } from "../../../../../utils/transaltion";
import { DefineTableColumns } from "./TableRunning.def";
import { useContext, useState } from "react";
import { SharedContext } from "../../context/SharedContext";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../../logic/runningShared.logic";
import { CloseCircleOutlined } from "@ant-design/icons";

export const FilterButton = (props: any) => {
  const [loadingAccept, setLoadingAccept] = useState<boolean>(false);
  const [isFirstSearchClick, setIsFirstSearchClick] = useState(true);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { filterRunningRisks } =
    useSharedContextFunctions() as SharedContextFunctions;

  const {
    copilotName,
    menu,
    setFilterparams,
    setSortingOrder,
    setSortingField,
    setSortingMenuActive,
    formRef,
  } = props;
  const { selectedRisk, setSelectedRisk } = useContext(SharedContext);
  const columns = DefineTableColumns({
    copilotName,
    menu,
    currentRiskId: selectedRisk?.id,
    setSelectedRisk,
    setLoadingAccept,
  });

  return (
    <>
      {/* filter button */}

      <Tooltip title={t("SEARCH")}>
        <Popover
          placement="bottom"
          content={() => (
            <div>
              <div
                style={{
                  textAlign: "end",
                  marginInlineEnd: "24px",
                  cursor: "pointer",
                }}
              >
                <CloseCircleOutlined
                  style={{ fontSize: "20px" }}
                  onClick={() => {
                    setPopoverVisible(false);
                  }}
                />
              </div>
              <ProTable
                style={{ padding: "-30px" }}
                columns={columns}
                request={(params): any => {
                  const filterArgs: {
                    [key: string]: string;
                  } = {};
                  const { current, pageSize, ...rest } = params;

                  Object.entries(rest).forEach(([key, value]) => {
                    try {
                      filterArgs[key] = JSON.parse(value);
                    } catch (e) {
                      filterArgs[key] = value;
                    }
                  });
                  if (!isFirstSearchClick) {
                    setFilterparams(filterArgs);
                    filterRunningRisks(filterArgs, {});
                    setPopoverVisible(false);
                  } else {
                    setIsFirstSearchClick(false);
                  }

                  return {};
                }}
                onReset={() => {
                  setFilterparams({});
                  setSortingOrder("desc");
                  setSortingField("created_at");
                  setSortingMenuActive(false);
                  filterRunningRisks({}, {});
                  setPopoverVisible(false);
                }}
                search={{
                  span: {
                    xs: 24,
                    sm: 12,
                    md: 12,
                    lg: 12,
                    xl: 12,
                    xxl: 12,
                  },
                  filterType: "query",
                  searchText: getTranslation("APPLY", "labels"),
                  resetText: t("RESET_TEXT_BUTTON"),
                  className: "custom-search-form",
                  labelWidth: "auto",
                }}
                form={{
                  formRef,
                  syncToUrl: (values: any) => {
                    // handleSearchClick();
                    return values;
                  },
                }}
                tableRender={(_, dom) => <></>}
              />
            </div>
          )}
          trigger={["click"]}
          open={popoverVisible}
          onOpenChange={(visible) => setPopoverVisible(visible)}
          overlayStyle={{
            background: "#fff",
            borderRadius: 4,
            maxWidth: "800px",
          }}
        >
          <Button icon={<FiSearch />} shape="default" />
        </Popover>
      </Tooltip>
    </>
  );
};
