import { BarConfig, Bar } from "@ant-design/plots";
import onChartClickEvent from "./functions/onChartClickEvent";
import { CHART_COLOR_TYPES } from "../chart-content/components/new-chart/NewChartModal";
import { formatData } from "./functions/formatData";
import { ChartParams } from "./functions/ChartParams.interface";
import { getTranslation } from "../../../../../../utils/transaltion";

interface MyColumnProps {
  config?: any;
  fields: any;
  params: ChartParams;
  data: any[];
  showDrawer?: (v: any) => void;
}

const MyBar: React.FC<MyColumnProps> = ({
  config,
  fields,
  params,
  data,
  showDrawer,
}) => {
  const my_config: BarConfig = {
    ...config,
    data: data || [],
    xField: "valueField",
    yField: "categoryField",
    ...(!config?.dual
      ? { isGroup: true, colorField: "groupField" }
      : { isStack: true, seriesField: "groupField" }),
    //   isGroup: true,
    //colorField: "groupField",
    meta: {
      valueField: {
        formatter: (v) => formatData(v, fields?.valueField, params).data,
      },
      groupField: {
        formatter: (v) => formatData(v, fields?.groupField, params).data,
      },
      categoryField: {
        formatter: (v) =>
          formatData(v, fields?.categoryField?.at(0), params).data,
      },
    },
    label: {
      //content: ({ value }) => `${value}\n ${config?.unit}`,
      position: "middle",
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
    legend: {
      position: "bottom",
    },
    style: { maxHeight: 250 },
    ...(showDrawer
      ? {
          onEvent: (chart, event) =>
            onChartClickEvent(chart, event, showDrawer),
        }
      : {}),
    tooltip: {
      title(title, datum) {
        return (
          getTranslation(fields?.categoryField?.at(0)?.value, "data") +
          ":    " +
          formatData(datum?.categoryField, fields?.categoryField?.at(0), params)
            .data
        );
      },
      shared: true,
      showMarkers: false,
      formatter: ({ categoryField, valueField }: any) => ({
        name: getTranslation(fields?.valueField?.value, "data"),
        value: valueField
          ? `${
              formatData(valueField, fields?.valueField, {
                unit: config?.unit,
                ...params,
              }).data
            }`
          : "Not set",
      }),
    },

    color:
      config?.pallet_type === CHART_COLOR_TYPES.PALLET
        ? config?.color_pallet?.map((color: any) => color?.color)
        : ({ categoryField: valueField }) =>
            config?.color_pallet?.find(
              (color: any) => color?.value === valueField
            )?.color,
  };
  return <Bar {...my_config} />;
};

export default MyBar;
