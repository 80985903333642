import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(255, 103, 31, 0.2); 
  }
  50% {
    transform: scale(1.05); /* Slightly smaller scale */
    box-shadow: 0 0 10px rgba(255, 103, 31, 0.4); /* intensity */
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(255, 103, 31, 0.2); 
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Button = styled.button`
  position: relative;
  background-color: #ea7600; /* Red color */
  color: white;
  border: none;
  border-radius: 8px;
  width: 65px;
  height: 22px;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  animation: ${pulse} 2s infinite;
  transition: transform 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: scale(1.1);
  }

  &:disabled {
    cursor: not-allowed;
    animation: none; /* Stop animation when disabled */
    transform: none; /* Reset transform when disabled */
  }
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Spinner = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${spin} 1s linear infinite;
`;

interface StopProps {
  loading: any;
  buttonKey: any;
  onClick?: () => void;
}

function AnimatedButton({ loading, onClick, buttonKey }: StopProps) {
  return (
    <Button
      onClick={onClick ? onClick : undefined}
      title="Click to stop the risk"
      disabled={loading}
      key={buttonKey}
      className="orange-gradient-btn"
      style={{
        backgroundColor: "#f5222d",
        borderColor: "#f5222d",
        color: "white",
      }}
    >
      {loading === buttonKey ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        "Stop Risk"
      )}
    </Button>
  );
}

export default AnimatedButton;
