import { Popconfirm, Space, Tag } from "antd";
import { STATUS } from "../../../../../constants/status";
import AnimatedButton from "../../../../common/StopAnimatedButton";
import { getTranslation } from "../../../../../utils/transaltion";
import { Reason } from "../../../../../interfaces/reasons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../../logic/runningShared.logic";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const RunningHistoryVirtualListTitle = (props: any) => {
  const { copilotName, menu, item } = props;
  const [loadingStop, setLoadingStop] = useState<any>(null);
  const { t } = useTranslation();

  const reasonsRisk: Reason[] = useSelector(
    (state: RootState) => state.reasonsRisksReducer
  );

  const { stopRisk } = useSharedContextFunctions() as SharedContextFunctions;

  return (
    <div
      //className="header-content"
      style={{
        marginTop: 0,
        display: "flex",
        flexDirection: window.innerWidth <= 600 ? "column" : "row",
        justifyContent:
          window.innerWidth <= 600 ? "flex-start" : "space-between",
        alignItems: window.innerWidth <= 600 ? "flex-start" : "center",
        width: "100%",
      }}
    >
      <h4
        style={{
          color: "black",
          marginInlineEnd: 0,
          marginBottom: window.innerWidth <= 600 ? "5px" : "0",
        }}
      >
        {item.risk_conf_name}
      </h4>
      <div
        //className="button-container"
        style={{
          marginTop: 0,

          display: "flex",
          flexDirection: window.innerWidth <= 600 ? "column" : "row",
          gap: "5px",
          width: window.innerWidth <= 600 ? "100%" : "auto",
        }}
      >
        <div className="custom-stop-column">
          {reasonsRisk?.find((obj: any) => obj.id === item?.reason?.id)
            ?.name !== "RISK_STOPPED" && item?.status !== STATUS.COMPLETE ? (
            <Popconfirm
              placement="topLeft"
              key="delete"
              disabled={false}
              title={getTranslation("CONFIRM_STOP", "errors")}
              onConfirm={async () => {
                stopRisk(
                  item?.id,
                  "risk_stopped",
                  copilotName,
                  menu,
                  setLoadingStop
                );
              }}
              okText={t("YES")}
              cancelText={t("NO")}
            >
              <Space>
                <AnimatedButton loading={loadingStop} buttonKey={item.id} />
              </Space>
            </Popconfirm>
          ) : (
            <div style={{ marginInlineEnd: -10 }}>
              {item?.status === STATUS.COMPLETE &&
              reasonsRisk?.find((obj: any) => obj.id === item?.reason?.id)
                ?.name === "RISK_STOPPED" ? (
                <Tag className="tag-red" title="Risk already stopped">
                  Stopped
                </Tag>
              ) : item?.status === STATUS.COMPLETE && !item.reason ? (
                <Tag title="Complete" className="tag-blue">
                  Complete
                </Tag>
              ) : (
                <Tag title="Rejected" className="tag-red">
                  {getTranslation(
                    reasonsRisk?.find((obj: any) => obj.id === item?.reason?.id)
                      ?.name || "",
                    "data"
                  )}
                </Tag>
              )}{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
