import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MainContext } from "../../utils/context";
import { InitActions } from "./init/init.PredefinedActions";
import { InitRisks } from "./init/init.PredefinedRisks";
import { InitExecutions } from "./init/init.PredefinedExecutions";
import { InitRoles } from "./init/init.Role";
import { InitCopilot } from "./init/init.copilot";
import { InitUserCopilot } from "./init/init.userCopilot";
import { InitReasonsActions } from "./init/init.reasonsActions";
import { InitReasonsExecutions } from "./init/init.reasonsExecutions";
import PredefinedExecutionService from "../../services/predefined-executions.service";
import PredefinedActionService from "../../services/predefined-actions.service";
import { initializationComplete } from "../reducers/initialization.Reducer";
import { RootState } from "./store";
import { isPermission } from "../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../constants/permissions";
import { InitOrganizations } from "./init/init.organizations";
import { InitRisksGlobal } from "./init/init.PredefinedRisksGlobal";
import { InitActionsGlobal } from "./init/init.PredefinedActionsGlobal";
import { InitExecutionsGlobal } from "./init/init.PredefinedExecutionsGlobal";
import { InitReasonsRisks } from "./init/init.reasonsRisks";
import CopilotsService from "../../services/copilots.service";
import OrganizationsService from "../../services/organizations.service";
import RiskReasonsService from "../../services/risk-reasons.service";
import ActionReasonsService from "../../services/action-reasons.service";
import RolesService from "../../services/roles.service";
import ExecutionReasonsService from "../../services/execution-reasons.service";
import PredefinedRisksService from "../../services/predefined-risks.service";
// import { InitPermission } from "./init/init.permissions";

const InitStore: React.FC = () => {
  const dispatch = useDispatch();
  const globalContext = useContext(MainContext);
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  useEffect(() => {
    if (globalContext) {
      const risksServices = new PredefinedRisksService(
        globalContext.context,
        globalContext.setContext
      );
      const actionsServices = new PredefinedActionService(
        globalContext.context,
        globalContext.setContext
      );
      const executionsServices = new PredefinedExecutionService(
        globalContext.context,
        globalContext.setContext
      );
      const rolesService = new RolesService(
        globalContext.context,
        globalContext.setContext
      );
      const copilotService = new CopilotsService(
        globalContext.context,
        globalContext.setContext
      );
      const reasonsActionsService = new ActionReasonsService(
        globalContext.context,
        globalContext.setContext
      );
      const reasonsExecutionsService = new ExecutionReasonsService(
        globalContext.context,
        globalContext.setContext
      );
      const organizationsServices = new OrganizationsService(
        globalContext.context,
        globalContext.setContext
      );
      const reasonsRisksService = new RiskReasonsService(
        globalContext.context,
        globalContext.setContext
      );

      // Initialize each feature and save the promises
      const initializationPromises: Promise<any>[] = [
        InitActions(actionsServices, dispatch),
        InitRisks(risksServices, dispatch),
        InitExecutions(executionsServices, dispatch),
        InitRoles(rolesService, dispatch),
        InitUserCopilot(copilotService, dispatch),
        InitReasonsActions(reasonsActionsService, dispatch),
        InitReasonsExecutions(reasonsExecutionsService, dispatch),
        InitReasonsRisks(reasonsRisksService, dispatch),

        ...(isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)
          ? [InitCopilot(copilotService, dispatch)]
          : []),
        ...(isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)
          ? [InitOrganizations(organizationsServices, dispatch)]
          : []),

        ...(isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)
          ? [InitRisksGlobal(risksServices, dispatch)]
          : []),
        ...(isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)
          ? [InitActionsGlobal(actionsServices, dispatch)]
          : []),
        ...(isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)
          ? [InitExecutionsGlobal(executionsServices, dispatch)]
          : []),
      ];

      // Wait for all initialization promises to resolve
      Promise.all(initializationPromises)
        .then(() => {
          dispatch(initializationComplete());
        })
        .catch((error) => {
          console.error("Error initializing:", error);
        });
    }
  }, []);

  return null;
};
export default InitStore;
