import React, { useContext, useState } from "react";
import { Tree, Divider, Table, Switch } from "antd";
import { DownCircleOutlined } from "@ant-design/icons";
import {
  IDataNeeded,
  IOptionDataNeeded,
} from "../../../../../interfaces/dataNeededURL";
import { getTranslation } from "../../../../../utils/transaltion";
import { useTranslation } from "react-i18next";
import { SharedContext } from "../../context/sharedContext";
import { WORK_MODE } from "../../../../../constants/workModes";

const { TreeNode } = Tree;

const DataNeededTree: React.FC<{
  dataNeededURL: IDataNeeded;
  setDataNeededURL: React.Dispatch<React.SetStateAction<IDataNeeded>>;
  workMode: string;
  actionParameters: any;
}> = ({ dataNeededURL, setDataNeededURL, workMode, actionParameters }) => {
  const { t } = useTranslation();
  const [expandedKeys, setExpandedKeys] = useState<string[]>([
    "input",
    "output",
  ]);
  const { dynamicFieldsSettings } = useContext(SharedContext);
  const getSettingsNameForType = (key: string, Settings: any) => {
    if (key?.startsWith("NUMBER_")) {
      // Extract the suffix after 'NUMBER_'
      const suffix = key?.substring("NUMBER_".length);
      console.log(suffix);
      let matchingKey: any;
      let name: any;
      if (workMode === WORK_MODE.UPDATE) {
        // Use Object.keys to map over the Settings object
        matchingKey = Object.keys(Settings || {})?.find(
          (paramKey) => paramKey === suffix
        );
        name = Settings[matchingKey]?.name;
      }
      if (workMode === WORK_MODE.CREATE) {
        // Use Object.keys to map over the Settings object
        // Search in the dynamicFieldsSettings array in CREATE mode
        matchingKey = dynamicFieldsSettings.find(
          (field) => field.suffix === suffix
        );

        if (matchingKey) {
          name = matchingKey?.name;
        }
      }

      // Check if a matching key was found
      if (matchingKey) {
        // Return the translated string using the matched object key
        return (
          getTranslation("NUMBER", "data") +
          " (" +
          getTranslation(name, "data") +
          ")"
        );
      }
    }

    return getTranslation(key, "data");
  };
  const handleSwitchChange = (
    value: boolean,
    item: IOptionDataNeeded,
    parent: IOptionDataNeeded
  ) => {
    const updatedItem = { ...item, is_taggable: value };
    // Find the index of the item within the parent's children array
    const index = parent?.children?.findIndex(
      (child) => child.value === updatedItem.value
    );
    // If the index is not found, return without making any changes
    if (index === undefined || index === -1) return;
    // If the item is found, update it with the updatedItem, otherwise return
    if (index !== -1) {
      const updatedParent = {
        ...parent,
        treeData: [
          ...(parent?.children?.slice(0, index) || []), // All items before the updated item
          updatedItem, // Updated item
          ...(parent?.children?.slice(index + 1) || []), // All items after the updated item
        ],
      };
      // Update the dataNeededURL with the updated parent
      // Find the correct array to update based on the parent's value
      const arrayToUpdate = dataNeededURL.OUTPUT.some(
        (input) => input.value === parent.value
      )
        ? dataNeededURL.OUTPUT
        : dataNeededURL.INPUT;

      // Find the index of the parent within the array
      const parentIndex = arrayToUpdate.findIndex(
        (item) => item.value === parent.value
      );

      // If the parent is found, update it within the array
      if (parentIndex !== -1) {
        const updatedArray = [
          ...arrayToUpdate.slice(0, parentIndex), // All items before the parent
          updatedParent, // Updated parent
          ...arrayToUpdate.slice(parentIndex + 1), // All items after the parent
        ];

        // Update the dataNeededURL with the updated array
        const updatedDataNeededURL = {
          ...dataNeededURL,
          [dataNeededURL.OUTPUT.some((input) => input.value === parent.value)
            ? "OUTPUT"
            : "INPUT"]: updatedArray, // Update the correct array
        };
        setDataNeededURL(updatedDataNeededURL);
      }
    }
  };

  const renderTreeNodes = (data: IOptionDataNeeded[]) => {
    return data?.map((item, index) => {
      if (item?.children) {
        const filteredChildren = item.children.filter(
          (child) => child.value !== "CATEGORY"
        );
        const allChildrenAreLeafNodes = filteredChildren.every(
          (child) => !child.children
        );
        if (allChildrenAreLeafNodes) {
          const dataSource = item.children
            .filter((child) => child.value !== "CATEGORY")
            .map((child, idx) => ({
              key: `${child.value}-${idx}`,
              value: child.value,
              type: child.type,
              is_taggable: child?.is_taggable,
            }));

          const columns = [
            {
              title: t("NAME", { item: "" }),
              dataIndex: "value",
              render: (text: any, record: any) => {
                return record.value;
              },
            },
            {
              title: t("TYPE"),
              dataIndex: "type",
              render: (text: any, record: any) => {
                const translatedType = getSettingsNameForType(
                  record.type,
                  actionParameters
                );

                return translatedType;
              },
            },
            {
              title: t("IS_TAGGABLE"),
              dataIndex: "is_taggable",
              render: (text: any, record: any, index: any) => {
                return (
                  <Switch
                    disabled={true}
                    checked={record?.is_taggable}
                    onChange={(value) =>
                      handleSwitchChange(value, record, item)
                    }
                  />
                );
              },
            },
          ];

          return (
            <TreeNode
              key={`${item.value}-${index}`}
              title={
                <label style={{ fontWeight: "bold", color: "#666" }}>
                  {getTranslation(item.value, "data")}(
                  {getTranslation(item.type, "labels")})
                </label>
              }
            >
              <TreeNode
                title={
                  <Table
                    className="custom-table-cell"
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    bordered={true}
                  />
                }
                key={`${item.value}-${index}-table`}
              />
            </TreeNode>
          );
        } else {
          return (
            <TreeNode
              key={`${item.value}-${index}`}
              title={
                <label style={{ fontWeight: "bold", color: "#666" }}>
                  {getTranslation(item.value, "labels")}(
                  {getTranslation(item.type, "labels")})
                </label>
              }
            >
              {renderTreeNodes(item.children)}
            </TreeNode>
          );
        }
      }
    });
  };

  return (
    <>
      <Divider orientation="left" orientationMargin={0}>
        <span
          style={{
            fontWeight: "bold",
            color: "#666",
          }}
        >
          {getTranslation("INPUT", "labels")}
        </span>
      </Divider>
      <Tree
        showLine
        defaultExpandedKeys={expandedKeys}
        switcherIcon={(props: any) => (
          <span style={{ color: "#ff671f", fontSize: "1.4rem" }}>
            {props?.expanded ? (
              <DownCircleOutlined
                style={{ fontSize: "20px", color: "#ff671f" }}
              />
            ) : (
              <DownCircleOutlined
                style={{ fontSize: "20px", color: "#1677ff" }}
              />
            )}
          </span>
        )}
      >
        <TreeNode key="input" title={null}>
          {renderTreeNodes(dataNeededURL?.INPUT)}
        </TreeNode>
      </Tree>
      <Divider orientation="left" orientationMargin={0}>
        <span style={{ fontWeight: "bold", color: "#666" }}>
          {getTranslation("OUTPUT", "labels")}
        </span>
      </Divider>

      <Tree
        showLine
        defaultExpandedKeys={expandedKeys}
        switcherIcon={(props: any) => (
          <span style={{ color: "#ff671f", fontSize: "1.4rem" }}>
            {props?.expanded ? (
              <DownCircleOutlined
                style={{ fontSize: "20px", color: "#ff671f" }}
              />
            ) : (
              <DownCircleOutlined
                style={{ fontSize: "20px", color: "#1677ff" }}
              />
            )}
          </span>
        )}
      >
        <TreeNode key="output" title={null}>
          {renderTreeNodes(dataNeededURL?.OUTPUT)}
        </TreeNode>
      </Tree>
    </>
  );
};

export default DataNeededTree;
