import React, { useState, MouseEvent } from "react";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { HandPointing, Note } from "../../../../../../assets/images";

interface ExpandCollapseArrowProps {
  title: string;
  type: string;
  onToggle?: (isExpanded: boolean, event: MouseEvent<HTMLSpanElement>) => void;
  defaultExpanded?: boolean;
}

const ExpandCollapseArrow: React.FC<ExpandCollapseArrowProps> = ({
  title,
  type,
  onToggle,
  defaultExpanded = false,
}) => {
  // State to track whether it's expanded or collapsed
  const [expanded, setExpanded] = useState(defaultExpanded);

  // Function to handle click and toggle the state
  const handleClick = (e: MouseEvent<HTMLSpanElement>) => {
    const newExpanded = !expanded;
    setExpanded(newExpanded);
    if (onToggle) {
      onToggle(newExpanded, e);
    }
  };

  return (
    <span
      onClick={handleClick}
      style={{
        fontSize: "1.4rem",
        color: "#004c97", // Blue
      }}
    >
      <a
        style={{
          color: "#004c97",
          textDecoration: "underline",
        }}
      >
        {" "}
        {/* {!expanded ? <RightOutlined /> : <DownOutlined />} */}
        {type === "action" ? (
          <HandPointing
            style={{
              //fontSize: "0.4rem",
              color: "#004c97",
              marginBottom: -2,
              marginInlineEnd: 3,
              width: 15,
              height: 15,
            }}
          />
        ) : (
          <Note
            style={{
              color: "#004c97",
              width: 15,
              height: 15,
              marginInlineEnd: 3,
              marginBottom: -2,
            }}
          />
        )}
        {title}{" "}
      </a>
    </span>
  );
};

export default ExpandCollapseArrow;
