import { useContext, useEffect, useRef } from "react";
import { Button, Spin } from "antd";
import type { ActionType } from "@ant-design/pro-components";
import { ProTable } from "@ant-design/pro-components";
import { MainContext } from "../../../../utils/context";
import { WORK_MODE } from "../../../../constants/workModes";
import { SharedContext } from "../context/sharedContext";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/OrganizationLogic";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import OrganizationTableDef from "../columnDef/OrganizationTableDef";

const OrganizationTable: React.FC = () => {
  const globalContext = useContext(MainContext);
  const { t } = useTranslation();
  const actionRef = useRef<ActionType>();
  const {
    getListOrganization,
    goToConfig,
    handleFilterOrganization,
    getEnumsOrganization,
  } = useSharedContextFunctions() as SharedContextFunctionsInterface;
  const {
    setWorkMode,
    getLoading,
    List,
    setList,
    total,
    setDynamicFieldsSettings,
    setDynamicFields,
    form,
  } = useContext(SharedContext);

  useEffect(() => {
    if (!globalContext || !globalContext.context.orga) {
      return;
    }
    setList([]);
    getEnumsOrganization();
  }, [globalContext]);

  if (!globalContext) {
    return null;
  }

  const columns = OrganizationTableDef();

  return (
    <Spin spinning={getLoading} size="large" tip="Loading...">
      <ProTable
        columns={columns}
        actionRef={actionRef}
        cardBordered
        dataSource={List}
        request={(params = {}): any => {
          const { name, description, currency } = params;
          handleFilterOrganization({ name, description, currency });
        }}
        onReset={() => {
          getListOrganization();
        }}
        editable={{
          type: "multiple",
        }}
        columnsState={{
          persistenceKey: "pro-table-singe-demos",
          persistenceType: "localStorage",
        }}
        rowKey="id"
        search={{
          span: { xs: 24, sm: 12, md: 12, lg: 10, xl: 9, xxl: 8 },
          searchText: t("SEARCH_TEXT_BUTTON"),
          resetText: t("RESET_TEXT_BUTTON"),
          labelWidth: "auto",
        }}
        options={{
          setting: {
            listsHeight: 400,
          },
        }}
        form={{
          syncToUrl: (values, type) => {
            if (type === "get") {
              return {
                ...values,
              };
            }
            return values;
          },
        }}
        pagination={{
          pageSize: 20,
          total: total,
        }}
        toolBarRender={() => [
          <Button
            type="primary"
            className="button-add-codif"
            icon={<PlusOutlined />}
            onClick={() => {
              setDynamicFields([]);
              setDynamicFieldsSettings([]);
              form.resetFields();
              setWorkMode(WORK_MODE.CREATE);
              goToConfig({}, WORK_MODE.CREATE);
            }}
          >
            {t("ADD_ORGANIZATION")}
          </Button>,
        ]}
        dateFormatter="string"
        headerTitle={t("ORGANIZATION")}
      />
    </Spin>
  );
};

export default OrganizationTable;
