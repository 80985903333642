import { Col, List, Row, Spin, Tag, Typography } from "antd";
import { STATUS } from "../../../../../../constants/status";
import moment from "moment";
import { getTranslation } from "../../../../../../utils/transaltion";
const { Text } = Typography;

export const ExecutionExpandableSection = (props: any) => {
  const { getRiskLoading, currentActionId, orgaDateFormat, dataSource } = props;

  const getStatus = (record: any) => {
    if (record?.status === STATUS.COMPLETE) {
      if (record?.reason) {
        if (record.reason?.name === "UNUSED") {
          return <Tag className="tag-red">Stopped</Tag>;
        } else {
          return <Tag className="tag-red">Rejected</Tag>;
        }
      } else {
        return <Tag className="tag-green">Accepted</Tag>;
      }
    }

    if (record?.status === STATUS.WAIT_FOR_EXECUTION) {
      return <Tag className="tag-blue">In progress</Tag>;
    }
    if (record?.status === STATUS.OPEN) {
      if (currentActionId === record?.id) {
        return <Tag className="tag-blue">In progress</Tag>;
      } else {
        return <Tag color="grey">Waiting</Tag>;
      }
    }
  };

  return (
    <Spin
      spinning={getRiskLoading}
      size="large"
      tip="Loading..."
      style={{ width: "100%" }}
    >
      <List
        style={{
          width: "100%",
          marginInlineStart: 20,
        }}
        header={null}
        size="small"
        bordered={false}
        dataSource={dataSource?.sort(
          (a: any, b: any) =>
            a.execution_conf_priority - b.execution_conf_priority
        )}
        renderItem={(execution: any) => (
          <List.Item style={{ width: "100%" }}>
            <List.Item.Meta
              title={null}
              description={
                <>
                  {/* Data Row */}
                  <Row
                    style={{
                      marginBlock: "0",
                    }}
                  >
                    <Col span={1}></Col>
                    <Col span={5}>
                      <div className="card-item">
                        <Text className="item-value">
                          {execution?.contexts?.at(0)?.decision_time
                            ? moment(
                                execution?.contexts?.at(0)?.decision_time
                              ).format(orgaDateFormat || "DD/MM/YYYY")
                            : "-"}
                        </Text>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="card-item">
                        <Text className="item-value">
                          {execution?.execution_conf_name}
                        </Text>
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="card-item">
                        <span
                          style={{
                            display: "inline-flex",
                          }}
                        >
                          {execution?.contexts?.at(0)?.computed && (
                            <Tag className="tag-light-blue">Automated</Tag>
                          )}
                          {getStatus(execution)}
                        </span>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div
                        style={{
                          marginInlineEnd: -55,
                          marginBlockStart: 10,
                          backgroundColor: "#F5F5F5",
                          borderRadius: 6,
                          marginTop: 0,
                          paddingTop: 3,
                          paddingBottom: 3,
                          paddingInlineEnd: 0,
                        }}
                      >
                        {execution?.status === STATUS.COMPLETE &&
                        execution?.reason? (
                          execution?.reason?.name === "OTHER" ? (
                            <>
                              <Tag
                                className="tag-red"
                                style={{
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {getTranslation(
                                  execution?.reason?.name || "",
                                  "data"
                                )}
                              </Tag>
                            </>
                          ) : (
                            <Tag
                              className="tag-red"
                              style={{
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                                overflowWrap: "break-word",
                              }}
                            >
                              {getTranslation(
                                execution?.reason?.name || "",
                                "data"
                              )}
                            </Tag>
                          )
                        ) : (
                          <Text className="item-value">{"  "}</Text>
                        )}
                      </div>
                    </Col>
                  </Row>
                </>
              }
            />
          </List.Item>
        )}
      />
    </Spin>
  );
};
