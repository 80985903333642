import { Card, Col, Row, Typography } from "antd";
import { getTranslation } from "../../../../../utils/transaltion";
import { getFormatedValue } from "../../../../../utils/datatypesFunctions";
import { useContext, useEffect } from "react";
import { MainContext } from "../../../../../utils/context";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";

import { ActionsExpandableSection } from "./expandable-sections/ActionsExpandableSection";
import { CalendarOutlined } from "@ant-design/icons";
import moment from "moment";
import { SharedContext } from "../../context/SharedContext";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../../logic/runningShared.logic";
import ExpandCollapseArrow from "./expandable-sections/ExpandIcon";

const { Text } = Typography;

export const RunningHistoryVirtualListDescription = (props: any) => {
  const {
    menu,
    item,
    index,
    expandedRowAction,
    getRiskLoading,
    handleRowClick,
    currentActionId,
    setExpandedRowAction,
    setGetRiskLoading,
    isSecondColumnOpen,
  } = props;
  const globalContext = useContext(MainContext);
  const orgaFormatNumber =
    globalContext?.context?.orga?.formatNumber || "en-US";
  const orgaCurrency = globalContext?.context?.orga?.currency || "EUR";
  const orgaDateFormat =
    globalContext?.context?.orga?.formatDate || "DD/MM/YYYY";

  const { setSelectedRisk } = useContext(SharedContext);
  const { getRunningRiskById } =
    useSharedContextFunctions() as SharedContextFunctions;
  /** get predefined risks from redux */
  const predefinedRiskList = useSelector(
    (state: RootState) => state.predefinedRiskOrgaReducer
  );
  const handleExpandActions = (index: number, riskID: string) => {
    //handleRowClick();
    getRiskById(riskID);
    setExpandedRowAction(expandedRowAction === index ? null : index);
  };
  const getRiskById = async (riskId: string) => {
    setSelectedRisk(null);
    setGetRiskLoading(true);
    const risk = await getRunningRiskById(riskId);
    setSelectedRisk(risk);
    setGetRiskLoading(false);
  };
  useEffect(() => {
    if (expandedRowAction !== index) {
      handleRowClick(false);
    }
  }, [expandedRowAction]);
  useEffect(() => {
    setExpandedRowAction(expandedRowAction === index ? null : index);
  }, [menu]);

  return (
    <>
      <Card>
        <Row
          style={{
            marginTop: -20,
            marginInline: "10px",
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Col
            style={{
              flex: "1 1 20%",
              minWidth: "200px",
              maxWidth: "300px",
              margin: "5px 0",
            }}
          >
            <Text style={{ color: "#7E7E7E" }}>Due Date</Text>
            <div className="card-item">
              <CalendarOutlined className="icon-blue" />
              <Text>
                {moment(item?.due_date).format(orgaDateFormat || "DD/MM/YYYY")}
              </Text>
            </div>
          </Col>

          {item?.context &&
            Object.entries(item.context).map(([key, value]) => (
              <Col
                key={key}
                style={{
                  flex: "1 1 20%",
                  minWidth: "200px",
                  maxWidth: "300px",
                  margin: "5px 0",
                }}
              >
                <Text style={{ color: "#7E7E7E" }}>
                  {getTranslation(key, "data")}
                </Text>
                <div className="card-item">
                  <Text>
                    {getFormatedValue(
                      item.context[key],
                      key,
                      {
                        formatNumber: orgaFormatNumber,
                        currency: orgaCurrency,
                        formatDate: orgaDateFormat,
                      },
                      {
                        [key]: {
                          type: predefinedRiskList?.find(
                            (obj: any) => obj.id === item?.risk_model
                          )?.policyData[key].TYPE,
                        },
                      },
                      false
                    )}
                  </Text>
                </div>
              </Col>
            ))}
        </Row>

        <Row
          style={{
            marginTop: 10,
          }}
        >
          <ExpandCollapseArrow
            type="action"
            title={"Actions"}
            onToggle={() => handleExpandActions(index, item?.id)}
            defaultExpanded={expandedRowAction === index}
          />
        </Row>
        {/* expanded actions */}
        <Row
          style={{
            marginBottom: -10,
            width: "100%",
          }}
        >
          {" "}
          {/* Expandable Content */}
          <Col span={24}>
            {" "}
            {expandedRowAction === index && (
              <ActionsExpandableSection
                isSecondColumnOpen={isSecondColumnOpen}
                menu={menu}
                getRiskLoading={getRiskLoading}
                handleRowClick={handleRowClick}
                currentActionId={currentActionId}
                orgaDateFormat={orgaDateFormat}
              />
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};
