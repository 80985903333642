import { setReasonsActions } from "../../reducers/reasonsActions.Reducer";

/** dispatch predefined risk */
export const InitReasonsActions = async (
  reasonsActionsServices: any,
  dispatch: any
) => {
  return reasonsActionsServices
    .getResources({ pageSize: 1000, sort: false })
    .then(async (res: any) => {
      dispatch(setReasonsActions(res?.items));
    })
    .catch((err: any) => {
      console.log(err);
    });
};
