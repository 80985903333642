import dayjs from "dayjs";

import { RuleField } from "./RiskAddRuleModal";
import { DATA_NEEDED_TYPE as DN_TYPE } from "../../../../../../constants/DataNeededTypes";

const dataForm = (rule: RuleField, dataNeeded: any, orga: any) => {
  const formatDate = orga?.formatDate || "YYYY/MM/DD";
  const formatNumber = orga?.formatNumber || "en-US";
  let result = "";

  if (
    typeof rule?.value === "string" &&
    rule.field &&
    Object.keys(dataNeeded).includes(rule.field)
  ) {
    const fieldType = dataNeeded[rule.field]?.TYPE;
    if (fieldType === DN_TYPE.STRING) {
      result = rule.value;
    } else if (fieldType === DN_TYPE.NUMBER) {
      const parsedValue = parseFloat(rule.value);
      result = !Number.isNaN(parsedValue)
        ? parsedValue.toLocaleString(formatNumber)
        : rule.value;
    } else if (fieldType === DN_TYPE.DATE) {
      if (dayjs(rule.value).isValid()) {
        result = dayjs(rule.value).format(formatDate);
      }
    } else {
      result = rule.value;
    }
  }
  return result;
};
export const riskStringFormula = (
  rule: RuleField,
  dataNeeded: any,
  orga: any
): string => {
  if (typeof rule?.value == "string") {
    return rule.field
      ? (
          rule.field +
          rule.operator +
          dataForm(rule, dataNeeded, orga)
        ).toString()
      : "";
  } else
    return rule?.value
      ?.map(
        (nestedRule, index) =>
          `(${riskStringFormula(nestedRule, dataNeeded, orga)}) ${
            index < rule.value?.length - 1 ? rule.operator : ""
          } `
      )
      .join(" ");
};

export const riskStringFormulaComponent = (
  rule: RuleField,
  dataNeeded: any,
  orga: any
): any => {
  if (typeof rule?.value == "string") {
    return (
      <>
        <span
          style={{
            fontWeight: "bold",
            color: "#083970",
            marginInlineStart: "5px",
          }}
        >
          {rule.field}
        </span>
        <span
          style={{
            fontWeight: "bold",
            color: "#ff671f",
            marginInlineStart: "5px",
          }}
        >
          {rule.operator}
        </span>
        <span
          style={{
            fontWeight: "bold",
            color: "#1677ff",
            marginInlineStart: "5px",
          }}
        >
          {dataForm(rule, dataNeeded, orga)}
        </span>
      </>
    );
  } else
    return rule?.value?.map((nestedRule, index) => (
      <>
        <span
          style={{
            fontWeight: "bolder",
            fontSize: 20,
            color: "#606060",
          }}
        >
          {"("}
        </span>
        {riskStringFormulaComponent(nestedRule, dataNeeded, orga)}
        <span
          style={{
            fontWeight: "bolder",
            fontSize: 20,
            color: "#606060",
          }}
        >
          {")"}
        </span>
        <span
          style={{
            fontWeight: "bold",
            marginInlineStart: "5px",
          }}
        >
          {index < rule.value?.length - 1 && rule.operator}
        </span>{" "}
      </>
    ));
};
