import { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../../utils/context";
/* AntD */
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Tag,
  Tooltip,
  message,
} from "antd";
import {
  LockOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
/* Services */
/* Global Vars */
import { WORK_MODE } from "../../../../constants/workModes";
import { PAGES } from "../../../../constants/pages";
import SaveCancel from "../../../common/SaveCancel";
/**redux */
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/store";
import { Reason } from "../../../../interfaces/reasons";
import { MyCollaps } from "../common/automation/components/MyCollapse";
import DndCxt from "../../../common/dnd-list/DnDContext";
import { ProColumns } from "@ant-design/pro-components";
import MenuOptions from "../../../common/menu/MenuOptions";
import { ExecAddAutomationModal } from "../common/automation/executions/ExecAddRuleModal";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../utils/transaltion";
import { AUTOMATION_TYPES } from "../../../../constants/automationType";
import { isPermission } from "../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../constants/permissions";

const { Option } = Select;

function AddExecutionModal(props: any) {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /** translation */
  const { t } = useTranslation();
  const {
    closeExecutionModal,
    actionExecutions,
    setActionExecutions,
    workModeExecution,
    executionRecord,
    updateSource,
    setWorkModeExecution,
    updateExecution,
  } = props;
  const { predefinedActionId } = props;
  /* API Services */
  const globalContext = useContext(MainContext);
  const [showAuto, setShowAuto] = useState(false);
  const [isPredefined, setIsPredefined] = useState(false);
  /** get predefined actions from redux */
  const predefinedActionList = useSelector(
    (state: RootState) => state.predefinedActionOrgaReducer
  );
  /** get predefined risks from redux */
  const predefinedExecutionss = useSelector(
    (state: RootState) => state.predefinedExecutionOrgaReducer
  );
  /** roles from redux */

  const executionRoles = useSelector((state: RootState) => state.roleReducer);
  /**reasons from Redux */
  const reasons: Reason[] = useSelector(
    (state: RootState) => state.reasonsExecutionsReducer
  );
  const [executionsReasons, setExecutionsReasons] = useState<Reason[]>([]);
  const [executionsRoles, setExecutionsRoles] = useState<any[]>(
    [...executionRoles]?.sort((a, b) => a.name.localeCompare(b.name))
  );

  const [predefinedFilteredExecutions, setPredefinedFilteredExecutions] =
    useState<any>([]);

  /* Rules */
  const [actionRules, setActionRules] = useState(
    workModeExecution !== WORK_MODE.CREATE
      ? (executionRecord && executionRecord["autoPolicy"]?.automation) || []
      : []
  );
  const [openRuleModal, setOpenRuleModal] = useState(false);
  const [ruleRecord, setRuleRecord] = useState(
    workModeExecution !== WORK_MODE.CREATE
      ? (executionRecord && executionRecord["autoPolicy"]?.automation) || []
      : []
  );
  const [execId, setExecId] = useState();
  const [openUsedReasonPopup, setOpenUsedReasonPopup] = useState("");
  const [workModeAutomation, setWorkModeAutomation] = useState(
    WORK_MODE.CREATE
  );
  const [isAutoModalDirty, setIsAutoModalDirty] = useState(false);

  const showRuleModal = () => {
    setOpenRuleModal(true);
  };
  const closeRuleModal = () => {
    setIsAutoModalDirty(false);
    setOpenRuleModal(false);
  };

  /** filter  predefined actions */
  useEffect(() => {
    if (!globalContext || !globalContext.context.orga) {
      return;
    }
    getReasonsCodes();
    filterPredefinedExecutions();
    if (
      workModeExecution === WORK_MODE.UPDATE ||
      workModeExecution === WORK_MODE.VIEW
    ) {
      form_execution.setFieldsValue({
        model: executionRecord?.model?.id,
      });
      filterRoles(executionRecord?.model?.id);
      filterReasons(executionRecord?.model?.id);
      setIsPredefined(!!executionRecord?.model?.id);

      // check if predefind Exec is auto
      setShowAuto(
        predefinedExecutionss?.find(
          (model: any) => model.id === executionRecord?.model?.id
        )?.canAuto
      );
    }
    if (workModeExecution !== WORK_MODE.CREATE) {
      setSelectedReasons(executionRecord?.reasons?.map((r: any) => r.id));
    }
  }, [workModeExecution, executionRecord]);

  const filterPredefinedExecutions = () => {
    if (predefinedActionId) {
      const filteredAction = predefinedActionList?.find(
        (item: any) => item.id === predefinedActionId
      );

      const execIds = filteredAction?.executions?.map((exec: any) => exec.id);

      const filteredExec = predefinedExecutionss
        ?.filter((item: any) => execIds?.includes(item?.id))
        ?.filter((item: any) => item?.enabled === true);
      setPredefinedFilteredExecutions(filteredExec);
    }
  };
  /** filter resons by predefined Exec  */
  const filterReasons = (predefinedId: any) => {
    setExecId(predefinedId);
    const filteredPredefinedExecution: any = predefinedExecutionss?.find(
      (model: any) => model.id === predefinedId
    );

    const reaonsIds = filteredPredefinedExecution?.reasons?.map(
      (item: any) => item.id
    );

    const filteredreasons = reasons?.filter((item: any) =>
      reaonsIds?.includes(item?.id)
    );
    //setExecutionsReasons(filteredreasons);
    setExecutionsReasons(filteredreasons);

    setSelectedReasons(filteredreasons?.map((r: any) => r.id));
  };
  /** filter role by predefined Exec  */
  const filterRoles = (predefinedId: any) => {
    //setExecId(predefinedId);
    const filteredPredefinedExecution: any = predefinedExecutionss?.find(
      (model: any) => model.id === predefinedId
    );

    const rolesIds = filteredPredefinedExecution?.roles?.map(
      (item: any) => item.id
    );

    const filteredRoles = executionRoles?.filter((item: any) =>
      rolesIds?.includes(item?.id)
    );

    setExecutionsRoles(filteredRoles);
  };
  /* Execution Form */
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [executionIsAuto, setExecutionAuto]: any = useState(
    workModeExecution === WORK_MODE.CREATE ? false : executionRecord?.auto
  );
  const [executionIsDisable, setExecutionDisable]: any = useState(
    workModeExecution === WORK_MODE.CREATE ? false : executionRecord?.enabled
  );

  useEffect(() => {
    setPredefinedFilteredExecutions(predefinedExecutionss);
    setExecutionDisable(
      workModeExecution === WORK_MODE.CREATE ? false : executionRecord?.enabled
    );

    form_execution.setFieldsValue({
      name: executionRecord?.name,
      model: executionRecord?.model?.id,
      roles: executionRecord?.roles?.map((role: any) => role?.id),
      // reasons: executionRecord?.map((reason: any) => reason?.id),
    });
    setActionRules(
      workModeExecution !== WORK_MODE.CREATE
        ? (executionRecord && executionRecord["autoPolicy"]?.automation) || []
        : []
    );

    setRuleRecord(
      workModeExecution !== WORK_MODE.CREATE
        ? (executionRecord && executionRecord["autoPolicy"]?.automation) || []
        : []
    );
    setExecutionAuto(
      workModeExecution === WORK_MODE.CREATE ? false : executionRecord?.auto
    );
    setExecutionDisable(
      workModeExecution === WORK_MODE.CREATE ? false : executionRecord?.enabled
    );
  }, [executionRecord]);

  const set_executionAuto = () => {
    setExecutionAuto(!executionIsAuto);
  };
  const set_executionDisable = () => {
    setExecutionDisable(!executionIsDisable);
  };
  const set_confirmLoading = (loading: boolean) => {
    setConfirmLoading(loading);
  };
  // useEffect(() => {
  //

  // }, [executionsReasons]);
  useEffect(() => {
    if (selectedReasons?.length > 0)
      form_execution.setFieldValue("reasons", selectedReasons);
  }, [selectedReasons]);

  /* Execution Services */
  const [form_execution] = Form.useForm();
  if (!globalContext) {
    return <></>;
  }

  const handleWorkModeChange = async () => {
    setWorkModeExecution(WORK_MODE.UPDATE);
  };
  const getReasonsCodes = async () => {
    setExecutionsReasons(
      executionsReasons?.sort((a: any, b: any) => a.name.localeCompare(b.name))
    );
    if (workModeExecution === WORK_MODE.CREATE) {
      setSelectedReasons(
        executionsReasons
          ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
          ?.map((reason: any) => reason.id)
      );
    }
  };

  const handleCreateUpdateExecution = async () => {
    form_execution
      .validateFields()
      .then(async (values) => {
        set_confirmLoading(true);
        const execution = form_execution.getFieldsValue();
        execution["enabled"] = executionIsDisable;
        execution["auto"] = executionIsAuto;
        execution["roles"] = execution.roles?.map((role: any) => ({
          id: role,
        }));
        execution["reasons"] = execution["reasons"].map((reason: any) => ({
          id: reason,
        }));

        if (workModeExecution === WORK_MODE.CREATE) {
          execution["model"] = { id: execution.model };
          execution["key"] = actionExecutions.length + 1;
          execution["priority"] = execution["key"];
          execution["autoPolicy"] = {
            ...execution["autoPolicy"],
            automation: actionRules,
          };
          setActionExecutions([...actionExecutions, execution]);
          closeExecutionModal();
        } else if (workModeExecution === WORK_MODE.UPDATE) {
          execution["model"] = executionRecord?.model;
          execution["priority"] = executionRecord?.priority;
          execution["autoPolicy"] = {
            ...execution["autoPolicy"],
            automation: actionRules,
          };
          if (updateSource === PAGES.ACTIONS) {
            setActionExecutions(
              actionExecutions.map((existExecution: any) => {
                if (existExecution?.key === executionRecord?.key) {
                  return { ...existExecution, ...execution };
                }
                return existExecution;
              })
            );

            closeExecutionModal();
          } else if (updateSource === PAGES.EXECUTIONS) {
            updateExecution({ ...execution })
              .then(() => {
                setActionExecutions(
                  actionExecutions.map((existExecution: any) => {
                    if (existExecution?.id === executionRecord?.id) {
                      return { ...existExecution, ...execution };
                    }
                    return existExecution;
                  })
                );
                message.success(t("errors:UPDATE_SUCCESS"));
                closeExecutionModal();
              })
              .catch((err: any) => {
                message.error(t("errors:UPDATE_FAILED"));
              });
          }
        }
        set_confirmLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        set_confirmLoading(false);
        if (err?.code === "ERR_NETWORK") {
          message.error(t("errors:NETWORK_ERROR"));
        } else {
          message.error(t("errors:CREATE_FAILED"));
        }
      });
  };

  const AutomationRulesColumns: ProColumns<any>[] = [
    {
      key: "sort",
      width: 60,
    },
    {
      render: (text, record, index) => index + 1,
      width: 60,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record, index) => (
        <>
          {t(record?.action) +
            " " +
            (record?.reason
              ? t("REASON") +
                " :" +
                getTranslation(
                  reasons
                    .find((r) => r.id === record?.reason)
                    ?.name?.toString() || "",
                  "data"
                )
              : "")}
        </>
      ),
    },
    {
      title: "More Risks",
      valueType: "option",
      key: "option",
      width: "20%",
      align: "center",
      render: (text, record) => (
        <span>
          <MenuOptions
            viewRecord={() => {
              setRuleRecord(record);
              setWorkModeAutomation(WORK_MODE.VIEW);
              showRuleModal();
            }}
            editRecord={async () => {
              setRuleRecord(record);
              setWorkModeAutomation(WORK_MODE.UPDATE);
              showRuleModal();
            }}
            deleteRecord={() => {
              setActionRules(
                actionRules.filter((execution: any) =>
                  execution?.id
                    ? execution?.id !== record.id
                    : execution?.key !== record.key
                )
              );
            }}
            title={t("errors:CONFIRM_DELETE_MESSAGE")}
            mode={workModeExecution}
          />
        </span>
      ),
    },
  ];

  return (
    <>
      <Form
        name="wrap"
        form={form_execution}
        {...{ labelCol: { style: { width: "auto" } } }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        initialValues={
          workModeExecution === WORK_MODE.CREATE
            ? {}
            : {
                name: executionRecord?.name,
                model: executionRecord?.model?.id,
                roles: executionRecord?.roles?.map((role: any) => role?.id),
                reasons: executionRecord?.reasons?.map(
                  (reason: any) => reason?.id
                ),
              }
        }
        //colon={false}
      >
        <div
          style={{
            marginTop: 10,
            display: "flex",
            marginBottom: 5,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginTop: 10 }}>
            {t("DETAILS", { item: t("EXECUTION") })}
          </div>
          <div style={{ marginRight: "50px", marginTop: 1 }}>
            {" "}
            {workModeExecution === WORK_MODE.VIEW && (
              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onClick={() => handleWorkModeChange()}
                icon={<EditOutlined size={6} style={{ color: "#004C97" }} />}
              ></Button>
            )}
          </div>
        </div>
        <div style={{ marginInline: "50px" }}>
          <Row>
            {/* Action Name */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={t("NAME", { item: "" })}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("errors:REQUIRED", {
                      label: t("NAME", { item: t("EXECUTION") }),
                    }),
                  },
                ]}
              >
                <Input readOnly={workModeExecution === WORK_MODE.VIEW} />
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row>
            {/* Predefined Execution */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={t("EXECUTION_PREDEFINED")}
                name="model"
                rules={[
                  {
                    required: true,
                    message: t("errors:REQUIRED", {
                      label: t("NAME", { item: t("EXECUTION_PREDEFINED") }),
                    }),
                  },
                ]}
              >
                <Select
                  disabled={
                    workModeExecution !== WORK_MODE.CREATE ||
                    actionRules.length > 0
                  }
                  showSearch
                  onChange={(value) => {
                    setIsPredefined(true);
                    filterReasons(value);
                    filterRoles(value);
                    setShowAuto(
                      predefinedFilteredExecutions?.find(
                        (model: any) => model.id === value
                      )?.canAuto
                    );
                    form_execution.setFieldValue("roles", null);
                  }}
                  filterOption={(input, option) =>
                    option?.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {predefinedFilteredExecutions?.map((item: any) => (
                    <Option key={item?.id} value={item?.id}>
                      {getTranslation(item?.name, "data")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row>
            {/* Role */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={t("ROLES")} name="roles">
                <Select
                  disabled={workModeExecution === WORK_MODE.VIEW}
                  mode="multiple"
                  showSearch
                  onChange={(value) => {}}
                  filterOption={(input, option) =>
                    option?.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {executionsRoles?.map((item: any) => (
                    <Option key={item?.id} value={item?.id}>
                      {getTranslation(item?.name, "data")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row>
            {/* Reason Code(s) */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={t("REASON_CODE")} name="reasons">
                <Select
                  disabled={workModeExecution === WORK_MODE.VIEW}
                  mode="multiple"
                  filterOption={(input, option) =>
                    option?.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onDeselect={(value) => {
                    // Remove the deselected item from selectedReasons
                    const deselectedItem: any = executionsReasons.find(
                      (obj: any) => obj.id === value
                    );
                    const reasonIsUsed = actionRules
                      .map((rule: any) => rule.reason)
                      ?.includes(value);
                    if (deselectedItem?.is_system || reasonIsUsed) {
                      if (reasonIsUsed) setOpenUsedReasonPopup(value);
                      setSelectedReasons(selectedReasons.concat());
                    } else {
                      setSelectedReasons(
                        selectedReasons.filter(
                          (item) => item !== deselectedItem?.id
                        )
                      );
                    }
                  }}
                  onSelect={(value) => {
                    // Add the selected item to selectedReasons
                    setSelectedReasons((prevSelectedReasons) =>
                      prevSelectedReasons.concat(value)
                    );
                  }}
                  tagRender={(props: any) => {
                    const { label, value, closable, onClose } = props;
                    const is_sys = executionsReasons.find(
                      (obj: any) => obj.id === value
                    )?.is_system;
                    return (
                      <Popover
                        open={
                          actionRules
                            .map((rule: any) => rule.reason)
                            ?.includes(value) && openUsedReasonPopup === value
                        }
                        onOpenChange={() => {
                          setOpenUsedReasonPopup("");
                        }}
                        content={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row-reverse",
                            }}
                          >
                            <a onClick={() => setOpenUsedReasonPopup("")}>
                              Close
                            </a>
                          </div>
                        }
                        title={
                          <span>
                            <ExclamationCircleOutlined
                              style={{ color: "orange", marginRight: "8px" }}
                            />
                            {t("errors:POPOVER_USED_IN_AUTO_RULES")}
                          </span>
                        }
                        trigger="click"
                      >
                        <Tag
                          color={is_sys ? "red" : "blue"}
                          closable={
                            !is_sys && workModeExecution !== WORK_MODE.VIEW
                          }
                          onClose={onClose}
                        >
                          {label}
                          {is_sys ? (
                            <LockOutlined style={{ marginInline: "4px" }} />
                          ) : null}
                        </Tag>
                      </Popover>
                    );
                  }}
                >
                  {executionsReasons
                    ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                    .map((item: any) => (
                      <Option key={item?.id} value={item?.id}>
                        {getTranslation(item?.name, "data")}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* Action IsDisable */}
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <Form.Item>
                <Tooltip
                  placement="left"
                  title={
                    !isPredefined
                      ? t("errors:SELECT_PREDEFINED_EXECUTION_FIRST")
                      : ""
                  }
                >
                  <Checkbox
                    disabled={
                      workModeExecution === WORK_MODE.VIEW || !isPredefined
                    }
                    checked={executionIsDisable}
                    onChange={() => {
                      set_executionDisable();
                    }}
                  >
                    {t("ENABLE")}
                  </Checkbox>
                </Tooltip>
              </Form.Item>
            </Col>
            {/* Action IsAuto */}
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <Form.Item>
                {false && showAuto && (
                  <Checkbox
                    disabled={
                      (true && workModeExecution === WORK_MODE.VIEW) ||
                      !isPredefined
                    }
                    checked={false && executionIsAuto}
                    onChange={() => {
                      set_executionAuto();
                    }}
                  >
                    {t("AUTO")}
                  </Checkbox>
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>

        {false && executionIsAuto && showAuto && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ marginBlockEnd: "20px" }}
          >
            <MyCollaps
              title="Automation"
              childs={
                <div>
                  <div
                    style={{
                      overflow: "auto",
                      maxHeight: "130px",
                    }}
                  >
                    <DndCxt
                      columns={AutomationRulesColumns}
                      dataSource={actionRules}
                      setDataSource={setActionRules}
                    />
                  </div>
                  <Row>
                    {/* Action Name */}
                    {workModeExecution !== WORK_MODE.VIEW && (
                      <Col xs={4} sm={4} md={4} lg={4} xl={4} offset={20}>
                        <Button
                          className="button-primary"
                          type="primary"
                          onClick={() => {
                            setWorkModeAutomation(WORK_MODE.CREATE);
                            showRuleModal();
                          }}
                        >
                          {t("ADD_BUTTON_TEXT")} {t("RULE")}{" "}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              }
            ></MyCollaps>
          </Col>
        )}

        {workModeExecution !== WORK_MODE.VIEW &&
          isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) && (
            <Row>
              <Form.Item>
                <SaveCancel
                  confirmLoading={confirmLoading}
                  onClickSave={() => {
                    handleCreateUpdateExecution();
                  }}
                  onClickCancel={() => {
                    closeExecutionModal();
                  }}
                />
              </Form.Item>
            </Row>
          )}
      </Form>

      {/* ADD Rule Modal */}
      <Modal
        destroyOnClose={true}
        title="Automation rule"
        open={openRuleModal}
        onCancel={() => {
          if (isAutoModalDirty) {
            Modal.confirm({
              title: "Confirm",
              content: t("errors:UNSAVED_CHANGES_LEAVING_MESSAGE"),
              onOk: () => {
                closeRuleModal();
              },
              onCancel: () => {},
              okText: t("YES"),
              cancelText: t("NO"),
            });
          } else {
            closeRuleModal();
          }
        }}
        width={1000}
        footer={null}
      >
        <ExecAddAutomationModal
          closeAutomationModal={closeRuleModal}
          actionAutomations={actionRules}
          setActionAutomations={setActionRules}
          workModeAutomation={workModeAutomation}
          automationRecord={ruleRecord}
          setIsAutoModalDirty={setIsAutoModalDirty}
          isAutoModalDirty={isAutoModalDirty}
          setWorkModeExecution={setWorkModeExecution}
          updateSource={PAGES.ACTIONS}
          actionsReasons={executionsReasons.filter((r) =>
            selectedReasons?.includes(r?.id.toString())
          )}
          dataNeeded={{
            ...((workModeExecution === WORK_MODE.CREATE
              ? predefinedFilteredExecutions?.find(
                  (model: any) => model.id === execId
                )?.dataNeeded || {}
              : executionRecord?.model?.dataNeeded) || {}),
          }}
          automationtype={AUTOMATION_TYPES.EXECUTION}
        />
      </Modal>
    </>
  );
}
export default AddExecutionModal;
