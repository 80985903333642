import { Line, LineConfig } from "@ant-design/plots";

const AcceptanceAutomationRateOverTime = (props: any) => {
  let { data } = props;

  const config: LineConfig = {
    data,
    xField: "risk_date",
    yField: "value",
    seriesField: "type",
    connectNulls: true,
    color: ({ type }: any) => {
      return type === "Acceptance" ? "#41B8D5" : "blue";
    },
    lineStyle: ({ type }: any) => {
      if (type === "Acceptance") {
        return {
          lineDash: [4, 4],
          opacity: 1,
        };
      }

      return {
        opacity: 0.5,
      };
    },
    tooltip: {
      shared: true,
      showMarkers: false,
      formatter: ({ type, value }: any) => ({
        name: type,
        value: value ? `${value?.toFixed(2)} %` : "Not set",
      }),
    },
    legend: {
      position: "bottom",
    },
  };

  return <Line {...config} height={300} />;
};

export default AcceptanceAutomationRateOverTime;
