import { setOrganizations } from "../../reducers/organizations.Reducer";

export const InitOrganizations = async (
  organizationServices: any,
  dispatch: any
) => {
  return organizationServices
    .getResources({ pageSize: 1000 }, ["copilot", "copilots", "actions", ""])
    .then(async (res: any) => {
      dispatch(setOrganizations(res?.items));
    })
    .catch((err: any) => {
      console.log(err);
    });
};
