import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

type Organization = {
  id: string;
  name: string;
};
const initialState: Array<Organization> = [];

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setOrganizations: (state, action: PayloadAction<Array<Organization>>) => {
      return action.payload;
    },
  },
});
export const { setOrganizations } = organizationsSlice.actions;

export const OrganizationsSelector = (state: RootState) =>
  state.organizationsReducer;

export default organizationsSlice.reducer;
