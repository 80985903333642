import { useState } from "react";
import {
  Button,
  Cascader,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tag,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { t } from "i18next";

import { ActionFields } from "../constants/configs";
import { WORK_MODE } from "../../../../../constants/workModes";
import { defaultStatConf, StatConf } from "../interfaces/StatConf";
import { stringFormulaComponent } from "../charts/chart-filter/StringFormula";
import ChartFilterModal from "../charts/chart-filter/ChartFilterModal";

interface EditFiltersModalProps {
  copilotContextFields: any;
  workMode: string;
  recordStatConf: StatConf | undefined;
  recordIndexStatConf: number | undefined;
  createStatConf: (value: StatConf) => void;
  updateStatConf: (value: StatConf, index: number) => void;
  closeStatModal: () => void;
}

export default function StatModal(props: EditFiltersModalProps) {
  const {
    copilotContextFields,
    workMode,
    recordStatConf,
    recordIndexStatConf,
    createStatConf,
    updateStatConf,
    closeStatModal,
  } = props;

  const [currentRecord, setCurrentRecord] = useState<any>(
    workMode === WORK_MODE.CREATE ? defaultStatConf : recordStatConf
  );
  const [openChartFilterModal, setOpenChartFilterModal] = useState(false);

  const onChangeCurrentRecord = (conf: any) => {
    const new_conf = { ...currentRecord, ...conf };
    setCurrentRecord(new_conf);
  };

  const closeStatFilterModal = () => {
    setOpenChartFilterModal(false);
  };
  const callStatFilterModal = () => {
    setOpenChartFilterModal(true);
  };

  return (
    <>
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        labelAlign="left"
        labelWrap
      >
        <p style={{ color: "#b0b0b0", marginBlockEnd: "5px" }}>
          Statistic configurations :
        </p>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={"Title"}
              name="title"
              rules={[{ required: true }]}
            >
              <Input
                disabled={workMode === WORK_MODE.VIEW}
                defaultValue={currentRecord?.title}
                value={currentRecord?.title}
                onChange={(e) => {
                  const v = e.target.value.toString();
                  onChangeCurrentRecord({
                    title: v,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item label={"Unit"} name="unit">
              <Input
                disabled={workMode === WORK_MODE.VIEW}
                defaultValue={currentRecord?.unit}
                value={currentRecord?.unit}
                onChange={(e) => {
                  const v = e.target.value.toString();
                  onChangeCurrentRecord({
                    unit: v,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={"Data field"}
              name="field"
              rules={[{ required: true }]}
            >
              <Cascader
                style={{ width: "95%" }}
                options={copilotContextFields}
                displayRender={(labels, selectedOptions = []) => {
                  return labels.join(".");
                }}
                value={currentRecord?.field?.split(".")}
                defaultValue={currentRecord?.field?.split(".")}
                onChange={(value, option: any) => {
                  const value_ = value?.join(".");
                  console.log(value_);
                  onChangeCurrentRecord({ field: value_, filter: "" });
                }}
                fieldNames={{
                  label: "value",
                  value: "value",
                  children: "children",
                }}
              />
              {/* <Select
                disabled={workMode === WORK_MODE.VIEW}
                defaultValue={currentRecord?.field}
                value={currentRecord?.field}
                onChange={(val) => {
                  console.log(val);
                  onChangeCurrentRecord({ field: val, filter: "" });
                }}
                dropdownStyle={{ width: "30%" }}
              >
                {Object.entries(copilotContextFields)?.map(([item, value]) => (
                  <Select.Option key={item} value={item}>
                    <span>
                      {item} <Tag color="blue">{value.TYPE}</Tag>
                    </span>
                  </Select.Option>
                ))}
              </Select> */}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label={"Operation"}
              name="calcul"
              rules={[{ required: true }]}
            >
              <Select
                disabled={workMode === WORK_MODE.VIEW}
                defaultValue={currentRecord?.operation}
                value={currentRecord?.operation}
                onChange={(val) => {
                  onChangeCurrentRecord({ operation: val });
                }}
                dropdownStyle={{ width: "auto" }}
                options={[
                  { value: "Sum", label: <span>Sum</span> },
                  { value: "Avg", label: <span>AVG</span> },
                  { value: "wAVG", label: <span>weighted AVG</span> },
                ]}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={"Filters"}
              name="filters"
              required
              tooltip="How to use .........."
            >
              <>
                {stringFormulaComponent(
                  currentRecord?.filter,
                  copilotContextFields[currentRecord?.field]?.TYPE,
                  undefined
                )}
              </>
              {workMode !== WORK_MODE.VIEW && (
                <EditOutlined
                  style={{
                    marginInlineStart: "5px",
                    color: "#1677ff",
                    fontSize: "16px",
                  }}
                  onClick={() => callStatFilterModal()}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginTop: "20px" }}>
            <Form.Item>
              <Space>
                <Button
                  onClick={() => {
                    closeStatModal();
                  }}
                >
                  {t("CANCEL")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="save-button"
                  onClick={() => {
                    if (workMode === WORK_MODE.CREATE) {
                      createStatConf(currentRecord);
                    } else if (workMode === WORK_MODE.UPDATE) {
                      recordIndexStatConf &&
                        updateStatConf(currentRecord, recordIndexStatConf);
                    }
                  }}
                >
                  {t("SAVE")}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        destroyOnClose={true}
        title={"Chart filter"}
        open={openChartFilterModal}
        onCancel={closeStatFilterModal}
        width={800}
        footer={null}
      >
        <ChartFilterModal
          onChangeFilterField={onChangeCurrentRecord}
          dataNeeded={copilotContextFields}
          filterField={currentRecord}
          fieldType={copilotContextFields[currentRecord?.field]?.TYPE}
          closeChartFilterModal={closeStatFilterModal}
        />
      </Modal>
    </>
  );
}
