import { configureStore } from "@reduxjs/toolkit";
import predefinedRiskGlobalReducer from "../reducers/predefinedRisksGlobal.Reducer";
import predefinedActionGlobalReducer from "../reducers/predefinedActionsGlobal.Reducer";
import predefinedExecutionGlobalReducer from "../reducers/predefinedExecutionsGlobal.Reducer";
import predefinedRiskOrgaReducer from "../reducers/predefinedRisksOrga.Reducer";
import predefinedActionOrgaReducer from "../reducers/predefinedActionsOrga.Reducer";
import predefinedExecutionOrgaReducer from "../reducers/predefinedExecutionsOrga.Reducer";
import roleReducer from "../reducers/roles.Reducer";
import copilotReducer from "../reducers/copilot.Reducer";
import userCopilotReducer from "../reducers/userCopilot.Reducer";
import reasonsActionsReducer from "../reducers/reasonsActions.Reducer";
import reasonsExecutionsReducer from "../reducers/reasonsExecutions.Reducer";
import permissionReducer from "../reducers/permissions.Reducer";
import initializationReducer from "../reducers/initialization.Reducer";
import organizationsReducer from "../reducers/organizations.Reducer";
import reasonsRisksReducer from "../reducers/reasonsRisks.Reducer";

export const store: any = configureStore({
  reducer: {
    predefinedRiskOrgaReducer,
    predefinedActionOrgaReducer,
    predefinedExecutionOrgaReducer,
    predefinedRiskGlobalReducer,
    predefinedActionGlobalReducer,
    predefinedExecutionGlobalReducer,
    roleReducer,
    copilotReducer,
    reasonsActionsReducer,
    reasonsExecutionsReducer,
    reasonsRisksReducer,
    permissionReducer,
    userCopilotReducer,
    organizationsReducer,
    initializationReducer,
  },
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
