export function daytoDateSinceReference(
  numberOfDays: number,
  referenceDate = 25569
): Date {
  const date = new Date((numberOfDays - referenceDate) * 24 * 60 * 60 * 1000);
  return date;
}

export function getMonthNameFromDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = { month: "long" };
  return date.toLocaleDateString(undefined, options);
}

export function getWeekNumber(date: any): number {
  const onejan: any = new Date(date.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(
    ((date - onejan) / 86400000 + onejan.getDay() + 1) / 7
  );
  return weekNumber;
}

export function getWeek(date: Date): Date {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
}
