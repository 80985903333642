import { useContext } from "react";
import { Alert } from "antd";
import moment from "moment";

import { LicenseItem } from "../../../interfaces/license";
import { MainContext } from "../../../utils/context";

import { WORK_MODE } from "../../../constants/workModes";
import { PREDEFINED_TYPES } from "../../../constants/predefinedTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/store";
import { getTranslation } from "../../../utils/transaltion";
import { useTranslation } from "react-i18next";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../organization/logic/OrganizationLogic";

export default function LicenseWarnning() {
  const { t } = useTranslation();
  const { goToConfig, getActionParameters } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  const globalContext = useContext(MainContext);
  const copilotList: any[] = useSelector(
    (state: RootState) => state.userCopilotReducer
  );

  const formatDate = globalContext?.context.orga?.formatDate || "YYYY/MM/DD";
  /* Manage organization license */
  const orgaLicenses: LicenseItem[] =
    globalContext?.context?.orga?.licenses || [];

  const currentOrgaLicense: LicenseItem | undefined = orgaLicenses.length
    ? [...orgaLicenses]
        ?.sort((a, b) => moment(a.start).diff(moment(b.start)))
        ?.find(
          (license) =>
            moment(license?.start)
              .startOf("day")
              .isBefore(moment().endOf("day")) &&
            moment(license?.expiry)
              .endOf("day")
              .isAfter(moment().startOf("day"))
        )
    : undefined;

  const expiredOrgaLicense: LicenseItem | undefined = orgaLicenses.length
    ? [...orgaLicenses]
        ?.sort((a, b) => moment(a.expiry).diff(moment(b.expiry)))
        .find((license) =>
          moment(license?.expiry).endOf("day").isBefore(moment().startOf("day"))
        )
    : undefined;

  const nextOrgaLicense: LicenseItem | undefined = orgaLicenses.length
    ? [...orgaLicenses]
        ?.sort((a, b) => moment(a.start).diff(moment(b.start)))
        ?.find((license) =>
          moment(license?.start).startOf("day").isAfter(moment().endOf("day"))
        )
    : undefined;

  const goToOrgaDetails = () => {
    const myOrga = JSON.parse(JSON.stringify(globalContext?.context?.orga));
    getActionParameters(WORK_MODE.VIEW, globalContext?.context?.orga);
    goToConfig(myOrga, WORK_MODE.VIEW, true);
  };

  const Details = (
    <span
      onClick={() => goToOrgaDetails()}
      style={{ color: "#004c97", cursor: "pointer" }}
    >
      {t("errors:MORE_DETAILS")}
    </span>
  );
  return (
    <>
      {globalContext?.context?.orga &&
        (currentOrgaLicense ? (
          moment(currentOrgaLicense?.expiry).diff(moment(), "days") < 30 && (
            <span
              style={{
                width: "100%",
              }}
            >
              <Alert
                style={{
                  marginInline: "8px",
                  marginBlock: "4px",
                  borderInlineStart: `4px solid ${"#faad14"}`,
                }}
                message={t("errors:LICENSE_WARNING")}
                description={
                  <span>
                    {t("errors:YOUR_CURRENT_LICENSE")}
                    {" ("}
                    {currentOrgaLicense?.copilots
                      ?.map((copilot) =>
                        getTranslation(
                          copilotList?.find((c) => c?.id === copilot.id)?.name,
                          "data"
                        )
                      )
                      .join(", ")}
                    {") "}
                    {t("errors:WILL_EXPIRE_IN")}{" "}
                    {moment(currentOrgaLicense?.expiry).diff(moment(), "days") +
                      1}{" "}
                    {t("labels:DAYS")}, {Details}
                  </span>
                }
                type={"warning"}
                showIcon
                closable
              />
            </span>
          )
        ) : expiredOrgaLicense ? (
          <span
            style={{
              width: "100%",
            }}
          >
            <Alert
              style={{
                marginInline: "8px",
                marginBlock: "4px",
                borderInlineStart: `4px solid ${"red"}`,
              }}
              message={t("errors:LICENSE_EXPIRED")}
              description={
                <span>
                  {t("errors:YOUR_LICENSE")} {" ("}
                  {expiredOrgaLicense?.copilots
                    ?.map((copilot) =>
                      getTranslation(
                        copilotList?.find((c) => c?.id === copilot.id)?.name,
                        "data"
                      )
                    )
                    .join(", ")}
                  {") "} {t("errors:EXPIRED_ON")}{" "}
                  {moment(expiredOrgaLicense?.expiry).format(formatDate)},
                  {!nextOrgaLicense
                    ? t(
                        "errors:CONTACT_YOUR_ADMINISTRATOR_TO_UPDATE_YOUR_LICENSE"
                      ) + ","
                    : ""}
                  {nextOrgaLicense
                    ? t("errors:YOUR_NEXT_LICENSE") +
                      " (" +
                      nextOrgaLicense?.copilots
                        ?.map((copilot) =>
                          getTranslation(
                            copilotList?.find((c) => c?.id === copilot.id)
                              ?.name,
                            "data"
                          )
                        )
                        .join(", ") +
                      ")  " +
                      t("errors:WILL_START_ON") +
                      moment(nextOrgaLicense?.start).format(formatDate) +
                      ", "
                    : ""}
                  {Details}
                </span>
              }
              type={"error"}
              showIcon
            />
          </span>
        ) : (
          <span
            style={{
              width: "100%",
            }}
          >
            <Alert
              style={{
                marginInline: "8px",
                marginBlock: "4px",
                borderInlineStart: `4px solid red`,
              }}
              message={t("errors:LICENSE_NOT_FOUND")}
              description={
                <span>
                  {t(
                    "errors:YOUR_ORGANIZATION_DOESNT_HAVE_A_LICENSE_TO_USE_COPILOTS"
                  )}
                  ,
                  {t(
                    "errors:CONTACT_YOUR_ADMINISTRATOR_TO_UPDATE_YOUR_LICENSE"
                  )}
                </span>
              }
              type={"error"}
              showIcon
            />
          </span>
        ))}
    </>
  );
}
