import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Select,
  DatePicker,
  Input,
  Button,
  notification,
} from "antd";
import RisksConfsService from "../../../services/risk-confs.service";
import { MainContext } from "../../../utils/context";
import dayjs from "dayjs";
import RunningRisksServices from "../../../services/running-risks.service";

const { Option } = Select;
const { TextArea } = Input;

interface ModalFormProps {
  visible: boolean;
  onClose: () => void;
}

const StartRisk: React.FC<ModalFormProps> = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [riskConf, setRiskConf] = useState<any>([]);
  const globalContext = useContext(MainContext);

  useEffect(() => {
    getRiskConf();
  }, []);

  if (!globalContext) {
    return <></>;
  }

  const risksServices = new RisksConfsService(
    globalContext?.context,
    globalContext?.setContext
  );

  const RunningRisksService = new RunningRisksServices(
    globalContext?.context,
    globalContext?.setContext,
    ""
  );

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        // Transform the context string into JSON object
        const contextObject: Record<string, any> = {};
        values.context.split(",").forEach((pair: string) => {
          const [key, value] = pair.trim().split(":");
          const trimmedKey = key.replace(/"/g, "").trim();
          const trimmedValue = value.trim();
          contextObject[trimmedKey] = isNaN(Number(trimmedValue))
            ? trimmedValue.replace(/"/g, "").trim()
            : Number(trimmedValue);
        });
        const transformedValues = {
          ...values,
          context: contextObject,
          due_date: values.due_date.toISOString(),
        };
        console.log("*** start risk args", transformedValues);
        await RunningRisksService.startRisk(transformedValues)
          .then((res) => {
            console.log(res);
            notification.success({
              message: `Start Risk successful , Risk Status :${res?.status}`,
              placement: "bottomRight",
            });
          })
          .catch((err) => {
            console.error(err);
            notification.error({
              message: "Failed to start risk",
              placement: "bottomRight",
            });
          });

        form.resetFields();
        onClose();
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  const getRiskConf = async () => {
    await risksServices
      .filterResource({ current: 1, pageSize: 10000 }, [], ["model", "copilot"])
      .then((response: any) => {
        setRiskConf(response?.items);
      });
  };

  const validateContext = (_: any, value: string) => {
    const pattern =
      /^\s*"?\w+"?\s*:\s*(?:"[^"]*"|\d+)\s*(,\s*"?\w+"?\s*:\s*(?:"[^"]*"|\d+)\s*)*$/;

    try {
      // Remove new lines and trim spaces
      const cleanedValue = value.replace(/\n/g, "").trim();
      if (pattern.test(cleanedValue)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Invalid context format"));
    } catch (err) {
      console.error("Error parsing context:", err);
      return Promise.reject(new Error("Invalid context format"));
    }
  };

  return (
    <Modal
      title="Start Risk"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          due_date: dayjs("2024-05-31 00:00:00"),
          context: `"ITEM_CODE": "SKU-233",\n"LOCATION_CODE": "245",\n"QUANTITY_AT_RISK": 500`,
        }}
      >
        <Form.Item name="risk_conf_src_id" label="Risk Conf" required>
          <Select placeholder="Select an option">
            {riskConf?.map((item: any) => (
              <Option key={item?.id} value={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="due_date" label="Due date" required>
          <DatePicker showTime defaultValue={dayjs("2024-05-31 00:00:00")} />
        </Form.Item>
        <Form.Item
          name="context"
          label="Context"
          required
          rules={[
            {
              validator: validateContext,
            },
          ]}
        >
          <TextArea rows={6} placeholder="Enter text here" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StartRisk;
