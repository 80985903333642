import { Button, Dropdown, MenuProps, Popconfirm, Space } from "antd";
import {
  EllipsisOutlined,
  ArrowsAltOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { WORK_MODE } from "../../../constants/workModes";

import { useTranslation } from "react-i18next";

function MenuOptions(props: any) {
  const { t } = useTranslation();
  const { viewRecord, editRecord, deleteRecord, title, mode } = props;
  const items: MenuProps["items"] = [
    viewRecord
      ? {
          key: "1",
          label: (
            <Space
              onClick={async () => {
                await viewRecord();
              }}
            >
              <ArrowsAltOutlined style={{ color: "#004C97" }} />
              <span style={{ width: "300px" }}>{t("VIEW")}</span>
            </Space>
          ),
          onClick: async () => {
            await viewRecord();
          },
        }
      : null,
    mode !== WORK_MODE.VIEW && editRecord
      ? {
          key: "2",
          label: (
            <Space
              onClick={async () => {
                await editRecord();
              }}
            >
              <EditOutlined style={{ color: "#004C97" }} />
              <span style={{ width: "300px" }}>{t("UPDATE")}</span>
            </Space>
          ),
          onClick: async () => {
            await editRecord();
          },
        }
      : null,
    mode !== WORK_MODE.VIEW && !!deleteRecord
      ? {
          key: "3",
          label: (
            <Popconfirm
              placement="topLeft"
              key="delete"
              disabled={false}
              title={title}
              onConfirm={async () => {
                await deleteRecord();
              }}
              okText={t("YES")}
              cancelText={t("NO")}
            >
              <Space>
                <DeleteOutlined style={{ color: "red" }} />
                <span style={{ width: "300px" }}>{t("DELETE")}</span>
              </Space>
            </Popconfirm>
          ),
        }
      : null,
  ];

  return (
    <Dropdown menu={{ items }} placement="bottomLeft">
      <Button
        type="dashed"
        icon={<EllipsisOutlined />}
        style={{
          height: "28px",
          color: "#aba7a7",
          border: "1px solid #aba7a7",
        }}
      />
    </Dropdown>
  );
}

export default MenuOptions;
