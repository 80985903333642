import React from "react";
import { Form, Select, Tag } from "antd";
import { selectFilterOption } from "../../../../../utils/functions";
import {
  SharedContextFunctions,
  useSharedContextFunctions,
} from "../../logic/runningShared.logic";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../utils/transaltion";
const { Option } = Select;

interface ReasonSelectionProps {
  currentActionExecutionReasons: any[];
  setInputOtherVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReasonCodeSelection: React.FC<ReasonSelectionProps> = ({
  currentActionExecutionReasons,
  setInputOtherVisible,
}) => {
  /** translation */
  const { t } = useTranslation();
  /** shared logic */
  const { handleOnSelectReasonCode } =
    useSharedContextFunctions() as SharedContextFunctions;
  const customTagRender = (props: any) => {
    const { label, value } = props;
    return (
      <Tag
        style={{
          backgroundColor: "#004c97",
          color: "white",
          borderRadius: "4px",
          padding: "0 8px",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        {label}
      </Tag>
    );
  };
  return (
    <Form.Item label={t("REASONS")} name="reason" required>
      <Select
        showSearch
        filterOption={(input, option) => selectFilterOption(input, option)}
        onSelect={(value) => {
          handleOnSelectReasonCode(
            value,
            currentActionExecutionReasons,
            setInputOtherVisible
          );
        }}
        tagRender={customTagRender}
        mode="tags"
        maxCount={1}
        allowClear
      >
        {currentActionExecutionReasons &&
          currentActionExecutionReasons?.length > 0 &&
          currentActionExecutionReasons
            ?.sort((a, b) => a.name.localeCompare(b.name))
            ?.map((item) => (
              <Option
                key={item?.id}
                value={item?.id}

                // style={{
                //   backgroundColor: "#004c97",
                //   color: "white",
                // }}
              >
                {getTranslation(item?.name, "data")}
              </Option>
            ))}
      </Select>
    </Form.Item>
  );
};

export default ReasonCodeSelection;
