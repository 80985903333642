export const inbound_freight_data: any[] = [
  {
    running_risk_id: 100,
    risk_date: 222 + 45345,
    risk_status: "Open",
    risk_location: 1001,
    sku_number: "SKU-233",
    quantity_at_risk: 20076,
    revenue_at_risk: 357352.8,
    action_type: "Early custom clearance",
    quantity_mitigated: 19474,
    revenue_mitigated: 346637.2,
    purchase_cost: 43586,
    cost_impact: 43586,
  },
  {
    running_risk_id: 101,
    risk_date: 222 + 45347,
    risk_status: "In progress",
    risk_location: 1001,
    sku_number: "SKU-492",
    quantity_at_risk: 38298,
    revenue_at_risk: 536172,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 31021,
    revenue_mitigated: 434294,
    purchase_cost: 59683,
    cost_impact: 59683,
  },
  {
    running_risk_id: 102,
    risk_date: 222 + 45347,
    risk_status: "In progress",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 13974,
    revenue_at_risk: 208212.6,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 11738,
    revenue_mitigated: 174896.2,
    purchase_cost: 32874,
    cost_impact: 32874,
  },
  {
    running_risk_id: 103,
    risk_date: 222 + 45301,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1005,
    sku_number: "SKU-839",
    quantity_at_risk: 21915,
    revenue_at_risk: 602662.5,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 21696,
    revenue_mitigated: 596640,
    purchase_cost: 41868,
    cost_impact: 41868,
  },
  {
    running_risk_id: 104,
    risk_date: 222 + 45301,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1045,
    sku_number: "SKU-241",
    quantity_at_risk: 22023,
    revenue_at_risk: 295108.2,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 17618,
    revenue_mitigated: 236081.2,
    purchase_cost: 47470,
    cost_impact: 47470,
  },
  {
    running_risk_id: 105,
    risk_date: 222 + 45302,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1045,
    sku_number: "SKU-624",
    quantity_at_risk: 27359,
    revenue_at_risk: 927470.1,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 25717,
    revenue_mitigated: 871806.3,
    purchase_cost: 49513,
    cost_impact: 49513,
  },
  {
    running_risk_id: 106,
    risk_date: 222 + 45303,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1045,
    sku_number: "SKU-241",
    quantity_at_risk: 22753,
    revenue_at_risk: 646185.2,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 20933,
    revenue_mitigated: 594497.2,
    purchase_cost: 36240,
    cost_impact: 36240,
  },
  {
    running_risk_id: 107,
    risk_date: 222 + 45303,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1045,
    sku_number: "SKU-233",
    quantity_at_risk: 35415,
    revenue_at_risk: 619762.5,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 30811,
    revenue_mitigated: 539192.5,
    purchase_cost: 33009,
    cost_impact: 33009,
  },
  {
    running_risk_id: 108,
    risk_date: 222 + 45304,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1005,
    sku_number: "SKU-492",
    quantity_at_risk: 16174,
    revenue_at_risk: 198940.2,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 16174,
    revenue_mitigated: 198940.2,
    purchase_cost: 36335,
    cost_impact: 36335,
  },
  {
    running_risk_id: 109,
    risk_date: 222 + 45306,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 14868,
    revenue_at_risk: 224506.8,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 14273,
    revenue_mitigated: 215522.3,
    purchase_cost: 43093,
    cost_impact: 43093,
  },
  {
    running_risk_id: 110,
    risk_date: 222 + 45306,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 34806,
    revenue_at_risk: 1628920.7999999998,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 31325,
    revenue_mitigated: 1466009.9999999998,
    purchase_cost: 36536,
    cost_impact: 36536,
  },
  {
    running_risk_id: 111,
    risk_date: 222 + 45307,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1001,
    sku_number: "SKU-241",
    quantity_at_risk: 16415,
    revenue_at_risk: 635260.5,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 14938,
    revenue_mitigated: 578100.6,
    purchase_cost: 36002,
    cost_impact: 36002,
  },
  {
    running_risk_id: 112,
    risk_date: 222 + 45308,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1005,
    sku_number: "SKU-624",
    quantity_at_risk: 25188,
    revenue_at_risk: 959662.8,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 20150,
    revenue_mitigated: 767715,
    purchase_cost: 42685,
    cost_impact: 42685,
  },
  {
    running_risk_id: 113,
    risk_date: 222 + 45310,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1001,
    sku_number: "SKU-233",
    quantity_at_risk: 15159,
    revenue_at_risk: 533596.8,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 15007,
    revenue_mitigated: 528246.4,
    purchase_cost: 39207,
    cost_impact: 39207,
  },
  {
    running_risk_id: 114,
    risk_date: 222 + 45311,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 14029,
    revenue_at_risk: 251119.09999999998,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 11784,
    revenue_mitigated: 210933.59999999998,
    purchase_cost: 30922,
    cost_impact: 30922,
  },
  {
    running_risk_id: 115,
    risk_date: 222 + 45314,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1003,
    sku_number: "SKU-135",
    quantity_at_risk: 25804,
    revenue_at_risk: 1181823.2,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 23224,
    revenue_mitigated: 1063659.2,
    purchase_cost: 40848,
    cost_impact: 40848,
  },
  {
    running_risk_id: 116,
    risk_date: 222 + 45314,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 21724,
    revenue_at_risk: 297618.8,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 19552,
    revenue_mitigated: 267862.39999999997,
    purchase_cost: 56425,
    cost_impact: 56425,
  },
  {
    running_risk_id: 117,
    risk_date: 222 + 45316,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 16371,
    revenue_at_risk: 250476.30000000002,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 14406,
    revenue_mitigated: 220411.80000000002,
    purchase_cost: 56338,
    cost_impact: 56338,
  },
  {
    running_risk_id: 118,
    risk_date: 222 + 45317,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 16191,
    revenue_at_risk: 463062.60000000003,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 16191,
    revenue_mitigated: 463062.60000000003,
    purchase_cost: 59414,
    cost_impact: 59414,
  },
  {
    running_risk_id: 119,
    risk_date: 222 + 45317,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-241",
    quantity_at_risk: 34021,
    revenue_at_risk: 891350.2,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 31640,
    revenue_mitigated: 828968,
    purchase_cost: 47164,
    cost_impact: 47164,
  },
  {
    running_risk_id: 120,
    risk_date: 222 + 45319,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-624",
    quantity_at_risk: 22432,
    revenue_at_risk: 466585.60000000003,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 21086,
    revenue_mitigated: 438588.8,
    purchase_cost: 50567,
    cost_impact: 50567,
  },
  {
    running_risk_id: 121,
    risk_date: 222 + 45321,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-233",
    quantity_at_risk: 12080,
    revenue_at_risk: 205360,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 11838,
    revenue_mitigated: 201246,
    purchase_cost: 52703,
    cost_impact: 52703,
  },
  {
    running_risk_id: 122,
    risk_date: 222 + 45321,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 12918,
    revenue_at_risk: 342327,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 12272,
    revenue_mitigated: 325208,
    purchase_cost: 57913,
    cost_impact: 57913,
  },
  {
    running_risk_id: 123,
    risk_date: 222 + 45324,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-233",
    quantity_at_risk: 25640,
    revenue_at_risk: 1005088.0000000001,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 25127,
    revenue_mitigated: 984978.4000000001,
    purchase_cost: 56484,
    cost_impact: 56484,
  },
  {
    running_risk_id: 124,
    risk_date: 222 + 45325,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-492",
    quantity_at_risk: 37575,
    revenue_at_risk: 1014525,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 33442,
    revenue_mitigated: 902934,
    purchase_cost: 47044,
    cost_impact: 47044,
  },
  {
    running_risk_id: 125,
    risk_date: 222 + 45327,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 19357,
    revenue_at_risk: 470375.10000000003,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 18776,
    revenue_mitigated: 456256.80000000005,
    purchase_cost: 40356,
    cost_impact: 40356,
  },
  {
    running_risk_id: 126,
    risk_date: 222 + 45330,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 35513,
    revenue_at_risk: 1477340.8,
    action_type: "Early custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 30541,
    revenue_mitigated: 1270505.6,
    purchase_cost: 30303,
    cost_impact: 30303,
  },
  {
    running_risk_id: 127,
    risk_date: 222 + 45303,
    risk_status: "Rejected",
    rejection_code: "Custom not allowing early request",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 28796,
    revenue_at_risk: 1039535.6000000001,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 28508,
    revenue_mitigated: 1029138.8000000002,
    purchase_cost: 42342,
    cost_impact: 42342,
  },
  {
    running_risk_id: 128,
    risk_date: 222 + 45305,
    risk_status: "Rejected",
    rejection_code: "Custom backlog",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-135",
    quantity_at_risk: 16540,
    revenue_at_risk: 403576,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 15382,
    revenue_mitigated: 375320.8,
    purchase_cost: 54505,
    cost_impact: 54505,
  },
  {
    running_risk_id: 129,
    risk_date: 222 + 45307,
    risk_status: "Rejected",
    rejection_code: "Custom not allowing early request",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 24401,
    revenue_at_risk: 734470.1,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 19521,
    revenue_mitigated: 587582.1,
    purchase_cost: 36605,
    cost_impact: 36605,
  },
  {
    running_risk_id: 130,
    risk_date: 222 + 45307,
    risk_status: "Rejected",
    rejection_code: "No early custom clearance available ",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 15530,
    revenue_at_risk: 515596.00000000006,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 14909,
    revenue_mitigated: 494978.80000000005,
    purchase_cost: 36105,
    cost_impact: 36105,
  },
  {
    running_risk_id: 131,
    risk_date: 222 + 45312,
    risk_status: "Rejected",
    rejection_code: "Custom not allowing early request",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 26887,
    revenue_at_risk: 567315.7000000001,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 22854,
    revenue_mitigated: 482219.4,
    purchase_cost: 34442,
    cost_impact: 34442,
  },
  {
    running_risk_id: 132,
    risk_date: 222 + 45314,
    risk_status: "Rejected",
    rejection_code: "Custom not allowing early request",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-241",
    quantity_at_risk: 15579,
    revenue_at_risk: 296001,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 12931,
    revenue_mitigated: 245689,
    purchase_cost: 59438,
    cost_impact: 59438,
  },
  {
    running_risk_id: 133,
    risk_date: 222 + 45316,
    risk_status: "Rejected",
    rejection_code: "Custom not allowing early request",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-241",
    quantity_at_risk: 26635,
    revenue_at_risk: 1187921,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 24771,
    revenue_mitigated: 1104786.6,
    purchase_cost: 56717,
    cost_impact: 56717,
  },
  {
    running_risk_id: 134,
    risk_date: 222 + 45320,
    risk_status: "Rejected",
    rejection_code: "Custom backlog",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-233",
    quantity_at_risk: 34309,
    revenue_at_risk: 1440978,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 33623,
    revenue_mitigated: 1412166,
    purchase_cost: 53851,
    cost_impact: 53851,
  },
  {
    running_risk_id: 135,
    risk_date: 222 + 45321,
    risk_status: "Rejected",
    rejection_code: "Custom backlog",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-492",
    quantity_at_risk: 37721,
    revenue_at_risk: 901531.8999999999,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 36967,
    revenue_mitigated: 883511.2999999999,
    purchase_cost: 46852,
    cost_impact: 46852,
  },
  {
    running_risk_id: 136,
    risk_date: 222 + 45325,
    risk_status: "Rejected",
    rejection_code: "Custom backlog",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 16051,
    revenue_at_risk: 654880.7999999999,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 16051,
    revenue_mitigated: 654880.7999999999,
    purchase_cost: 47395,
    cost_impact: 47395,
  },
  {
    running_risk_id: 137,
    risk_date: 222 + 45306,
    risk_status: "Rejected",
    rejection_code: "No early custom clearance available ",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 35267,
    revenue_at_risk: 842881.2999999999,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 33504,
    revenue_mitigated: 800745.5999999999,
    purchase_cost: 35933,
    cost_impact: 35933,
  },
  {
    running_risk_id: 138,
    risk_date: 222 + 45309,
    risk_status: "Rejected",
    rejection_code: "Custom backlog",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 29843,
    revenue_at_risk: 1157908.4,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 28351,
    revenue_mitigated: 1100018.7999999998,
    purchase_cost: 45059,
    cost_impact: 45059,
  },
  {
    running_risk_id: 139,
    risk_date: 222 + 45313,
    risk_status: "Rejected",
    rejection_code: "Custom not allowing early request",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 21342,
    revenue_at_risk: 665870.4,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 18354,
    revenue_mitigated: 572644.8,
    purchase_cost: 52207,
    cost_impact: 52207,
  },
  {
    running_risk_id: 140,
    risk_date: 222 + 45317,
    risk_status: "Rejected",
    rejection_code: "Custom backlog",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 19422,
    revenue_at_risk: 687538.7999999999,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 16509,
    revenue_mitigated: 584418.6,
    purchase_cost: 52439,
    cost_impact: 52439,
  },
  {
    running_risk_id: 141,
    risk_date: 222 + 45322,
    risk_status: "Rejected",
    rejection_code: "Custom not allowing early request",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-135",
    quantity_at_risk: 28931,
    revenue_at_risk: 841892.1000000001,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 28931,
    revenue_mitigated: 841892.1000000001,
    purchase_cost: 56366,
    cost_impact: 56366,
  },
  {
    running_risk_id: 142,
    risk_date: 222 + 45324,
    risk_status: "Rejected",
    rejection_code: "No early custom clearance available ",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 36538,
    revenue_at_risk: 942680.4,
    action_type: "Early custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 30327,
    revenue_mitigated: 782436.6,
    purchase_cost: 59170,
    cost_impact: 59170,
  },
  {
    running_risk_id: 143,
    risk_date: 222 + 45308,
    risk_status: "Rejected",
    rejection_code: "Custom backlog",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 12387,
    revenue_at_risk: 361700.39999999997,
    action_type: "Early custom clearance",
    execution_stage: "Truck booking",
    quantity_mitigated: 11024,
    revenue_mitigated: 321900.79999999993,
    purchase_cost: 54434,
    cost_impact: 54434,
  },
  {
    running_risk_id: 144,
    risk_date: 222 + 45311,
    risk_status: "Rejected",
    rejection_code: "Custom backlog",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 39072,
    revenue_at_risk: 843955.2000000001,
    action_type: "Early custom clearance",
    execution_stage: "Truck booking",
    quantity_mitigated: 39072,
    revenue_mitigated: 843955.2000000001,
    purchase_cost: 30225,
    cost_impact: 30225,
  },
  {
    running_risk_id: 145,
    risk_date: 222 + 45314,
    risk_status: "Rejected",
    rejection_code: "No early custom clearance available ",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-241",
    quantity_at_risk: 39072,
    revenue_at_risk: 926006.4,
    action_type: "Early custom clearance",
    execution_stage: "Truck booking",
    quantity_mitigated: 33211,
    revenue_mitigated: 787100.7000000001,
    purchase_cost: 55300,
    cost_impact: 55300,
  },
  {
    running_risk_id: 146,
    risk_date: 222 + 45323,
    risk_status: "Rejected",
    rejection_code: "Container Backlog",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-241",
    quantity_at_risk: 11454,
    revenue_at_risk: 185554.8,
    action_type: "Early custom clearance",
    execution_stage: "Truck booking",
    quantity_mitigated: 10194,
    revenue_mitigated: 165142.8,
    purchase_cost: 36382,
    cost_impact: 36382,
  },
  {
    running_risk_id: 147,
    risk_date: 222 + 45336,
    risk_status: "Open",
    risk_location: 1005,
    sku_number: "SKU-839",
    quantity_at_risk: 25766,
    revenue_at_risk: 324651.6,
    action_type: "Expedited custom clearance",
    quantity_mitigated: 23962,
    revenue_mitigated: 301921.2,
    purchase_cost: 51418,
    cost_impact: 51418,
  },
  {
    running_risk_id: 148,
    risk_date: 222 + 45336,
    risk_status: "In progress",
    risk_location: 1001,
    sku_number: "SKU-233",
    quantity_at_risk: 28800,
    revenue_at_risk: 1330560,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 26496,
    revenue_mitigated: 1224115.2,
    purchase_cost: 33395,
    cost_impact: 33395,
  },
  {
    running_risk_id: 149,
    risk_date: 222 + 45333,
    risk_status: "In progress",
    risk_location: 1005,
    sku_number: "SKU-839",
    quantity_at_risk: 31151,
    revenue_at_risk: 504646.19999999995,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 27413,
    revenue_mitigated: 444090.6,
    purchase_cost: 45846,
    cost_impact: 45846,
  },
  {
    running_risk_id: 150,
    risk_date: 222 + 45301,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 29667,
    revenue_at_risk: 685307.7000000001,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 26997,
    revenue_mitigated: 623630.7000000001,
    purchase_cost: 42771,
    cost_impact: 42771,
  },
  {
    running_risk_id: 151,
    risk_date: 222 + 45304,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 35952,
    revenue_at_risk: 1527960,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 29481,
    revenue_mitigated: 1252942.5,
    purchase_cost: 56400,
    cost_impact: 56400,
  },
  {
    running_risk_id: 152,
    risk_date: 222 + 45304,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 13633,
    revenue_at_risk: 644840.8999999999,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 12542,
    revenue_mitigated: 593236.6,
    purchase_cost: 37081,
    cost_impact: 37081,
  },
  {
    running_risk_id: 153,
    risk_date: 222 + 45308,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 24716,
    revenue_at_risk: 1203669.2000000002,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 23480,
    revenue_mitigated: 1143476.0000000002,
    purchase_cost: 38718,
    cost_impact: 38718,
  },
  {
    running_risk_id: 154,
    risk_date: 222 + 45309,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 31300,
    revenue_at_risk: 1183140,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 25979,
    revenue_mitigated: 982006.2,
    purchase_cost: 50416,
    cost_impact: 50416,
  },
  {
    running_risk_id: 155,
    risk_date: 222 + 45309,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 39985,
    revenue_at_risk: 1283518.5,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 34787,
    revenue_mitigated: 1116662.7,
    purchase_cost: 42742,
    cost_impact: 42742,
  },
  {
    running_risk_id: 156,
    risk_date: 222 + 45310,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1003,
    sku_number: "SKU-135",
    quantity_at_risk: 30203,
    revenue_at_risk: 1256444.8,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 29599,
    revenue_mitigated: 1231318.4000000001,
    purchase_cost: 47463,
    cost_impact: 47463,
  },
  {
    running_risk_id: 157,
    risk_date: 222 + 45312,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 33676,
    revenue_at_risk: 1488479.2000000002,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 31655,
    revenue_mitigated: 1399151.0000000002,
    purchase_cost: 35180,
    cost_impact: 35180,
  },
  {
    running_risk_id: 158,
    risk_date: 222 + 45312,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 33725,
    revenue_at_risk: 381092.5,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 26980,
    revenue_mitigated: 304874,
    purchase_cost: 54661,
    cost_impact: 54661,
  },
  {
    running_risk_id: 159,
    risk_date: 222 + 45315,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 22651,
    revenue_at_risk: 838087,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 21518,
    revenue_mitigated: 796166,
    purchase_cost: 40907,
    cost_impact: 40907,
  },
  {
    running_risk_id: 160,
    risk_date: 222 + 45317,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-135",
    quantity_at_risk: 11586,
    revenue_at_risk: 322090.8,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 10196,
    revenue_mitigated: 283448.8,
    purchase_cost: 57356,
    cost_impact: 57356,
  },
  {
    running_risk_id: 161,
    risk_date: 222 + 45317,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 11915,
    revenue_at_risk: 229959.5,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 9532,
    revenue_mitigated: 183967.6,
    purchase_cost: 45967,
    cost_impact: 45967,
  },
  {
    running_risk_id: 162,
    risk_date: 222 + 45318,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 34188,
    revenue_at_risk: 916238.4,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 28376,
    revenue_mitigated: 760476.8,
    purchase_cost: 30832,
    cost_impact: 30832,
  },
  {
    running_risk_id: 163,
    risk_date: 222 + 45318,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 29015,
    revenue_at_risk: 754390,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 25533,
    revenue_mitigated: 663858,
    purchase_cost: 59194,
    cost_impact: 59194,
  },
  {
    running_risk_id: 164,
    risk_date: 222 + 45318,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-241",
    quantity_at_risk: 25036,
    revenue_at_risk: 956375.2000000001,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 22032,
    revenue_mitigated: 841622.4,
    purchase_cost: 39774,
    cost_impact: 39774,
  },
  {
    running_risk_id: 165,
    risk_date: 222 + 45321,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-624",
    quantity_at_risk: 16615,
    revenue_at_risk: 353899.5,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 15950,
    revenue_mitigated: 339735,
    purchase_cost: 59070,
    cost_impact: 59070,
  },
  {
    running_risk_id: 166,
    risk_date: 222 + 45322,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-233",
    quantity_at_risk: 20926,
    revenue_at_risk: 701021,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 20717,
    revenue_mitigated: 694019.5,
    purchase_cost: 43974,
    cost_impact: 43974,
  },
  {
    running_risk_id: 167,
    risk_date: 222 + 45324,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 14679,
    revenue_at_risk: 619453.8,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 14385,
    revenue_mitigated: 607047,
    purchase_cost: 30911,
    cost_impact: 30911,
  },
  {
    running_risk_id: 168,
    risk_date: 222 + 45325,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 30510,
    revenue_at_risk: 1122768,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 28374,
    revenue_mitigated: 1044163.2,
    purchase_cost: 33371,
    cost_impact: 33371,
  },
  {
    running_risk_id: 169,
    risk_date: 222 + 45327,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 12259,
    revenue_at_risk: 295441.9,
    action_type: "Expedited custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 11891,
    revenue_mitigated: 286573.10000000003,
    purchase_cost: 50936,
    cost_impact: 50936,
  },
  {
    running_risk_id: 170,
    risk_date: 222 + 45308,
    risk_status: "Rejected",
    rejection_code: "Custom not allowing expedited request",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 24539,
    revenue_at_risk: 831872.1,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 21594,
    revenue_mitigated: 732036.5999999999,
    purchase_cost: 47281,
    cost_impact: 47281,
  },
  {
    running_risk_id: 171,
    risk_date: 222 + 45313,
    risk_status: "Rejected",
    rejection_code: "Vessel schedule disruptions",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-135",
    quantity_at_risk: 27776,
    revenue_at_risk: 366643.19999999995,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 23610,
    revenue_mitigated: 311651.99999999994,
    purchase_cost: 50217,
    cost_impact: 50217,
  },
  {
    running_risk_id: 172,
    risk_date: 222 + 45319,
    risk_status: "Rejected",
    rejection_code: "Vessel schedule disruptions",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 13325,
    revenue_at_risk: 599625,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 13059,
    revenue_mitigated: 587655,
    purchase_cost: 42462,
    cost_impact: 42462,
  },
  {
    running_risk_id: 173,
    risk_date: 222 + 45322,
    risk_status: "Rejected",
    rejection_code: "Custom not allowing expedited request",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 12455,
    revenue_at_risk: 509409.5,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 11832,
    revenue_mitigated: 483928.8,
    purchase_cost: 30615,
    cost_impact: 30615,
  },
  {
    running_risk_id: 174,
    risk_date: 222 + 45322,
    risk_status: "Rejected",
    rejection_code: "Custom not allowing expedited request",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 15982,
    revenue_at_risk: 415532,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 15822,
    revenue_mitigated: 411372,
    purchase_cost: 48015,
    cost_impact: 48015,
  },
  {
    running_risk_id: 175,
    risk_date: 222 + 45329,
    risk_status: "Rejected",
    rejection_code: "Custom not allowing expedited request",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-135",
    quantity_at_risk: 38739,
    revenue_at_risk: 1290008.7,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 31379,
    revenue_mitigated: 1044920.6999999998,
    purchase_cost: 56834,
    cost_impact: 56834,
  },
  {
    running_risk_id: 176,
    risk_date: 222 + 45303,
    risk_status: "Rejected",
    rejection_code: "Custom not allowing expedited request",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 15644,
    revenue_at_risk: 732139.2,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 13923,
    revenue_mitigated: 651596.3999999999,
    purchase_cost: 59183,
    cost_impact: 59183,
  },
  {
    running_risk_id: 177,
    risk_date: 222 + 45306,
    risk_status: "Rejected",
    rejection_code: "Container Backlog",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 34176,
    revenue_at_risk: 1466150.4,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 33492,
    revenue_mitigated: 1436806.7999999998,
    purchase_cost: 54882,
    cost_impact: 54882,
  },
  {
    running_risk_id: 178,
    risk_date: 222 + 45311,
    risk_status: "Rejected",
    rejection_code: "Custom backlog",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-233",
    quantity_at_risk: 23183,
    revenue_at_risk: 308333.9,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 19706,
    revenue_mitigated: 262089.80000000002,
    purchase_cost: 33661,
    cost_impact: 33661,
  },
  {
    running_risk_id: 179,
    risk_date: 222 + 45313,
    risk_status: "Rejected",
    rejection_code: "Custom not allowing expedited request",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-492",
    quantity_at_risk: 24424,
    revenue_at_risk: 530000.7999999999,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 21737,
    revenue_mitigated: 471692.89999999997,
    purchase_cost: 36338,
    cost_impact: 36338,
  },
  {
    running_risk_id: 180,
    risk_date: 222 + 45317,
    risk_status: "Rejected",
    rejection_code: "Custom not allowing expedited request",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 18745,
    revenue_at_risk: 882889.5,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 17433,
    revenue_mitigated: 821094.3,
    purchase_cost: 36038,
    cost_impact: 36038,
  },
  {
    running_risk_id: 181,
    risk_date: 222 + 45318,
    risk_status: "Rejected",
    rejection_code: "Custom backlog",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 30022,
    revenue_at_risk: 471345.39999999997,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 27020,
    revenue_mitigated: 424214,
    purchase_cost: 34229,
    cost_impact: 34229,
  },
  {
    running_risk_id: 182,
    risk_date: 222 + 45321,
    risk_status: "Rejected",
    rejection_code: "Container Backlog",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 24727,
    revenue_at_risk: 947044.1,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 23738,
    revenue_mitigated: 909165.4,
    purchase_cost: 46070,
    cost_impact: 46070,
  },
  {
    running_risk_id: 183,
    risk_date: 222 + 45324,
    risk_status: "Rejected",
    rejection_code: "Custom backlog",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 22058,
    revenue_at_risk: 511745.6,
    action_type: "Expedited custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 19632,
    revenue_mitigated: 455462.39999999997,
    purchase_cost: 57523,
    cost_impact: 57523,
  },
  {
    running_risk_id: 184,
    risk_date: 222 + 45340,
    risk_status: "Open",
    risk_location: 1005,
    sku_number: "SKU-839",
    quantity_at_risk: 11330,
    revenue_at_risk: 407880,
    action_type: "Expedited truck transfer",
    quantity_mitigated: 11330,
    revenue_mitigated: 407880,
    freight_cost: 53369,
    cost_impact: 53369,
    days_of_supply_impact: 2.25,
  },
  {
    running_risk_id: 185,
    risk_date: 222 + 45339,
    risk_status: "In progress",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 18506,
    revenue_at_risk: 819815.7999999999,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 17951,
    revenue_mitigated: 795229.2999999999,
    freight_cost: 55050,
    cost_impact: 55050,
    days_of_supply_impact: 4.07,
  },
  {
    running_risk_id: 186,
    risk_date: 222 + 45301,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 16582,
    revenue_at_risk: 281894,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 16416,
    revenue_mitigated: 279072,
    freight_cost: 57921,
    cost_impact: 57921,
    days_of_supply_impact: 3.45,
  },
  {
    running_risk_id: 187,
    risk_date: 222 + 45302,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 35888,
    revenue_at_risk: 394768,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 30864,
    revenue_mitigated: 339504,
    freight_cost: 50600,
    cost_impact: 50600,
    days_of_supply_impact: 4.52,
  },
  {
    running_risk_id: 188,
    risk_date: 222 + 45303,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 18218,
    revenue_at_risk: 668600.6000000001,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 17854,
    revenue_mitigated: 655241.8,
    freight_cost: 51158,
    cost_impact: 51158,
    days_of_supply_impact: 4.08,
  },
  {
    running_risk_id: 189,
    risk_date: 222 + 45305,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 20198,
    revenue_at_risk: 916989.2,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 16158,
    revenue_mitigated: 733573.2,
    freight_cost: 64216,
    cost_impact: 64216,
    days_of_supply_impact: 4.53,
  },
  {
    running_risk_id: 190,
    risk_date: 222 + 45305,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1003,
    sku_number: "SKU-135",
    quantity_at_risk: 16158,
    revenue_at_risk: 436266,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 15996,
    revenue_mitigated: 431892,
    freight_cost: 44147,
    cost_impact: 44147,
    days_of_supply_impact: 2.83,
  },
  {
    running_risk_id: 191,
    risk_date: 222 + 45305,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 35200,
    revenue_at_risk: 1291840,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 28864,
    revenue_mitigated: 1059308.8,
    freight_cost: 49064,
    cost_impact: 49064,
    days_of_supply_impact: 5.42,
  },
  {
    running_risk_id: 192,
    risk_date: 222 + 45306,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 38950,
    revenue_at_risk: 1857915,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 34666,
    revenue_mitigated: 1653568.2,
    freight_cost: 74255,
    cost_impact: 74255,
    days_of_supply_impact: 2.71,
  },
  {
    running_risk_id: 193,
    risk_date: 222 + 45309,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 18845,
    revenue_at_risk: 471125,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 18845,
    revenue_mitigated: 471125,
    freight_cost: 70316,
    cost_impact: 70316,
    days_of_supply_impact: 5.2,
  },
  {
    running_risk_id: 194,
    risk_date: 222 + 45310,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1003,
    sku_number: "SKU-135",
    quantity_at_risk: 37356,
    revenue_at_risk: 1102002,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 33994,
    revenue_mitigated: 1002823,
    freight_cost: 62784,
    cost_impact: 62784,
    days_of_supply_impact: 3.69,
  },
  {
    running_risk_id: 195,
    risk_date: 222 + 45310,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 16642,
    revenue_at_risk: 625739.2000000001,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 13480,
    revenue_mitigated: 506848.00000000006,
    freight_cost: 60674,
    cost_impact: 60674,
    days_of_supply_impact: 4.21,
  },
  {
    running_risk_id: 196,
    risk_date: 222 + 45313,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 36818,
    revenue_at_risk: 611178.8,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 31295,
    revenue_mitigated: 519497,
    freight_cost: 64304,
    cost_impact: 64304,
    days_of_supply_impact: 5.15,
  },
  {
    running_risk_id: 197,
    risk_date: 222 + 45314,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1045,
    sku_number: "SKU-233",
    quantity_at_risk: 21317,
    revenue_at_risk: 694934.2000000001,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 18759,
    revenue_mitigated: 611543.4,
    freight_cost: 54445,
    cost_impact: 54445,
    days_of_supply_impact: 3.67,
  },
  {
    running_risk_id: 198,
    risk_date: 222 + 45315,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 10529,
    revenue_at_risk: 428530.30000000005,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 9266,
    revenue_mitigated: 377126.20000000007,
    freight_cost: 43003,
    cost_impact: 43003,
    days_of_supply_impact: 4.99,
  },
  {
    running_risk_id: 199,
    risk_date: 222 + 45317,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1001,
    sku_number: "SKU-135",
    quantity_at_risk: 26445,
    revenue_at_risk: 859462.5,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 21685,
    revenue_mitigated: 704762.5,
    freight_cost: 35523,
    cost_impact: 35523,
    days_of_supply_impact: 4.2,
  },
  {
    running_risk_id: 200,
    risk_date: 222 + 45318,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 25856,
    revenue_at_risk: 478336,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 23270,
    revenue_mitigated: 430495,
    freight_cost: 66354,
    cost_impact: 66354,
    days_of_supply_impact: 3.15,
  },
  {
    running_risk_id: 201,
    risk_date: 222 + 45318,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 18347,
    revenue_at_risk: 427485.10000000003,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 17430,
    revenue_mitigated: 406119.00000000006,
    freight_cost: 38657,
    cost_impact: 38657,
    days_of_supply_impact: 3.04,
  },
  {
    running_risk_id: 202,
    risk_date: 222 + 45321,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1045,
    sku_number: "SKU-233",
    quantity_at_risk: 12458,
    revenue_at_risk: 470912.39999999997,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 12458,
    revenue_mitigated: 470912.39999999997,
    freight_cost: 74026,
    cost_impact: 74026,
    days_of_supply_impact: 5.75,
  },
  {
    running_risk_id: 203,
    risk_date: 222 + 45321,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 29283,
    revenue_at_risk: 538807.2,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 27233,
    revenue_mitigated: 501087.19999999995,
    freight_cost: 62948,
    cost_impact: 62948,
    days_of_supply_impact: 2.47,
  },
  {
    running_risk_id: 204,
    risk_date: 222 + 45321,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1001,
    sku_number: "SKU-135",
    quantity_at_risk: 16228,
    revenue_at_risk: 417059.6,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 13632,
    revenue_mitigated: 350342.39999999997,
    freight_cost: 38924,
    cost_impact: 38924,
    days_of_supply_impact: 5.38,
  },
  {
    running_risk_id: 205,
    risk_date: 222 + 45323,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 29671,
    revenue_at_risk: 537045.1000000001,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 27594,
    revenue_mitigated: 499451.40000000014,
    freight_cost: 71867,
    cost_impact: 71867,
    days_of_supply_impact: 4.34,
  },
  {
    running_risk_id: 206,
    risk_date: 222 + 45323,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 33595,
    revenue_at_risk: 1135511,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 27548,
    revenue_mitigated: 931122.4,
    freight_cost: 71450,
    cost_impact: 71450,
    days_of_supply_impact: 3.6,
  },
  {
    running_risk_id: 207,
    risk_date: 222 + 45324,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 20065,
    revenue_at_risk: 770496,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 19463,
    revenue_mitigated: 747379.2,
    freight_cost: 35779,
    cost_impact: 35779,
    days_of_supply_impact: 3.87,
  },
  {
    running_risk_id: 208,
    risk_date: 222 + 45326,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-135",
    quantity_at_risk: 27297,
    revenue_at_risk: 925368.2999999999,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 26478,
    revenue_mitigated: 897604.2,
    freight_cost: 47383,
    cost_impact: 47383,
    days_of_supply_impact: 4.24,
  },
  {
    running_risk_id: 209,
    risk_date: 222 + 45328,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 12161,
    revenue_at_risk: 397664.7,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 11067,
    revenue_mitigated: 361890.9,
    freight_cost: 72289,
    cost_impact: 72289,
    days_of_supply_impact: 5.38,
  },
  {
    running_risk_id: 210,
    risk_date: 222 + 45330,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 12900,
    revenue_at_risk: 433440,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 12642,
    revenue_mitigated: 424771.2,
    freight_cost: 69551,
    cost_impact: 69551,
    days_of_supply_impact: 3.02,
  },
  {
    running_risk_id: 211,
    risk_date: 222 + 45302,
    risk_status: "Rejected",
    rejection_code: "No expedited transfer available",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 32949,
    revenue_at_risk: 1380563.0999999999,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 29325,
    revenue_mitigated: 1228717.4999999998,
    freight_cost: 38661,
    cost_impact: 38661,
    days_of_supply_impact: 2.67,
  },
  {
    running_risk_id: 212,
    risk_date: 222 + 45303,
    risk_status: "Rejected",
    rejection_code: "Chassis availability",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 32498,
    revenue_at_risk: 562215.4,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 26648,
    revenue_mitigated: 461010.4,
    freight_cost: 64470,
    cost_impact: 64470,
    days_of_supply_impact: 4.56,
  },
  {
    running_risk_id: 213,
    risk_date: 222 + 45307,
    risk_status: "Rejected",
    rejection_code: "No expedited transfer available",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 18047,
    revenue_at_risk: 615402.7000000001,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 15520,
    revenue_mitigated: 529232.0000000001,
    freight_cost: 71624,
    cost_impact: 71624,
    days_of_supply_impact: 5.76,
  },
  {
    running_risk_id: 214,
    risk_date: 222 + 45311,
    risk_status: "Rejected",
    rejection_code: "No expedited transfer available",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-839",
    quantity_at_risk: 34633,
    revenue_at_risk: 1038990,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 28053,
    revenue_mitigated: 841590,
    freight_cost: 74342,
    cost_impact: 74342,
    days_of_supply_impact: 4.92,
  },
  {
    running_risk_id: 215,
    risk_date: 222 + 45314,
    risk_status: "Rejected",
    rejection_code: "No expedited transfer available",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 18294,
    revenue_at_risk: 320145,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 16282,
    revenue_mitigated: 284935,
    freight_cost: 66113,
    cost_impact: 66113,
    days_of_supply_impact: 2.37,
  },
  {
    running_risk_id: 216,
    risk_date: 222 + 45316,
    risk_status: "Rejected",
    rejection_code: "No expedited transfer available",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 30806,
    revenue_at_risk: 505218.39999999997,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 25261,
    revenue_mitigated: 414280.39999999997,
    freight_cost: 74023,
    cost_impact: 74023,
    days_of_supply_impact: 3.82,
  },
  {
    running_risk_id: 217,
    risk_date: 222 + 45317,
    risk_status: "Rejected",
    rejection_code: "Chassis availability",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 32190,
    revenue_at_risk: 505383,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 27683,
    revenue_mitigated: 434623.1,
    freight_cost: 49977,
    cost_impact: 49977,
    days_of_supply_impact: 5.17,
  },
  {
    running_risk_id: 218,
    risk_date: 222 + 45323,
    risk_status: "Rejected",
    rejection_code: "No expedited transfer available",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 16355,
    revenue_at_risk: 508640.5,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 16355,
    revenue_mitigated: 508640.5,
    freight_cost: 70013,
    cost_impact: 70013,
    days_of_supply_impact: 3.2,
  },
  {
    running_risk_id: 219,
    risk_date: 222 + 45304,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 33165,
    revenue_at_risk: 1001583,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 30512,
    revenue_mitigated: 921462.4,
    freight_cost: 50442,
    cost_impact: 50442,
    days_of_supply_impact: 3.56,
  },
  {
    running_risk_id: 220,
    risk_date: 222 + 45307,
    risk_status: "Rejected",
    rejection_code: "Carrier cancellation",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 36324,
    revenue_at_risk: 1285869.5999999999,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck loading",
    quantity_mitigated: 34145,
    revenue_mitigated: 1208732.9999999998,
    freight_cost: 67433,
    cost_impact: 67433,
    days_of_supply_impact: 2.65,
  },
  {
    running_risk_id: 221,
    risk_date: 222 + 45312,
    risk_status: "Rejected",
    rejection_code: "Carrier cancellation",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-241",
    quantity_at_risk: 22748,
    revenue_at_risk: 475433.19999999995,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck loading",
    quantity_mitigated: 19563,
    revenue_mitigated: 408866.69999999995,
    freight_cost: 65547,
    cost_impact: 65547,
    days_of_supply_impact: 5.87,
  },
  {
    running_risk_id: 222,
    risk_date: 222 + 45314,
    risk_status: "Rejected",
    rejection_code: "Chassis availability",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-624",
    quantity_at_risk: 23364,
    revenue_at_risk: 1091098.8,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck loading",
    quantity_mitigated: 21495,
    revenue_mitigated: 1003816.5,
    freight_cost: 70514,
    cost_impact: 70514,
    days_of_supply_impact: 3.46,
  },
  {
    running_risk_id: 223,
    risk_date: 222 + 45316,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-233",
    quantity_at_risk: 24291,
    revenue_at_risk: 335215.8,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck loading",
    quantity_mitigated: 20647,
    revenue_mitigated: 284928.6,
    freight_cost: 45308,
    cost_impact: 45308,
    days_of_supply_impact: 3.01,
  },
  {
    running_risk_id: 224,
    risk_date: 222 + 45321,
    risk_status: "Rejected",
    rejection_code: "Chassis availability",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 20069,
    revenue_at_risk: 248855.6,
    action_type: "Expedited truck transfer",
    execution_stage: "Truck loading",
    quantity_mitigated: 16657,
    revenue_mitigated: 206546.80000000002,
    freight_cost: 37103,
    cost_impact: 37103,
    days_of_supply_impact: 3.2,
  },
  {
    running_risk_id: 225,
    risk_date: 222 + 45340,
    risk_status: "Open",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 38337,
    revenue_at_risk: 1583318.0999999999,
    action_type: "Rerouted truck transfer",
    quantity_mitigated: 37187,
    revenue_mitigated: 1535823.0999999999,
    freight_cost: 49408,
    cost_impact: 49408,
    days_of_supply_impact: 2.38,
  },
  {
    running_risk_id: 226,
    risk_date: 222 + 45355,
    risk_status: "In progress",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 11537,
    revenue_at_risk: 503013.2,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 10383,
    revenue_mitigated: 452698.80000000005,
    freight_cost: 48354,
    cost_impact: 48354,
    days_of_supply_impact: 1.97,
  },
  {
    running_risk_id: 227,
    risk_date: 222 + 45303,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 24132,
    revenue_at_risk: 615366,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 19547,
    revenue_mitigated: 498448.5,
    freight_cost: 47583,
    cost_impact: 47583,
    days_of_supply_impact: 3.58,
  },
  {
    running_risk_id: 228,
    risk_date: 222 + 45305,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1001,
    sku_number: "SKU-135",
    quantity_at_risk: 36622,
    revenue_at_risk: 1772504.8,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 30030,
    revenue_mitigated: 1453452,
    freight_cost: 47122,
    cost_impact: 47122,
    days_of_supply_impact: 3.93,
  },
  {
    running_risk_id: 229,
    risk_date: 222 + 45305,
    risk_status: "Accepted",
    approval_type: "Automated",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 16995,
    revenue_at_risk: 378988.5,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 16315,
    revenue_mitigated: 363824.5,
    freight_cost: 53260,
    cost_impact: 53260,
    days_of_supply_impact: 3.41,
  },
  {
    running_risk_id: 230,
    risk_date: 222 + 45308,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-233",
    quantity_at_risk: 28377,
    revenue_at_risk: 734964.2999999999,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 23553,
    revenue_mitigated: 610022.7,
    freight_cost: 74319,
    cost_impact: 74319,
    days_of_supply_impact: 3.4,
  },
  {
    running_risk_id: 231,
    risk_date: 222 + 45310,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-839",
    quantity_at_risk: 10909,
    revenue_at_risk: 418905.6,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 9382,
    revenue_mitigated: 360268.8,
    freight_cost: 68394,
    cost_impact: 68394,
    days_of_supply_impact: 3.03,
  },
  {
    running_risk_id: 232,
    risk_date: 222 + 45310,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 22755,
    revenue_at_risk: 650793,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 19114,
    revenue_mitigated: 546660.4,
    freight_cost: 58632,
    cost_impact: 58632,
    days_of_supply_impact: 4.08,
  },
  {
    running_risk_id: 233,
    risk_date: 222 + 45312,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 27939,
    revenue_at_risk: 1299163.5,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 22631,
    revenue_mitigated: 1052341.5,
    freight_cost: 66768,
    cost_impact: 66768,
    days_of_supply_impact: 2.54,
  },
  {
    running_risk_id: 234,
    risk_date: 222 + 45315,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 20711,
    revenue_at_risk: 919568.4,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 18019,
    revenue_mitigated: 800043.6,
    freight_cost: 64645,
    cost_impact: 64645,
    days_of_supply_impact: 2.13,
  },
  {
    running_risk_id: 235,
    risk_date: 222 + 45316,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 32047,
    revenue_at_risk: 1150487.3,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 30124,
    revenue_mitigated: 1081451.6,
    freight_cost: 43595,
    cost_impact: 43595,
    days_of_supply_impact: 3.43,
  },
  {
    running_risk_id: 236,
    risk_date: 222 + 45319,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 35607,
    revenue_at_risk: 601758.2999999999,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 33471,
    revenue_mitigated: 565659.9,
    freight_cost: 45831,
    cost_impact: 45831,
    days_of_supply_impact: 3.36,
  },
  {
    running_risk_id: 237,
    risk_date: 222 + 45321,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 16171,
    revenue_at_risk: 224776.9,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 15848,
    revenue_mitigated: 220287.19999999998,
    freight_cost: 41222,
    cost_impact: 41222,
    days_of_supply_impact: 1.82,
  },
  {
    running_risk_id: 238,
    risk_date: 222 + 45322,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-135",
    quantity_at_risk: 27182,
    revenue_at_risk: 916033.4,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 23105,
    revenue_mitigated: 778638.5,
    freight_cost: 36299,
    cost_impact: 36299,
    days_of_supply_impact: 1.99,
  },
  {
    running_risk_id: 239,
    risk_date: 222 + 45325,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 27573,
    revenue_at_risk: 1031230.2,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 26194,
    revenue_mitigated: 979655.6,
    freight_cost: 44602,
    cost_impact: 44602,
    days_of_supply_impact: 3.52,
  },
  {
    running_risk_id: 240,
    risk_date: 222 + 45328,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 24291,
    revenue_at_risk: 840468.6,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 20890,
    revenue_mitigated: 722794,
    freight_cost: 58266,
    cost_impact: 58266,
    days_of_supply_impact: 2.95,
  },
  {
    running_risk_id: 241,
    risk_date: 222 + 45330,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 32542,
    revenue_at_risk: 579247.6,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck transfer",
    quantity_mitigated: 30264,
    revenue_mitigated: 538699.2,
    freight_cost: 67462,
    cost_impact: 67462,
    days_of_supply_impact: 4.09,
  },
  {
    running_risk_id: 242,
    risk_date: 222 + 45304,
    risk_status: "Rejected",
    rejection_code: "Chassis availability",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 14192,
    revenue_at_risk: 380345.60000000003,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 14050,
    revenue_mitigated: 376540.00000000006,
    freight_cost: 36367,
    cost_impact: 36367,
    days_of_supply_impact: 3.04,
  },
  {
    running_risk_id: 243,
    risk_date: 222 + 45306,
    risk_status: "Rejected",
    rejection_code: "Freight cost budget constraint",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-241",
    quantity_at_risk: 35488,
    revenue_at_risk: 471990.4,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 28745,
    revenue_mitigated: 382308.5,
    freight_cost: 52432,
    cost_impact: 52432,
    days_of_supply_impact: 3.56,
  },
  {
    running_risk_id: 244,
    risk_date: 222 + 45308,
    risk_status: "Rejected",
    rejection_code: "No alternate route available",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-624",
    quantity_at_risk: 21149,
    revenue_at_risk: 594286.9,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 18188,
    revenue_mitigated: 511082.80000000005,
    freight_cost: 66060,
    cost_impact: 66060,
    days_of_supply_impact: 3.09,
  },
  {
    running_risk_id: 245,
    risk_date: 222 + 45310,
    risk_status: "Rejected",
    rejection_code: "Chassis availability",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-233",
    quantity_at_risk: 27814,
    revenue_at_risk: 456149.6,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 22807,
    revenue_mitigated: 374034.79999999993,
    freight_cost: 38604,
    cost_impact: 38604,
    days_of_supply_impact: 3.45,
  },
  {
    running_risk_id: 246,
    risk_date: 222 + 45312,
    risk_status: "Rejected",
    rejection_code: "No alternate route available",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 35516,
    revenue_at_risk: 440398.4,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 30899,
    revenue_mitigated: 383147.60000000003,
    freight_cost: 58132,
    cost_impact: 58132,
    days_of_supply_impact: 4.17,
  },
  {
    running_risk_id: 247,
    risk_date: 222 + 45313,
    risk_status: "Rejected",
    rejection_code: "Chassis availability",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 15065,
    revenue_at_risk: 384157.5,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 14462,
    revenue_mitigated: 368781,
    freight_cost: 50957,
    cost_impact: 50957,
    days_of_supply_impact: 1.93,
  },
  {
    running_risk_id: 248,
    risk_date: 222 + 45315,
    risk_status: "Rejected",
    rejection_code: "Carrier cancellation",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 27094,
    revenue_at_risk: 1276127.4000000001,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 26552,
    revenue_mitigated: 1250599.2000000002,
    freight_cost: 66943,
    cost_impact: 66943,
    days_of_supply_impact: 3.53,
  },
  {
    running_risk_id: 249,
    risk_date: 222 + 45316,
    risk_status: "Rejected",
    rejection_code: "Chassis availability",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 38722,
    revenue_at_risk: 1494669.2,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 38335,
    revenue_mitigated: 1479731,
    freight_cost: 61879,
    cost_impact: 61879,
    days_of_supply_impact: 1.85,
  },
  {
    running_risk_id: 250,
    risk_date: 222 + 45319,
    risk_status: "Rejected",
    rejection_code: "No alternate route available",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-135",
    quantity_at_risk: 28229,
    revenue_at_risk: 527882.2999999999,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 27100,
    revenue_mitigated: 506769.99999999994,
    freight_cost: 44313,
    cost_impact: 44313,
    days_of_supply_impact: 3.72,
  },
  {
    running_risk_id: 251,
    risk_date: 222 + 45320,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-233",
    quantity_at_risk: 26429,
    revenue_at_risk: 425506.9,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 25108,
    revenue_mitigated: 404238.80000000005,
    freight_cost: 59792,
    cost_impact: 59792,
    days_of_supply_impact: 4.16,
  },
  {
    running_risk_id: 252,
    risk_date: 222 + 45323,
    risk_status: "Rejected",
    rejection_code: "Freight cost too high",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 29623,
    revenue_at_risk: 1024955.8,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck booking",
    quantity_mitigated: 27253,
    revenue_mitigated: 942953.8,
    freight_cost: 37443,
    cost_impact: 37443,
    days_of_supply_impact: 2.82,
  },
  {
    running_risk_id: 253,
    risk_date: 222 + 45327,
    risk_status: "Rejected",
    rejection_code: "Carrier cancellation",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-135",
    quantity_at_risk: 36802,
    revenue_at_risk: 599872.6,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck loading",
    quantity_mitigated: 31282,
    revenue_mitigated: 509896.60000000003,
    freight_cost: 48911,
    cost_impact: 48911,
    days_of_supply_impact: 3.37,
  },
  {
    running_risk_id: 254,
    risk_date: 222 + 45329,
    risk_status: "Rejected",
    rejection_code: "Carrier cancellation",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 10999,
    revenue_at_risk: 471857.1,
    action_type: "Rerouted truck transfer",
    execution_stage: "Truck loading",
    quantity_mitigated: 10449,
    revenue_mitigated: 448262.1,
    freight_cost: 67009,
    cost_impact: 67009,
    days_of_supply_impact: 2.72,
  },
  {
    running_risk_id: 255,
    risk_date: 222 + 45352,
    risk_status: "Open",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 15530,
    revenue_at_risk: 433287,
    action_type: "Deferred custom clearance",
    quantity_mitigated: 15064,
    revenue_mitigated: 420285.6,
    freight_cost: 42898,
    cost_impact: 42898,
  },
  {
    running_risk_id: 256,
    risk_date: 222 + 45351,
    risk_status: "In progress",
    risk_location: 1045,
    sku_number: "SKU-233",
    quantity_at_risk: 32236,
    revenue_at_risk: 776887.6000000001,
    action_type: "Deferred custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 31914,
    revenue_mitigated: 769127.4,
    freight_cost: 35282,
    cost_impact: 35282,
  },
  {
    running_risk_id: 257,
    risk_date: 222 + 45349,
    risk_status: "In progress",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 27117,
    revenue_at_risk: 940959.9,
    action_type: "Deferred custom clearance",
    execution_stage: "Truck booking",
    quantity_mitigated: 27117,
    revenue_mitigated: 940959.9,
    freight_cost: 70101,
    cost_impact: 70101,
  },
  {
    running_risk_id: 258,
    risk_date: 222 + 45305,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-135",
    quantity_at_risk: 25101,
    revenue_at_risk: 800721.8999999999,
    action_type: "Deferred custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 22591,
    revenue_mitigated: 720652.8999999999,
    freight_cost: 44242,
    cost_impact: 44242,
  },
  {
    running_risk_id: 259,
    risk_date: 222 + 45306,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 32938,
    revenue_at_risk: 408431.2,
    action_type: "Deferred custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 30632,
    revenue_mitigated: 379836.8,
    freight_cost: 35911,
    cost_impact: 35911,
  },
  {
    running_risk_id: 260,
    risk_date: 222 + 45313,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 30007,
    revenue_at_risk: 891207.9,
    action_type: "Deferred custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 29107,
    revenue_mitigated: 864477.9,
    freight_cost: 66054,
    cost_impact: 66054,
  },
  {
    running_risk_id: 261,
    risk_date: 222 + 45317,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 13651,
    revenue_at_risk: 345370.3,
    action_type: "Deferred custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 13378,
    revenue_mitigated: 338463.39999999997,
    freight_cost: 46256,
    cost_impact: 46256,
  },
  {
    running_risk_id: 262,
    risk_date: 222 + 45320,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-135",
    quantity_at_risk: 13782,
    revenue_at_risk: 620190,
    action_type: "Deferred custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 11439,
    revenue_mitigated: 514755,
    freight_cost: 64620,
    cost_impact: 64620,
  },
  {
    running_risk_id: 263,
    risk_date: 222 + 45325,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 32501,
    revenue_at_risk: 1501546.2000000002,
    action_type: "Deferred custom clearance",
    execution_stage: "Truck transfer",
    quantity_mitigated: 30551,
    revenue_mitigated: 1411456.2000000002,
    freight_cost: 53343,
    cost_impact: 53343,
  },
  {
    running_risk_id: 264,
    risk_date: 222 + 45327,
    risk_status: "Rejected",
    rejection_code: "Vessel schedule disruptions",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-241",
    quantity_at_risk: 28039,
    revenue_at_risk: 888836.2999999999,
    action_type: "Deferred custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 23833,
    revenue_mitigated: 755506.0999999999,
    freight_cost: 54437,
    cost_impact: 54437,
  },
  {
    running_risk_id: 265,
    risk_date: 222 + 45306,
    risk_status: "Rejected",
    rejection_code: "Vessel schedule disruptions",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-624",
    quantity_at_risk: 31353,
    revenue_at_risk: 1319961.3,
    action_type: "Deferred custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 27904,
    revenue_mitigated: 1174758.4000000001,
    freight_cost: 49466,
    cost_impact: 49466,
  },
  {
    running_risk_id: 266,
    risk_date: 222 + 45313,
    risk_status: "Rejected",
    rejection_code: "Not capacity available for deferred clearance",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-233",
    quantity_at_risk: 31829,
    revenue_at_risk: 404228.3,
    action_type: "Deferred custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 25781,
    revenue_mitigated: 327418.69999999995,
    freight_cost: 64463,
    cost_impact: 64463,
  },
  {
    running_risk_id: 267,
    risk_date: 222 + 45315,
    risk_status: "Rejected",
    rejection_code: "Vessel schedule disruptions",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 22103,
    revenue_at_risk: 828862.5,
    action_type: "Deferred custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 20777,
    revenue_mitigated: 779137.5,
    freight_cost: 46248,
    cost_impact: 46248,
  },
  {
    running_risk_id: 268,
    risk_date: 222 + 45319,
    risk_status: "Rejected",
    rejection_code: "Vessel schedule disruptions",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 17478,
    revenue_at_risk: 501618.6,
    action_type: "Deferred custom clearance",
    execution_stage: "Custom clearance validation",
    quantity_mitigated: 16779,
    revenue_mitigated: 481557.3,
    freight_cost: 48713,
    cost_impact: 48713,
  },
  {
    running_risk_id: 269,
    risk_date: 222 + 45325,
    risk_status: "Rejected",
    rejection_code: "Not capacity available for deferred clearance",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 31366,
    revenue_at_risk: 1474202,
    action_type: "Deferred custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 28857,
    revenue_mitigated: 1356279,
    freight_cost: 55193,
    cost_impact: 55193,
  },
  {
    running_risk_id: 270,
    risk_date: 222 + 45305,
    risk_status: "Rejected",
    rejection_code: "Vessel schedule disruptions",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 24279,
    revenue_at_risk: 1048852.8,
    action_type: "Deferred custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 20152,
    revenue_mitigated: 870566.4000000001,
    freight_cost: 35866,
    cost_impact: 35866,
  },
  {
    running_risk_id: 271,
    risk_date: 222 + 45310,
    risk_status: "Rejected",
    rejection_code: "Not capacity available for deferred clearance",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-135",
    quantity_at_risk: 33250,
    revenue_at_risk: 405650,
    action_type: "Deferred custom clearance",
    execution_stage: "Custom clearance confirmed",
    quantity_mitigated: 30258,
    revenue_mitigated: 369147.6,
    freight_cost: 61593,
    cost_impact: 61593,
  },
  {
    running_risk_id: 272,
    risk_date: 222 + 45316,
    risk_status: "Rejected",
    rejection_code: "Not capacity available for deferred clearance",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-233",
    quantity_at_risk: 17594,
    revenue_at_risk: 288541.6,
    action_type: "Deferred custom clearance",
    execution_stage: "Truck booking",
    quantity_mitigated: 16890,
    revenue_mitigated: 276996,
    freight_cost: 37046,
    cost_impact: 37046,
  },
  {
    running_risk_id: 273,
    risk_date: 222 + 45317,
    risk_status: "Rejected",
    rejection_code: "Not capacity available for deferred clearance",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 36042,
    revenue_at_risk: 609109.7999999999,
    action_type: "Deferred custom clearance",
    execution_stage: "Truck booking",
    quantity_mitigated: 30636,
    revenue_mitigated: 517748.39999999997,
    freight_cost: 68895,
    cost_impact: 68895,
  },
  {
    running_risk_id: 274,
    risk_date: 222 + 45320,
    risk_status: "Rejected",
    rejection_code: "Not capacity available for deferred clearance",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-241",
    quantity_at_risk: 21349,
    revenue_at_risk: 365067.9,
    action_type: "Deferred custom clearance",
    execution_stage: "Truck booking",
    quantity_mitigated: 17506,
    revenue_mitigated: 299352.60000000003,
    freight_cost: 59489,
    cost_impact: 59489,
  },
  {
    running_risk_id: 275,
    risk_date: 222 + 45323,
    risk_status: "Rejected",
    rejection_code: "Not capacity available for deferred clearance",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-624",
    quantity_at_risk: 29263,
    revenue_at_risk: 910079.3,
    action_type: "Deferred custom clearance",
    execution_stage: "Truck loading",
    quantity_mitigated: 29263,
    revenue_mitigated: 910079.3,
    freight_cost: 46468,
    cost_impact: 46468,
  },
  {
    running_risk_id: 276,
    risk_date: 222 + 45329,
    risk_status: "Rejected",
    rejection_code: "Not capacity available for deferred clearance",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-233",
    quantity_at_risk: 22231,
    revenue_at_risk: 593567.7,
    action_type: "Deferred custom clearance",
    execution_stage: "Truck loading",
    quantity_mitigated: 20008,
    revenue_mitigated: 534213.6,
    freight_cost: 64997,
    cost_impact: 64997,
  },
  {
    running_risk_id: 277,
    risk_date: 222 + 45346,
    risk_status: "Open",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 13761,
    revenue_at_risk: 492643.8,
    action_type: "No action",
    quantity_mitigated: 13623,
    revenue_mitigated: 487703.39999999997,
  },
  {
    running_risk_id: 278,
    risk_date: 222 + 45301,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-135",
    quantity_at_risk: 25623,
    revenue_at_risk: 479150.1,
    action_type: "No action",
    quantity_mitigated: 20755,
    revenue_mitigated: 388118.5,
  },
  {
    running_risk_id: 279,
    risk_date: 222 + 45305,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-839",
    quantity_at_risk: 23272,
    revenue_at_risk: 958806.4,
    action_type: "No action",
    quantity_mitigated: 20479,
    revenue_mitigated: 843734.8,
  },
  {
    running_risk_id: 280,
    risk_date: 222 + 45310,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 34696,
    revenue_at_risk: 1419066.4,
    action_type: "No action",
    quantity_mitigated: 31226,
    revenue_mitigated: 1277143.4,
  },
  {
    running_risk_id: 281,
    risk_date: 222 + 45311,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-135",
    quantity_at_risk: 20539,
    revenue_at_risk: 649032.4,
    action_type: "No action",
    quantity_mitigated: 19717,
    revenue_mitigated: 623057.2000000001,
  },
  {
    running_risk_id: 282,
    risk_date: 222 + 45315,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 27520,
    revenue_at_risk: 476096,
    action_type: "No action",
    quantity_mitigated: 25869,
    revenue_mitigated: 447533.7,
  },
  {
    running_risk_id: 283,
    risk_date: 222 + 45316,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-135",
    quantity_at_risk: 27069,
    revenue_at_risk: 1302018.9000000001,
    action_type: "No action",
    quantity_mitigated: 25716,
    revenue_mitigated: 1236939.6,
  },
  {
    running_risk_id: 284,
    risk_date: 222 + 45319,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-839",
    quantity_at_risk: 16769,
    revenue_at_risk: 653991,
    action_type: "No action",
    quantity_mitigated: 14086,
    revenue_mitigated: 549354,
  },
  {
    running_risk_id: 285,
    risk_date: 222 + 45323,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-241",
    quantity_at_risk: 25691,
    revenue_at_risk: 367381.30000000005,
    action_type: "No action",
    quantity_mitigated: 24150,
    revenue_mitigated: 345345.00000000006,
  },
  {
    running_risk_id: 286,
    risk_date: 222 + 45326,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-492",
    quantity_at_risk: 35946,
    revenue_at_risk: 1498948.2000000002,
    action_type: "No action",
    quantity_mitigated: 29835,
    revenue_mitigated: 1244119.5000000002,
  },
  {
    running_risk_id: 287,
    risk_date: 222 + 45327,
    risk_status: "Accepted",
    approval_type: "Manual",
    risk_location: 1003,
    sku_number: "SKU-233",
    quantity_at_risk: 10203,
    revenue_at_risk: 199978.80000000002,
    action_type: "No action",
    quantity_mitigated: 9591,
    revenue_mitigated: 187983.6,
  },
  {
    running_risk_id: 288,
    risk_date: 222 + 45306,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 1001,
    sku_number: "SKU-492",
    quantity_at_risk: 15183,
    revenue_at_risk: 522295.19999999995,
    action_type: "No action",
    quantity_mitigated: 13665,
    revenue_mitigated: 470075.99999999994,
  },
  {
    running_risk_id: 289,
    risk_date: 222 + 45310,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 1045,
    sku_number: "SKU-241",
    quantity_at_risk: 12631,
    revenue_at_risk: 588604.6,
    action_type: "No action",
    quantity_mitigated: 12631,
    revenue_mitigated: 588604.6,
  },
  {
    running_risk_id: 290,
    risk_date: 222 + 45318,
    risk_status: "Rejected",
    rejection_code: "Change of demand",
    approval_type: "Manual",
    risk_location: 1005,
    sku_number: "SKU-839",
    quantity_at_risk: 32837,
    revenue_at_risk: 1132876.5,
    action_type: "No action",
    quantity_mitigated: 29553,
    revenue_mitigated: 1019578.5,
  },
];
