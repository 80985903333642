import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Col, Collapse, Form, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { MainContext } from "../../../../../utils/context";
import { SharedContext } from "../../context/sharedContext";
import { WORK_MODE } from "../../../../../constants/workModes";
import { DescriptionRegex } from "../../../../../constants/RegExp";
import { selectFilterOption } from "../../../../../utils/functions";
import { Currencies } from "../../data/data.currency";
import { Languages } from "../../data/languages";
import { DateFormats } from "../../data/format.date";
import OrganizationActionSettings from "../settings/OrganizationActionSettings";
import { useTranslation } from "react-i18next";
import { isPermission } from "../../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../../constants/permissions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import OrganizationLicensesList from "./OrganizationLicensesList";
import { ORGA_DEFAULT_SETTINGS } from "../../../../../constants/orgaDefaultSettings";

const OrganizationDetails: React.FC = () => {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /**props */

  const { workMode, form, Record, isDisable, setIsDisable } =
    useContext(SharedContext);
  const [initialValOrganization, setInitialOrganization] = useState<any>({});
  const { t } = useTranslation();
  const globalContext = useContext(MainContext);
  useEffect(() => {
    if (!globalContext || !globalContext.context.orga) {
      return;
    }
    if (workMode === WORK_MODE.CREATE || !workMode) {
      setIsDisable(true);
    } else {
      if (workMode === WORK_MODE.UPDATE || workMode === WORK_MODE.VIEW) {
        setIsDisable(Record?.enabled);
      }
    }
    const initialValues =
      workMode === WORK_MODE.CREATE || !workMode
        ? {
            name: "",
            description: "",
            emailAdmin: "",
            formatNumber: ORGA_DEFAULT_SETTINGS?.DEFAULT_NUMBER_FORMAT,
            formatDate: ORGA_DEFAULT_SETTINGS?.DEFAULT_DATE,
            currency: ORGA_DEFAULT_SETTINGS?.DEFAULT_CURRENCY,
            language: ORGA_DEFAULT_SETTINGS?.DEFAULT_LANGUAGE,
            enabled: true,
          }
        : {
            name: Record?.name,
            description: Record?.description,
            emailAdmin: Record?.emailAdmin,
            formatNumber:
              Record?.formatNumber ||
              ORGA_DEFAULT_SETTINGS?.DEFAULT_NUMBER_FORMAT,
            formatDate:
              Record?.formatDate || ORGA_DEFAULT_SETTINGS?.DEFAULT_DATE,
            currency:
              Record?.currency || ORGA_DEFAULT_SETTINGS?.DEFAULT_CURRENCY,
            language:
              Record?.language || ORGA_DEFAULT_SETTINGS?.DEFAULT_LANGUAGE,
            enabled: Record?.enabled,
          };
    form.setFieldsValue(initialValues);
    setInitialOrganization(initialValues);
  }, [workMode, Record, form]);

  if (!globalContext) {
    return <></>;
  }
  return (
    <div
      style={{
        top: 30,
        background: "transparent",
        display: "flex",
        flexDirection: "column",
        paddingBottom: 20,
        alignItems: "left",
        margin: "20px 0",
        justifyContent: "space-between",
      }}
    >
      <Collapse
        className="panel-style"
        accordion
        expandIconPosition="end"
        size="large"
        defaultActiveKey="menu"
        style={{ marginBottom: "40px", height: "100%" }}
      >
        <Collapse.Panel
          className="panel-style"
          header={
            <span>
              {" "}
              {t(`labels:DETAILS`, {
                item: t("ORGANIZATION"),
              })}
            </span>
          }
          key="menu"
        >
          <Form
            preserve={false}
            form={form}
            name="wrap"
            {...{ labelCol: { style: { width: 200 } } }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            initialValues={initialValOrganization}
          >
            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t("ORGANIZATION_NAME")}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:NAME`, {
                          item: t("ORGANIZATION"),
                        }),
                      }),
                    },
                  ]}
                >
                  <Input
                    readOnly={
                      workMode === WORK_MODE.VIEW ||
                      !isPermission(
                        permissionsList,
                        PERMISSIONS_LIST.EXOBRAIN_ADMIN
                      )
                        ? true
                        : false
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t("DESCRIPTION")}
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t("DESCRIPTION"),
                      }),
                    },
                    {
                      pattern: DescriptionRegex,
                      message: t(`errors:INVALID`, {
                        label: t("DESCRIPTION"),
                      }),
                    },
                  ]}
                >
                  <Input
                    readOnly={
                      workMode === WORK_MODE.VIEW ||
                      !isPermission(
                        permissionsList,
                        PERMISSIONS_LIST.EXOBRAIN_ADMIN
                      )
                        ? true
                        : false
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t("EMAIL_ADMIN")}
                  name="emailAdmin"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t("EMAIL_ADMIN"),
                      }),
                    },
                    {
                      type: "email",
                      message: t(`errors:INVALID`, {
                        label: t("EMAIL_ADMIN"),
                      }),
                    },
                  ]}
                >
                  <Input
                    readOnly={
                      workMode === WORK_MODE.VIEW ||
                      !isPermission(
                        permissionsList,
                        PERMISSIONS_LIST.EXOBRAIN_ADMIN
                      )
                        ? true
                        : false
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {/* Disable */}
              {isPermission(
                permissionsList,
                PERMISSIONS_LIST.EXOBRAIN_ADMIN
              ) && (
                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Form.Item name="enabled">
                    <Checkbox
                      checked={isDisable}
                      onChange={() => {
                        setIsDisable(!isDisable);
                      }}
                      disabled={workMode === WORK_MODE.VIEW ? true : false}
                    >
                      {t("ENABLED")}
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      {isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) &&
        globalContext?.context?.orga?.id === Record?.id && (
          <Collapse
            className="panel-style"
            accordion
            expandIconPosition="end"
            size="large"
            defaultActiveKey="setting"
          >
            <Collapse.Panel
              className="panel-style"
              header={
                <span>
                  {" "}
                  {t(`labels:SETTINGS`, {
                    item: t("ORGANIZATION"),
                  })}{" "}
                </span>
              }
              key="setting"
            >
              <Form
                form={form}
                name="wrap"
                {...{ labelCol: { style: { width: 200 } } }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
              >
                <Row>
                  <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                    <Form.Item
                      label={t("CURRENCY")}
                      name="currency"
                      rules={[
                        {
                          required: true,
                          message: t(`errors:REQUIRED`, {
                            label: t("CURRENCY"),
                          }),
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          selectFilterOption(input, option)
                        }
                        disabled={workMode === WORK_MODE.VIEW ? true : false}
                      >
                        {Object.entries(Currencies).map(([id, name]) => (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                    <Form.Item
                      label={t("PREFFERED_LANGUAGE")}
                      name="language"
                      rules={[
                        {
                          required: true,
                          message: t(`errors:REQUIRED`, {
                            label: t("PREFFERED_LANGUAGE"),
                          }),
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          selectFilterOption(input, option)
                        }
                        disabled={workMode === WORK_MODE.VIEW ? true : false}
                      >
                        {Object.entries(Languages).map(([id, [name]]) => (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                {/*/**Date format */}
                <Row>
                  <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                    <Form.Item
                      label={t("FORMAT_DATE")}
                      name="formatDate"
                      rules={[
                        {
                          required: true,
                          message: t(`errors:REQUIRED`, {
                            label: t("FORMAT_DATE"),
                          }),
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          selectFilterOption(input, option)
                        }
                        disabled={workMode === WORK_MODE.VIEW ? true : false}
                      >
                        {DateFormats.map((format, index) => (
                          <Select.Option key={index} value={format}>
                            {dayjs("2024-12-31").format(format)}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {/** number format  */}
                <Row>
                  {" "}
                  <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                    <Form.Item
                      label={t("FORMAT_NUMBER")}
                      name="formatNumber"
                      rules={[
                        {
                          required: true,
                          message: t(`errors:REQUIRED`, {
                            label: t("FORMAT_NUMBER"),
                          }),
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) =>
                          selectFilterOption(input, option)
                        }
                        disabled={workMode === WORK_MODE.VIEW ? true : false}
                      >
                        {Object.entries(Languages).map(([id, [name]]) => (
                          <Select.Option key={id} value={id}>
                            {name}
                            {" : "}
                            <span style={{ color: "#004c97" }}>
                              {new Intl.NumberFormat(id, {
                                maximumSignificantDigits: 3,
                              }).format(1554777.123123)}
                            </span>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Collapse.Panel>
          </Collapse>
        )}
      {workMode === WORK_MODE.VIEW && (
        <Collapse
          className="panel-style"
          accordion
          expandIconPosition="end"
          size="large"
          defaultActiveKey="licenses"
          style={{ marginTop: "40px", height: "100%" }}
        >
          <Collapse.Panel
            className="panel-style"
            header={
              <span>
                {" "}
                {t(`labels:Licenses`, {
                  item: t("ORGANIZATION"),
                })}{" "}
              </span>
            }
            key="licenses"
          >
            <OrganizationLicensesList orga={Record} />
          </Collapse.Panel>
        </Collapse>
      )}
      {/** dynamic fields */}
      {workMode !== WORK_MODE.CREATE &&
        isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) &&
        globalContext?.context?.orga?.id === Record?.id && (
          <OrganizationActionSettings />
        )}
    </div>
  );
};

export default OrganizationDetails;
