import React, { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  Col,
  Collapse,
  ColorPicker,
  Form,
  Input,
  Row,
  Select,
  Tag,
  Typography,
} from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { Reason } from "../../../../../interfaces/reasons";
import { SharedContext } from "../../context/sharedContext";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../../logic/PredefinedsLogic";
import { WORK_MODE } from "../../../../../constants/workModes";
import { selectFilterOption } from "../../../../../utils/functions";
import DataNeededDynamicFields from "../dynamic-fields/DataNeededDynamicFields";
import { LockOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../utils/transaltion";

const PreExecutionDetails: React.FC<{
  typeComponent: string;
}> = (props) => {
  /**props */
  const { typeComponent } = props;
  /** translation  */
  const { t } = useTranslation();
  /** roles from redux */
  const rolesRedux = useSelector((state: RootState) => state.roleReducer);
  /**reasons from Redux */
  const executionReasonsRedux: Reason[] = useSelector(
    (state: RootState) => state.reasonsExecutionsReducer
  );
  const {
    isAuto,
    setIsAuto,
    isDisable,
    setIsDisable,
    executionColor,
    setExecutionColor,
    workMode,
    form,
    Record,
  } = useContext(SharedContext);
  const { handleUpdateExecution } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;

  const [initialValExec, setInitialValExec] = useState<any>({});
  useEffect(() => {
    console.log(executionColor);
  }, [executionColor]);

  useEffect(() => {
    handleUpdateExecution(Record);
    if (workMode === WORK_MODE.CREATE || !workMode) {
      setIsDisable(true);
    }
    const initialValues =
      workMode === WORK_MODE.CREATE || !workMode
        ? {
            name: "",
            model: "",
            copilot_name: "",
            uiConfiguration: "#DBE7F1",
            reasons:
              executionReasonsRedux
                ?.filter((reason: any) => reason.is_system)
                .map((reason: any) => reason.id) || [],
            enabled: true,
          }
        : {
            name: Record?.name,
            roles: Record?.roles?.map((role: any) => role.id),
            reasons: Record?.reasons?.map((reason: any) => reason.id),
            uiConfiguration: Record?.uiConfiguration?.color || "#DBE7F1",
            canAuto: Record?.canAuto,
            enabled: Record?.enabled,
          };

    form.setFieldsValue(initialValues);

    setInitialValExec(initialValues);
  }, [workMode, Record, executionReasonsRedux, typeComponent, form]);

  return (
    <div style={{ marginTop: 20, width: "100%", marginBottom: "20px" }}>
      <Collapse
        className="panel-style"
        accordion
        expandIconPosition="end"
        size="large"
        defaultActiveKey="menu"
      >
        <Collapse.Panel
          className="panel-style"
          header={
            <span>
              {" "}
              {t(`labels:DETAILS`, {
                item: t(typeComponent),
              })}{" "}
            </span>
          }
          key="menu"
        >
          <Form
            form={form}
            preserve={false}
            name="wrap"
            {...{ labelCol: { style: { width: 200 } } }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            initialValues={initialValExec}
          >
            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t(`labels:NAME`, {
                    item: t(typeComponent),
                  })}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:NAME`, {
                          item: t(typeComponent),
                        }),
                      }),
                    },
                  ]}
                >
                  <Input
                    readOnly={workMode === WORK_MODE.VIEW}
                    disabled={workMode === WORK_MODE.VIEW}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t("ROLES")}
                  name="roles"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:ROLES`),
                      }),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      selectFilterOption(input, option)
                    }
                    mode="multiple"
                    disabled={workMode === WORK_MODE.VIEW}
                    onChange={(value) => {}}
                  >
                    {rolesRedux?.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {getTranslation(item.name, "data")}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t("REASON_CODE")}
                  name="reasons"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`labels:REASON_CODE`),
                      }),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      selectFilterOption(input, option)
                    }
                    style={{ width: "90%" }}
                    mode="multiple"
                    disabled={workMode === WORK_MODE.VIEW}
                    onChange={(value) => {}}
                    tagRender={(props: any) => {
                      const { label, value, closable, onClose } = props;
                      const is_sys = executionReasonsRedux?.find(
                        (obj: any) => obj.id === value
                      )?.is_system;
                      return (
                        <Tag
                          color={is_sys ? "red" : "blue"}
                          closable={workMode !== WORK_MODE.VIEW && !is_sys}
                          onClose={onClose}
                        >
                          {label}
                          {is_sys ? (
                            <LockOutlined style={{ marginInline: "4px" }} />
                          ) : (
                            ""
                          )}
                        </Tag>
                      );
                    }}
                  >
                    {executionReasonsRedux?.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {getTranslation(item.name, "data")}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Form.Item
                  label={t("COLOR_ON_CHARTS")}
                  name="uiConfiguration"
                  rules={[
                    {
                      required: true,
                      message: t(`errors:REQUIRED`, {
                        label: t(`translation:COLOR_ON_CHARTS`),
                      }),
                    },
                  ]}
                >
                  <ColorPicker
                    disabled={workMode === WORK_MODE.VIEW ? true : false}
                    defaultValue={executionColor}
                    value={executionColor}
                    onChange={(value, hex) => {
                      setExecutionColor(hex);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              {/* Disable */}
              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Form.Item name="enabled">
                  <Checkbox
                    disabled={true}
                    checked={isDisable}
                    onChange={() => {
                      setIsDisable(!isDisable);
                    }}
                  >
                    {t("ENABLED")}
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                <Form.Item name="canAuto">
                  <Checkbox
                    disabled={workMode === WORK_MODE.VIEW}
                    checked={isAuto}
                    onChange={() => {
                      setIsAuto(!isAuto);
                    }}
                  >
                    {t("AUTO_FEASIBLE")}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Collapse.Panel>
      </Collapse>
      <div style={{ marginTop: 40, width: "100%", marginBottom: "20px" }}>
        <Collapse
          className="panel-style"
          accordion
          expandIconPosition="end"
          size="large"
          destroyInactivePanel={true}
          defaultActiveKey="settings"
        >
          <Collapse.Panel
            className="panel-style"
            header={
              <span> {t("SETTINGS", { item: t("EXECUTION_PREDEFINED") })}</span>
            }
            key="settings"
          >
            <Form
              preserve={false}
              form={form}
              name="wrap"
              {...{ labelCol: { style: { width: 200 } } }}
              labelAlign="left"
              labelWrap
              wrapperCol={{ flex: 1 }}
            >
              <Typography className="typography-setting">
                {t("DATA_NEEDED")}
              </Typography>
              <DataNeededDynamicFields />
            </Form>
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default PreExecutionDetails;
