import { Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const CustomExclamationIcon = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("INFO")} color={"#1677ff"} key={"#1677ff"}>
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="exclamation-circle"
        width="2rem"
        height="2rem"
        fill="currentColor"
        aria-hidden="true"
        color="#1677ff"
      >
        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path>
      </svg>
    </Tooltip>
  );
};

export const CustomErrorIcon = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("ERROR")} color={"#ff4d4f"} key={"#ff4d4f"}>
      <svg
        fillRule="evenodd"
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="close-circle"
        width="2rem"
        height="2rem"
        fill="currentColor"
        aria-hidden="true"
        color="#ff4d4f"
      >
        <path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64zm127.98 274.82h-.04l-.08.06L512 466.75 384.14 338.88c-.04-.05-.06-.06-.08-.06a.12.12 0 00-.07 0c-.03 0-.05.01-.09.05l-45.02 45.02a.2.2 0 00-.05.09.12.12 0 000 .07v.02a.27.27 0 00.06.06L466.75 512 338.88 639.86c-.05.04-.06.06-.06.08a.12.12 0 000 .07c0 .03.01.05.05.09l45.02 45.02a.2.2 0 00.09.05.12.12 0 00.07 0c.02 0 .04-.01.08-.05L512 557.25l127.86 127.87c.04.04.06.05.08.05a.12.12 0 00.07 0c.03 0 .05-.01.09-.05l45.02-45.02a.2.2 0 00.05-.09.12.12 0 000-.07v-.02a.27.27 0 00-.05-.06L557.25 512l127.87-127.86c.04-.04.05-.06.05-.08a.12.12 0 000-.07c0-.03-.01-.05-.05-.09l-45.02-45.02a.2.2 0 00-.09-.05.12.12 0 00-.07 0z"></path>
      </svg>
    </Tooltip>
  );
};

export const CustomWarningIcon = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("WARNING")} color={"#faad14"} key={"#faad14"}>
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="exclamation-triangle"
        width="2rem"
        height="2rem"
        fill="currentColor"
        aria-hidden="true"
        color="#faad14"
      >
        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path>
      </svg>
    </Tooltip>
  );
};
