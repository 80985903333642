import React, { useContext, useEffect } from "react";
import { Collapse, Form, Button, Tooltip, Col } from "antd";
import DndCxt from "../../../../common/dnd-list/DnDContext";
import { WORK_MODE } from "../../../../../constants/workModes";
import { SharedContext } from "../../context/sharedContext";
import { ActionsDndListDef } from "../columnsDef/ActionsDndList.def";
import { useTranslation } from "react-i18next";
import { isPermission } from "../../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../../constants/permissions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";

const RiskActionsSection: React.FC<{ workModeRisk: any }> = ({
  workModeRisk,
}) => {
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  const { t } = useTranslation();
  /** confs shared context */
  const {
    setActionRecord,
    riskActions,
    setRiskActions,
    predefinedRiskId,
    setOpenActionModal,
    setWorkModeAction,
  } = useContext(SharedContext);
  /** action dnd table columns definition */
  const columns = ActionsDndListDef(workModeRisk);

  return (
    <div style={{ top: 30, width: "100%", marginBottom: "20px" }}>
      <Collapse
        className="panel-style"
        accordion
        expandIconPosition="end"
        size="large"
      >
        <Collapse.Panel
          className="panel-style"
          header={<span> {t("ACTIONS")}</span>}
          key="menu_action"
        >
          <Form>
            <div style={{ marginBlockEnd: "5" }}>
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  overflow: "auto",
                  maxHeight: "130px",
                }}
              >
                <Col>
                  <Form.Item name="risk_actions">
                    <DndCxt
                      columns={columns}
                      dataSource={riskActions}
                      setDataSource={setRiskActions}
                    />
                  </Form.Item>
                </Col>
              </div>
            </div>
            <div style={{ display: "flex", paddingTop: 5, marginTop: 0 }}>
              <Tooltip
                placement="left"
                title={
                  predefinedRiskId === ""
                    ? t("errors:SELECT_PREDEFINED_RISK_FIRST")
                    : ""
                }
              >
                {workModeRisk !== WORK_MODE.VIEW &&
                  isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) && (
                    <Button
                      style={{
                        marginLeft: "auto",
                      }}
                      className="button-primary"
                      type="primary"
                      onClick={() => {
                        setWorkModeAction(WORK_MODE.CREATE);
                        setOpenActionModal(true);
                        setActionRecord({});
                      }}
                      disabled={predefinedRiskId !== "" ? false : true}
                    >
                      {t("ADD_BUTTON_TEXT")}
                      {t("ACTION")}
                    </Button>
                  )}
              </Tooltip>
            </div>
            <Form.Item></Form.Item>
          </Form>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default RiskActionsSection;
