import { t } from "i18next";

export const languages = {
  "en-US": "ENGLISH",
  "fr-FR": "FRENCH",
  "ar-DZ": "ARABIC",
};
export const LanguagesEnum = {
  "en-US": { text: t("ENGLISH") },
  "fr-FR": { text: t("FRENCH") },
  "ar-DZ": { text: t("ARABIC") },
};
