import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

type ReasonsExecutions = {
  id: string;
  is_system: boolean;
  name: string;
};
const initialState: Array<ReasonsExecutions> = [];

export const reasonsExecutionsSlice = createSlice({
  name: "reasonsExecutions",
  initialState,
  reducers: {
    addReasonsExecutions: (state, action: PayloadAction<ReasonsExecutions>) => {
      state.push(action.payload);
    },
    setReasonsExecutions: (
      state,
      action: PayloadAction<Array<ReasonsExecutions>>
    ) => {
      return action.payload;
    },
    updateReasonsExecutions: (
      state,
      action: PayloadAction<ReasonsExecutions>
    ) => {
      const { id, name, is_system } = action.payload;
      const existingReasonsExecutions = state.find(
        (ReasonsExecutions) => ReasonsExecutions.id === id
      );
      if (existingReasonsExecutions) {
        existingReasonsExecutions.name = name;
        existingReasonsExecutions.is_system = is_system;
      }
    },
  },
});
export const {
  addReasonsExecutions,
  setReasonsExecutions,
  updateReasonsExecutions,
} = reasonsExecutionsSlice.actions;

export const ReasonsExecutionsSelector = (state: RootState) =>
  state.reasonsExecutionsReducer;

export default reasonsExecutionsSlice.reducer;
