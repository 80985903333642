import { setPredefinedRisksGlobal } from "../../reducers/predefinedRisksGlobal.Reducer";

/** dispatch predefined risk */
export const InitRisksGlobal = async (risksServices: any, dispatch: any) => {
  return risksServices
    .getResources({ pageSize: 1000, sort: false }, ["copilot", "actions"])
    .then(async (res: any) => {
      dispatch(setPredefinedRisksGlobal(res?.items));
    })
    .catch((err: any) => {
      console.log(err);
    });
};
