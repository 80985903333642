import { setPredefinedExecutionGlobal } from "../../reducers/predefinedExecutionsGlobal.Reducer";

/** dispatch predefined Execution global list*/
export const InitExecutionsGlobal = async (
  executionsServices: any,
  dispatch: any
) => {
  return executionsServices
    .getPredefinedExecutionGlobal()
    .then(async (res: any) => {
      dispatch(setPredefinedExecutionGlobal(res?.items));
    })
    .catch((err: any) => {
      console.log(err);
    });
};
