import React, { useContext } from "react";
import { Button, Space, Popconfirm, FormInstance } from "antd";
import { WORK_MODE } from "../../../../constants/workModes";
import { SharedContext } from "../context/sharedContext";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/PredefinedsLogic";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store/store";
import { isPermission } from "../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../constants/permissions";

const SaveCancelBar: React.FC<{
  typeComponent: string;
  form: FormInstance<any>;
}> = (props) => {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  const { form, typeComponent } = props;
  /** translation  */
  const { t } = useTranslation();
  /**confs shared context */
  const { workMode, Record, setGetLoading, getLoading } =
    useContext(SharedContext);

  /** get shared logic */

  const { goToTable, onFinish } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  return (
    <div
      style={{
        marginInlineStart: -23,
        backgroundColor: "white",
        zIndex: 999,
        position: "fixed",
        width: "100%",
        bottom: 0,
      }}
    >
      <div
        style={{
          paddingTop: 5,
          marginBottom: 25,
          alignSelf: "left",
          width: "30%",
          zIndex: 99999,
          height: 20,
        }}
      >
        {" "}
        {(isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) ||
          isPermission(permissionsList, PERMISSIONS_LIST.EXOBRAIN_ADMIN)) && (
          <Space size={"small"}>
            {workMode !== WORK_MODE.VIEW && (
              <Popconfirm
                placement="top"
                key="delete"
                style={{ zIndex: 9999999 }}
                disabled={false}
                title={t("errors:CANCEL_LEAVING_PAGE_MESSAGE")}
                onConfirm={async () => {
                  form.resetFields();
                  await goToTable(typeComponent);
                }}
                okText={t("YES")}
                cancelText={t("NO")}
              >
                <Button
                  className="cancel-button"
                  onClick={() => {
                    goToTable(typeComponent);
                    form.resetFields();
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </Popconfirm>
            )}
            {workMode !== WORK_MODE.VIEW && (
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                className="save-button"
                loading={getLoading}
                onClick={() =>
                  onFinish(typeComponent, form, setGetLoading, Record?.id)
                }
              >
                {t("SAVE")}
              </Button>
            )}
          </Space>
        )}
      </div>

      <div style={{ marginBottom: 5, alignSelf: "right" }}></div>
    </div>
  );
};

export default SaveCancelBar;
