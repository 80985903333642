import { Card, Col, List, Row, Spin, Tag, Typography } from "antd";
import { getTranslation } from "../../../../../utils/transaltion";
import {
  FormatCurrency,
  getFormatedValue,
} from "../../../../../utils/datatypesFunctions";
import { useContext } from "react";
import { MainContext } from "../../../../../utils/context";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { useTranslation } from "react-i18next";
import { Flag } from "../../../../../assets/images";
import moment from "moment";
import { CalendarOutlined, StarOutlined } from "@ant-design/icons";
const { Text } = Typography;

export const RunningOpenActionsVirtualListDescription = (props: any) => {
  const { item } = props;
  const globalContext = useContext(MainContext);
  const { t } = useTranslation();
  const orgaFormatNumber =
    globalContext?.context?.orga?.formatNumber || "en-US";
  const orgaCurrency = globalContext?.context?.orga?.currency || "EUR";
  const orgaDateFormat =
    globalContext?.context?.orga?.formatDate || "DD/MM/YYYY";

  /** get predefined risks from redux */
  const predefinedRiskList = useSelector(
    (state: RootState) => state.predefinedRiskOrgaReducer
  );

  return (
    <>
      <Card>
        <Row
          style={{
            marginInline: "-30px",
            marginBlock: "-18px",
          }}
        >
          <Col span={11}>
            <Text>{t("Action name")}</Text>
            <div className="card-item">
              <Flag className="icon-blue" />
              <Text className="item-value">
                {item?.current_action?.action_conf_name}
              </Text>
            </div>
          </Col>
          <Col span={7}>
            <Text>Due Date</Text>
            <div className="card-item">
              <CalendarOutlined className="icon-blue" />
              <Text>
                {moment(item?.due_date).format(orgaDateFormat || "DD/MM/YYYY")}
              </Text>
            </div>
          </Col>
          <Col span={6}>
            <Text>Impact</Text>
            <div className="card-item">
              <StarOutlined className="icon-blue" />
              <Text>
                {item?.current_action?.contexts?.at(0)?.data?.ACTION_DETAILS
                  ?.FREIGHT_COST
                  ? FormatCurrency(
                      item?.current_action?.contexts?.at(0)?.data
                        ?.ACTION_DETAILS?.FREIGHT_COST as number,
                      orgaFormatNumber,
                      null,
                      orgaCurrency,
                      0,
                      0
                    )
                  : item?.current_action?.contexts?.at(0)?.data?.ACTION_DETAILS
                      ?.PARTIAL_FREIGHT_COST
                  ? FormatCurrency(
                      item?.current_action?.contexts?.at(0)?.data
                        ?.ACTION_DETAILS?.PARTIAL_FREIGHT_COST as number,
                      orgaFormatNumber,
                      null,
                      orgaCurrency,
                      0,
                      0
                    )
                  : item?.current_action?.contexts?.at(0)?.data?.ACTION_DETAILS
                      ?.TOTAL_FREIGHT_COST
                  ? FormatCurrency(
                      item?.current_action?.contexts?.at(0)?.data
                        ?.ACTION_DETAILS?.TOTAL_FREIGHT_COST as number,
                      orgaFormatNumber,
                      null,
                      orgaCurrency,
                      0,
                      0
                    )
                  : "-"}
              </Text>
            </div>
          </Col>
        </Row>
        <Row
          style={{
            marginInline: "-30px",
            marginTop: 20,
            marginBlockEnd: "-20px",
          }}
        >
          {item?.context &&
            Object.entries(item.context).map(([key, value]) => (
              <Col xs={24} sm={12} md={8} lg={8} key={key}>
                <Text style={{ color: "#7E7E7E" }}>
                  {getTranslation(key, "data")}
                </Text>
                <div className="card-item-2">
                  <Text>
                    {getFormatedValue(
                      item.context[key],
                      key,
                      {
                        formatNumber: orgaFormatNumber,
                        currency: orgaCurrency,
                        formatDate: orgaDateFormat,
                      },
                      {
                        [key]: {
                          type: predefinedRiskList?.find(
                            (obj: any) => obj.id === item?.risk_model
                          )?.policyData[key].TYPE,
                        },
                      },
                      false
                    )}
                  </Text>
                </div>
              </Col>
            ))}
        </Row>
      </Card>
    </>
  );
};
