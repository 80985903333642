import React, { useState } from "react";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { blue } from "@ant-design/colors";
import { Sortingtypes } from "../../../../constants/sortingTypes";

interface CustomSortIconProps {
  fieldKey: string;
  selectedIcon: string | null;
  onClick: (fieldKey: string, sortOrder: Sortingtypes) => void;
}

const CustomSelectableSortingIcon: React.FC<CustomSortIconProps> = ({
  fieldKey,
  selectedIcon,
  onClick,
}) => {
  const handleIconClick = (sortOrder: Sortingtypes) => {
    onClick(fieldKey, sortOrder);
  };
  const [isHoveredUp, setIsHoveredUp] = useState<boolean>(false);
  const [isHoveredDown, setIsHoveredDown] = useState<boolean>(false);

  return (
    <div
      style={{
        display: "inline-block",
        cursor: "pointer",
        textAlign: "center",
      }}
    >
      <CaretUpOutlined
        onMouseEnter={() => setIsHoveredUp(true)}
        onMouseLeave={() => setIsHoveredUp(false)}
        className="sorting-icons"
        style={{
          marginBottom: -3,
          color:
            selectedIcon === "asc" || isHoveredUp ? blue.primary : "#bfbfbf",
          display: "block",
        }}
        onClick={() => handleIconClick("asc")}
      />
      <CaretDownOutlined
        onMouseEnter={() => setIsHoveredDown(true)}
        onMouseLeave={() => setIsHoveredDown(false)}
        className="sorting-icons"
        style={{
          marginTop: -3,
          color:
            selectedIcon === "desc" || isHoveredDown ? blue.primary : "#bfbfbf",
          display: "block",
        }}
        onClick={() => handleIconClick("desc")}
      />
    </div>
  );
};

export default CustomSelectableSortingIcon;
