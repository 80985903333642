import { Card, Empty } from "antd";
import { MdLibraryAdd } from "react-icons/md";

interface NewStatCardProps {
  createStatConfModal: () => void;
}

const NewStatCard = (props: NewStatCardProps) => {
  const { createStatConfModal: addChartConfModal } = props;

  return (
    <Card style={{ height: "100%" }} onClick={addChartConfModal}>
      <Empty
        style={{
          height: 30,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        image={
          <MdLibraryAdd
            style={{
              paddingBlockStart: "10px",
              fontSize: "32px",
              color: "#0082ba",
            }}
          />
        }
        description={
          <span style={{ color: "#0082ba" }}>Add new statistic</span>
        }
      />
    </Card>
  );
};

export default NewStatCard;
