import { setUserCopilot } from "../../reducers/userCopilot.Reducer";

export const InitUserCopilot = async (copilotServices: any, dispatch: any) => {
  return copilotServices
    .getUserCopilots()
    .then(async (res: any) => {
      console.log("redux user copilot", res);
      dispatch(setUserCopilot(res?.data?.items));
    })
    .catch((err: any) => {
      console.log(err);
    });
};
