export const CHART_TYPES = [
  { label: "Pie chart", name: "PIE_CHART", config: { group: false } },
  {
    label: "Bar chart",
    name: "BAR_CHART",
    config: { dual: true, group: true },
  },
  {
    label: "Column chart",
    name: "COLUMN_CHART",
    config: { dual: true, group: true },
  },
  {
    label: "Line chart",
    name: "LINE_CHART",
    config: { dual: true, group: true },
  },
  {
    label: "Water fall chart",
    name: "WATER_FALL_CHART",
    config: { dual: false, group: false },
  },
  {
    label: "Gauge chart",
    name: "GAUGE_CHART",
    config: { dual: false, group: false },
  },
  {
    label: "Table view",
    name: "TABLE_VIEW",
    config: { dual: false, group: false },
  },
  {
    label: "Map view",
    name: "MAP_VIEW",
    config: { dual: false, group: false },
  },
  /* {
    label: "Dual x-axis chart",
    name: "DUAL_X_AXIS_CHART",
    config: { disabled: true },
  }, */
];
