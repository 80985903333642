import { ProColumns } from "@ant-design/pro-components";
import { ActionItem } from "../../../../../interfaces/actions";
import MenuOptions from "../../../../common/menu/MenuOptions";
import { WORK_MODE } from "../../../../../constants/workModes";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/ActionLogic";
import { SharedContext } from "../../context/sharedContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../utils/transaltion";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { isPermission } from "../../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../../constants/permissions";
import { useLocation, useNavigate } from "react-router-dom";

export const ActionsTableDef: any = (
  valueEnumPredefinedRisk: any,
  valueEnumPredefinedAction: any
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const updateURL = (uuidAction: string, mode: string) => {
    const currentPath = location.pathname; // Current URL path
    const newPath = `${currentPath}/action/${uuidAction}`; // Append '/action/id'
    // Replace the current URL without navigating
    navigate(newPath, { replace: true, state: { mode: mode } });
  };
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /**translation */
  const { t } = useTranslation();
  const {
    showActionModal,
    changeWorkMode,
    setRecordAction,
    deleteAction,
    getPRedefinedActionIdsByName,
  } = useSharedContextFunctions() as SharedContextFunctionsInterface;
  /**confs shared context */
  const { confirmDeleteLoading } = useContext(SharedContext);

  const columns: ProColumns<ActionItem>[] = [
    {
      title: t("ACTIONS"),
      dataIndex: "name",
      width: "30%",
      valueType: "text",
      formItemProps: {
        rules: [
          {
            required: true,
            message: "",
          },
        ],
      },
    },
    {
      title: t("RISK"),
      dataIndex: "risk",
      width: "30%",
      valueType: "select",
      valueEnum: valueEnumPredefinedRisk,
      fieldProps: {
        showSearch: true, // Enable search in filter
        optionFilterProp: "label", // Search by label
        filterOption: (input: any, option: any) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      },
      render: (text, record) => record.risk?.name,
    },

    {
      title: t("ACTION_PREDEFINED"),
      dataIndex: "model",
      valueType: "select",
      valueEnum: valueEnumPredefinedAction,
      fieldProps: {
        showSearch: true, // Enable search in filter
        optionFilterProp: "label", // Search by label
        filterOption: (input: any, option: any) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      },
      search: {
        transform: (value) => {
          return {
            model: {
              value: getPRedefinedActionIdsByName(value),
              operator: "OR",
            },
          };
        },
      },
      width: "20%",
      hideInTable: false,
      render: (text, record) => {
        return getTranslation(record?.model?.name || "", "data");
      },
    },
    isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)
      ? {
          title: t("MORE_ACTIONS"),
          valueType: "option",
          key: "option",
          width: "20%",
          align: "center",
          render: (text, record) => (
            <span>
              <MenuOptions
                viewRecord={() => {
                  updateURL(record?.id, WORK_MODE.VIEW);
                  changeWorkMode(WORK_MODE.VIEW);
                  setRecordAction(record);
                  showActionModal();
                }}
                editRecord={() => {
                  updateURL(record?.id, WORK_MODE.UPDATE);
                  changeWorkMode(WORK_MODE.UPDATE);
                  setRecordAction(record);
                  showActionModal();
                }}
                deleteRecord={() => {
                  deleteAction(record.id);
                }}
                title={t("errors:CONFIRM_DELETE_ACTION_ON_CASCADE")}
                confirmDeleteLoading={confirmDeleteLoading}
              />
            </span>
          ),
        }
      : {},
  ];

  return columns;
};
