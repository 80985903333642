import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
// Define the initial state
const initialState: boolean = false;

// Create the slice
export const initializationSlice = createSlice({
  name: "initialization",
  initialState,
  reducers: {
    initializationComplete: (state) => true,
  },
});

export const { initializationComplete } = initializationSlice.actions;
export const InitializationSelector = (state: RootState) =>
  state.initializationReducer;

export default initializationSlice.reducer;
