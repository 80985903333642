import CrudService from "./main/crud.service";
import { Context } from "../interfaces/context";
import { FilterRules } from "./interfaces/FilterRules";

const ressource = { name: "users", isPublic: false };

class UsersService extends CrudService {
  constructor(
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(ressource, context, setContext, path);
  }

  async filterResource(
    params: any,
    filters?: FilterRules,
    fulls = ["risk", "roles"]
  ): Promise<any> {
    return await super.filterResource(params, filters, fulls);
  }

  async updateUserMe(args: any) {
    let data = await this.patch(
      `/organizations/${this.context.orga.id}/users/me`,
      args
    );

    return { data };
  }
}

export default UsersService;
