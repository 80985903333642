import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Tooltip,
  message,
} from "antd";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import "dayjs/locale/en";
import {
  DATA_NEEDED_TYPE,
  DATA_NEEDED_TYPES,
} from "../../../../../constants/DataNeededTypes";
import { SharedContext } from "../../context/sharedContext";
import { WORK_MODE } from "../../../../../constants/workModes";
import { selectFilterOption } from "../../../../../utils/functions";
import { useTranslation } from "react-i18next";
import { PREDEFINED_TYPES } from "../../../../../constants/predefinedTypes";

const DataNeededWithPositions: React.FC<{ PredefinedType?: string }> = ({
  PredefinedType,
}) => {
  const { dynamicFields, setDynamicFields, workMode, formDataNeeded } =
    useContext(SharedContext);
  const { t } = useTranslation();
  const [initialFields, setInitialFields] = useState<number[]>([]);

  useEffect(() => {
    if (workMode === WORK_MODE.UPDATE) {
      setInitialFields(dynamicFields?.map((field: any) => field.key));
    }
  }, [workMode]);

  const addDynamicField = async () => {
    await formDataNeeded
      .validateFields([
        `Data_Name${dynamicFields.length}`,
        `Data_Type${dynamicFields.length}`,
        dynamicFields.length > 0 &&
          dynamicFields[dynamicFields.length - 1].hasOwnProperty("position") &&
          `Data_Position${dynamicFields.length}`,
      ])
      .then(() => {
        const newPosition = getNewPosition(dynamicFields.length + 1);
        if (newPosition === undefined) {
          setDynamicFields([
            ...dynamicFields,
            {
              key: dynamicFields.length + 1,
              dataName: "",
              dataType: DATA_NEEDED_TYPE.STRING,
            },
          ]);
        } else {
          if (
            PredefinedType === PREDEFINED_TYPES.RISK_PREDEFINED &&
            workMode === WORK_MODE.UPDATE
          ) {
            setDynamicFields([
              ...dynamicFields,
              {
                key: dynamicFields.length + 1,
                dataName: "",
                dataType: DATA_NEEDED_TYPE.STRING,
              },
            ]);
          } else {
            setDynamicFields([
              ...dynamicFields,
              {
                key: dynamicFields.length + 1,
                dataName: "",
                dataType: DATA_NEEDED_TYPE.STRING,
                position: newPosition,
              },
            ]);
          }
        }
      })
      .catch((err) => {});
  };

  const removeDynamicField = (fieldKey: number) => {
    const updatedFields = dynamicFields.filter(
      (field) => field.key !== fieldKey
    );
    formDataNeeded.resetFields([
      `Data_Name${fieldKey}`,
      `Data_Type${fieldKey}`,
    ]);
    setDynamicFields(updatedFields);
  };

  const getNewPosition = (fieldKey: number) => {
    const usedPositions = dynamicFields
      .filter((item) => item.key !== fieldKey && item.position !== undefined)
      .map((item) => parseInt(String(item?.position), 10));

    let newPosition: any;
    for (let i = 1; i <= 5; i++) {
      if (!usedPositions.includes(i)) {
        newPosition = i;
        break;
      }
    }
    return newPosition;
  };

  const addDynamicFieldPosition = async (fieldKey: number) => {
    const newPosition = getNewPosition(fieldKey);

    if (newPosition === undefined) {
      message.error(t("errors:POSITIONS_ALL_USED"));
      return;
    }
    const updatedFields = dynamicFields.map((field) => {
      if (field.key === fieldKey) {
        return { ...field, position: newPosition };
      }
      return field;
    });

    setDynamicFields(updatedFields);
  };

  const removeDynamicFieldPosition = (fieldKey: number) => {
    const updatedFields = dynamicFields.map((field) => {
      if (field.key === fieldKey) {
        delete field["position"];
      }
      return field;
    });
    const updatedFormItems = Object.entries(formDataNeeded.getFieldsValue())
      .filter(([name]) => !name.startsWith(`Data_Position${fieldKey}`))
      .map(([name, value]) => ({ name, value }));
    formDataNeeded.resetFields();
    formDataNeeded.setFields(updatedFormItems);
    setDynamicFields(updatedFields);
  };

  const handleInputChange = (key: number, field: string, value: any) => {
    const updatedFields = dynamicFields.map((fieldItem) =>
      fieldItem.key === key ? { ...fieldItem, [field]: value } : fieldItem
    );
    setDynamicFields(updatedFields);
  };

  useEffect(() => {
    if (dynamicFields.length > 0) {
      const currentFormValues = formDataNeeded.getFieldsValue();

      const dynamicFieldValues: { [key: string]: any } = {};
      const dynamicFieldTypeValues: { [key: string]: any } = {};
      const dynamicFieldPositionValues: { [key: string]: number } = {};

      dynamicFields.forEach((field: any) => {
        const fieldName = `Data_Name${field.key}`;
        const currentValue = currentFormValues[fieldName];
        const newValue = field.dataName ? field.dataName : undefined;
        if (currentValue !== newValue) {
          dynamicFieldValues[fieldName] = newValue;
        }

        const typeFieldName = `Data_Type${field.key}`;
        const currentTypeValue = currentFormValues[typeFieldName];
        const newTypeValue = field.dataType
          ? field.dataType
          : DATA_NEEDED_TYPE.STRING;
        if (currentTypeValue !== newTypeValue) {
          dynamicFieldTypeValues[typeFieldName] = newTypeValue;
        }

        const positionFieldName = `Data_Position${field.key}`;
        const currentPositionValue = currentFormValues[positionFieldName];
        const newPositionValue = field.position ? field.position : undefined;
        if (
          currentPositionValue !== newPositionValue &&
          field.hasOwnProperty("position")
        ) {
          dynamicFieldPositionValues[positionFieldName] = newPositionValue;
        }
      });

      const filteredDynamicFieldValues = Object.fromEntries(
        Object.entries(dynamicFieldValues).filter(
          ([_, value]) => value !== null
        ) as [string, any][]
      );

      const filteredDynamicFieldTypeValues = Object.fromEntries(
        Object.entries(dynamicFieldTypeValues).filter(
          ([_, value]) => value !== null
        ) as [string, any][]
      );
      const filteredDynamicFieldPositionValues = Object.fromEntries(
        Object.entries(dynamicFieldPositionValues).filter(
          ([_, value]) => value !== null
        ) as [string, number][]
      );

      formDataNeeded.setFieldsValue({
        ...filteredDynamicFieldValues,
        ...filteredDynamicFieldTypeValues,
        ...filteredDynamicFieldPositionValues,
      });
    }
  }, [dynamicFields]);

  return (
    <>
      <Form form={formDataNeeded}>
        {dynamicFields?.map((field: any, index) => (
          <Row key={field.key} gutter={16}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                {...{ labelCol: { style: { width: 100 } } }}
                name={`Data_Name${field.key}`}
                label={`${t("NAME_FIELD")}`}
                rules={[
                  {
                    required: true,
                    message: t(`errors:REQUIRED`, {
                      label: t(`labels:FIELD`),
                    }),
                  },
                  {
                    validator: (_, value) => {
                      const formValues = formDataNeeded.getFieldsValue();
                      const dataNameValues = Object.entries(formValues)
                        .filter(
                          ([key, val]) =>
                            key.startsWith("Data_Name") &&
                            key !== `Data_Name${field.key}` &&
                            val !== ""
                        )
                        .map(([key, val]) => val);

                      if (dataNameValues.includes(value)) {
                        return Promise.reject(t("NAME_USED"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  disabled={
                    workMode === WORK_MODE.VIEW ||
                    (workMode === WORK_MODE.UPDATE &&
                      initialFields?.includes(field.key) &&
                      field.hasOwnProperty("position"))
                  }
                  value={field.dataName || undefined}
                  onChange={(e) => {
                    handleInputChange(field.key, "dataName", e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                {...{ labelCol: { style: { width: 100 } } }}
                name={`Data_Type${field.key}`}
                label={`${t("TYPE")}`}
                rules={[
                  {
                    required: true,
                    message: t(`errors:REQUIRED`, {
                      label: t(`labels:FIELD`),
                    }),
                  },
                ]}
              >
                <Select
                  disabled={
                    workMode === WORK_MODE.VIEW ||
                    (workMode === WORK_MODE.UPDATE &&
                      initialFields?.includes(field.key) &&
                      field.hasOwnProperty("position"))
                  }
                  onChange={(value) => {
                    handleInputChange(field.key, "dataType", value);
                  }}
                  filterOption={(input, option) =>
                    selectFilterOption(input, option)
                  }
                  value={field.dataType ? field.dataType : undefined}
                >
                  {DATA_NEEDED_TYPES.map((type) => (
                    <Select.Option key={type.value} value={type.value}>
                      {type.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {PredefinedType === PREDEFINED_TYPES.RISK_PREDEFINED &&
              field.hasOwnProperty("position") && (
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <Form.Item
                    {...{ labelCol: { style: { width: 100 } } }}
                    name={`Data_Position${field.key}`}
                    label={`${t("POSITION")}`}
                    rules={[
                      PredefinedType === PREDEFINED_TYPES.RISK_PREDEFINED &&
                        field.hasOwnProperty("position") && {
                          required: true,
                          message: t(`errors:REQUIRED`, {
                            label: t("POSITION"),
                          }),
                        },
                      {
                        validator: (_, value) => {
                          const intValue = parseInt(value, 10);
                          if (isNaN(intValue) || intValue < 1 || intValue > 5) {
                            return Promise.reject(t("errors:POSITION_RANGE"));
                          }
                          const usedPositions = dynamicFields
                            .filter(
                              (item) =>
                                item.key !== field.key &&
                                item.position !== undefined
                            )
                            .map((item) => parseInt(String(item.position), 10));
                          if (usedPositions.includes(intValue)) {
                            return Promise.reject(t("errors:POSITION_UNIQUE"));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    tooltip={t("errors:SELECT_POSITION")}
                  >
                    <Input
                      style={{ height: 32, width: "calc(100% - 24px)" }}
                      suffix={
                        workMode === WORK_MODE.CREATE ||
                        (workMode === WORK_MODE.UPDATE &&
                          !initialFields.includes(field.key) &&
                          !field.hasOwnProperty("position")) ? (
                          <Tooltip title={t("errors:DELETE_POSITION")}>
                            <Button
                              type="text"
                              icon={
                                <DeleteOutlined style={{ color: "#EE2737" }} />
                              }
                              onClick={() => {
                                removeDynamicFieldPosition(field.key);
                              }}
                            />
                          </Tooltip>
                        ) : null
                      }
                      disabled={
                        workMode === WORK_MODE.VIEW ||
                        (workMode === WORK_MODE.UPDATE &&
                          initialFields.includes(field.key))
                      }
                      count={{
                        show: false,
                        max: 1,
                      }}
                      onChange={(e) => {
                        const value = e.target.value
                          .replace(/[^\d]/g, "")
                          .slice(0, 1);
                        handleInputChange(field.key, "position", value);
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
            <Col xs={4} sm={4} md={4} lg={4} xl={2}>
              <Space>
                {!field.hasOwnProperty("position") &&
                  getNewPosition(field.key) &&
                  ((workMode === WORK_MODE.UPDATE &&
                    !initialFields.includes(field.key)) ||
                    workMode === WORK_MODE.CREATE) && (
                    <Button
                      type="primary"
                      onClick={() => addDynamicFieldPosition(field.key)}
                    >
                      {"+ "}
                      {t("ADD_POSITION_FIELD")}
                    </Button>
                  )}
                <Popconfirm
                  placement="top"
                  key="delete"
                  style={{ zIndex: 9999999 }}
                  disabled={false}
                  title={t("errors:CONFIRM_DELETE_MESSAGE")}
                  onConfirm={async () => {
                    removeDynamicField(field.key);
                  }}
                  okText={t("YES")}
                  cancelText={t("NO")}
                >
                  {(workMode === WORK_MODE.CREATE ||
                    !(
                      workMode === WORK_MODE.UPDATE &&
                      PredefinedType === PREDEFINED_TYPES.RISK_PREDEFINED &&
                      initialFields.includes(field.key)
                    )) && (
                    <Tooltip title={t("errors:REMOVE_DATA_NEEDED_LINE")}>
                      <MinusCircleOutlined
                        style={{ fontSize: "20px", color: "#ff671f" }}
                      />
                    </Tooltip>
                  )}
                </Popconfirm>
                {index + 1 === dynamicFields?.length &&
                  workMode !== WORK_MODE.VIEW && (
                    <Tooltip title={t("errors:ADD_DATA_NEEDED_LINE")}>
                      <PlusCircleOutlined
                        style={{ fontSize: "20px", color: "#1677ff" }}
                        onClick={addDynamicField}
                      />
                    </Tooltip>
                  )}
              </Space>
            </Col>
          </Row>
        ))}
        {dynamicFields?.length === 0 && workMode !== WORK_MODE.VIEW && (
          <Tooltip title={t("errors:ADD_DATA_NEEDED_LINE")}>
            <PlusCircleOutlined
              style={{ fontSize: "20px", color: "#1677ff" }}
              onClick={addDynamicField}
            />
          </Tooltip>
        )}
      </Form>
    </>
  );
};

export default DataNeededWithPositions;
