import React, { useEffect } from "react";
import { Mix, MixConfig } from "@ant-design/plots";
import { ChartParams } from "../../pages/copilots/common/charts/charts/functions/ChartParams.interface";
import { getTranslation } from "../../../utils/transaltion";

interface MixedAreaBarLineProps {
  params: ChartParams;
  data: any;
}

const MixedAreaBarLine: React.FC<MixedAreaBarLineProps> = ({
  params,
  data,
}) => {
  useEffect(() => {}, [data]);

  // Helper function to get the valueField name for each chart type

  const uniqueCategories = Array.from(
    new Set([
      ...(data?.resultColumn?.[0]?.data?.map((d: any) => d.categoryField) ||
        []),
      ...(data?.resultArea?.[0]?.data?.map((d: any) => d.categoryField) || []),
      ...(data?.resultLine?.[0]?.data?.map((d: any) => d.categoryField) || []),
    ])
  );

  const xAxisConfig = {
    type: "cat",
    label: {
      autoHide: true,

      autoRotate: true,
      rotate: -95,
      formatter: (text: string) =>
        uniqueCategories.includes(text) ? text : "",
      style: {
        fontSize: 11,
      },
      offset: 25,
    },
    tickLine: {
      length: 5,
    },
  };

  const configMixed: MixConfig = {
    appendPadding: 8,
    // tooltip: tooltipConfig,
    tooltip: {
      domStyles: {
        "g2-tooltip": {
          backgroundColor: "#fff",
          boxShadow: "0 2px 12px rgba(0, 0, 0, 0.2)",
          borderRadius: "4px",
        },
        "g2-tooltip-list-item": {
          color: "#333",
          fontSize: "12px",
        },
        "g2-tooltip-title": {
          fontSize: "14px",
          fontWeight: "bold",
          color: "#008BC7",
        },
      },
      shared: true,
      // Customize the tooltip content to use field aliases from meta
      customContent: (title, items) => {
        const content = items
          .map((item: any) => {
            const { value, color, name } = item;

            return `<div style="margin-bottom: 6px;">
                    <span style="background-color: ${color}; width: 10px; height: 10px; display: inline-block; margin-right: 5px;"></span>
                    ${getTranslation(name, "data")}: <strong>${value}</strong>
                  </div>`;
          })
          .join("");

        return `<div style="padding: 10px;">
                  <div><strong>${title}</strong></div>
                  ${content}
                </div>`;
      },
    },
    syncViewPadding: true,
    plots: [
      {
        type: "column",
        options: {
          data: data?.resultColumn?.[0]?.data || [],
          isStack: true,
          xField: "categoryField",
          yField: "valueField",
          seriesField: "seriesField",
          xAxis: xAxisConfig,
          color: "#004C97",
          meta: {
            categoryField: { sync: true },
            valueField: {
              sync: true,
            },
          },
          label: {
            position: "middle",
          },
        },
      },
      {
        type: "area",
        options: {
          data: data?.resultArea?.[0]?.data || [],
          isStack: true,
          xField: "categoryField",
          yField: "valueField",
          seriesField: "seriesField",
          xAxis: xAxisConfig,
          meta: {
            categoryField: { sync: true },
            valueField: { sync: true },
          },
          smooth: true,

          color: "#5BC2E7",
        },
      },
      {
        type: "line",
        options: {
          data: data?.resultLine?.[0]?.data || [],
          xField: "categoryField",
          yField: "valueField",
          seriesField: "seriesField",
          xAxis: xAxisConfig,
          meta: {
            groupField: { sync: true },
            categoryField: { sync: true },
            valueField: { sync: true },
          },
          smooth: true,
          color: "#FF671F",
        },
      },
    ],
  };

  return <Mix {...configMixed} />;
};

export default MixedAreaBarLine;
