export const Inbound_Freight_Risk = [
  /** Risk Early request custom clearance*/
  {
    id: 1,
    risk_conf_name: "Custom clearance delay",
    due_date: "2024-12-03 00:00:00",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge25255645", name: "Action Error" },
      { id: "fgfergger25255645", name: "Execution Error" },
    ],
    current_action: {
      action_conf: null,
      action_conf_name: "Early request custom clearance",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Container_sealing: "CS109823",
                BOL: "BL1876544",
                Invoice: "INV9721",
                Certificate_of_origin: "CO3396",
                Inspection_certificate: "IC2950",
                Intercom: "EXW",
                Declared_value: "1,450,000€",
                Tax_and_duty_value: "2,173,003€",
              },
              Table: [
                {
                  Item_Code: "SKU001",
                  GOOD_VALUE: "500,000€",
                  HTS: "HS84713000",
                  TYPE: "Electronics",
                },
                {
                  Item_Code: "SKU003",
                  GOOD_VALUE: "950,000€",
                  HTS: "HS84713000",
                  TYPE: "Electronics",
                },
              ],
              OTHER_OPTIONS: [],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                "Demurrage fee": "412,200€",
                "Quantity at risk": "5,340,000",
                "Customer penalties": "84,200€",
                "Early request cost": "11,560€",
                "Custom clearance Planned ETA": "2024-12-13",
                "Custom clearance New ETA": "2024-12-15",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },

              Early_Request_Custom_clearance: [
                {
                  Route_Name: "101-Customer 3 Route",
                  Shipping_Location: "Tilbury",
                  Current_Location: "Antwerp",
                  Receiving_Location: "Rotterdam",
                  Customer: "C0003",
                  Clearance: 3,
                  Planned_Boat_ETA: "10-Dec-2024",
                },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions:[
     
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd227354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck loading",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck transfer",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        }
      ]
      ,
      feasible: true,
      id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    status: "WAIT_FOR_ACTION",
    copilot: "",
    model: "",
    context: {
      Date_of_risk: "2024-12-07",
      "Port of Destination": "Rotterdam",
      "Boat number": "123476",
      "Container sealing": "CS109823",
      "Demurrage fee": "412,200€",
      Quantity: "5,340,000",
    },
    impact: "11,560€",
    actions:[
      {
        action_conf: null,
        action_conf_name: "Early request custom clearance",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Container_sealing: "CS109823",
                  BOL: "BL1876544",
                  Invoice: "INV9721",
                  Certificate_of_origin: "CO3396",
                  Inspection_certificate: "IC2950",
                  Intercom: "EXW",
                  Declared_value: "1,450,000€",
                  Tax_and_duty_value: "2,173,003€",
                },
                Table: [
                  {
                    Item_Code: "SKU001",
                    GOOD_VALUE: "500,000€",
                    HTS: "HS84713000",
                    TYPE: "Electronics",
                  },
                  {
                    Item_Code: "SKU003",
                    GOOD_VALUE: "950,000€",
                    HTS: "HS84713000",
                    TYPE: "Electronics",
                  },
                ],
                OTHER_OPTIONS: [],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Demurrage fee": "412,200€",
                  "Quantity at risk": "5,340,000",
                  "Customer penalties": "84,200€",
                  "Early request cost": "11,560€",
                  "Custom clearance Planned ETA": "2024-12-13",
                  "Custom clearance New ETA": "2024-12-15",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Tilbury",
                    Current_Location: "Antwerp",
                    Receiving_Location: "Rotterdam",
                    Customer: "C0003",
                    Clearance: 3,
                    Planned_Boat_ETA: "10-Dec-2024",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
       
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite custom clearance request",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL832901",
                  Invoice: "INV24302",
                  Certificate_of_origin: "CO680246",
                  Inspection_certificate: "IC298405",
                  Intercom: "EXW",
                  Declared_value: "2,240,000€",
                  Tax_and_duty_value: "336,000€",
                },
                Table: [
                  {
                    Item_Code: "SKU004",
                    GOOD_VALUE: "2,240,000€",
                    HTS: "HS7629403775",
                    TYPE: "Medical Equipment",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Expedited request cost": "47,612€",
                  "Custom clearance Planned ETA": "2024-12-14",
                  "Custom clearance New ETA": "2024-12-12",
                  "Demurrage fee": "487,619€",
                  "Quantity at risk": "4,480,000",
                  "Customer penalties": "97,100€",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Early clearance request",
                    Reason_Code: "no early custom clearance available",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Dakar Senegal",
                    Current_Location: "Plymouth",
                    Receiving_Location: "Rotterdam",
                    Clearance: 3,
                    Planned_Boat_ETA: "11-Dec-2024",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
         
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Defer custom clearance",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL7239988",
                  Invoice: "INV235709",
                  Certificate_of_origin: "CO26841",
                  Inspection_certificate: "IC25602",
                  Intercom: "EXW",
                  Declared_value: "2,506,005€",
                  Tax_and_duty_value: "375,900.75€",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Demurrage fee": "523,911€",
                  "Quantity at risk": "1,235,400",
                  "Customer penalties": "70,003€",
                  "Custom clearance Planned ETA": "2024-12-11",
                  "Custom clearance New ETA": "2024-12-13",
                },
                Table: [
                  {
                    Item_Code: "SKU001",
                    GOOD_VALUE: "450,011€",
                    HTS: "HS96204765026",
                    TYPE: "2",
                  },
                  {
                    Item_Code: "SKU02",
                    GOOD_VALUE: "2,055,994€",
                    HTS: "HS96204765026",
                    TYPE: "Agriculture",
                  },
                ],
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Istanbul",
                    Current_Location: "Port Said",
                    Receiving_Location: "Dammam",
                    Clearance: 3,
                    Planned_Boat_ETA: "08-Dec-2024",
                  },
                ],
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Early clearance request",
                    Reason_Code: "no early custom clearance available",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Expedited clearance request",
                    Reason_Code: "custom delay",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite transfer",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL745291",
                  Custom_clearance_status: "Pending",
                  Custom_clearance_ETA: "2024-12-12",
                  Port_of_Destination: "Le Havre",
                  Planned_destination: "Zurich",
                  Planned_transfer_time_in_days: 2,
                  New_transfer_time_in_days: 1,
                  Total_weight: "22 tons",
                },
                Table: [
                  {
                    Item_Code: "SKU004",
                    NUMBER_OF_PALLETS: 28,
                    NUMBER_OF_UNITS: "1,405",
                    TOTAL_WEIGHT: "22 tons",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Expedited transfer cost": "18,100€",
                  "Inventory shortage cost avoidance": "69,055€",
                  "Planned ETA": "2024-12-14",
                  "New ETA": "2024-12-15",
                  "Customer penalties": "125,300€",
                  "Quantity at risk": "1,230,004",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Expedited clearance request",
                    Reason_Code: "custom delay",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
                Inbound_Inventory: [
                  {
                    date: "2024-12-08",
                    Supply: 0,
                    Demand: 59516,
                    Quantity: 59315,
                  },
                  {
                    date: "2024-12-09",
                    Supply: 12304,
                    Demand: 62030,
                    Quantity: -201,
                  },
                  {
                    date: "2024-12-10",
                    Supply: 14455,
                    Demand: 64344,
                    Quantity: 116773,
                  },
                  {
                    date: "2024-12-11",
                    Supply: 32544,
                    Demand: 62148,
                    Quantity: 13429,
                  },
                  {
                    date: "2024-12-12",
                    Supply: 54996,
                    Demand: 64262,
                    Quantity: 141281,
                  },
                  {
                    date: "2024-12-13",
                    Supply: 12536,
                    Demand: 64172,
                    Quantity: 97719,
                  },
                  {
                    date: "2024-12-14",
                    Supply: 0,
                    Demand: 61250,
                    Quantity: 91247,
                  },
                ],
                Inbound_delivery_delay: [
                  {
                    "Current inventory plan by day": "",
                    "New inventory plan by day": "",
                    "Demand  by day": "",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
         
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Reroute transfer",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL82036",
                  Custom_clearance_status: "Pending",
                  Custom_clearance_ETA: "2024-12-10",
                  Port_of_Destination: "Damman",
                  Planned_destination: "Sakakah",
                  New_destination: "Buraydah",
                  Planned_transfer_time_in_days: 2,
                  New_transfer_time_in_days: 1,
                  Total_weight: "20 tons",
                },
                Table: [
                  {
                    Item_Code: "SKU006",
                    NUMBER_OF_PALLETS: 26,
                    NUMBER_OF_UNITS: "1,300",
                    TOTAL_WEIGHT: "20 tons",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Rerouted transfer cost": "8,200€",
                  "Inventory shortage cost avoidance": "59,250€",
                  "Planned ETA": "2024-12-12",
                  "New ETA": "2024-12-11",
                  "Customer penalties": "95,900€",
                  "Quantity at risk": "153,005",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [],
                Inbound_Inventory: [
                  {
                    date: "2024-12-08",
                    Supply: 27896,
                    Demand: 59516,
                    Quantity: 90040,
                  },
                  {
                    date: "2024-12-09",
                    Supply: 900,
                    Demand: 21834,
                    Quantity: 68730,
                  },
                  {
                    date: "2024-12-10",
                    Supply: 14758,
                    Demand: 22931,
                    Quantity: 46896,
                  },
                  {
                    date: "2024-12-11",
                    Supply: 32569,
                    Demand: 24699,
                    Quantity: 23965,
                  },
                  {
                    date: "2024-12-12",
                    Supply: 15305,
                    Demand: 22679,
                    Quantity: -734,
                  },
                  {
                    date: "2024-12-13",
                    Supply: 0,
                    Demand: 19330,
                    Quantity: 129592,
                  },
                  {
                    date: "2024-12-14",
                    Supply: 23569,
                    Demand: 18304,
                    Quantity: 110262,
                  },
                ],
                Inbound_delivery_delay: [
                  {
                    "Current inventory plan by day": "",
                    "New inventory plan by day": "",
                    "Demand  by day": "",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
          
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
    ]
  },

  /** Risk 4 Expedite custom clearance request*/
  {
    id: 4,
    risk_conf_name: "Custom clearance delay",
    due_date: "2024-12-05 00:00:00",
    copilot: "Procurement	",
    model: "Custom clearance delay",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge455645", name: "Action Error" },
      { id: "fgfergger545545645", name: "Execution Error" },
    ],
    status: "WAIT_FOR_ACTION",
    context: {
      Date_of_risk: "2024-12-09",
      "Port of Destination": "Rotterdam",
      "Boat number": "38761",
      "Container sealing": "CS8751321",
      "Demurrage fee": "487,619",
      Quantity: "4,480,000",
    },
    impact: "47,612€",

    current_action: {
      action_conf: null,
      action_conf_name: "Expedite custom clearance request",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                BOL: "BL832901",
                Invoice: "INV24302",
                Certificate_of_origin: "CO680246",
                Inspection_certificate: "IC298405",
                Intercom: "EXW",
                Declared_value: "2,240,000€",
                Tax_and_duty_value: "336,000€",
              },
              Table: [
                {
                  Item_Code: "SKU004",
                  GOOD_VALUE: "2,240,000€",
                  HTS: "HS7629403775",
                  TYPE: "Medical Equipment",
                },
              ],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                "Expedited request cost": "47,612€",
                "Custom clearance Planned ETA": "2024-12-14",
                "Custom clearance New ETA": "2024-12-12",
                "Demurrage fee": "487,619€",
                "Quantity at risk": "4,480,000",
                "Customer penalties": "97,100€",
              },

              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              OTHER_OPTIONS: [
                {
                  Action_Name: "Early clearance request",
                  Reason_Code: "no early custom clearance available",
                  Execution_Stage: "",
                  User: "Exobrain",
                },
              ],
              Early_Request_Custom_clearance: [
                {
                  Route_Name: "101-Customer 3 Route",
                  Shipping_Location: "Dakar Senegal",
                  Current_Location: "Plymouth",
                  Receiving_Location: "Rotterdam",
                  Clearance: 3,
                  Planned_Boat_ETA: "11-Dec-2024",
                },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions:[
       
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd227354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck loading",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck transfer",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        }
      ]
      ,
      feasible: true,
      id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    actions:[
      {
        action_conf: null,
        action_conf_name: "Early request custom clearance",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Container_sealing: "CS109823",
                  BOL: "BL1876544",
                  Invoice: "INV9721",
                  Certificate_of_origin: "CO3396",
                  Inspection_certificate: "IC2950",
                  Intercom: "EXW",
                  Declared_value: "1,450,000€",
                  Tax_and_duty_value: "2,173,003€",
                },
                Table: [
                  {
                    Item_Code: "SKU001",
                    GOOD_VALUE: "500,000€",
                    HTS: "HS84713000",
                    TYPE: "Electronics",
                  },
                  {
                    Item_Code: "SKU003",
                    GOOD_VALUE: "950,000€",
                    HTS: "HS84713000",
                    TYPE: "Electronics",
                  },
                ],
                OTHER_OPTIONS: [],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Demurrage fee": "412,200€",
                  "Quantity at risk": "5,340,000",
                  "Customer penalties": "84,200€",
                  "Early request cost": "11,560€",
                  "Custom clearance Planned ETA": "2024-12-13",
                  "Custom clearance New ETA": "2024-12-15",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Tilbury",
                    Current_Location: "Antwerp",
                    Receiving_Location: "Rotterdam",
                    Customer: "C0003",
                    Clearance: 3,
                    Planned_Boat_ETA: "10-Dec-2024",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
       
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite custom clearance request",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL832901",
                  Invoice: "INV24302",
                  Certificate_of_origin: "CO680246",
                  Inspection_certificate: "IC298405",
                  Intercom: "EXW",
                  Declared_value: "2,240,000€",
                  Tax_and_duty_value: "336,000€",
                },
                Table: [
                  {
                    Item_Code: "SKU004",
                    GOOD_VALUE: "2,240,000€",
                    HTS: "HS7629403775",
                    TYPE: "Medical Equipment",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Expedited request cost": "47,612€",
                  "Custom clearance Planned ETA": "2024-12-14",
                  "Custom clearance New ETA": "2024-12-12",
                  "Demurrage fee": "487,619€",
                  "Quantity at risk": "4,480,000",
                  "Customer penalties": "97,100€",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Early clearance request",
                    Reason_Code: "no early custom clearance available",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Dakar Senegal",
                    Current_Location: "Plymouth",
                    Receiving_Location: "Rotterdam",
                    Clearance: 3,
                    Planned_Boat_ETA: "11-Dec-2024",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
         
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Defer custom clearance",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL7239988",
                  Invoice: "INV235709",
                  Certificate_of_origin: "CO26841",
                  Inspection_certificate: "IC25602",
                  Intercom: "EXW",
                  Declared_value: "2,506,005€",
                  Tax_and_duty_value: "375,900.75€",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Demurrage fee": "523,911€",
                  "Quantity at risk": "1,235,400",
                  "Customer penalties": "70,003€",
                  "Custom clearance Planned ETA": "2024-12-11",
                  "Custom clearance New ETA": "2024-12-13",
                },
                Table: [
                  {
                    Item_Code: "SKU001",
                    GOOD_VALUE: "450,011€",
                    HTS: "HS96204765026",
                    TYPE: "2",
                  },
                  {
                    Item_Code: "SKU02",
                    GOOD_VALUE: "2,055,994€",
                    HTS: "HS96204765026",
                    TYPE: "Agriculture",
                  },
                ],
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Istanbul",
                    Current_Location: "Port Said",
                    Receiving_Location: "Dammam",
                    Clearance: 3,
                    Planned_Boat_ETA: "08-Dec-2024",
                  },
                ],
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Early clearance request",
                    Reason_Code: "no early custom clearance available",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Expedited clearance request",
                    Reason_Code: "custom delay",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite transfer",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL745291",
                  Custom_clearance_status: "Pending",
                  Custom_clearance_ETA: "2024-12-12",
                  Port_of_Destination: "Le Havre",
                  Planned_destination: "Zurich",
                  Planned_transfer_time_in_days: 2,
                  New_transfer_time_in_days: 1,
                  Total_weight: "22 tons",
                },
                Table: [
                  {
                    Item_Code: "SKU004",
                    NUMBER_OF_PALLETS: 28,
                    NUMBER_OF_UNITS: "1,405",
                    TOTAL_WEIGHT: "22 tons",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Expedited transfer cost": "18,100€",
                  "Inventory shortage cost avoidance": "69,055€",
                  "Planned ETA": "2024-12-14",
                  "New ETA": "2024-12-15",
                  "Customer penalties": "125,300€",
                  "Quantity at risk": "1,230,004",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Expedited clearance request",
                    Reason_Code: "custom delay",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
                Inbound_Inventory: [
                  {
                    date: "2024-12-08",
                    Supply: 0,
                    Demand: 59516,
                    Quantity: 59315,
                  },
                  {
                    date: "2024-12-09",
                    Supply: 12304,
                    Demand: 62030,
                    Quantity: -201,
                  },
                  {
                    date: "2024-12-10",
                    Supply: 14455,
                    Demand: 64344,
                    Quantity: 116773,
                  },
                  {
                    date: "2024-12-11",
                    Supply: 32544,
                    Demand: 62148,
                    Quantity: 13429,
                  },
                  {
                    date: "2024-12-12",
                    Supply: 54996,
                    Demand: 64262,
                    Quantity: 141281,
                  },
                  {
                    date: "2024-12-13",
                    Supply: 12536,
                    Demand: 64172,
                    Quantity: 97719,
                  },
                  {
                    date: "2024-12-14",
                    Supply: 0,
                    Demand: 61250,
                    Quantity: 91247,
                  },
                ],
                Inbound_delivery_delay: [
                  {
                    "Current inventory plan by day": "",
                    "New inventory plan by day": "",
                    "Demand  by day": "",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
         
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Reroute transfer",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL82036",
                  Custom_clearance_status: "Pending",
                  Custom_clearance_ETA: "2024-12-10",
                  Port_of_Destination: "Damman",
                  Planned_destination: "Sakakah",
                  New_destination: "Buraydah",
                  Planned_transfer_time_in_days: 2,
                  New_transfer_time_in_days: 1,
                  Total_weight: "20 tons",
                },
                Table: [
                  {
                    Item_Code: "SKU006",
                    NUMBER_OF_PALLETS: 26,
                    NUMBER_OF_UNITS: "1,300",
                    TOTAL_WEIGHT: "20 tons",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Rerouted transfer cost": "8,200€",
                  "Inventory shortage cost avoidance": "59,250€",
                  "Planned ETA": "2024-12-12",
                  "New ETA": "2024-12-11",
                  "Customer penalties": "95,900€",
                  "Quantity at risk": "153,005",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [],
                Inbound_Inventory: [
                  {
                    date: "2024-12-08",
                    Supply: 27896,
                    Demand: 59516,
                    Quantity: 90040,
                  },
                  {
                    date: "2024-12-09",
                    Supply: 900,
                    Demand: 21834,
                    Quantity: 68730,
                  },
                  {
                    date: "2024-12-10",
                    Supply: 14758,
                    Demand: 22931,
                    Quantity: 46896,
                  },
                  {
                    date: "2024-12-11",
                    Supply: 32569,
                    Demand: 24699,
                    Quantity: 23965,
                  },
                  {
                    date: "2024-12-12",
                    Supply: 15305,
                    Demand: 22679,
                    Quantity: -734,
                  },
                  {
                    date: "2024-12-13",
                    Supply: 0,
                    Demand: 19330,
                    Quantity: 129592,
                  },
                  {
                    date: "2024-12-14",
                    Supply: 23569,
                    Demand: 18304,
                    Quantity: 110262,
                  },
                ],
                Inbound_delivery_delay: [
                  {
                    "Current inventory plan by day": "",
                    "New inventory plan by day": "",
                    "Demand  by day": "",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
          
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
    ]
  },
  /** Risk 6  "Defer custom clearance"*/
  {
    id: 6,
    risk_conf_name: "Custom clearance delay",
    due_date: "2024-12-04 00:00:00",
    copilot: "Procurement	",
    model: "Custom clearance delay",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge25255645", name: "Action Error" },
      { id: "fgfergger25255645", name: "Execution Error" },
    ],
    status: "WAIT_FOR_ACTION",
    context: {
      Date_of_risk: "2024-12-08",
      "Port of Destination": "Dammam",
      "Boat number": "37651",
      "Container sealing": "CS5872187",
      "Demurrage fee": "523,911€",
      Quantity: "1,235,400",
    },
    impact: "70,003€",
    current_action: {
      action_conf: null,
      action_conf_name: "Defer custom clearance",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                BOL: "BL7239988",
                Invoice: "INV235709",
                Certificate_of_origin: "CO26841",
                Inspection_certificate: "IC25602",
                Intercom: "EXW",
                Declared_value: "2,506,005€",
                Tax_and_duty_value: "375,900.75€",
              },

              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                "Demurrage fee": "523,911€",
                "Quantity at risk": "1,235,400",
                "Customer penalties": "70,003€",
                "Custom clearance Planned ETA": "2024-12-11",
                "Custom clearance New ETA": "2024-12-13",
              },
              Table: [
                {
                  Item_Code: "SKU001",
                  GOOD_VALUE: "450,011€",
                  HTS: "HS96204765026",
                  TYPE: "2",
                },
                {
                  Item_Code: "SKU02",
                  GOOD_VALUE: "2,055,994€",
                  HTS: "HS96204765026",
                  TYPE: "Agriculture",
                },
              ],
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              Early_Request_Custom_clearance: [
                {
                  Route_Name: "101-Customer 3 Route",
                  Shipping_Location: "Istanbul",
                  Current_Location: "Port Said",
                  Receiving_Location: "Dammam",
                  Clearance: 3,
                  Planned_Boat_ETA: "08-Dec-2024",
                },
              ],
              OTHER_OPTIONS: [
                {
                  Action_Name: "Early clearance request",
                  Reason_Code: "no early custom clearance available",
                  Execution_Stage: "",
                  User: "Exobrain",
                },
                {
                  Action_Name: "Expedited clearance request",
                  Reason_Code: "custom delay",
                  Execution_Stage: "",
                  User: "Exobrain",
                },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd227354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck loading",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck transfer",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    actions:[
      {
        action_conf: null,
        action_conf_name: "Early request custom clearance",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Container_sealing: "CS109823",
                  BOL: "BL1876544",
                  Invoice: "INV9721",
                  Certificate_of_origin: "CO3396",
                  Inspection_certificate: "IC2950",
                  Intercom: "EXW",
                  Declared_value: "1,450,000€",
                  Tax_and_duty_value: "2,173,003€",
                },
                Table: [
                  {
                    Item_Code: "SKU001",
                    GOOD_VALUE: "500,000€",
                    HTS: "HS84713000",
                    TYPE: "Electronics",
                  },
                  {
                    Item_Code: "SKU003",
                    GOOD_VALUE: "950,000€",
                    HTS: "HS84713000",
                    TYPE: "Electronics",
                  },
                ],
                OTHER_OPTIONS: [],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Demurrage fee": "412,200€",
                  "Quantity at risk": "5,340,000",
                  "Customer penalties": "84,200€",
                  "Early request cost": "11,560€",
                  "Custom clearance Planned ETA": "2024-12-13",
                  "Custom clearance New ETA": "2024-12-15",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Tilbury",
                    Current_Location: "Antwerp",
                    Receiving_Location: "Rotterdam",
                    Customer: "C0003",
                    Clearance: 3,
                    Planned_Boat_ETA: "10-Dec-2024",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
       
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite custom clearance request",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL832901",
                  Invoice: "INV24302",
                  Certificate_of_origin: "CO680246",
                  Inspection_certificate: "IC298405",
                  Intercom: "EXW",
                  Declared_value: "2,240,000€",
                  Tax_and_duty_value: "336,000€",
                },
                Table: [
                  {
                    Item_Code: "SKU004",
                    GOOD_VALUE: "2,240,000€",
                    HTS: "HS7629403775",
                    TYPE: "Medical Equipment",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Expedited request cost": "47,612€",
                  "Custom clearance Planned ETA": "2024-12-14",
                  "Custom clearance New ETA": "2024-12-12",
                  "Demurrage fee": "487,619€",
                  "Quantity at risk": "4,480,000",
                  "Customer penalties": "97,100€",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Early clearance request",
                    Reason_Code: "no early custom clearance available",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Dakar Senegal",
                    Current_Location: "Plymouth",
                    Receiving_Location: "Rotterdam",
                    Clearance: 3,
                    Planned_Boat_ETA: "11-Dec-2024",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
         
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Defer custom clearance",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL7239988",
                  Invoice: "INV235709",
                  Certificate_of_origin: "CO26841",
                  Inspection_certificate: "IC25602",
                  Intercom: "EXW",
                  Declared_value: "2,506,005€",
                  Tax_and_duty_value: "375,900.75€",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Demurrage fee": "523,911€",
                  "Quantity at risk": "1,235,400",
                  "Customer penalties": "70,003€",
                  "Custom clearance Planned ETA": "2024-12-11",
                  "Custom clearance New ETA": "2024-12-13",
                },
                Table: [
                  {
                    Item_Code: "SKU001",
                    GOOD_VALUE: "450,011€",
                    HTS: "HS96204765026",
                    TYPE: "2",
                  },
                  {
                    Item_Code: "SKU02",
                    GOOD_VALUE: "2,055,994€",
                    HTS: "HS96204765026",
                    TYPE: "Agriculture",
                  },
                ],
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Istanbul",
                    Current_Location: "Port Said",
                    Receiving_Location: "Dammam",
                    Clearance: 3,
                    Planned_Boat_ETA: "08-Dec-2024",
                  },
                ],
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Early clearance request",
                    Reason_Code: "no early custom clearance available",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Expedited clearance request",
                    Reason_Code: "custom delay",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite transfer",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL745291",
                  Custom_clearance_status: "Pending",
                  Custom_clearance_ETA: "2024-12-12",
                  Port_of_Destination: "Le Havre",
                  Planned_destination: "Zurich",
                  Planned_transfer_time_in_days: 2,
                  New_transfer_time_in_days: 1,
                  Total_weight: "22 tons",
                },
                Table: [
                  {
                    Item_Code: "SKU004",
                    NUMBER_OF_PALLETS: 28,
                    NUMBER_OF_UNITS: "1,405",
                    TOTAL_WEIGHT: "22 tons",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Expedited transfer cost": "18,100€",
                  "Inventory shortage cost avoidance": "69,055€",
                  "Planned ETA": "2024-12-14",
                  "New ETA": "2024-12-15",
                  "Customer penalties": "125,300€",
                  "Quantity at risk": "1,230,004",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Expedited clearance request",
                    Reason_Code: "custom delay",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
                Inbound_Inventory: [
                  {
                    date: "2024-12-08",
                    Supply: 0,
                    Demand: 59516,
                    Quantity: 59315,
                  },
                  {
                    date: "2024-12-09",
                    Supply: 12304,
                    Demand: 62030,
                    Quantity: -201,
                  },
                  {
                    date: "2024-12-10",
                    Supply: 14455,
                    Demand: 64344,
                    Quantity: 116773,
                  },
                  {
                    date: "2024-12-11",
                    Supply: 32544,
                    Demand: 62148,
                    Quantity: 13429,
                  },
                  {
                    date: "2024-12-12",
                    Supply: 54996,
                    Demand: 64262,
                    Quantity: 141281,
                  },
                  {
                    date: "2024-12-13",
                    Supply: 12536,
                    Demand: 64172,
                    Quantity: 97719,
                  },
                  {
                    date: "2024-12-14",
                    Supply: 0,
                    Demand: 61250,
                    Quantity: 91247,
                  },
                ],
                Inbound_delivery_delay: [
                  {
                    "Current inventory plan by day": "",
                    "New inventory plan by day": "",
                    "Demand  by day": "",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
         
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Reroute transfer",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL82036",
                  Custom_clearance_status: "Pending",
                  Custom_clearance_ETA: "2024-12-10",
                  Port_of_Destination: "Damman",
                  Planned_destination: "Sakakah",
                  New_destination: "Buraydah",
                  Planned_transfer_time_in_days: 2,
                  New_transfer_time_in_days: 1,
                  Total_weight: "20 tons",
                },
                Table: [
                  {
                    Item_Code: "SKU006",
                    NUMBER_OF_PALLETS: 26,
                    NUMBER_OF_UNITS: "1,300",
                    TOTAL_WEIGHT: "20 tons",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Rerouted transfer cost": "8,200€",
                  "Inventory shortage cost avoidance": "59,250€",
                  "Planned ETA": "2024-12-12",
                  "New ETA": "2024-12-11",
                  "Customer penalties": "95,900€",
                  "Quantity at risk": "153,005",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [],
                Inbound_Inventory: [
                  {
                    date: "2024-12-08",
                    Supply: 27896,
                    Demand: 59516,
                    Quantity: 90040,
                  },
                  {
                    date: "2024-12-09",
                    Supply: 900,
                    Demand: 21834,
                    Quantity: 68730,
                  },
                  {
                    date: "2024-12-10",
                    Supply: 14758,
                    Demand: 22931,
                    Quantity: 46896,
                  },
                  {
                    date: "2024-12-11",
                    Supply: 32569,
                    Demand: 24699,
                    Quantity: 23965,
                  },
                  {
                    date: "2024-12-12",
                    Supply: 15305,
                    Demand: 22679,
                    Quantity: -734,
                  },
                  {
                    date: "2024-12-13",
                    Supply: 0,
                    Demand: 19330,
                    Quantity: 129592,
                  },
                  {
                    date: "2024-12-14",
                    Supply: 23569,
                    Demand: 18304,
                    Quantity: 110262,
                  },
                ],
                Inbound_delivery_delay: [
                  {
                    "Current inventory plan by day": "",
                    "New inventory plan by day": "",
                    "Demand  by day": "",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
          
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
    ]
  },


  /**Expedite transfer */
  {
    id: 5,
    risk_conf_name: "Inbound Delivery delay",
    due_date: "2024-12-04 00:00:00",
    copilot: "Procurement	",
    model: "Inbound Delivery delay",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge455645", name: "Action Error" },
      { id: "fgfergger545545645", name: "Execution Error" },
    ],
    status: "WAIT_FOR_ACTION",
    context: {
      Date_of_risk: "2024-12-08",

      "Port of Destination": "Le Havre",
      "Final destination": "Zurich",
      BOL: "BL745291",
      Quantity: "1,230,004",
      "Customer penalties": "125,300€",
    },
    impact: "87,155€",

    current_action: {
      action_conf: null,
      action_conf_name: "Expedite transfer",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                BOL: "BL745291",
                Custom_clearance_status: "Pending",
                Custom_clearance_ETA: "2024-12-12",
                Port_of_Destination: "Le Havre",
                Planned_destination: "Zurich",
                Planned_transfer_time_in_days: 2,
                New_transfer_time_in_days: 1,
                Total_weight: "22 tons",
              },
              Table: [
                {
                  Item_Code: "SKU004",
                  NUMBER_OF_PALLETS: 28,
                  NUMBER_OF_UNITS: "1,405",
                  TOTAL_WEIGHT: "22 tons",
                },
              ],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                "Expedited transfer cost": "18,100€",
                "Inventory shortage cost avoidance": "69,055€",
                "Planned ETA": "2024-12-14",
                "New ETA": "2024-12-15",
                "Customer penalties": "125,300€",
                "Quantity at risk": "1,230,004",
              },

              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              OTHER_OPTIONS: [
                {
                  Action_Name: "Expedited clearance request",
                  Reason_Code: "custom delay",
                  Execution_Stage: "",
                  User: "Exobrain",
                },
              ],
              Inbound_Inventory: [
                {
                  date: "2024-12-08",
                  Supply: 0,
                  Demand: 59516,
                  Quantity: 59315,
                },
                {
                  date: "2024-12-09",
                  Supply: 12304,
                  Demand: 62030,
                  Quantity: -201,
                },
                {
                  date: "2024-12-10",
                  Supply: 14455,
                  Demand: 64344,
                  Quantity: 116773,
                },
                {
                  date: "2024-12-11",
                  Supply: 32544,
                  Demand: 62148,
                  Quantity: 13429,
                },
                {
                  date: "2024-12-12",
                  Supply: 54996,
                  Demand: 64262,
                  Quantity: 141281,
                },
                {
                  date: "2024-12-13",
                  Supply: 12536,
                  Demand: 64172,
                  Quantity: 97719,
                },
                {
                  date: "2024-12-14",
                  Supply: 0,
                  Demand: 61250,
                  Quantity: 91247,
                },
              ],
              Inbound_delivery_delay: [
                {
                  "Current inventory plan by day": "",
                  "New inventory plan by day": "",
                  "Demand  by day": "",
                },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions:[
       
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd227354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck loading",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck transfer",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        }
      ]
      ,
      feasible: true,
      id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    actions:[
      {
        action_conf: null,
        action_conf_name: "Early request custom clearance",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Container_sealing: "CS109823",
                  BOL: "BL1876544",
                  Invoice: "INV9721",
                  Certificate_of_origin: "CO3396",
                  Inspection_certificate: "IC2950",
                  Intercom: "EXW",
                  Declared_value: "1,450,000€",
                  Tax_and_duty_value: "2,173,003€",
                },
                Table: [
                  {
                    Item_Code: "SKU001",
                    GOOD_VALUE: "500,000€",
                    HTS: "HS84713000",
                    TYPE: "Electronics",
                  },
                  {
                    Item_Code: "SKU003",
                    GOOD_VALUE: "950,000€",
                    HTS: "HS84713000",
                    TYPE: "Electronics",
                  },
                ],
                OTHER_OPTIONS: [],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Demurrage fee": "412,200€",
                  "Quantity at risk": "5,340,000",
                  "Customer penalties": "84,200€",
                  "Early request cost": "11,560€",
                  "Custom clearance Planned ETA": "2024-12-13",
                  "Custom clearance New ETA": "2024-12-15",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Tilbury",
                    Current_Location: "Antwerp",
                    Receiving_Location: "Rotterdam",
                    Customer: "C0003",
                    Clearance: 3,
                    Planned_Boat_ETA: "10-Dec-2024",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
       
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite custom clearance request",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL832901",
                  Invoice: "INV24302",
                  Certificate_of_origin: "CO680246",
                  Inspection_certificate: "IC298405",
                  Intercom: "EXW",
                  Declared_value: "2,240,000€",
                  Tax_and_duty_value: "336,000€",
                },
                Table: [
                  {
                    Item_Code: "SKU004",
                    GOOD_VALUE: "2,240,000€",
                    HTS: "HS7629403775",
                    TYPE: "Medical Equipment",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Expedited request cost": "47,612€",
                  "Custom clearance Planned ETA": "2024-12-14",
                  "Custom clearance New ETA": "2024-12-12",
                  "Demurrage fee": "487,619€",
                  "Quantity at risk": "4,480,000",
                  "Customer penalties": "97,100€",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Early clearance request",
                    Reason_Code: "no early custom clearance available",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Dakar Senegal",
                    Current_Location: "Plymouth",
                    Receiving_Location: "Rotterdam",
                    Clearance: 3,
                    Planned_Boat_ETA: "11-Dec-2024",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
         
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Defer custom clearance",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL7239988",
                  Invoice: "INV235709",
                  Certificate_of_origin: "CO26841",
                  Inspection_certificate: "IC25602",
                  Intercom: "EXW",
                  Declared_value: "2,506,005€",
                  Tax_and_duty_value: "375,900.75€",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Demurrage fee": "523,911€",
                  "Quantity at risk": "1,235,400",
                  "Customer penalties": "70,003€",
                  "Custom clearance Planned ETA": "2024-12-11",
                  "Custom clearance New ETA": "2024-12-13",
                },
                Table: [
                  {
                    Item_Code: "SKU001",
                    GOOD_VALUE: "450,011€",
                    HTS: "HS96204765026",
                    TYPE: "2",
                  },
                  {
                    Item_Code: "SKU02",
                    GOOD_VALUE: "2,055,994€",
                    HTS: "HS96204765026",
                    TYPE: "Agriculture",
                  },
                ],
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Istanbul",
                    Current_Location: "Port Said",
                    Receiving_Location: "Dammam",
                    Clearance: 3,
                    Planned_Boat_ETA: "08-Dec-2024",
                  },
                ],
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Early clearance request",
                    Reason_Code: "no early custom clearance available",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Expedited clearance request",
                    Reason_Code: "custom delay",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite transfer",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL745291",
                  Custom_clearance_status: "Pending",
                  Custom_clearance_ETA: "2024-12-12",
                  Port_of_Destination: "Le Havre",
                  Planned_destination: "Zurich",
                  Planned_transfer_time_in_days: 2,
                  New_transfer_time_in_days: 1,
                  Total_weight: "22 tons",
                },
                Table: [
                  {
                    Item_Code: "SKU004",
                    NUMBER_OF_PALLETS: 28,
                    NUMBER_OF_UNITS: "1,405",
                    TOTAL_WEIGHT: "22 tons",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Expedited transfer cost": "18,100€",
                  "Inventory shortage cost avoidance": "69,055€",
                  "Planned ETA": "2024-12-14",
                  "New ETA": "2024-12-15",
                  "Customer penalties": "125,300€",
                  "Quantity at risk": "1,230,004",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Expedited clearance request",
                    Reason_Code: "custom delay",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
                Inbound_Inventory: [
                  {
                    date: "2024-12-08",
                    Supply: 0,
                    Demand: 59516,
                    Quantity: 59315,
                  },
                  {
                    date: "2024-12-09",
                    Supply: 12304,
                    Demand: 62030,
                    Quantity: -201,
                  },
                  {
                    date: "2024-12-10",
                    Supply: 14455,
                    Demand: 64344,
                    Quantity: 116773,
                  },
                  {
                    date: "2024-12-11",
                    Supply: 32544,
                    Demand: 62148,
                    Quantity: 13429,
                  },
                  {
                    date: "2024-12-12",
                    Supply: 54996,
                    Demand: 64262,
                    Quantity: 141281,
                  },
                  {
                    date: "2024-12-13",
                    Supply: 12536,
                    Demand: 64172,
                    Quantity: 97719,
                  },
                  {
                    date: "2024-12-14",
                    Supply: 0,
                    Demand: 61250,
                    Quantity: 91247,
                  },
                ],
                Inbound_delivery_delay: [
                  {
                    "Current inventory plan by day": "",
                    "New inventory plan by day": "",
                    "Demand  by day": "",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
         
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Reroute transfer",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL82036",
                  Custom_clearance_status: "Pending",
                  Custom_clearance_ETA: "2024-12-10",
                  Port_of_Destination: "Damman",
                  Planned_destination: "Sakakah",
                  New_destination: "Buraydah",
                  Planned_transfer_time_in_days: 2,
                  New_transfer_time_in_days: 1,
                  Total_weight: "20 tons",
                },
                Table: [
                  {
                    Item_Code: "SKU006",
                    NUMBER_OF_PALLETS: 26,
                    NUMBER_OF_UNITS: "1,300",
                    TOTAL_WEIGHT: "20 tons",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Rerouted transfer cost": "8,200€",
                  "Inventory shortage cost avoidance": "59,250€",
                  "Planned ETA": "2024-12-12",
                  "New ETA": "2024-12-11",
                  "Customer penalties": "95,900€",
                  "Quantity at risk": "153,005",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [],
                Inbound_Inventory: [
                  {
                    date: "2024-12-08",
                    Supply: 27896,
                    Demand: 59516,
                    Quantity: 90040,
                  },
                  {
                    date: "2024-12-09",
                    Supply: 900,
                    Demand: 21834,
                    Quantity: 68730,
                  },
                  {
                    date: "2024-12-10",
                    Supply: 14758,
                    Demand: 22931,
                    Quantity: 46896,
                  },
                  {
                    date: "2024-12-11",
                    Supply: 32569,
                    Demand: 24699,
                    Quantity: 23965,
                  },
                  {
                    date: "2024-12-12",
                    Supply: 15305,
                    Demand: 22679,
                    Quantity: -734,
                  },
                  {
                    date: "2024-12-13",
                    Supply: 0,
                    Demand: 19330,
                    Quantity: 129592,
                  },
                  {
                    date: "2024-12-14",
                    Supply: 23569,
                    Demand: 18304,
                    Quantity: 110262,
                  },
                ],
                Inbound_delivery_delay: [
                  {
                    "Current inventory plan by day": "",
                    "New inventory plan by day": "",
                    "Demand  by day": "",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
          
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
    ]
  },

  /**reroute transfer */
  {
    id: 7,
    risk_conf_name: "Inbound Delivery delay",
    due_date: "2024-12-06 00:00:00",
    copilot: "Procurement	",
    model: "Inbound Delivery delay",
    reason: [
      { id: "fgfergger545645", name: "Missing Data" },
      { id: "fgfergge455645", name: "Action Error" },
      { id: "fgfergger545545645", name: "Execution Error" },
    ],
    status: "WAIT_FOR_ACTION",
    context: {
      Date_of_risk: "2024-12-10",
      "Port of Destination": "Dammam",
      "Final destination": "Sakakah",
      BOL: "BL82036",
      Quantity: "153,005",
      "Customer penalties": "95,900€",
    },
    impact: "67,450€",

    current_action: {
      action_conf: null,
      action_conf_name: "Reroute transfer",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                BOL: "BL82036",
                Custom_clearance_status: "Pending",
                Custom_clearance_ETA: "2024-12-10",
                Port_of_Destination: "Damman",
                Planned_destination: "Sakakah",
                New_destination: "Buraydah",
                Planned_transfer_time_in_days: 2,
                New_transfer_time_in_days: 1,
                Total_weight: "20 tons",
              },
              Table: [
                {
                  Item_Code: "SKU006",
                  NUMBER_OF_PALLETS: 26,
                  NUMBER_OF_UNITS: "1,300",
                  TOTAL_WEIGHT: "20 tons",
                },
              ],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                "Rerouted transfer cost": "8,200€",
                "Inventory shortage cost avoidance": "59,250€",
                "Planned ETA": "2024-12-12",
                "New ETA": "2024-12-11",
                "Customer penalties": "95,900€",
                "Quantity at risk": "153,005",
              },

              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              OTHER_OPTIONS: [],
              Inbound_Inventory: [
                {
                  date: "2024-12-08",
                  Supply: 27896,
                  Demand: 59516,
                  Quantity: 90040,
                },
                {
                  date: "2024-12-09",
                  Supply: 900,
                  Demand: 21834,
                  Quantity: 68730,
                },
                {
                  date: "2024-12-10",
                  Supply: 14758,
                  Demand: 22931,
                  Quantity: 46896,
                },
                {
                  date: "2024-12-11",
                  Supply: 32569,
                  Demand: 24699,
                  Quantity: 23965,
                },
                {
                  date: "2024-12-12",
                  Supply: 15305,
                  Demand: 22679,
                  Quantity: -734,
                },
                {
                  date: "2024-12-13",
                  Supply: 0,
                  Demand: 19330,
                  Quantity: 129592,
                },
                {
                  date: "2024-12-14",
                  Supply: 23569,
                  Demand: 18304,
                  Quantity: 110262,
                },
              ],
              Inbound_delivery_delay: [
                {
                  "Current inventory plan by day": "",
                  "New inventory plan by day": "",
                  "Demand  by day": "",
                },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",

          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions:[
        
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd227354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck loading",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck transfer",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        }
      ]
      ,
      feasible: true,
      id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    actions:[
      {
        action_conf: null,
        action_conf_name: "Early request custom clearance",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  Container_sealing: "CS109823",
                  BOL: "BL1876544",
                  Invoice: "INV9721",
                  Certificate_of_origin: "CO3396",
                  Inspection_certificate: "IC2950",
                  Intercom: "EXW",
                  Declared_value: "1,450,000€",
                  Tax_and_duty_value: "2,173,003€",
                },
                Table: [
                  {
                    Item_Code: "SKU001",
                    GOOD_VALUE: "500,000€",
                    HTS: "HS84713000",
                    TYPE: "Electronics",
                  },
                  {
                    Item_Code: "SKU003",
                    GOOD_VALUE: "950,000€",
                    HTS: "HS84713000",
                    TYPE: "Electronics",
                  },
                ],
                OTHER_OPTIONS: [],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Demurrage fee": "412,200€",
                  "Quantity at risk": "5,340,000",
                  "Customer penalties": "84,200€",
                  "Early request cost": "11,560€",
                  "Custom clearance Planned ETA": "2024-12-13",
                  "Custom clearance New ETA": "2024-12-15",
                },
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
    
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Tilbury",
                    Current_Location: "Antwerp",
                    Receiving_Location: "Rotterdam",
                    Customer: "C0003",
                    Clearance: 3,
                    Planned_Boat_ETA: "10-Dec-2024",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
       
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite custom clearance request",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL832901",
                  Invoice: "INV24302",
                  Certificate_of_origin: "CO680246",
                  Inspection_certificate: "IC298405",
                  Intercom: "EXW",
                  Declared_value: "2,240,000€",
                  Tax_and_duty_value: "336,000€",
                },
                Table: [
                  {
                    Item_Code: "SKU004",
                    GOOD_VALUE: "2,240,000€",
                    HTS: "HS7629403775",
                    TYPE: "Medical Equipment",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Expedited request cost": "47,612€",
                  "Custom clearance Planned ETA": "2024-12-14",
                  "Custom clearance New ETA": "2024-12-12",
                  "Demurrage fee": "487,619€",
                  "Quantity at risk": "4,480,000",
                  "Customer penalties": "97,100€",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Early clearance request",
                    Reason_Code: "no early custom clearance available",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Dakar Senegal",
                    Current_Location: "Plymouth",
                    Receiving_Location: "Rotterdam",
                    Clearance: 3,
                    Planned_Boat_ETA: "11-Dec-2024",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
         
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Defer custom clearance",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL7239988",
                  Invoice: "INV235709",
                  Certificate_of_origin: "CO26841",
                  Inspection_certificate: "IC25602",
                  Intercom: "EXW",
                  Declared_value: "2,506,005€",
                  Tax_and_duty_value: "375,900.75€",
                },
    
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Demurrage fee": "523,911€",
                  "Quantity at risk": "1,235,400",
                  "Customer penalties": "70,003€",
                  "Custom clearance Planned ETA": "2024-12-11",
                  "Custom clearance New ETA": "2024-12-13",
                },
                Table: [
                  {
                    Item_Code: "SKU001",
                    GOOD_VALUE: "450,011€",
                    HTS: "HS96204765026",
                    TYPE: "2",
                  },
                  {
                    Item_Code: "SKU02",
                    GOOD_VALUE: "2,055,994€",
                    HTS: "HS96204765026",
                    TYPE: "Agriculture",
                  },
                ],
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                Early_Request_Custom_clearance: [
                  {
                    Route_Name: "101-Customer 3 Route",
                    Shipping_Location: "Istanbul",
                    Current_Location: "Port Said",
                    Receiving_Location: "Dammam",
                    Clearance: 3,
                    Planned_Boat_ETA: "08-Dec-2024",
                  },
                ],
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Early clearance request",
                    Reason_Code: "no early custom clearance available",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                  {
                    Action_Name: "Expedited clearance request",
                    Reason_Code: "custom delay",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions: [
          
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        ],
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Expedite transfer",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL745291",
                  Custom_clearance_status: "Pending",
                  Custom_clearance_ETA: "2024-12-12",
                  Port_of_Destination: "Le Havre",
                  Planned_destination: "Zurich",
                  Planned_transfer_time_in_days: 2,
                  New_transfer_time_in_days: 1,
                  Total_weight: "22 tons",
                },
                Table: [
                  {
                    Item_Code: "SKU004",
                    NUMBER_OF_PALLETS: 28,
                    NUMBER_OF_UNITS: "1,405",
                    TOTAL_WEIGHT: "22 tons",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Expedited transfer cost": "18,100€",
                  "Inventory shortage cost avoidance": "69,055€",
                  "Planned ETA": "2024-12-14",
                  "New ETA": "2024-12-15",
                  "Customer penalties": "125,300€",
                  "Quantity at risk": "1,230,004",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [
                  {
                    Action_Name: "Expedited clearance request",
                    Reason_Code: "custom delay",
                    Execution_Stage: "",
                    User: "Exobrain",
                  },
                ],
                Inbound_Inventory: [
                  {
                    date: "2024-12-08",
                    Supply: 0,
                    Demand: 59516,
                    Quantity: 59315,
                  },
                  {
                    date: "2024-12-09",
                    Supply: 12304,
                    Demand: 62030,
                    Quantity: -201,
                  },
                  {
                    date: "2024-12-10",
                    Supply: 14455,
                    Demand: 64344,
                    Quantity: 116773,
                  },
                  {
                    date: "2024-12-11",
                    Supply: 32544,
                    Demand: 62148,
                    Quantity: 13429,
                  },
                  {
                    date: "2024-12-12",
                    Supply: 54996,
                    Demand: 64262,
                    Quantity: 141281,
                  },
                  {
                    date: "2024-12-13",
                    Supply: 12536,
                    Demand: 64172,
                    Quantity: 97719,
                  },
                  {
                    date: "2024-12-14",
                    Supply: 0,
                    Demand: 61250,
                    Quantity: 91247,
                  },
                ],
                Inbound_delivery_delay: [
                  {
                    "Current inventory plan by day": "",
                    "New inventory plan by day": "",
                    "Demand  by day": "",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
         
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
      {
        action_conf: null,
        action_conf_name: "Reroute transfer",
        action_conf_policy: {},
        action_conf_priority: 1,
        action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
        action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.443005+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {
              DATA: {
                ACTION_DETAILS: {
                  BOL: "BL82036",
                  Custom_clearance_status: "Pending",
                  Custom_clearance_ETA: "2024-12-10",
                  Port_of_Destination: "Damman",
                  Planned_destination: "Sakakah",
                  New_destination: "Buraydah",
                  Planned_transfer_time_in_days: 2,
                  New_transfer_time_in_days: 1,
                  Total_weight: "20 tons",
                },
                Table: [
                  {
                    Item_Code: "SKU006",
                    NUMBER_OF_PALLETS: 26,
                    NUMBER_OF_UNITS: "1,300",
                    TOTAL_WEIGHT: "20 tons",
                  },
                ],
                ERRORS: {
                  ERROR: [
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "MISSING_DATA",
                      DETAILS: {
                        DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                  WARNING: [
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                      },
                      MODULE: "Exobrain-actions",
                    },
                    {
                      CODE: "UNPROCESSABLE_DATA",
                      DETAILS: {
                        DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                      },
                      MODULE: "Exobrain-actions",
                    },
                  ],
                },
                IMPACT: {
                  "Rerouted transfer cost": "8,200€",
                  "Inventory shortage cost avoidance": "59,250€",
                  "Planned ETA": "2024-12-12",
                  "New ETA": "2024-12-11",
                  "Customer penalties": "95,900€",
                  "Quantity at risk": "153,005",
                },
    
                MASTER: {
                  ITEM_MASTER: [
                    {
                      ITEM_CODE: "VGPSLWNL003",
                      ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                      ITEM_TYPE: "F",
                      UNIT_PER_PALLET: null,
                      WEIGHT_PER_UNIT_IN_KG: null,
                    },
                  ],
                  LOCATION_ITEM_MASTER: [
                    {
                      DAYS_OF_SUPPLY_THRESHOLD: null,
                      ITEM_CODE: "VGPSLWNL003",
                      LOCATION_CODE: "264",
                      STANDARD_COST_PER_UNIT: null,
                    },
                  ],
                  LOCATION_MASTER: [
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "JEDDAH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "JEDDAH PORT",
                      LOCATION_ID: "JEDDAH PORT",
                      LOCATION_PRIMARY_LANGUAGE: null,
                      LOCATION_TYPE: null,
                      LOCATION_ZIP_CODE: null,
                    },
                    {
                      APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                      LOCATION_CITY: "   RIYADH",
                      LOCATION_CONTACT_NAME: null,
                      LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                      LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                      LOCATION_ID: "264",
                      LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                      LOCATION_TYPE: "BRANCH",
                      LOCATION_ZIP_CODE: null,
                    },
                  ],
                },
                OTHER_OPTIONS: [],
                Inbound_Inventory: [
                  {
                    date: "2024-12-08",
                    Supply: 27896,
                    Demand: 59516,
                    Quantity: 90040,
                  },
                  {
                    date: "2024-12-09",
                    Supply: 900,
                    Demand: 21834,
                    Quantity: 68730,
                  },
                  {
                    date: "2024-12-10",
                    Supply: 14758,
                    Demand: 22931,
                    Quantity: 46896,
                  },
                  {
                    date: "2024-12-11",
                    Supply: 32569,
                    Demand: 24699,
                    Quantity: 23965,
                  },
                  {
                    date: "2024-12-12",
                    Supply: 15305,
                    Demand: 22679,
                    Quantity: -734,
                  },
                  {
                    date: "2024-12-13",
                    Supply: 0,
                    Demand: 19330,
                    Quantity: 129592,
                  },
                  {
                    date: "2024-12-14",
                    Supply: 23569,
                    Demand: 18304,
                    Quantity: 110262,
                  },
                ],
                Inbound_delivery_delay: [
                  {
                    "Current inventory plan by day": "",
                    "New inventory plan by day": "",
                    "Demand  by day": "",
                  },
                ],
              },
            },
            decision_time: "2024-06-11T12:57:28.367528+00:00",
            id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
            inputs_data: null,
            is_deleted: false,
            note: "",
            outputs_data: null,
            reason: null,
            reason_comment: "",
            role: "",
    
            updated_at: "2024-09-03T12:57:28.367542+00:00",
            updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
            user: {
              id: "602d5335-2be1-485f-a694-313d9428fafa",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.456366+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        current_execution: {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck Booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 1,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        executions:[
          
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck booking",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 2,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd227354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck loading",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 3,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
          {
            action: {
              id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
            },
            contexts: [
              {
                computed: false,
                created_at: "2024-08-14T17:25:07.384630+00:00",
                created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                data: {},
                decision_time: null,
                id: "69fd7354-d60b-41bc-aa96-56515831adb3",
                inputs_data: {},
                is_deleted: false,
                note: "",
                outputs_data: {},
                reason: null,
                reason_comment: "",
                role: "",
                updated_at: "2024-08-14T17:25:07.384634+00:00",
                updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                user: {
                  id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
                },
              },
            ],
            created_at: "2024-08-14T17:25:07.393646+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            execution_conf: null,
            execution_conf_name: "Truck transfer",
            execution_conf_policy: {
              automation: [],
            },
            execution_conf_priority: 4,
            execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
            execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
            id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
            is_deleted: false,
            roles: [
              {
                id: "746fd295-827b-4568-9933-1f193cf8f13d",
              },
              {
                id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
              },
            ],
            status: "OPEN",
            updated_at: "2024-08-14T17:25:07.393649+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          }
        ]
        ,
        feasible: true,
        id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
        is_deleted: false,
        risk: {
          id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
        },
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        // "status": "WAIT_FOR_EXECUTION",
        status: "OPEN",
        updated_at: "2024-09-03T12:57:28.379118+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
      },
    ]
  },
];

export const Inbound_Freight_start_Risk = {
  id: 11,
  risk_conf_name: "Custom clearance delay",
  due_date: "2024-12-04 00:00:00",
  copilot: "Procurement	",
  model: "Custom clearance delay",
  reason: [
    { id: "fgfergger545645", name: "Missing Data" },
    { id: "fgfergge25255645", name: "Action Error" },
    { id: "fgfergger25255645", name: "Execution Error" },
  ],
  status: "WAIT_FOR_ACTION",
  context: {
    Date_of_risk: "2024-12-08",
    "Port of Destination": "Dammam",
    "Boat number": "37651",
    "Container sealing": "CS5872187",
    "Demurrage fee": "523,911€",
    Quantity: "1,235,400",
  },
  impact: "70,003€",
  current_action: {
    action_conf: null,
    action_conf_name: "Defer custom clearance",
    action_conf_policy: {},
    action_conf_priority: 1,
    action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
    action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
    contexts: [
      {
        computed: false,
        created_at: "2024-08-14T17:25:07.443005+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        data: {
          DATA: {
            ACTION_DETAILS: {
              BOL: "BL7239988",
              Invoice: "INV235709",
              Certificate_of_origin: "CO26841",
              Inspection_certificate: "IC25602",
              Intercom: "EXW",
              Declared_value: "2,506,005€",
              Tax_and_duty_value: "375,900.75€",
            },

            ERRORS: {
              ERROR: [
                {
                  CODE: "MISSING_DATA",
                  DETAILS: {
                    DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                  },
                  MODULE: "Exobrain-actions",
                },
                {
                  CODE: "MISSING_DATA",
                  DETAILS: {
                    DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                  },
                  MODULE: "Exobrain-actions",
                },
              ],
              WARNING: [
                {
                  CODE: "UNPROCESSABLE_DATA",
                  DETAILS: {
                    DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                  },
                  MODULE: "Exobrain-actions",
                },
                {
                  CODE: "UNPROCESSABLE_DATA",
                  DETAILS: {
                    DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                  },
                  MODULE: "Exobrain-actions",
                },
              ],
            },
            IMPACT: {
              "Demurrage fee": "523,911€",
              "Quantity at risk": "1,235,400",
              "Customer penalties": "70,003€",
              "Custom clearance Planned ETA": "2024-12-11",
              "Custom clearance New ETA": "2024-12-13",
            },
            Table: [
              {
                Item_Code: "SKU001",
                GOOD_VALUE: "450,011€",
                HTS: "HS96204765026",
                TYPE: "2",
              },
              {
                Item_Code: "SKU02",
                GOOD_VALUE: "2,055,994€",
                HTS: "HS96204765026",
                TYPE: "Agriculture",
              },
            ],
            MASTER: {
              ITEM_MASTER: [
                {
                  ITEM_CODE: "VGPSLWNL003",
                  ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                  ITEM_TYPE: "F",
                  UNIT_PER_PALLET: null,
                  WEIGHT_PER_UNIT_IN_KG: null,
                },
              ],
              LOCATION_ITEM_MASTER: [
                {
                  DAYS_OF_SUPPLY_THRESHOLD: null,
                  ITEM_CODE: "VGPSLWNL003",
                  LOCATION_CODE: "264",
                  STANDARD_COST_PER_UNIT: null,
                },
              ],
              LOCATION_MASTER: [
                {
                  APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                  LOCATION_CITY: "JEDDAH",
                  LOCATION_CONTACT_NAME: null,
                  LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                  LOCATION_DESCRIPTION: "JEDDAH PORT",
                  LOCATION_ID: "JEDDAH PORT",
                  LOCATION_PRIMARY_LANGUAGE: null,
                  LOCATION_TYPE: null,
                  LOCATION_ZIP_CODE: null,
                },
                {
                  APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                  LOCATION_CITY: "   RIYADH",
                  LOCATION_CONTACT_NAME: null,
                  LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                  LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                  LOCATION_ID: "264",
                  LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                  LOCATION_TYPE: "BRANCH",
                  LOCATION_ZIP_CODE: null,
                },
              ],
            },
            Early_Request_Custom_clearance: [
              {
                Route_Name: "101-Customer 3 Route",
                Shipping_Location: "Istanbul",
                Current_Location: "Port Said",
                Receiving_Location: "Dammam",
                Clearance: 3,
                Planned_Boat_ETA: "08-Dec-2024",
              },
            ],
            OTHER_OPTIONS: [
              {
                Action_Name: "Early clearance request",
                Reason_Code: "no early custom clearance available",
                Execution_Stage: "",
                User: "Exobrain",
              },
              {
                Action_Name: "Expedited clearance request",
                Reason_Code: "custom delay",
                Execution_Stage: "",
                User: "Exobrain",
              },
            ],
          },
        },
        decision_time: "2024-06-11T12:57:28.367528+00:00",
        id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
        inputs_data: null,
        is_deleted: false,
        note: "",
        outputs_data: null,
        reason: null,
        reason_comment: "",
        role: "",

        updated_at: "2024-09-03T12:57:28.367542+00:00",
        updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
        user: {
          id: "602d5335-2be1-485f-a694-313d9428fafa",
        },
      },
    ],
    created_at: "2024-08-14T17:25:07.456366+00:00",
    created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
    current_execution: {
      action: {
        id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
      },
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.384630+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {},
          decision_time: null,
          id: "69fd7354-d60b-41bc-aa96-56515831adb3",
          inputs_data: {},
          is_deleted: false,
          note: "",
          outputs_data: {},
          reason: null,
          reason_comment: "",
          role: "",
          updated_at: "2024-08-14T17:25:07.384634+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          user: {
            id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.393646+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      execution_conf: null,
      execution_conf_name: "Truck Booking",
      execution_conf_policy: {
        automation: [],
      },
      execution_conf_priority: 1,
      execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
      execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
      id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
      is_deleted: false,
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      status: "OPEN",
      updated_at: "2024-08-14T17:25:07.393649+00:00",
      updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
    },
    executions:[
    
      {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 2,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd227354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck loading",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 3,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck transfer",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 4,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      }
    ]
,    
    feasible: true,
    id: "ca0798po45136-804b-4718-8ac1-ca1705a5c8e9",
    is_deleted: false,
    risk: {
      id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
    },
    roles: [
      {
        id: "746fd295-827b-4568-9933-1f193cf8f13d",
      },
      {
        id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
      },
    ],
    // "status": "WAIT_FOR_EXECUTION",
    status: "OPEN",
    updated_at: "2024-09-03T12:57:28.379118+00:00",
    updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
  },
  actions:[
    {
      action_conf: null,
      action_conf_name: "Early request custom clearance",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                Container_sealing: "CS109823",
                BOL: "BL1876544",
                Invoice: "INV9721",
                Certificate_of_origin: "CO3396",
                Inspection_certificate: "IC2950",
                Intercom: "EXW",
                Declared_value: "1,450,000€",
                Tax_and_duty_value: "2,173,003€",
              },
              Table: [
                {
                  Item_Code: "SKU001",
                  GOOD_VALUE: "500,000€",
                  HTS: "HS84713000",
                  TYPE: "Electronics",
                },
                {
                  Item_Code: "SKU003",
                  GOOD_VALUE: "950,000€",
                  HTS: "HS84713000",
                  TYPE: "Electronics",
                },
              ],
              OTHER_OPTIONS: [],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                "Demurrage fee": "412,200€",
                "Quantity at risk": "5,340,000",
                "Customer penalties": "84,200€",
                "Early request cost": "11,560€",
                "Custom clearance Planned ETA": "2024-12-13",
                "Custom clearance New ETA": "2024-12-15",
              },
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
  
              Early_Request_Custom_clearance: [
                {
                  Route_Name: "101-Customer 3 Route",
                  Shipping_Location: "Tilbury",
                  Current_Location: "Antwerp",
                  Receiving_Location: "Rotterdam",
                  Customer: "C0003",
                  Clearance: 3,
                  Planned_Boat_ETA: "10-Dec-2024",
                },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions:[
     
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd227354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck loading",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck transfer",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        }
      ]
      ,
      feasible: true,
      id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    {
      action_conf: null,
      action_conf_name: "Expedite custom clearance request",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                BOL: "BL832901",
                Invoice: "INV24302",
                Certificate_of_origin: "CO680246",
                Inspection_certificate: "IC298405",
                Intercom: "EXW",
                Declared_value: "2,240,000€",
                Tax_and_duty_value: "336,000€",
              },
              Table: [
                {
                  Item_Code: "SKU004",
                  GOOD_VALUE: "2,240,000€",
                  HTS: "HS7629403775",
                  TYPE: "Medical Equipment",
                },
              ],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                "Expedited request cost": "47,612€",
                "Custom clearance Planned ETA": "2024-12-14",
                "Custom clearance New ETA": "2024-12-12",
                "Demurrage fee": "487,619€",
                "Quantity at risk": "4,480,000",
                "Customer penalties": "97,100€",
              },
  
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              OTHER_OPTIONS: [
                {
                  Action_Name: "Early clearance request",
                  Reason_Code: "no early custom clearance available",
                  Execution_Stage: "",
                  User: "Exobrain",
                },
              ],
              Early_Request_Custom_clearance: [
                {
                  Route_Name: "101-Customer 3 Route",
                  Shipping_Location: "Dakar Senegal",
                  Current_Location: "Plymouth",
                  Receiving_Location: "Rotterdam",
                  Clearance: 3,
                  Planned_Boat_ETA: "11-Dec-2024",
                },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions:[
       
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd227354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck loading",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck transfer",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        }
      ]
      ,
      feasible: true,
      id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    {
      action_conf: null,
      action_conf_name: "Defer custom clearance",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                BOL: "BL7239988",
                Invoice: "INV235709",
                Certificate_of_origin: "CO26841",
                Inspection_certificate: "IC25602",
                Intercom: "EXW",
                Declared_value: "2,506,005€",
                Tax_and_duty_value: "375,900.75€",
              },
  
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                "Demurrage fee": "523,911€",
                "Quantity at risk": "1,235,400",
                "Customer penalties": "70,003€",
                "Custom clearance Planned ETA": "2024-12-11",
                "Custom clearance New ETA": "2024-12-13",
              },
              Table: [
                {
                  Item_Code: "SKU001",
                  GOOD_VALUE: "450,011€",
                  HTS: "HS96204765026",
                  TYPE: "2",
                },
                {
                  Item_Code: "SKU02",
                  GOOD_VALUE: "2,055,994€",
                  HTS: "HS96204765026",
                  TYPE: "Agriculture",
                },
              ],
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              Early_Request_Custom_clearance: [
                {
                  Route_Name: "101-Customer 3 Route",
                  Shipping_Location: "Istanbul",
                  Current_Location: "Port Said",
                  Receiving_Location: "Dammam",
                  Clearance: 3,
                  Planned_Boat_ETA: "08-Dec-2024",
                },
              ],
              OTHER_OPTIONS: [
                {
                  Action_Name: "Early clearance request",
                  Reason_Code: "no early custom clearance available",
                  Execution_Stage: "",
                  User: "Exobrain",
                },
                {
                  Action_Name: "Expedited clearance request",
                  Reason_Code: "custom delay",
                  Execution_Stage: "",
                  User: "Exobrain",
                },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions: [
        
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd227354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck loading",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck transfer",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
      ],
      feasible: true,
      id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    {
      action_conf: null,
      action_conf_name: "Expedite transfer",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                BOL: "BL745291",
                Custom_clearance_status: "Pending",
                Custom_clearance_ETA: "2024-12-12",
                Port_of_Destination: "Le Havre",
                Planned_destination: "Zurich",
                Planned_transfer_time_in_days: 2,
                New_transfer_time_in_days: 1,
                Total_weight: "22 tons",
              },
              Table: [
                {
                  Item_Code: "SKU004",
                  NUMBER_OF_PALLETS: 28,
                  NUMBER_OF_UNITS: "1,405",
                  TOTAL_WEIGHT: "22 tons",
                },
              ],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                "Expedited transfer cost": "18,100€",
                "Inventory shortage cost avoidance": "69,055€",
                "Planned ETA": "2024-12-14",
                "New ETA": "2024-12-15",
                "Customer penalties": "125,300€",
                "Quantity at risk": "1,230,004",
              },
  
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              OTHER_OPTIONS: [
                {
                  Action_Name: "Expedited clearance request",
                  Reason_Code: "custom delay",
                  Execution_Stage: "",
                  User: "Exobrain",
                },
              ],
              Inbound_Inventory: [
                {
                  date: "2024-12-08",
                  Supply: 0,
                  Demand: 59516,
                  Quantity: 59315,
                },
                {
                  date: "2024-12-09",
                  Supply: 12304,
                  Demand: 62030,
                  Quantity: -201,
                },
                {
                  date: "2024-12-10",
                  Supply: 14455,
                  Demand: 64344,
                  Quantity: 116773,
                },
                {
                  date: "2024-12-11",
                  Supply: 32544,
                  Demand: 62148,
                  Quantity: 13429,
                },
                {
                  date: "2024-12-12",
                  Supply: 54996,
                  Demand: 64262,
                  Quantity: 141281,
                },
                {
                  date: "2024-12-13",
                  Supply: 12536,
                  Demand: 64172,
                  Quantity: 97719,
                },
                {
                  date: "2024-12-14",
                  Supply: 0,
                  Demand: 61250,
                  Quantity: 91247,
                },
              ],
              Inbound_delivery_delay: [
                {
                  "Current inventory plan by day": "",
                  "New inventory plan by day": "",
                  "Demand  by day": "",
                },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions:[
       
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd227354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck loading",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck transfer",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        }
      ]
      ,
      feasible: true,
      id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
    {
      action_conf: null,
      action_conf_name: "Reroute transfer",
      action_conf_policy: {},
      action_conf_priority: 1,
      action_conf_src_id: "04b4ecba-4adb-40a9-a485-fe40ab809479",
      action_model: "6a7ac275-4a4b-43eb-a9b5-4cb36b2b591c",
      contexts: [
        {
          computed: false,
          created_at: "2024-08-14T17:25:07.443005+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          data: {
            DATA: {
              ACTION_DETAILS: {
                BOL: "BL82036",
                Custom_clearance_status: "Pending",
                Custom_clearance_ETA: "2024-12-10",
                Port_of_Destination: "Damman",
                Planned_destination: "Sakakah",
                New_destination: "Buraydah",
                Planned_transfer_time_in_days: 2,
                New_transfer_time_in_days: 1,
                Total_weight: "20 tons",
              },
              Table: [
                {
                  Item_Code: "SKU006",
                  NUMBER_OF_PALLETS: 26,
                  NUMBER_OF_UNITS: "1,300",
                  TOTAL_WEIGHT: "20 tons",
                },
              ],
              ERRORS: {
                ERROR: [
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_526_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "MISSING_DATA",
                    DETAILS: {
                      DATA: "INVENTORY_AT_DESTINATION_AT_3084_PRODUCT_VGPSLWNL003",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
                WARNING: [
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "RISK.CONTEXT.DATE_AT_ORIGIN_IN_THE_PAST",
                    },
                    MODULE: "Exobrain-actions",
                  },
                  {
                    CODE: "UNPROCESSABLE_DATA",
                    DETAILS: {
                      DATA: "CONTAINER_PRODUCTS_LIST.QUANTITY_IN_PALLETS_OF_SUDU5192116",
                    },
                    MODULE: "Exobrain-actions",
                  },
                ],
              },
              IMPACT: {
                "Rerouted transfer cost": "8,200€",
                "Inventory shortage cost avoidance": "59,250€",
                "Planned ETA": "2024-12-12",
                "New ETA": "2024-12-11",
                "Customer penalties": "95,900€",
                "Quantity at risk": "153,005",
              },
  
              MASTER: {
                ITEM_MASTER: [
                  {
                    ITEM_CODE: "VGPSLWNL003",
                    ITEM_DESCRIPTION: "POTATO STEALTH 9*9 LAMBWESTON 4*2.5 KG",
                    ITEM_TYPE: "F",
                    UNIT_PER_PALLET: null,
                    WEIGHT_PER_UNIT_IN_KG: null,
                  },
                ],
                LOCATION_ITEM_MASTER: [
                  {
                    DAYS_OF_SUPPLY_THRESHOLD: null,
                    ITEM_CODE: "VGPSLWNL003",
                    LOCATION_CODE: "264",
                    STANDARD_COST_PER_UNIT: null,
                  },
                ],
                LOCATION_MASTER: [
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "JEDDAH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "JEDDAH PORT",
                    LOCATION_ID: "JEDDAH PORT",
                    LOCATION_PRIMARY_LANGUAGE: null,
                    LOCATION_TYPE: null,
                    LOCATION_ZIP_CODE: null,
                  },
                  {
                    APPROVAL_BUFFER_TIME_IN_DAYS: 1,
                    LOCATION_CITY: "   RIYADH",
                    LOCATION_CONTACT_NAME: null,
                    LOCATION_COUNTRY: "KINGDOM OF SAUDI ARABIA",
                    LOCATION_DESCRIPTION: "CS INVENTORY RIYADH ORGANIZATION",
                    LOCATION_ID: "264",
                    LOCATION_PRIMARY_LANGUAGE: "ARABIC",
                    LOCATION_TYPE: "BRANCH",
                    LOCATION_ZIP_CODE: null,
                  },
                ],
              },
              OTHER_OPTIONS: [],
              Inbound_Inventory: [
                {
                  date: "2024-12-08",
                  Supply: 27896,
                  Demand: 59516,
                  Quantity: 90040,
                },
                {
                  date: "2024-12-09",
                  Supply: 900,
                  Demand: 21834,
                  Quantity: 68730,
                },
                {
                  date: "2024-12-10",
                  Supply: 14758,
                  Demand: 22931,
                  Quantity: 46896,
                },
                {
                  date: "2024-12-11",
                  Supply: 32569,
                  Demand: 24699,
                  Quantity: 23965,
                },
                {
                  date: "2024-12-12",
                  Supply: 15305,
                  Demand: 22679,
                  Quantity: -734,
                },
                {
                  date: "2024-12-13",
                  Supply: 0,
                  Demand: 19330,
                  Quantity: 129592,
                },
                {
                  date: "2024-12-14",
                  Supply: 23569,
                  Demand: 18304,
                  Quantity: 110262,
                },
              ],
              Inbound_delivery_delay: [
                {
                  "Current inventory plan by day": "",
                  "New inventory plan by day": "",
                  "Demand  by day": "",
                },
              ],
            },
          },
          decision_time: "2024-06-11T12:57:28.367528+00:00",
          id: "54e3b1a0-7c83-45f7-8eb3-7a9ff6ad4347",
          inputs_data: null,
          is_deleted: false,
          note: "",
          outputs_data: null,
          reason: null,
          reason_comment: "",
          role: "",
  
          updated_at: "2024-09-03T12:57:28.367542+00:00",
          updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
          user: {
            id: "602d5335-2be1-485f-a694-313d9428fafa",
          },
        },
      ],
      created_at: "2024-08-14T17:25:07.456366+00:00",
      created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      current_execution: {
        action: {
          id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
        },
        contexts: [
          {
            computed: false,
            created_at: "2024-08-14T17:25:07.384630+00:00",
            created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            data: {},
            decision_time: null,
            id: "69fd7354-d60b-41bc-aa96-56515831adb3",
            inputs_data: {},
            is_deleted: false,
            note: "",
            outputs_data: {},
            reason: null,
            reason_comment: "",
            role: "",
            updated_at: "2024-08-14T17:25:07.384634+00:00",
            updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            user: {
              id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
            },
          },
        ],
        created_at: "2024-08-14T17:25:07.393646+00:00",
        created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        execution_conf: null,
        execution_conf_name: "Truck Booking",
        execution_conf_policy: {
          automation: [],
        },
        execution_conf_priority: 1,
        execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
        execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
        id: "ebf9bafe-b462-4809-93fd-2f5d5f119611",
        is_deleted: false,
        roles: [
          {
            id: "746fd295-827b-4568-9933-1f193cf8f13d",
          },
          {
            id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
          },
        ],
        status: "OPEN",
        updated_at: "2024-08-14T17:25:07.393649+00:00",
        updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
      },
      executions:[
        
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck booking",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 2,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "eb22f954bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd227354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck loading",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 3,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebjhf339bafe-b462-4809-93fd-2f5d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        },
        {
          action: {
            id: "ca073236-804b-4718-8ac1-ca1705a5c8e9",
          },
          contexts: [
            {
              computed: false,
              created_at: "2024-08-14T17:25:07.384630+00:00",
              created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              data: {},
              decision_time: null,
              id: "69fd7354-d60b-41bc-aa96-56515831adb3",
              inputs_data: {},
              is_deleted: false,
              note: "",
              outputs_data: {},
              reason: null,
              reason_comment: "",
              role: "",
              updated_at: "2024-08-14T17:25:07.384634+00:00",
              updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              user: {
                id: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
              },
            },
          ],
          created_at: "2024-08-14T17:25:07.393646+00:00",
          created_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
          execution_conf: null,
          execution_conf_name: "Truck transfer",
          execution_conf_policy: {
            automation: [],
          },
          execution_conf_priority: 4,
          execution_conf_src_id: "7a41571a-8a74-45bf-af48-7f69897aa6f8",
          execution_model: "73e441ca-79de-4476-9fca-b422899abd92",
          id: "ebf3649bafe-b462-4809-93f4d-2f54d5f119611",
          is_deleted: false,
          roles: [
            {
              id: "746fd295-827b-4568-9933-1f193cf8f13d",
            },
            {
              id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
            },
          ],
          status: "OPEN",
          updated_at: "2024-08-14T17:25:07.393649+00:00",
          updated_by: "efa6e7b3-299a-414a-b4a5-f85dbfbca482",
        }
      ]
      ,
      feasible: true,
      id: "ca0745136-804b-4718-8ac1-ca1705a5c8e9",
      is_deleted: false,
      risk: {
        id: "3297f478-e357-4fc3-a4c0-8a48762f073c",
      },
      roles: [
        {
          id: "746fd295-827b-4568-9933-1f193cf8f13d",
        },
        {
          id: "963ffc75-cf7d-4c23-8eda-9425aaaef3b6",
        },
      ],
      // "status": "WAIT_FOR_EXECUTION",
      status: "OPEN",
      updated_at: "2024-09-03T12:57:28.379118+00:00",
      updated_by: "602d5335-2be1-485f-a694-313d9428fafa",
    },
  ]
};

export const Inbound_Freight_reasons = [
  { id: "freight forwarder calendar", name: "freight forwarder calendar" },
  {
    id: "no freight forwarder capacity",
    name: "no freight forwarder capacity",
  },
  { id: "custom constraints", name: "custom constraints" },
  { id: "other", name: "other" },
];
  
























