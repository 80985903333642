import React, { createContext, useState, ReactNode } from "react";
import { Reject_Arguments } from "../../../../interfaces/reject-arguments";
import { RisksItem } from "../../../../interfaces/risks";

// Define the types
type SharedContextType = {
  descriptorsList: any;
  setDescriptorsList: React.Dispatch<React.SetStateAction<any>>;
  currentActionSettings: any;
  setCurrentActionSettings: React.Dispatch<React.SetStateAction<any>>;
  actionConfs: any[];
  setActionConfs: React.Dispatch<React.SetStateAction<any>>;
  execConfs: any[];
  setExecConfs: React.Dispatch<React.SetStateAction<any>>;
  runningRisksFilter: any[];
  setRunningRisksFilter: React.Dispatch<React.SetStateAction<any>>;
  runningRisks: any[];
  setRunningRisks: React.Dispatch<React.SetStateAction<any>>;
  workModeRisk: string;
  setWorkModeRisk: React.Dispatch<React.SetStateAction<string>>;
  data: RisksItem[];
  setData: React.Dispatch<React.SetStateAction<RisksItem[]>>;
  openAddReasonCodeModal: boolean;
  setOpenAddReasonCodeModal: React.Dispatch<React.SetStateAction<boolean>>;
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRisk: any;
  setSelectedRisk: React.Dispatch<React.SetStateAction<any>>;
  currentAction: any;
  setCurrentAction: React.Dispatch<React.SetStateAction<any>>;
  currentActionExecutionReasons: any;
  setCurrentActionExecutionReasons: React.Dispatch<React.SetStateAction<any>>;
  currentActionExecutionRejectArguments: Reject_Arguments;
  setCurrentActionExecutionRejectArguments: React.Dispatch<
    React.SetStateAction<any>
  >;
  typeReject: string;
  setTypeReject: React.Dispatch<React.SetStateAction<any>>;
  totalRunningRisk: number;
  setTotalRunningRisk: React.Dispatch<React.SetStateAction<any>>;
  getLoading: boolean;
  setGetLoading: React.Dispatch<React.SetStateAction<any>>;
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  currency: string;
  setCurrency: React.Dispatch<React.SetStateAction<string>>;
  formatNmber: string;
  setFormatNmber: React.Dispatch<React.SetStateAction<string>>;
  formatDate: string;
  setFormatDate: React.Dispatch<React.SetStateAction<string>>;
  currentRiskActions: any[];
  setCurrentRiskActions: React.Dispatch<React.SetStateAction<any[]>>;
};

export const SharedContext = createContext<SharedContextType>({
  // Provide default values that match the defined types
  descriptorsList: [],
  setDescriptorsList: () => {},
  currentActionSettings: {},
  setCurrentActionSettings: () => {},
  actionConfs: [],
  setActionConfs: () => {},
  execConfs: [],
  setExecConfs: () => {},
  runningRisksFilter: [],
  setRunningRisksFilter: () => {},
  totalRunningRisk: 0,
  setTotalRunningRisk: () => {},
  runningRisks: [],
  setRunningRisks: () => {},
  workModeRisk: "",
  setWorkModeRisk: () => {},
  data: [],
  setData: () => {},
  openAddReasonCodeModal: false,
  setOpenAddReasonCodeModal: () => {},
  openDrawer: false,
  setOpenDrawer: () => {},
  currentAction: {},
  setCurrentAction: () => {},
  selectedRisk: {},
  setSelectedRisk: () => {},
  currentActionExecutionReasons: {},
  setCurrentActionExecutionReasons: () => {},
  currentActionExecutionRejectArguments: {
    type: "",
    id: "",
    contexts: { id: "" },
  },
  setCurrentActionExecutionRejectArguments: () => {},
  typeReject: "",
  setTypeReject: () => {},
  getLoading: true,
  setGetLoading: () => {},
  currency: "",
  setCurrency: () => {},
  language: "",
  setLanguage: () => {},
  formatNmber: "",
  setFormatNmber: () => {},
  formatDate: "",
  setFormatDate: () => {},
  currentRiskActions: [],
  setCurrentRiskActions: () => {},
});

// Create a provider component to set the values
export const SharedContextProviderRunningDemo: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [runningRisksFilter, setRunningRisksFilter] = useState<any>([]);
  const [typeReject, setTypeReject] = useState<string>("");
  const [runningRisks, setRunningRisks] = useState<any>([]);
  const [totalRunningRisk, setTotalRunningRisk] = useState<any>(0);
  const [getLoading, setGetLoading] = useState<any>(true);
  const [currentAction, setCurrentAction] = useState<any>({});
  const [selectedRisk, setSelectedRisk] = useState<any>({});
  const [currentActionExecutionReasons, setCurrentActionExecutionReasons] =
    useState<any>({});
  const [
    currentActionExecutionRejectArguments,
    setCurrentActionExecutionRejectArguments,
  ] = useState<Reject_Arguments>({ type: "", id: "", contexts: { id: "" } });
  const [workModeRisk, setWorkModeRisk] = useState<string>("");
  const [data, setData] = useState<RisksItem[]>([]);
  const [openAddReasonCodeModal, setOpenAddReasonCodeModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [actionConfs, setActionConfs] = useState<any>([]);
  const [execConfs, setExecConfs] = useState<any>([]);
  /** users informations */
  const [language, setLanguage] = useState<any>([]);
  const [formatNmber, setFormatNmber] = useState<any>([]);
  const [currency, setCurrency] = useState<any>([]);
  const [formatDate, setFormatDate] = useState<any>([]);
  const [currentRiskActions, setCurrentRiskActions] = useState<any>([]);
  const [currentActionSettings, setCurrentActionSettings] = useState<any>({});
  const [descriptorsList, setDescriptorsList] = useState<any>({});

  const contextValues: SharedContextType = {
    selectedRisk,
    setSelectedRisk,

    descriptorsList,
    setDescriptorsList,
    currentActionSettings,
    setCurrentActionSettings,
    currentRiskActions,
    setCurrentRiskActions,
    formatDate,
    setFormatDate,
    currency,
    setCurrency,
    language,
    setLanguage,
    formatNmber,
    setFormatNmber,
    actionConfs,
    setActionConfs,
    execConfs,
    setExecConfs,
    runningRisksFilter,
    setRunningRisksFilter,
    runningRisks,
    setRunningRisks,
    workModeRisk,
    setWorkModeRisk,
    data,
    setData,
    openAddReasonCodeModal,
    setOpenAddReasonCodeModal,
    openDrawer,
    setOpenDrawer,
    currentAction,
    setCurrentAction,
    currentActionExecutionReasons,
    setCurrentActionExecutionReasons,
    currentActionExecutionRejectArguments,
    setCurrentActionExecutionRejectArguments,
    typeReject,
    setTypeReject,
    totalRunningRisk,
    setTotalRunningRisk,
    getLoading,
    setGetLoading,
  };

  return (
    <SharedContext.Provider value={contextValues}>
      {children}
    </SharedContext.Provider>
  );
};
