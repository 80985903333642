import { Context } from "../../interfaces/context";
import Api from "./api";
import { getFilterRule } from "../functions/getFilterRule";
import { getManyUrlQuery, getOneUrlQuery } from "../functions/getUrlQuery";
import { FilterRules } from "../interfaces/FilterRules";
class CrudService extends Api {
  ressource: string;
  constructor(
    ressource: { name: string; isPublic: boolean },
    context: Context,
    setContext: (c: Context) => void,
    path?: string
  ) {
    super(context, setContext, path ?? "");
    this.ressource = ressource.isPublic
      ? `${ressource.name}`
      : `organizations/${this?.context?.orga?.id}/${ressource.name}`;
  }

  async filterResource(
    params: any,
    filters?: FilterRules,
    fulls?: string[]
  ): Promise<any> {
    const url = getManyUrlQuery(`/${this.ressource}/search`, params, fulls);
    const filter = getFilterRule(filters);

    const { data } = await this.post(url, filter);
    return data;
  }

  async getResources(params: any, fulls?: string[]): Promise<any> {
    const url = getManyUrlQuery(`/${this.ressource}/`, params, fulls);

    const { data } = await this.get(url);
    return data;
  }

  async getResource(id: string, fulls?: string[]): Promise<any> {
    const url = getOneUrlQuery(`/${this.ressource}/${id}`, fulls);

    let data = await this.get(url);
    return { data };
  }

  async deleteResource(id: string) {
    const { data } = await this.delete(`/${this.ressource}/${id}`);
    return data;
  }

  async createResource(args: any) {
    const { data } = await this.post(`/${this.ressource}/`, args);
    return { data };
  }

  async updateResource(id: string, args: any) {
    let data = await this.patch(`/${this.ressource}/${id}`, args);
    return { data };
  }
}

export default CrudService;
