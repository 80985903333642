import { MenuOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import DndList from "./DnDList";
import { Context } from "./../../../interfaces/context";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 1 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: "none", cursor: "move" }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

interface RowProps {
  columns: [];
  dataSource: [];
  setDataSource: (c: any) => void;
}

function DndCxt(props: any) {
  const { columns, dataSource, setDataSource } = props;

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = dataSource?.findIndex(
        (i: any) => i.key === active.id
      );
      const overIndex = dataSource?.findIndex((i: any) => i.key === over?.id);
      const updatedDataSource = arrayMove(dataSource, activeIndex, overIndex);
      setDataSource(updatedDataSource);
    }
  };

  return (
    <>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={dataSource?.map((i: any) => i.key) || []}
          strategy={verticalListSortingStrategy}
        >
          <DndList
            Row={Row}
            dataSource={dataSource}
            setDataSource={setDataSource}
            columns={columns}
          />
        </SortableContext>
      </DndContext>
    </>
  );
}

export default DndCxt;
