import { useContext, useEffect, useState } from "react";
import { validate as uuidValidate } from "uuid";
import { MainContext } from "../../../../../utils/context";
/* AntD */
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Tag,
  Tooltip,
  message,
} from "antd";
import { ProColumns } from "@ant-design/pro-components";
import {
  LockOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
/* Components */
import AddExecutionModal from "../../executions/AddExecutionModal";
import DndCxt from "../../../../common/dnd-list/DnDContext";
import MenuOptions from "../../../../common/menu/MenuOptions";
/* Global Vars */
import { WORK_MODE } from "../../../../../constants/workModes";
import { PAGES } from "../../../../../constants/pages";
import SaveCancel from "../../../../common/SaveCancel";
/** redux */
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store/store";
import { Reason } from "../../../../../interfaces/reasons";
import { MyCollaps } from "../../common/automation/components/MyCollapse";
import { ActionAddAutomationModal } from "../../common/automation/actions/ActionAddRuleModal";
import ActionParametersSettings from "./ActionParamettersSetting";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/ActionLogic";
import {
  constructActionParametersObject,
  navigateOnInvalidUUID,
  updateURL,
} from "../../../../../utils/functions";
import { SharedContext } from "../../context/sharedContext";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../utils/transaltion";
import { AUTOMATION_TYPES } from "../../../../../constants/automationType";
import { isPermission } from "../../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../../constants/permissions";
import CustomTag from "../../../../common/tags/CustomTag";
import { extractDataNeededFromURL } from "../../../predefineds/logic/PredefinedsLogic";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const { Option } = Select;

function AddActionModal(props: any) {
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  const { t } = useTranslation();
  const [showAuto, setShowAuto] = useState(false);
  const {
    dynamicFieldsSettings,
    setActionRecord,
    setWorkModeRisk,
    getLoadingRisk,
  } = useContext(SharedContext);
  /* ActionModal States */
  const { closeActionModal, workModeAction, actionRecord } = props;

  let { setWorkModeAction } = props;
  /* To handle action from the risk table */
  const { riskActions, setRiskActions } = props;
  /* To update action from the action table */
  const { updateSource /* setActionRecord */ } = props;
  /** predefined risk id to filter predefined actions */
  const { predefinedRiskId } = props;
  const [predefinedFilteredActions, setPredefinedFilteredActions] =
    useState<any>([]);
  /* Handle used reason popup */
  const [openUsedReasonPopup, setOpenUsedReasonPopup] = useState("");
  /* Rules */
  const [actionRules, setActionRules] = useState(
    workModeAction !== WORK_MODE.CREATE
      ? (actionRecord && actionRecord["autoPolicy"]?.automation) || []
      : []
  );
  const [actionID, setActionID] = useState("");
  const [isPredefined, setIsPredefined] = useState(false);
  const { getActionParameters, updateAction } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  /* Action Form */
  const [form_action] = Form.useForm();

  const [actionsReasons, setActionsReasons] = useState<Reason[]>([]);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [actionIsDisable, setActionDisable]: any = useState(
    workModeAction === WORK_MODE.CREATE ? false : actionRecord?.enabled
  );
  const [actionIsAuto, setActionAuto]: any = useState(
    workModeAction === WORK_MODE.CREATE ? false : actionRecord?.auto
  );

  const [actionExecutions, setActionExecutions] = useState(
    workModeAction === WORK_MODE.CREATE
      ? []
      : actionRecord?.executions
          ?.sort((a: any, b: any) => a.priority - b.priority)
          ?.map((obj: any, index: any) => ({
            ...obj,
            key: index + 1,
          }))
  );

  /* Execution Modal */
  const navigate = useNavigate();
  const location = useLocation();
  const { uuidexecution: uuidExecution } = useParams<{
    uuidexecution?: string;
  }>();

  const [openExecutionModal, setOpenExecutionModal] = useState(false);
  const [openRuleModal, setOpenRuleModal] = useState(false);
  const [executionRecord, setExecutionRecord] = useState([]);
  const [ruleRecord, setRuleRecord] = useState([]);
  const [workModeExecution, setWorkModeExecution] = useState("");
  const [workModeAutomation, setWorkModeAutomation] = useState(
    WORK_MODE.CREATE
  );
  const [isAutoModalDirty, setIsAutoModalDirty] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  /** roles from redux */
  const roless = useSelector((state: RootState) => state.roleReducer);
  const [actionRoles, setActionRoles] = useState<any[]>(
    [...roless]?.sort((a, b) => a.name.localeCompare(b.name))
  );

  /** reasons from redux */
  const reasons: Reason[] = useSelector(
    (state: RootState) => state.reasonsActionsReducer
  );
  const globalContext = useContext(MainContext);
  useEffect(() => {
    const PredefinedActionSettings =
      predefinedFilteredActions?.find((model: any) => model.id === actionID)
        ?.actionParameters || {};

    if (workModeAction === WORK_MODE.CREATE) {
      getActionParameters(workModeAction, PredefinedActionSettings);
    } else {
      //update or view
      getActionParameters(
        workModeAction,
        actionRecord?.model?.actionParameters,
        actionRecord?.actionSettings
      );
      filterReasonsUpdateView(actionRecord?.model?.reasons);
    }

    if (!globalContext || !globalContext.context.orga) {
      return;
    }
    // check the predefined action selected  if auto=true
    setShowAuto(
      predefinedFilteredActions?.find((model: any) => model.id === actionID)
        ?.canAuto
    );
  }, [globalContext, workModeAction, actionRecord, actionID]);

  const columns: ProColumns<any>[] = [
    {
      key: "sort",
      width: 60,
    },
    {
      render: (text, record, index) => index + 1,
      width: 60,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "50%",
    },
    {
      title: "Enabled Status",
      width: "8%",
      render: (text, record, index) => {
        return record?.enabled ? (
          <CustomTag color={"#22A06B"} text={"Enabled"} />
        ) : (
          <CustomTag color={"#DE350B"} text={"Disabled"} />
        );
      },
    },

    {
      title: "Deleted Status",
      width: "8%",
      render: (text, record) => {
        return (
          record?.is_deleted && <CustomTag color={"#DE350B"} text={"Deleted"} />
        );
      },
    },

    isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN)
      ? {
          title: "More Risks",
          valueType: "option",
          key: "option",
          width: "20%",
          align: "center",
          render: (text, record) => (
            <span>
              <MenuOptions
                viewRecord={() => {
                  updateURL(
                    location.pathname,
                    navigate,
                    "execution",
                    record?.id
                  );
                  setRecord(record);
                  changeWorkMode(WORK_MODE.VIEW);
                  showExecutionModal();
                }}
                editRecord={async () => {
                  updateURL(
                    location.pathname,
                    navigate,
                    "execution",
                    record?.id
                  );
                  setRecord(record);
                  changeWorkMode(WORK_MODE.UPDATE);
                  showExecutionModal();
                }}
                deleteRecord={() => {
                  setActionExecutions(
                    actionExecutions.filter((execution: any) =>
                      execution?.id
                        ? execution?.id !== record.id
                        : execution?.key !== record.key
                    )
                  );
                }}
                title={t("errors:CONFIRM_DELETE_MESSAGE")}
                mode={workModeAction}
              />
            </span>
          ),
        }
      : {},
  ];

  const AutomationRulesColumns: ProColumns<any>[] = [
    {
      key: "sort",
      width: 60,
    },
    {
      render: (text, record, index) => index + 1,
      width: 60,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record, index) => (
        <>
          {t(record?.action) +
            " " +
            (record?.reason
              ? t("REASON") +
                " :" +
                getTranslation(
                  reasons
                    .find((r) => r.id === record?.reason)
                    ?.name?.toString() || "",
                  "data"
                )
              : "")}
        </>
      ),
    },
    {
      title: "More Risks",
      valueType: "option",
      key: "option",
      width: "20%",
      align: "center",
      render: (text, record) => (
        <span>
          <MenuOptions
            viewRecord={() => {
              setRuleRecord(record);
              setWorkModeAutomation(WORK_MODE.VIEW);
              showRuleModal();
            }}
            editRecord={async () => {
              setRuleRecord(record);
              setWorkModeAutomation(WORK_MODE.UPDATE);
              showRuleModal();
            }}
            deleteRecord={() => {
              setActionRules(
                actionRules.filter((execution: any) =>
                  execution?.id
                    ? execution?.id !== record.id
                    : execution?.key !== record.key
                )
              );
            }}
            title={t("errors:CONFIRM_DELETE_MESSAGE")}
            mode={workModeAction}
          />
        </span>
      ),
    },
  ];
  /** get predefined risks from redux */
  const predefinedRiskList = useSelector(
    (state: RootState) => state.predefinedRiskOrgaReducer
  );
  /** get predefined actions from redux */
  const predefinedActions = useSelector(
    (state: RootState) => state.predefinedActionOrgaReducer
  );

  // useeffects

  useEffect(() => {
    setActionDisable(
      workModeAction === WORK_MODE.CREATE ? false : actionRecord?.enabled
    );
    form_action.setFieldsValue({
      name: actionRecord?.name,
      model: actionRecord?.model?.id,
      roles: actionRecord?.roles?.map((role: any) => role?.id),
      reasons: actionsReasons?.map((reason: any) => reason?.id),
    });
    setActionExecutions(
      workModeAction === WORK_MODE.CREATE
        ? []
        : actionRecord?.executions
            ?.sort((a: any, b: any) => a.priority - b.priority)
            ?.map((obj: any, index: any) => ({
              ...obj,
              key: index + 1,
            }))
    );

    setActionRules(
      workModeAction !== WORK_MODE.CREATE
        ? (actionRecord && actionRecord["autoPolicy"]?.automation) || []
        : []
    );
  }, [actionRecord]);
  useEffect(() => {
    getReasonsCodes();
  }, [actionsReasons]);

  useEffect(() => {
    filterPredefinedActions();
    if (
      workModeAction === WORK_MODE.UPDATE ||
      workModeAction === WORK_MODE.VIEW
    ) {
      setActionID(actionRecord?.model?.id);
    }

    if (selectedReasons?.length > 0)
      form_action.setFieldValue("reasons", selectedReasons);
  }, [selectedReasons]);

  useEffect(() => {
    if (!globalContext || !globalContext.context.orga) {
      return;
    }
    if (
      workModeAction === WORK_MODE.UPDATE ||
      workModeAction === WORK_MODE.VIEW
    ) {
      if (actionRecord?.model?.id) {
        form_action.setFieldsValue({
          model: actionRecord?.model?.id,
        });
        setIsPredefined(true);
      }
      setSelectedReasons(actionRecord?.reasons?.map((r: any) => r.id));
    }
    if (
      workModeAction === WORK_MODE.UPDATE ||
      workModeAction === WORK_MODE.VIEW
    ) {
      filterRoles(actionRecord?.model?.id);
      filterReasons(actionRecord?.model?.id);
    }
  }, [workModeAction, actionRecord]);
  /**handling acces with link execution */
  useEffect(() => {
    if (uuidExecution && uuidValidate(uuidExecution)) {
      setOpenExecutionModal(true);
      setExecutionRecord(
        actionExecutions?.find((obj: any) => obj?.id === uuidExecution)
      );
      setWorkModeExecution(WORK_MODE.VIEW);
    }
    navigateOnInvalidUUID(navigate, uuidExecution);
  }, [uuidExecution, actionExecutions]);

  /* Services */
  if (!globalContext) {
    return <></>;
  }

  // functions
  const handleWorkModeChange = async () => {
    setWorkModeAction(WORK_MODE.UPDATE);
    setWorkModeRisk(WORK_MODE.UPDATE);
  };

  /** filter  predefined actions */
  const filterPredefinedActions = () => {
    if (predefinedRiskId) {
      const filteredRisk = predefinedRiskList?.find(
        (risk: any) => risk.id === predefinedRiskId
      );
      const actionIds = filteredRisk?.actions?.map((action: any) => action.id);

      const filteredActions = predefinedActions
        ?.filter((predefinedAction: any) =>
          actionIds?.includes(predefinedAction?.id)
        )
        ?.filter((item: any) => item?.enabled === true);
      setPredefinedFilteredActions(filteredActions);
    } else {
      // here coming from action table
      // cheking if the predefined hav auto true
      setPredefinedFilteredActions(predefinedActions);
      setShowAuto(
        predefinedActions?.find((model: any) => model.id === actionID)?.canAuto
      );
    }
  };

  /** filter reasons in view and update mode */
  const filterReasonsUpdateView = (reasonsIds: any[]) => {
    const filteredReasons = reasons.filter((reason) =>
      reasonsIds?.some((reasonId) => reasonId.id === reason.id)
    );

    setActionsReasons(filteredReasons);
  };
  /** filter resons by predefined action  */
  const filterReasons = (predefinedActionId: any) => {
    const filteredPredefinedAction: any = predefinedActions?.find(
      (model: any) => model.id === predefinedActionId
    );

    const reaonsIds = filteredPredefinedAction?.reasons?.map(
      (item: any) => item.id
    );

    const filteredreasons = reasons?.filter((item: any) =>
      reaonsIds?.includes(item?.id)
    );

    setActionsReasons(filteredreasons);
  };
  /** filter resons by predefined action  */
  const filterRoles = (predefinedId: any) => {
    const filteredPredefinedAction: any = predefinedActions?.find(
      (model: any) => model?.id === predefinedId
    );

    const rolesIds = filteredPredefinedAction?.roles?.map(
      (item: any) => item.id
    );

    const filteredRoles = roless?.filter((item: any) =>
      rolesIds?.includes(item?.id)
    );

    setActionRoles(filteredRoles);
  };

  const set_actionAuto = () => {
    setActionAuto(!actionIsAuto);
  };
  const set_actionDisable = () => {
    setActionDisable(!actionIsDisable);
  };

  const set_confirmLoading = (loading: boolean) => {
    setConfirmLoading(loading);
  };

  const getReasonsCodes = async () => {
    setActionsReasons(
      actionsReasons?.sort((a: any, b: any) => a.name.localeCompare(b.name))
    );
    if (workModeAction === WORK_MODE.CREATE) {
      setSelectedReasons(
        actionsReasons
          ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
          ?.map((reason: any) => reason.id)
      );
    }
  };

  /*   Execution modal treatement  */
  const changeWorkMode = (workMode: string) => {
    setWorkModeExecution(workMode);
  };

  const showExecutionModal = () => {
    setOpenExecutionModal(true);
  };

  const showRuleModal = () => {
    setOpenRuleModal(true);
  };

  const closeExecutionModal = () => {
    updateURL(location.pathname, navigate, "execution");
    setOpenExecutionModal(false);
  };

  const closeRuleModal = () => {
    setIsAutoModalDirty(false);
    setOpenRuleModal(false);
  };

  const setRecord = (record: any) => {
    setExecutionRecord(record);
  };

  const handleCreateUpdateAction = async () => {
    set_confirmLoading(true);

    form_action
      .validateFields()
      .then(async (values) => {
        const action = form_action.getFieldsValue();
        let action_ = JSON.parse(JSON.stringify(action));
        action_["model"] = { id: action_.model };
        action_["enabled"] = actionIsDisable;
        action_["auto"] = actionIsAuto;
        action_["priority"] = action_["key"];
        action_["executions"] = actionExecutions;
        for (const [index, exec] of action_.executions.entries()) {
          exec["key"] = index + 1;
          exec["priority"] = exec.key;
        }

        action_["roles"] = action_.roles?.map((role: any) => ({ id: role }));
        action_["reasons"] = selectedReasons?.map((reason: any) => ({
          id: reason,
        }));
        if (workModeAction === WORK_MODE.CREATE) {
          action_["key"] = riskActions?.length + 1;
          action_["priority"] = action_["key"];
          action_["autoPolicy"] = {
            ...action_["autoPolicy"],
            automation: actionRules,
          };
          const argsActionSettingContent = constructActionParametersObject(
            dynamicFieldsSettings
          );

          action_["actionSettings"] = argsActionSettingContent;
          setRiskActions([...riskActions, action_]);
          setActionRecord(action_);
          closeActionModal();
        } else if (workModeAction === WORK_MODE.UPDATE) {
          action_["priority"] = actionRecord["priority"];
          action_["id"] = actionRecord["id"];
          action_["autoPolicy"] = {
            ...action_["autoPolicy"],
            automation: actionRules,
          };

          if (updateSource === PAGES.RISKS) {
            const argsActionSettingContent = constructActionParametersObject(
              dynamicFieldsSettings
            );

            action_["actionSettings"] = argsActionSettingContent;

            setRiskActions(
              riskActions.map((existAction: any) => {
                if (existAction?.id === actionRecord?.id) {
                  return { ...existAction, ...action_ };
                }
                return existAction;
              })
            );
            setActionRecord(action_);
            closeActionModal();
          } else if (updateSource === PAGES.ACTIONS) {
            for (const [index, exec] of action_.executions.entries()) {
              exec["priority"] = index + 1;
            }
            delete action_["model"];
            const argsActionSettingContent = constructActionParametersObject(
              dynamicFieldsSettings
            );

            let args = action_;
            args["actionSettings"] = argsActionSettingContent;
            setActionRecord(args);

            updateAction(args);
          }
        }
      })
      .catch((err: any) => {
        set_confirmLoading(false);
        if (err?.code === "ERR_NETWORK") {
          message.error(t("errors:NETWORK_ERROR"));
        } else {
          message.error(t("errors:CREATE_FAILED"));
        }
      });
  };

  return (
    <>
      <Form
        form={form_action}
        name="wrap"
        {...{ labelCol: { style: { width: "auto" } } }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        initialValues={
          workModeAction === WORK_MODE.CREATE
            ? {
                reasons: actionsReasons?.map((reason: any) => reason?.id),
              }
            : {
                name: actionRecord?.name,
                model: actionRecord?.model?.id,
                roles: actionRecord?.roles?.map((role: any) => role?.id),
                reasons: actionsReasons?.map((reason: any) => reason?.id),
              }
        }
      >
        <div
          style={{
            marginTop: 10,
            display: "flex",
            marginBottom: 5,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginTop: 10, fontSize: "1.4rem" }}>
            {t("DETAILS", { item: t("ACTION") })}
          </div>
          <div style={{ marginRight: "50px", marginTop: 1 }}>
            {" "}
            {workModeAction === WORK_MODE.VIEW && (
              <Button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                }}
                onClick={() => handleWorkModeChange()}
                icon={<EditOutlined size={6} style={{ color: "#004C97" }} />}
              ></Button>
            )}
          </div>
        </div>

        <div style={{ marginInline: "50px" }}>
          <Row>
            {/* Action Name */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={t("NAME", { item: t("") })}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("errors:REQUIRED", { label: t("FIELD") }),
                  },
                ]}
              >
                <Input readOnly={workModeAction === WORK_MODE.VIEW} />
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row>
            {/* Predefined Actions */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label={t("ACTIONS_PREDEFINED")}
                name="model"
                rules={[
                  {
                    required: true,
                    message: t("errors:REQUIRED", {
                      label: t("ACTIONS_PREDEFINED"),
                    }),
                  },
                ]}
              >
                <Select
                  disabled={
                    workModeAction !== WORK_MODE.CREATE ||
                    actionRules.length > 0
                  }
                  showSearch
                  onChange={(value) => {
                    setIsPredefined(true);
                    setActionID(value);
                    filterReasons(value);
                    filterRoles(value);
                    form_action.setFieldValue("roles", null);
                  }}
                  filterOption={(input, option) =>
                    option?.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {predefinedFilteredActions?.map((item: any) => (
                    <Option key={item?.id} value={item?.id}>
                      {getTranslation(item?.name, "data")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row>
            {/* Roles */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={t("ROLES")} name="roles">
                <Select
                  disabled={workModeAction === WORK_MODE.VIEW}
                  showSearch
                  mode="multiple"
                  filterOption={(input, option) =>
                    option?.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {actionRoles?.map((item: any) => (
                    <Option key={item?.id} value={item?.id}>
                      {getTranslation(item?.name, "data")}
                    </Option>
                  ))}
                </Select>
              </Form.Item>{" "}
            </Col>
          </Row>
          <Row>
            {/* Reasons */}
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={t("REASONS")} name="reasons">
                <Select
                  disabled={workModeAction === WORK_MODE.VIEW}
                  showSearch
                  mode="multiple"
                  filterOption={(input, option) =>
                    option?.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option?.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onDeselect={(value) => {
                    const deselectedItem: any = actionsReasons.find(
                      (obj: any) => obj.id === value
                    );
                    const reasonIsUsed = actionRules
                      .map((rule: any) => rule.reason)
                      ?.includes(value);
                    if (deselectedItem?.is_system || reasonIsUsed) {
                      if (reasonIsUsed) setOpenUsedReasonPopup(value);
                      setSelectedReasons(selectedReasons.concat());
                    } else {
                      setSelectedReasons(
                        selectedReasons.filter((item) => item !== value)
                      );
                    }
                  }}
                  onSelect={(value) => {
                    const selected: any = selectedReasons.find(
                      (obj) => obj === value
                    );
                    if (!selected) {
                      setSelectedReasons(selectedReasons.concat(value));
                    }
                  }}
                  tagRender={(props: any) => {
                    const { label, value, closable, onClose } = props;
                    const is_sys = actionsReasons.find(
                      (obj: any) => obj.id === value
                    )?.is_system;
                    return (
                      <Popover
                        open={
                          actionRules
                            .map((rule: any) => rule.reason)
                            ?.includes(value) && openUsedReasonPopup === value
                        }
                        onOpenChange={() => {
                          setOpenUsedReasonPopup("");
                        }}
                        content={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row-reverse",
                            }}
                          >
                            <a onClick={() => setOpenUsedReasonPopup("")}>
                              Close
                            </a>
                          </div>
                        }
                        title={
                          <span>
                            <ExclamationCircleOutlined
                              style={{ color: "orange", marginRight: "8px" }}
                            />
                            {t("errors:POPOVER_USED_IN_AUTO_RULES")}{" "}
                          </span>
                        }
                        trigger="click"
                      >
                        <Tag
                          color={is_sys ? "red" : "blue"}
                          closable={
                            !is_sys && workModeAction !== WORK_MODE.VIEW
                          }
                          onClose={onClose}
                        >
                          {label}
                          {is_sys ? (
                            <LockOutlined style={{ marginInline: "4px" }} />
                          ) : (
                            ""
                          )}
                        </Tag>
                      </Popover>
                    );
                  }}
                >
                  {actionsReasons
                    ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                    ?.map((item: any) => (
                      <Option
                        key={item?.id}
                        value={item?.id}
                        is_system={item?.is_system}
                      >
                        {getTranslation(item?.name, "data")}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{ marginBlockEnd: "20px" }}
        >
          <MyCollaps
            title={t("EXECUTIONS")}
            childs={
              <div>
                {/* Nest Form.List */}
                <div
                  style={{
                    overflow: "auto",
                    maxHeight: "130px",
                  }}
                >
                  <DndCxt
                    columns={columns}
                    dataSource={actionExecutions}
                    setDataSource={setActionExecutions}
                  />
                </div>
                <Row>
                  {/* Action Name */}
                  {workModeAction !== WORK_MODE.VIEW && (
                    <Col xs={4} sm={4} md={4} lg={4} xl={4} offset={20}>
                      <Tooltip
                        placement="left"
                        title={
                          actionID === ""
                            ? t("errors:SELECT_PREDEFINED_ACTION_FIRST")
                            : ""
                        }
                      >
                        <Button
                          className="button-primary"
                          type="primary"
                          onClick={() => {
                            changeWorkMode(WORK_MODE.CREATE);
                            showExecutionModal();
                          }}
                          disabled={actionID === ""}
                        >
                          {t("ADD_BUTTON_TEXT")}
                          {t("EXECUTION")}
                        </Button>
                      </Tooltip>
                    </Col>
                  )}
                </Row>
              </div>
            }
          ></MyCollaps>
        </Col>

        <Row style={{ paddingInline: "50px" }}>
          {/* Action Name */}
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item id="disable">
              <Tooltip
                placement="left"
                title={
                  !isPredefined
                    ? t("errors:SELECT_PREDEFINED_ACTION_FIRST")
                    : ""
                }
              >
                {" "}
                <Checkbox
                  disabled={workModeAction === WORK_MODE.VIEW || !isPredefined}
                  checked={actionIsDisable}
                  onChange={() => {
                    set_actionDisable();
                  }}
                >
                  {t("ENABLE")}
                </Checkbox>
              </Tooltip>
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item id="auto">
              <Tooltip
                placement="right"
                title={t("errors:ALLOWS_AUTO_RESPONSE")}
              >
                {showAuto && (
                  <Checkbox
                    disabled={
                      workModeAction === WORK_MODE.VIEW || !isPredefined
                    }
                    checked={actionIsAuto}
                    onChange={() => {
                      set_actionAuto();
                    }}
                  >
                    {t("AUTO")}
                  </Checkbox>
                )}
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>

        {actionIsAuto && showAuto && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ marginBlockEnd: "20px" }}
          >
            <MyCollaps
              title={t("AUTOMATION")}
              childs={
                <div>
                  <div
                    style={{
                      overflow: "auto",
                      maxHeight: "130px",
                    }}
                  >
                    <DndCxt
                      columns={AutomationRulesColumns}
                      dataSource={actionRules}
                      setDataSource={setActionRules}
                    />
                  </div>
                  <Row>
                    {/* Action Name */}
                    {workModeAction !== WORK_MODE.VIEW && (
                      <Col xs={4} sm={4} md={4} lg={4} xl={4} offset={20}>
                        <Button
                          className="button-primary"
                          type="primary"
                          onClick={() => {
                            setWorkModeAutomation(WORK_MODE.CREATE);
                            showRuleModal();
                          }}
                          disabled={actionID === ""}
                        >
                          {t("ADD_BUTTON_TEXT")}
                          {t("RULE")}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              }
            ></MyCollaps>
          </Col>
        )}
        <Row>
          {" "}
          <Col xs={4} sm={4} md={4} lg={4} xl={4} offset={20}></Col>
        </Row>
        {/** dynamic fields */}
        <ActionParametersSettings
          form={form_action}
          workMode={workModeAction}
        />

        {workModeAction !== WORK_MODE.VIEW &&
          isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) && (
            <Row>
              <Form.Item>
                <SaveCancel
                  confirmLoading={confirmLoading}
                  onClickSave={() => {
                    handleCreateUpdateAction();
                  }}
                  onClickCancel={() => {
                    closeActionModal();
                  }}
                />
              </Form.Item>
            </Row>
          )}
      </Form>

      {/* ADD Execution Modal */}
      <Modal
        destroyOnClose={true}
        title={t("EXECUTION_CONFIGURATION")}
        open={openExecutionModal}
        onCancel={closeExecutionModal}
        width={1000}
        footer={null}
        style={{ minWidth: 1000 }}
      >
        <AddExecutionModal
          closeExecutionModal={closeExecutionModal}
          actionExecutions={actionExecutions}
          setActionExecutions={setActionExecutions}
          workModeExecution={workModeExecution}
          executionRecord={executionRecord}
          setWorkModeExecution={setWorkModeExecution}
          updateSource={PAGES.ACTIONS}
          predefinedActionId={actionID}
        />
      </Modal>
      {/* ADD Rule Modal */}
      <Modal
        destroyOnClose={true}
        title={t("AUTOMATION_RULE")}
        open={openRuleModal}
        maskClosable
        onCancel={() => {
          if (isAutoModalDirty) {
            Modal.confirm({
              title: "Confirm",
              content: t("errors:UNSAVED_CHANGES_LEAVING_MESSAGE"),
              onOk: () => {
                closeRuleModal();
              },
              onCancel: () => {},
              okText: t("YES"),
              cancelText: t("NO"),
            });
          } else {
            closeRuleModal();
          }
        }}
        width={1000}
        footer={null}
      >
        <ActionAddAutomationModal
          closeAutomationModal={closeRuleModal}
          actionAutomations={actionRules}
          setActionAutomations={setActionRules}
          workModeAutomation={workModeAutomation}
          setIsAutoModalDirty={setIsAutoModalDirty}
          isAutoModalDirty={isAutoModalDirty}
          automationRecord={ruleRecord}
          setWorkModeExecution={setWorkModeExecution}
          updateSource={PAGES.ACTIONS}
          actionsReasons={actionsReasons.filter((r) =>
            selectedReasons?.includes(r?.id.toString())
          )}
          dataNeeded={
            extractDataNeededFromURL(
              workModeAction === WORK_MODE.CREATE
                ? predefinedFilteredActions?.find(
                    (model: any) => model.id === actionID
                  )?.dataNeeded || {}
                : actionRecord?.model?.dataNeeded || {},
              ["CATEGORY"]
            ).OUTPUT
          }
          automationtype={AUTOMATION_TYPES.ACTION}
        />
      </Modal>
    </>
  );
}
export default AddActionModal;
