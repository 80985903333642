import { Pie, PieConfig } from "@ant-design/plots";
import onChartClickEvent from "./functions/onChartClickEvent";
import { CHART_COLOR_TYPES } from "../chart-content/components/new-chart/NewChartModal";
import { formatData } from "./functions/formatData";
import { ChartParams } from "./functions/ChartParams.interface";
import { getTranslation } from "../../../../../../utils/transaltion";

interface MyPieProps {
  config: PieConfig | any;
  fields: any;
  params: ChartParams;
  data: any[];
  showDrawer?: (v: any) => void;
}

const data_ = [
  {
    categoryField: "Action 1",
    valueField: 27,
  },
  {
    categoryField: "Action 2",
    valueField: 25,
  },
  {
    categoryField: "Action 3",
    valueField: 18,
  },
  {
    categoryField: "Action 4",
    valueField: 15,
  },
  {
    categoryField: "Action 5",
    valueField: 10,
  },
  {
    categoryField: "Action 6",
    valueField: 5,
  },
];

const MyPie: React.FC<MyPieProps> = ({
  config,
  fields,
  params,
  data,
  showDrawer,
}) => {
  const my_config: PieConfig = {
    angleField: "valueField",
    colorField: "categoryField",
    appendPadding: 10,
    radius: 0.75,
    meta: {
      valueField: {
        formatter: (v) => formatData(v, fields?.valueField, params).data,
      },
    },
    label: {
      type: "spider",
      labelHeight: 28,
      content: ({ valueField }) => `${valueField}\n ${config?.unit || ""}`,
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    tooltip: {
      shared: true,
      showMarkers: false,
      formatter: ({ categoryField, valueField }: any) => ({
        name: getTranslation(categoryField, "data"),
        value: valueField
          ? `${
              formatData(valueField, fields?.valueField, {
                unit: config?.unit,
                ...params,
              }).data
            }`
          : "Not set",
      }),
    },

    legend: {
      position: "top",
      itemName: {
        formatter: (datum: any) => {
          return ` ${getTranslation(datum, "labels")}`;
        },
      },
    },

    style: {
      height: 250,
    },

    ...(showDrawer
      ? {
          onEvent: (chart, event) =>
            onChartClickEvent(chart, event, showDrawer),
        }
      : {}),

    color:
      config?.pallet_type === CHART_COLOR_TYPES.PALLET
        ? config?.color_pallet?.map((color: any) => color?.color)
        : ({ categoryField }) =>
            config?.color_pallet?.find(
              (color: any) => color?.value === categoryField
            )?.color || [],
    data,
  };
  return <Pie {...my_config} />;
};

export default MyPie;
