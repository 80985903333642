import React, { useEffect, useState } from "react";
import {
  chatWithGPT,
  createThread,
  retrieveAssistant,
} from "../../../services/open-ai.service";
import { Button, Card, Col, Input, Row, Spin } from "antd";
import {
  BulbOutlined,
  EyeOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  RocketOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import "./chat.css";
import { FiArrowUpRight } from "react-icons/fi";
import SetupInstructions from "./Content";
import OpenAI from "openai";
import { getTranslation } from "../../../utils/transaltion";

// Define the type for a chat message
interface ChatMessage {
  role: "user" | "bot";
  content: any;
  assistant: AssistantType;
}

const cardData = [
  {
    title: getTranslation("PROCUREMENT_RECOMMENDATION_REJECTED", "labels"),
    icon: <FileTextOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "MONITOR_ACTIONS",
  },
  {
    title: getTranslation("FULFILLMENT_ACTION_RATIONALE", "labels"),
    icon: <SmileOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "MONITOR_ACTIONS",
  },
  {
    title: getTranslation("INVENTORY_RECOMMENDATION_CONTEXT", "labels"),
    icon: <BulbOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "MONITOR_ACTIONS",
  },
  {
    title: getTranslation(
      "STOCK_DEPLOYMENT_RECOMMENDATION_REJECTION",
      "labels"
    ),
    icon: <RocketOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "MONITOR_ACTIONS",
  },
  {
    title: getTranslation("MY_EXOBRAIN_SUPPORT", "labels"),
    icon: <FileTextOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "HELP",
  },
  {
    title: getTranslation("SPECIFY_AREA_HELP", "labels"),
    icon: <SmileOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "HELP",
  },
  {
    title: getTranslation("SUMMARY_SETUP_INSTRUCTIONS", "labels"),
    icon: <BulbOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "HELP",
  },
  {
    title: getTranslation("SPECIFIC_DATA_REQUIREMENT", "labels"),
    icon: <RocketOutlined style={{ fontSize: "20px", color: "#0056A6" }} />,
    assistant: "HELP",
  },
];

type AssistantType = "MONITOR_ACTIONS" | "HELP";

const GPTChatComponent: React.FC = () => {
  const [input, setInput] = useState<string>("");
  const [chatLog, setChatLog] = useState<ChatMessage[]>([]);
  const [chatLoading, setChatLoading] = useState<boolean>(false);
  const [selectedAssistant, setSelectedAssistant] =
    useState<AssistantType>("MONITOR_ACTIONS");

  const [helpAssistant, setHelpAssistant] =
    useState<OpenAI.Beta.Assistants.Assistant>();
  const [helpThread, setHelpThread] = useState<OpenAI.Beta.Threads.Thread>();
  const [monitorAssistant, setMonitorAssistant] =
    useState<OpenAI.Beta.Assistants.Assistant>();
  const [monitorThread, setMonitorThread] =
    useState<OpenAI.Beta.Threads.Thread>();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  useEffect(() => {
    initAssistant();
  }, [selectedAssistant]);

  const initAssistant = async () => {
    if (selectedAssistant === "MONITOR_ACTIONS") {
      if (!monitorThread) {
        const thread = await createThread();
        setMonitorThread(thread);
      }
      if (!monitorAssistant) {
        const assistant = await retrieveAssistant(selectedAssistant);
        setMonitorAssistant(assistant);
      }
    } else {
      if (!helpThread) {
        const thread = await createThread();
        setHelpThread(thread);
      }
      if (!helpAssistant) {
        const assistant = await retrieveAssistant(selectedAssistant);
        setHelpAssistant(assistant);
      }
    }
  };

  const handleSendMessage = async (data?: string) => {
    let content = "";
    setChatLoading(true);
    if (data) {
      if (!data.trim()) return;
      content = data;
    } else {
      if (!input.trim()) return; // Prevent sending empty messages
      content = input;
    }
    console.log(content);
    const userMessage: ChatMessage = {
      role: "user",
      content: content,
      assistant: selectedAssistant,
    };
    setChatLog([...chatLog, userMessage]);
    setInput("");

    await initAssistant();
    const response =
      selectedAssistant === "MONITOR_ACTIONS"
        ? await chatWithGPT(content, monitorAssistant!, monitorThread!)
        : await chatWithGPT(content, helpAssistant!, helpThread!);
    const botMessage: ChatMessage = {
      role: "bot",
      content: response,
      assistant: selectedAssistant,
    };
    setChatLog([...chatLog, userMessage, botMessage]);
    setChatLoading(false);
  };

  return (
    <div>
      <Row gutter={[16, 16]} justify="center">
        <Col>
          <div className="custom-button-container">
            {/* First Button */}
            <Button
              icon={<EyeOutlined />}
              className={
                selectedAssistant === "MONITOR_ACTIONS"
                  ? "custom-button monitor-actions-btn"
                  : "custom-button help-btn"
              }
              onClick={() => {
                setSelectedAssistant("MONITOR_ACTIONS");
              }}
            >
              {getTranslation("MONITOR_ACTIONS", "labels")}
            </Button>

            {/* Second Button */}
            <Button
              icon={<InfoCircleOutlined />}
              className={
                selectedAssistant === "HELP"
                  ? "custom-button monitor-actions-btn"
                  : "custom-button help-btn"
              }
              onClick={() => {
                setSelectedAssistant("HELP");
              }}
            >
              {getTranslation("NEED_MORE_HELP", "labels")}{" "}
            </Button>
          </div>
        </Col>
      </Row>

      <br></br>
      <Row gutter={[16, 16]} style={{ padding: "20px" }}>
        {cardData
          .filter((data) => data.assistant === selectedAssistant)
          .map((card, index) => (
            <Col key={index} xs={24} sm={12} md={6} className="card-container">
              <Card
                className="card"
                hoverable
                onClick={() => {
                  handleSendMessage(card?.title);
                }}
              >
                <div className="card-title">{card.title}</div>
                {/* <div className="card-icon">{card.icon}</div> */}
              </Card>
            </Col>
          ))}
      </Row>
      <br></br>
      {chatLog
        ?.filter((data) => data.assistant === selectedAssistant)
        ?.map((msg, index) => (
          <Row key={index} className={msg.role}>
            <SetupInstructions content={msg.content} role={msg.role} />
          </Row>
        ))}
      {chatLoading && (
        <Row justify="center">
          <Spin
            style={{
              marginBlock: "20px",
            }}
            indicator={<LoadingOutlined spin />}
            size="large"
            spinning={chatLoading}
            tip={"Processing..."}
          >
            <br></br>
            <span
              style={{
                padding: 50,
                marginBlock: "20px",
              }}
            >
              {""}
            </span>
            <br></br>
            <br></br>
            <br></br>
          </Spin>
        </Row>
      )}

      <br></br>
      <Row>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              backgroundColor: "#eaeaea",
              borderRadius: "8px",
              marginInline: "15px",
            }}
          >
            <Input
              placeholder={getTranslation("ENTER_PROMPT", "labels")}
              style={{
                border: "none",
                boxShadow: "none",
                backgroundColor: "transparent",
                width: "100%",
                marginRight: "10px",
              }}
              value={input}
              disabled={chatLoading}
              onChange={handleInputChange}
              onPressEnter={() => handleSendMessage()}
            />
            <FiArrowUpRight
              style={{
                fontSize: "24px",
                color: "#999",
                backgroundColor: "#fff",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={() => !chatLoading && handleSendMessage()}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GPTChatComponent;
