import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Card, Modal, message } from "antd";
import CustomIcon from "./CustomIcon";
import { WORK_MODE } from "../../../../constants/workModes";
import { useTranslation } from "react-i18next";
import {
  SharedContextFunctionsInterface,
  useSharedContextFunctions,
} from "../logic/codificationLogic";

interface IconSelectProps {
  setIconName: Dispatch<SetStateAction<string | null>>;
  workMode: string;
  iconName?: string | null;
  style?: any;
  size?: string;
}

const IconSelect: React.FC<IconSelectProps> = ({
  setIconName,
  workMode,
  iconName,
  style,
  size,
}) => {
  const { t } = useTranslation();
  const { isIconAlreadyUsed: isIconAlreadyUsed } =
    useSharedContextFunctions() as SharedContextFunctionsInterface;
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selectedIcon, setSelectedIcon] = useState<string | null>(null);
  const files = require.context(
    `../../../../assets/images/svg/`,
    false,
    /\.svg$/
  );
  const iconNames = files
    .keys()
    .map((key) => key.replace(/^.*[\\/]/, "").replace(/\.\w+$/, ""));

  useEffect(() => {
    console.log(iconName);
    if (workMode === WORK_MODE.UPDATE) {
      console.log(iconName);
      setIconName(iconName || "");
      setSelectedIcon(iconName || null);
    }
  }, [workMode, iconName]);

  const handleIconChange = (iconName: string) => {
    const isUnusedIcon = isIconAlreadyUsed(iconName);
    if (isUnusedIcon) {
      setSelectedIcon(iconName);
      setIconName(iconName);
      setModalVisible(false);
    } else {
      message.error(t("errors:USED_ICON"));
    }
  };

  const renderButtonContent = () => {
    if (selectedIcon) {
      return (
        <div
          style={{
            marginTop: -2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...style,
          }}
        >
          <CustomIcon name={selectedIcon} size={size} />
        </div>
      );
    } else {
      return t("CHOOSE_ICON");
    }
  };

  const iconCards = Array.from(
    Array(Math.ceil(iconNames.length / 4)),
    (_, i) => (
      <Card key={i} style={{ marginBottom: 16 }} bodyStyle={{ padding: 0 }}>
        {iconNames.slice(i * 4, i * 4 + 4).map((iconName, index) => (
          <div
            key={"icon" + i + "-" + index}
            style={{ display: "inline-block", width: "25%", padding: 8 }}
          >
            <Button type="link" onClick={() => handleIconChange(iconName)}>
              <CustomIcon name={iconName} size={size} />
            </Button>
          </div>
        ))}
      </Card>
    )
  );

  return (
    <>
      <Button
        onClick={() => setModalVisible(true)}
        type="dashed"
        style={{
          color: "#aba7a7",
          border: "1px solid #aba7a7",
        }}
      >
        {renderButtonContent()}
      </Button>
      <Modal
        title={null}
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Card style={{ height: 200, overflow: "auto" }}> {iconCards}</Card>
      </Modal>
    </>
  );
};

export default IconSelect;
