import React, { useContext } from "react";
import { Collapse, Typography } from "antd";
import SettingsDynamicFields from "../dynamic-fields/SettingDynamicFields";
import { PREDEFINED_TYPES } from "../../../../../constants/predefinedTypes";
import { useTranslation } from "react-i18next";
import { SharedContext } from "../../context/sharedContext";

const OrganizationActionSettings: React.FC<{}> = () => {
  const { t } = useTranslation();
  const { dynamicFieldsSettings } = useContext(SharedContext);
  return (
    <div style={{ marginTop: 40, width: "100%", marginBottom: "20px" }}>
      <Collapse
        className="panel-style"
        accordion
        expandIconPosition="end"
        size="large"
        destroyInactivePanel={true}
        defaultActiveKey="settings"
      >
        <Collapse.Panel
          className="panel-style"
          header={
            <span>
              {" "}
              {t(`labels:SETTINGS`, {
                item: t(""),
              })}{" "}
            </span>
          }
          key="settings"
        >
          <Typography className="typography-setting">
            {" "}
            {t(`labels:SETTINGS`, {
              item: t("labels:ACTION"),
            })}{" "}
          </Typography>
          {dynamicFieldsSettings.length > 0 && (
            <SettingsDynamicFields
              typeComponent={PREDEFINED_TYPES.ORGANIZATION}
            />
          )}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default OrganizationActionSettings;
