import moment from "moment";
import { DATA_NEEDED_TYPE } from "../../../../../../../constants/DataNeededTypes";
import { ChartParams } from "./ChartParams.interface";

const options = (currency?: string): Intl.NumberFormatOptions => {
  return {
    style: currency ? "currency" : "decimal",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
};

interface ConversionResult {
  data: any;
  errors: string[];
}

export const formatData = (
  data: string | number,
  field: any,
  params: ChartParams
): ConversionResult => {
  const { dateFormat, numberFormat, currency, language, unit } = params;

  if (!field) {
    return {
      data: data,
      errors: ["Field details not found"],
    };
  }

  var formattedData;
  if (field?.type === DATA_NEEDED_TYPE.STRING) {
    formattedData = data;
  } else if (
    field?.type === DATA_NEEDED_TYPE.NUMBER &&
    typeof data == "number"
  ) {
    formattedData = `${Intl.NumberFormat(numberFormat, options()).format(
      data
    )} ${unit || ""}`;
  } else if (field?.type === DATA_NEEDED_TYPE.DATE) {
    formattedData = moment(data)?.format(dateFormat || "DD/MM/YYYY");
  } else if (
    field?.type === DATA_NEEDED_TYPE.AMOUNT &&
    typeof data == "number"
  ) {
    formattedData = `${Intl.NumberFormat(
      numberFormat,
      options(currency)
    ).format(data)}`;
  }

  return {
    data: formattedData,
    errors: [],
  };
};

export const castToOriginType = (data: any, field: any): ConversionResult => {
  const errors: string[] = [];

  switch (field?.type) {
    case DATA_NEEDED_TYPE.STRING:
      if (typeof data === "string") {
        return { data, errors };
      } else {
        errors.push("Invalid data type for STRING");
        return { data: null, errors };
      }

    case DATA_NEEDED_TYPE.BOOLEAN:
      if (typeof data === "boolean") {
        return { data, errors };
      } else if (typeof data === "string") {
        return { data: data.toLowerCase() === "true", errors };
      } else if (typeof data === "number") {
        return { data: data !== 0, errors };
      } else {
        errors.push("Invalid data type for BOOLEAN");
        return { data: null, errors };
      }

    case DATA_NEEDED_TYPE.DATE:
      if (data instanceof Date) {
        return { data, errors };
      } else if (typeof data === "string" || typeof data === "number") {
        const date = new Date(data);
        if (!isNaN(date.getTime())) {
          return { data: date, errors };
        } else {
          errors.push("Invalid data type for DATE");
          return { data: null, errors };
        }
      } else {
        errors.push("Invalid data type for DATE");
        return { data: null, errors };
      }

    case DATA_NEEDED_TYPE.NUMBER:
    case DATA_NEEDED_TYPE.AMOUNT:
      if (typeof data === "number") {
        return { data, errors };
      } else if (typeof data === "string" && !isNaN(Number(data))) {
        return { data: Number(data), errors };
      } else {
        errors.push("Invalid data type for NUMBER/AMOUNT");
        return { data: null, errors };
      }

    default:
      errors.push("Unsupported data type");
      return { data: null, errors };
  }
};
