import React, { useContext, useEffect, useState } from "react";
import {
  Form,
  Select,
  Collapse,
  FormInstance,
  Pagination,
  Input,
  Space,
  Empty,
  Checkbox,
  Spin,
} from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";

import { WORK_MODE } from "../../../../../constants/workModes";
import { MainContext } from "../../../../../utils/context";
import { SharedContext } from "../../context/sharedContext";
import { RootState } from "../../../../../redux/store/store";
import { selectFilterOption } from "../../../../../utils/functions";
import { getTranslation } from "../../../../../utils/transaltion";
import { Scope } from "../../../../../interfaces/scope";
import ScopesService from "../../../../../services/scopes.service";
import { UUID } from "crypto";
import ScopeTag from "./scope/ScopeTag";
import { isPermission } from "../../../../../utils/permissionsFunctions";
import { PERMISSIONS_LIST } from "../../../../../constants/permissions";
import { Role } from "../../../../../interfaces/role";
import { ROLES_LIST } from "../../../../../constants/roles";

const { Option } = Select;
const { Panel } = Collapse;
const PAGE_SIZE = 20;

interface UserAccountDetailsProps {
  workModeUser: string;
  record: any;
  accountDetailsForm: FormInstance<any>;
}

const UserAccountDetails: React.FC<UserAccountDetailsProps> = ({
  workModeUser,
  record,
  accountDetailsForm,
}) => {
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  const { t } = useTranslation();
  /** global app context */
  const globalContext = useContext(MainContext);
  /**confs shared context */
  const { setWorkModeScope, setOpenScopeModal, userScopes, setUserScopes } =
    useContext(SharedContext);
  /** roles from redux */
  const rolesRedux = useSelector((state: RootState) => state.roleReducer);
  /** scopes */
  const [scopesPage, setScopesPage] = useState<number>(1);
  const [pageScopes, setPageScopes] = useState<Scope[]>([]);
  const [filtredUserScopes, setFiltredUserScopes] = useState<Scope[]>([]);
  const [searchScopes, setSearchScopes] = useState<{
    key: string;
    value: string;
    all: boolean;
  }>({ key: "", value: "", all: false });
  const [getUserScopesLoading, setGetUserScopesLoading] = useState(false);
  const [scopeAllSearch, setScopeAllSearch] = useState(false);
  /** useeffect */
  useEffect(() => {
    setUserScopes([]);
    if (workModeUser === WORK_MODE.VIEWPROFILE) {
      setUserScopes(globalContext?.context?.user?.scopes);
    } else if (
      workModeUser === WORK_MODE.UPDATE ||
      workModeUser === WORK_MODE.VIEW
    ) {
      getUserScopes(record?.id);
    }
  }, []);

  /* handle search changes */
  useEffect(() => {
    setPageScopes(
      displayPerPage(userScopes, PAGE_SIZE, scopesPage, searchScopes)
    );
  }, [scopesPage, userScopes, searchScopes]);

  if (!globalContext) {
    return <></>;
  }
  const scopesService = new ScopesService(
    globalContext.context,
    globalContext.setContext,
    ""
  );
  const getUserScopes = async (userId: UUID) => {
    setUserScopes([]);
    setGetUserScopesLoading(true);
    await scopesService
      .getUserScopes(userId)
      .then(async (response) => {
        console.log(response);
        setUserScopes(response?.items);
        setGetUserScopesLoading(false);
      })
      .catch((err) => {
        setGetUserScopesLoading(false);
      });
  };

  /* Scope Pagination */
  function displayPerPage(
    userScopes: Scope[],
    pageSize: number,
    pageNumber: number,
    searchScopes: any
  ) {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const filtredList = userScopes?.filter(
      (scope) =>
        (searchScopes?.key?.trim() === "" ||
          scope?.key
            ?.toLowerCase()
            .includes(searchScopes?.key?.toLowerCase())) &&
        (searchScopes?.all
          ? scope.all
          : searchScopes?.value?.trim() === "" ||
            scope?.value
              ?.toLowerCase()
              .includes(searchScopes?.value?.toLowerCase()))
    );
    setFiltredUserScopes(filtredList);
    return filtredList?.slice(startIndex, endIndex);
  }

  return (
    <div
      style={{
        top: 30,
        width: "100%",
        marginBottom: "20px",
      }}
    >
      <Collapse
        className="panel-style"
        accordion
        expandIconPosition="end"
        size="large"
        activeKey={"menu_account_details"}
      >
        <Panel
          className="panel-style"
          header={<span>{t("USER_ACCOUNT_DETAILS")}</span>}
          key="menu_account_details"
        >
          <Form
            form={accountDetailsForm}
            initialValues={
              workModeUser === WORK_MODE.CREATE
                ? {}
                : workModeUser === WORK_MODE.UPDATE ||
                  workModeUser === WORK_MODE.VIEW
                ? {
                    role: record?.roles?.map((role: any) => role.id),
                  }
                : workModeUser === WORK_MODE.VIEWPROFILE
                ? {
                    role: globalContext?.context?.user?.roles?.map(
                      (role: any) => role.id
                    ),
                  }
                : {}
            }
          >
            <Form.Item
              label={t("ROLES")}
              name="role"
              rules={[
                {
                  required: true,
                  message: t(`errors:REQUIRED`, {
                    label: t(`labels:ROLES`),
                  }),
                },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                disabled={
                  workModeUser === WORK_MODE.VIEW ||
                  workModeUser === WORK_MODE.VIEWPROFILE
                }
                filterOption={(input, option) =>
                  selectFilterOption(input, option)
                }
              >
                {rolesRedux
                  ?.filter((role: Role) =>
                    isPermission(
                      permissionsList,
                      PERMISSIONS_LIST.EXOBRAIN_ADMIN
                    )
                      ? true
                      : role.name !== ROLES_LIST.EXOBRAIN_ADMIN
                  )
                  .map((role: any, index: any) => (
                    <Option key={index} value={role.id}>
                      {getTranslation(role.name, "data")}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            {/*scope*/}
            <Form.Item name="user_scopes">
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  marginBottom: 5,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ marginTop: 10 }}>
                  {getTranslation("SCOPES", "labels")}
                </div>
                <div>
                  <Space.Compact style={{ border: 0, borderBlockEnd: "2px" }}>
                    <SearchOutlined style={{ color: "#0082ba" }} />
                    <Input
                      style={{ border: 0 }}
                      placeholder={getTranslation("SEARCH_ON_KEYS", "labels")}
                      allowClear
                      onChange={(e) => {
                        setScopesPage(1);
                        setSearchScopes({
                          value: searchScopes.value,
                          key: e.target.value,
                          all: scopeAllSearch,
                        });
                      }}
                    />
                    <Input
                      style={{ border: 0 }}
                      placeholder={getTranslation("SEARCH_ON_VALUES", "labels")}
                      allowClear
                      onChange={(e) => {
                        setScopesPage(1);
                        setSearchScopes({
                          value: e.target.value,
                          key: searchScopes.key,
                          all: scopeAllSearch,
                        });
                      }}
                      suffix={
                        <Checkbox
                          value={scopeAllSearch}
                          onChange={() => {
                            setScopesPage(1);
                            setSearchScopes({
                              value: searchScopes.value,
                              key: searchScopes.key,
                              all: !scopeAllSearch,
                            });
                            setScopeAllSearch(!scopeAllSearch);
                          }}
                        >
                          {t("ALL")}
                        </Checkbox>
                      }
                    />
                    {workModeUser !== WORK_MODE.VIEW &&
                      workModeUser !== WORK_MODE.VIEWPROFILE && (
                        <EditOutlined
                          style={{
                            color: "#0082ba",
                            fontSize: "16px",
                            marginInlineStart: "8px",
                          }}
                          onClick={() => {
                            setWorkModeScope(WORK_MODE.CREATE);
                            setOpenScopeModal(true);
                          }}
                        />
                      )}
                  </Space.Compact>
                </div>
              </div>
              <div style={{ marginBlockEnd: "5" }}>
                <div
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "4px",
                    paddingInline: "8px",
                  }}
                >
                  <Spin spinning={getUserScopesLoading}>
                    {pageScopes?.length > 0 ? (
                      pageScopes?.map((scope) => {
                        return (
                          <ScopeTag
                            closable={
                              workModeUser !== WORK_MODE.VIEW &&
                              workModeUser !== WORK_MODE.VIEWPROFILE
                            }
                            onClose={(e: any) => {
                              setUserScopes(
                                userScopes?.filter(
                                  (s: Scope) =>
                                    scope?.key + scope?.value !==
                                    s.key + s.value
                                )
                              );
                            }}
                            scope={scope}
                          />
                        );
                      })
                    ) : (
                      <Empty
                        description={getTranslation(
                          "NO_SCOPE_IS_ATTACHED_TO_THIS_USER",
                          ""
                        )}
                      />
                    )}
                  </Spin>
                </div>
                {pageScopes?.length > 0 && (
                  <Pagination
                    defaultCurrent={1}
                    pageSize={PAGE_SIZE}
                    total={filtredUserScopes?.length}
                    style={{ textAlign: "end", marginBlock: "4px" }}
                    onChange={(page) => {
                      setScopesPage(page);
                    }}
                  />
                )}
              </div>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
    </div>
  );
};

export default UserAccountDetails;
