import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

type Copilot = {
  id: string;
  name: string;
  uiConfiguration: any;
};
const initialState: Array<Copilot> = [];

export const userCopilotSlice = createSlice({
  name: "userCopilots",
  initialState,
  reducers: {
    addUserCopilot: (state, action: PayloadAction<Copilot>) => {
      state.push(action.payload);
    },
    setUserCopilot: (state, action: PayloadAction<Array<Copilot>>) => {
      return action.payload;
    },
    updateUserCopilot: (state, action: PayloadAction<Copilot>) => {
      const { id, name } = action.payload;
      const existingCopilot = state.find((copilot) => copilot.id === id);
      if (existingCopilot) {
        console.log(action.payload.uiConfiguration?.iconName);
        existingCopilot.name = name;
        existingCopilot["uiConfiguration"].iconName =
          action.payload?.uiConfiguration?.iconName;
      }
    },
  },
});
export const { setUserCopilot, addUserCopilot, updateUserCopilot } =
  userCopilotSlice.actions;

export const UserCopilotSelector = (state: RootState) =>
  state.userCopilotReducer;

export default userCopilotSlice.reducer;
