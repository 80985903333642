import React from "react";
import { Card } from "antd";
import ReactMarkdown from "react-markdown";

interface SetupInstructionsProps {
  content: string;
  role: string;
}

const SetupInstructions: React.FC<SetupInstructionsProps> = ({
  content,
  role,
}) => {
  // Split the content into paragraphs based on new lines

  return (
    <Card
      style={
        role === "bot"
          ? {
              marginBlock: "5px",
              marginInlineStart: "20px",
              marginInlineEnd: "15px",
            }
          : { marginInlineEnd: "15px" }
      }
    >
      <div className="markdown-content">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </Card>
  );
};

export default SetupInstructions;
