import { useContext, useEffect, useState } from "react";
import { Form, Input, Button, Space, Row, Col, Select, message } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { WORK_MODE } from "../../../../../../constants/workModes";
import { ruleActionOptions } from "../components/options";
import ActionExpressionForm from "./ActionExpressionForm";
import { stringFormulaComponent } from "./ActionStringFormula";
import { MainContext } from "../../../../../../utils/context";
import { getTranslation } from "../../../../../../utils/transaltion";
import { AUTOMATION_TYPES } from "../../../../../../constants/automationType";
import { PERMISSIONS_LIST } from "../../../../../../constants/permissions";
import { isPermission } from "../../../../../../utils/permissionsFunctions";
import { RootState } from "../../../../../../redux/store/store";
import { commentPattern } from "../../../../../../constants/regExpValidation";
import { ACTION_REASONS } from "../../../../../../constants/reasons";

export interface RuleField {
  operator: string;
  field?: string;
  value: string | RuleField[];
}

export function ActionAddAutomationModal(props: any) {
  const { t } = useTranslation();
  const globalContext = useContext(MainContext);
  const orga = globalContext?.context?.orga;
  const {
    closeAutomationModal,
    actionAutomations,
    setActionAutomations,
    automationRecord,
    workModeAutomation,
    setIsAutoModalDirty,
    isAutoModalDirty,
    actionsReasons,
    dataNeeded,
    automationtype,
  } = props;
  /** current user permissions from redux */
  const permissionsList = useSelector(
    (state: RootState) => state.permissionReducer
  );
  /* Declarations */
  const ruleExprInitialState =
    workModeAutomation === WORK_MODE.CREATE
      ? {
          operator: "",
          field: "",
          value: "",
        }
      : JSON.parse(JSON.stringify(automationRecord?.rules));
  const [rulesExpr, setRulesExpr] = useState<RuleField>(ruleExprInitialState);
  const [autoAction, setAutoAction] = useState(automationRecord?.action);
  const [isOtherReason, setIsOtherReason] = useState(
    workModeAutomation === WORK_MODE.CREATE
      ? false
      : actionsReasons?.find(
          (item: any) => item?.id === automationRecord?.reason
        )?.name === ACTION_REASONS.OTHER
  );
  /* Form */
  const [form_automation] = Form.useForm();
  /* Form initial values */
  const initialValues =
    workModeAutomation === WORK_MODE.CREATE
      ? {
          rule_description: "",
          rule_action: "",
          rule_reason: "",
          rule_reason_comment: "",
        }
      : {
          rule_description: automationRecord?.name,
          rule_action: automationRecord?.action,
          rule_reason: automationRecord?.reason,
          rule_reason_comment: automationRecord?.reason_comment,
        };

  /* Functions */
  /* Track rule changes */
  useEffect(() => {
    console.log(dataNeeded);
    if (
      !isAutoModalDirty &&
      JSON.stringify(ruleExprInitialState) !== JSON.stringify(rulesExpr)
    ) {
      setIsAutoModalDirty(true);
    }
  }, [rulesExpr]);
  /* Onchange for Select Action */
  const onChangeAutoAction = (value: any) => {
    !isAutoModalDirty && setIsAutoModalDirty(true);
    setAutoAction(value);
  };
  /* Handle Create Update Automation */
  const onFinish = (values: any) => {
    form_automation
      .validateFields()
      .then(() => {
        const newRule = {
          key: Date.now(), // key needed in sortable table
          name: values.rule_description,
          action: values.rule_action,
          reason: values.rule_action === "REJECT" ? values.rule_reason : null,
          reason_comment: isOtherReason ? values.rule_reason_comment : null,
          rules: rulesExpr,
        };
        if (workModeAutomation === WORK_MODE.CREATE) {
          setActionAutomations([...actionAutomations, newRule]);
        } else {
          setActionAutomations(
            actionAutomations?.map((r: any) => {
              return r.key === automationRecord?.key ? newRule : r;
            })
          );
        }
        closeAutomationModal();
      })
      .catch((err) => {
        message.warning(t("errors:REQUIRED_FIELDS"));
      });
  };

  return (
    <Form
      form={form_automation}
      name="dynamic_form_item"
      {...{ labelCol: { style: { width: "90px" } } }}
      labelAlign="left"
      labelWrap
      wrapperCol={{ flex: 1 }}
      style={{ marginInline: "20px" }}
      initialValues={initialValues}
    >
      <Row>
        {/* Action Name */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={t("DESCRIPTION")}
            name="rule_description"
            rules={[
              {
                required: true,
                message: t("errors:REQUIRED", { label: t("DESCRIPTION") }),
              },
            ]}
          >
            <Input
              disabled={workModeAutomation === WORK_MODE.VIEW}
              onChange={(value) => {
                initialValues?.rule_description !== value &&
                  setIsAutoModalDirty(true);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        {/* Action */}
        <Col xs={12} sm={12} md={10} lg={10} xl={8}>
          <Form.Item
            label={t("ACTION")}
            name="rule_action"
            rules={[
              {
                required: true,
                message: t("errors:REQUIRED", {
                  label: t("labels:ACTION"),
                }),
              },
            ]}
          >
            <Select
              options={ruleActionOptions}
              disabled={workModeAutomation === WORK_MODE.VIEW}
              onChange={(value) => {
                onChangeAutoAction(value);
              }}
              value={autoAction}
              style={{ width: "95%" }}
            />
          </Form.Item>
        </Col>
        {autoAction === "REJECT" && (
          <Col xs={24} sm={24} md={14} lg={14} xl={16}>
            <Form.Item
              label={t("REASON")}
              name="rule_reason"
              rules={[
                {
                  required: true,
                  message: t("errors:REQUIRED", { label: t("REASON") }),
                },
              ]}
            >
              <Select
                showSearch
                disabled={workModeAutomation === WORK_MODE.VIEW}
                onChange={(value) => {
                  setIsOtherReason(
                    actionsReasons?.find((item: any) => item?.id === value)
                      ?.name === ACTION_REASONS.OTHER
                  );
                  initialValues.rule_reason !== value &&
                    setIsAutoModalDirty(true);
                }}
              >
                {actionsReasons
                  ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
                  ?.map((item: any) => (
                    <Select.Option
                      key={item?.id}
                      value={item?.id}
                      is_system={item?.is_system}
                    >
                      {automationtype === AUTOMATION_TYPES.ACTION
                        ? getTranslation(item?.name, "data")
                        : automationtype === AUTOMATION_TYPES.EXECUTION &&
                          getTranslation(item?.name, "data")}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {autoAction === "REJECT" && isOtherReason && (
          <Col xs={24} sm={24} md={14} lg={14} xl={16}>
            <Form.Item
              label={t("COMMENT")}
              name="rule_reason_comment"
              rules={[
                {
                  required: true,
                  message: t("errors:REQUIRED", { label: t("COMMENT") }),
                },
                {
                  pattern: commentPattern,
                  message: t("errors:INVALID", { label: t("COMMENT") }),
                },
              ]}
            >
              <Input
                type={"string"}
                disabled={workModeAutomation === WORK_MODE.VIEW}
                onChange={(value) =>
                  initialValues.rule_reason_comment !== value &&
                  setIsAutoModalDirty(true)
                }
              ></Input>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row>
        {/* Action rule formula */}
        <Form.Item label={t("FORMULA")}>
          {/* Convert rule Object to readable formula */}
          <>
            {Object.keys(dataNeeded).length > 0 ? (
              stringFormulaComponent(rulesExpr, dataNeeded, orga)
            ) : (
              <span style={{ color: "red" }}>
                {t("errors:NO_DATA_NEEDED_FOUND")}
              </span>
            )}
          </>
        </Form.Item>
      </Row>
      {workModeAutomation !== WORK_MODE.VIEW && (
        <>
          {/* Expressions */}
          <ActionExpressionForm
            data={rulesExpr}
            id={""}
            rulesExpr={rulesExpr}
            setRulesExpr={setRulesExpr}
            dataNeeded={dataNeeded}
            formAutomation={form_automation}
          />
          {isPermission(permissionsList, PERMISSIONS_LIST.ORG_ADMIN) && (
            <Row>
              <Col offset={20} style={{ marginTop: "20px" }}>
                <Form.Item>
                  <Space>
                    <Button
                      onClick={() => {
                        closeAutomationModal();
                      }}
                    >
                      {t("CANCEL")}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() =>
                        onFinish(form_automation.getFieldsValue(true))
                      }
                    >
                      {t("SAVE")}
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      )}
    </Form>
  );
}
